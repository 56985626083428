import React from "react";
import Item from "../components/Item";

class Items extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/v1/library/default/items", {
      method: 'GET',
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((body) => {
        if (body) {
          this.setState({ items: body.items });
        }
      });
  }

  render() {
    let items = this.state.items.map((item, index) => {
      return <Item key={index} item={item} />;
      // return <div key={index}>{item.item_name}</div>;
    });

    return (
      <div className="wholePage" id="container">
        List of items:
        <div className="items-container">
          {items}
        </div>
      </div>
    );
  }
}

export default Items;
