import React, { Component } from 'react';
import CampaignForm from './CampaignForm';
import Loader from '../components/Loader.js';

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
      samples: [],
      msg: false,
      popUp: false,
      loading: true
    };
    this.displayCampaigns = this.displayCampaigns.bind(this);
    this.handleCampaignClick = this.handleCampaignClick.bind(this);
    this.handleCreateCampaignClick = this.handleCreateCampaignClick.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
  }

  componentDidMount() {
    this.displayCampaigns();
  }

  displayCampaigns() {
    Promise.all([
      fetch('/api/v1/campaigns', {credentials: 'same-origin', method: 'GET', headers: { 'Content-Type':'application/json'}}),
      fetch('/api/v1/campaign_samples', {credentials: 'same-origin', method: 'GET', headers: { 'Content-Type':'application/json'}})
    ])
    .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    .then(([body1, body2]) => this.setState({
      campaigns: body1.campaigns,
      msg: body1.msg,
      samples: body2.samples,
      loading: false
    }));
  }

  handleCampaignClick(e, id) {
    e.preventDefault();
    this.props.history.push(`/campaigns/${String(id)}`);
  }

  handleCreateCampaignClick() {
    this.setState({ popUp: true });
    // this.props.history.push("/campaigns/create");
  }

  closePopUp() {
    this.setState({ popUp: false });
  }

  render() {
    let campaigns;
    let campaignCreateButton;
    if (!this.props.currentUser) {
      campaigns = <div className="campaigns-un-full-access">{this.state.msg}</div>;
    } else {
      campaignCreateButton =  <div className='campaigns-create-container' onClick={this.handleCreateCampaignClick}>
                                <div className='campaigns-create-button'>
                                  Create a Campaign
                                </div>
                              </div>
      if (this.state.campaigns.length === 0) {
        campaigns = <div className="campaigns-un-full-access">{this.state.msg}</div>;
      } else {
        campaigns = this.state.campaigns.map((campaign, index) => {
          // return  <div key={index}>
          //           <Link to={"/campaigns/" + campaign.id}>
          //             {campaign.name}
          //           </Link>
          //         </div>
          let image;
          if (campaign.photo) {
            image = <div className='campaigns-square-personal'>
                      <img className="campaigns-image-personal" src={campaign.photo} alt="campaign" />
                    </div>
          } else {
            image =   <div className='campaigns-square-personal'>
                        <div className='campaigns-square-symbol'>
                          {campaign.name.toUpperCase()[0]}
                        </div>
                      </div>
          }

          return  <div className='campaigns-square-container-personal' onClick={((e) => this.handleCampaignClick(e, campaign.id))} key={index}>
                    {image}
                    <div className='campaigns-square-title-personal'>
                      {campaign.name}
                    </div>
                  </div>

          })
      }
    }

    let samples = this.state.samples.map((sample, index) => {
      // return  <div key={index}>
      //           <Link to={"/campaigns/" + sample.id}>
      //             {sample.name}
      //           </Link>
      //         </div>

      let image;
      if (sample.photo) {
        image = <div className='campaigns-square'>
                  <img className="campaigns-image-personal" src={sample.photo} alt="campaign" />
                </div>
      } else {
        image =   <div className='campaigns-square'>
                    <div className='campaigns-square-symbol'>
                      {sample.name.toUpperCase()[0]}
                    </div>
                  </div>
      }


      return  <div className='campaigns-square-container' onClick={((e) => this.handleCampaignClick(e, sample.id))} key={index}>
                {image}
                <div className='campaigns-square-title'>
                  {sample.name}
                </div>
              </div>
    })

    let campaignForm = this.state.popUp ?
      <CampaignForm
        closePopUp={this.closePopUp}
        pop={this.props.pop}
      /> :
      null;

    if (this.state.loading !== false) return <Loader/>;
    const loader = this.state.loading ? <i className="fa fa-cog fa-spin" /> : null;

    return(
      <div className="wholePage" id="container">
        <div className="campaigns-container">
          <div className='campaigns-list-container campaigns-samples-list'>
            <div className='campaigns-squares-title'>
              Check out how other public campaigns are doing:
            </div>
            <div className='campaigns-squares-container'>
              {samples}
            </div>
          </div>
          <div className='campaigns-list-container campaigns-personal-list'>
            <div className='campaigns-squares-title-personal'>
              Your Campaigns:
            </div>
            <div className='campaigns-squares-container-personal'>
              {campaigns}
            </div>
            {campaignCreateButton}
          </div>
        </div>
        {campaignForm}
        {loader}
      </div>
    )
  }
}

export default Campaigns
