export const alignments = [
  {"name": "Good", "value": 0 },
  {"name": "Neutral", "value": 1 },
  {"name": "Evil", "value": 2 },
  {"name": "--Custom--", "value": -1}
];
export const volatilities = [
  {"name": "Lawful", "value": 0 },
  {"name": "Neutral", "value": 1 },
  {"name": "Chaotic", "value": 2 },
  {"name": "--Custom--", "value": -1}
];
export const sizes = [
  {"name": "Tiny", "space":"2.5 x 2.5 ft.", "value": 0 },
  {"name": "Small", "space":"5 x 5 ft.", "value": 1 },
  {"name": "Medium", "space":"5 x 5 ft.", "value": 2 },
  {"name": "Large", "space":"10 x 10 ft.", "value": 3 },
  {"name": "Huge", "space":"15 x 15 ft.", "value": 4 },
  {"name": "Gargantuan", "space":"20 x 20 ft. or larger", "value": 5 },
  {"name": "--Custom--", "space":"", "value": -1}
];
