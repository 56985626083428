import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader.js';
import BioPrompt from "./BioPrompt";
import RacePrompt from "./RacePrompt";
import ClassPrompt from "./ClassPrompt";
import BackgroundPrompt from "./BackgroundPrompt";
import QuikStats from "../QuikStats";
import SectionsMenu from "./SectionsMenu";
import ConfirmationPopup from '../../components/ConfirmationPopup';
import CharPromptDetailPop from "./CharPromptDetailPop";

// import check from "../../../icons/check.png";
// import exclamation from "../../../icons/exclamation.png";

class CharPromptMenu extends Component {

  constructor(props) {
    super(props);
    this.state = this.initialState;
    // this.state = {
    //   initiatedDestination: null,
    //   selectedSection: null,
    //   sections: [
    //     'bio',
    //     'race',
    //     'class',
    //     'background'
    //   ],
    //   displayedCharacter: null,
    //   characterSelections: [],
    //   quikStatsPayloadRace: {},
    //   quikStatsPayloadClass: {},
    //   quikStatsPayloadBackground: {},
    //   quikStatsPayloadBio: {},
    //   popUp: false
    // };
    this.togglePopUp = this.togglePopUp.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.deleteCharacter = this.deleteCharacter.bind(this);
    this.publishCharacter = this.publishCharacter.bind(this);
    this.getLatestSavedCharacter = this.getLatestSavedCharacter.bind(this);
    this.setRequestedPrompt = this.setRequestedPrompt.bind(this);
    this.toRequestedPrompt = this.toRequestedPrompt.bind(this);
    this.lockSectionIn = this.lockSectionIn.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.throwError = this.throwError.bind(this);
    this.updateUpperHpIncrease = this.updateUpperHpIncrease.bind(this);
    this.updateQuikStatsBio = this.updateQuikStatsBio.bind(this);
    this.updateQuikStatsRace = this.updateQuikStatsRace.bind(this);
    this.updateQuikStatsClass = this.updateQuikStatsClass.bind(this);
    this.updateQuikStatsBackground = this.updateQuikStatsBackground.bind(this);
    this.resetBuilder = this.resetBuilder.bind(this);
    // this.reRouteToCampaignChar = this.reRouteToCampaignChar.bind(this);
    this.handleLevelUpClick = this.handleLevelUpClick.bind(this);
    this.onReadMoreClick = this.onReadMoreClick.bind(this);
    this.closeDetailPop = this.closeDetailPop.bind(this);
  }

  get initialState() {
    return {
      initiatedDestination: null,
      selectedSection: null,
      sections: [
        'bio',
        'race',
        'class',
        'background'
      ],
      hpIncrease: 0,
      displayedCharacter: null,
      characterSelections: [],
      quikStatsPayloadRace: {},
      quikStatsPayloadClass: {},
      quikStatsPayloadBackground: {},
      quikStatsPayloadBio: {},
      popUp: false,
      popUpType: null,
      isLevelingUp: false,
      detailsRequested: null,
      isDetailPoppedUp: false,
      loading: true,
    };
  }

  resetBuilder(loading=true) {
    if (loading) {
      this.setState(this.initialState);
    } else {
      // this.setState((prevState) => {
      //   let state = Object.assign({}, this.initialState);
      //   state.loading = false;
      //   return { state };
      // });
      this.setState({
        initiatedDestination: null,
        selectedSection: null,
        sections: [
          'bio',
          'race',
          'class',
          'background'
        ],
        hpIncrease: 0,
        displayedCharacter: null,
        characterSelections: [],
        quikStatsPayloadRace: {},
        quikStatsPayloadClass: {},
        quikStatsPayloadBackground: {},
        quikStatsPayloadBio: {},
        popUp: false,
        popUpType: null,
        isLevelingUp: false,
        detailsRequested: null,
        isDetailPoppedUp: false,
        loading: false
      });
    }
  }

  componentDidMount() {
    // TODO make check for cached character prior to making any db call -- needs caching
    this.getLatestSavedCharacter();
  }

  getLatestSavedCharacter() {
    let url;
    if (this.props.match.params.id) {
      url = `/api/v1/auto/char_sheet_in_progress/${this.props.match.params.id}`;
    } else {
      url = '/api/v1/auto/char_sheet_in_progress';
    }

    fetch(url, {
      credentials: 'same-origin',
      method: 'GET',
      headers: { 'Content-Type':'application/json'}
    })
    .then((response) => response.json())
    .then((body) => {
      if (body) {
        if(body.status === true) {
          this.setState((prevState) => {
            return {
              displayedCharacter: body.character,
              selectedSection: null,
              quikStatsPayloadRace: {},
              quikStatsPayloadClass: {},
              quikStatsPayloadBackground: {},
              quikStatsPayloadBio: {},
              characterSelections: [],
              hpIncrease: 0,
              isLevelingUp:
                body.character &&
                Number.isInteger(body.character.level) &&
                body.character.level > 1
                ? true
                : false,
              loading: false,
            };
          });
        } else {
          this.props.history.push("/error_authorization");
        }
      }
    });
  }

  lockSectionIn(payload, section, selections) {
    // localStorage.setItem('charSheetSelections', selections);
    // if (section === 'bio') {
    //   localStorage.setItem('payloadBio', payload);
    // } else if (section === 'race') {
    //   localStorage.setItem('payloadRace', payload);
    // } else if (section === 'class') {
    //   localStorage.setItem('payloadClass', payload);
    // } else if (section === 'background') {
    //   localStorage.setItem('payloadBackground', payload);
    // }
    // also try to update character with latest selections

// may not need this anymore
    // this.setState((prevState) => {
    //   return {
    //     selectedSection: null,
    //     quikStatsPayloadRace: {},
    //     quikStatsPayloadClass: {},
    //     quikStatsPayloadBackground: {},
    //     quikStatsPayloadBio: {},
    //     characterSelections: []
    //   };
    // });
    this.getLatestSavedCharacter();
  }

  handleGoBack(e) {
    e.preventDefault();
    this.getLatestSavedCharacter();
  }

  throwError() {
    console.log("error saving CharSheet");
  }

  // purely for cosmetic effect
  setRequestedPrompt(e,url) {
    e.preventDefault();
    this.setState({
      initiatedDestination: url
    });
  }
  // match the setRequestedPrompt and remove old displayed stats pertaining
  // to section meant to overwrite
  toRequestedPrompt(e,url) {
    e.preventDefault();
    if (url === this.state.initiatedDestination) {
      if (url === 'class' && this.state.isLevelingUp) {
        this.handleLevelUpClick()
      } else {
        let formPayload = new FormData();
        formPayload.append('menuSection', url);
        if (this.state.displayedCharacter) {
          formPayload.append('character_id', this.state.displayedCharacter.id);
        }
        fetch('/api/v1/auto/character/update', {
          method: 'POST',
          body: formPayload,
          credentials: 'same-origin'
        })
        .then((response) => response.json())
        .then((body) => {
          if (body) {
            this.setState({
              displayedCharacter: body.character,
              selectedSection: url
            });
          }
        });
      }
    }
  }

  updateQuikStatsBio(payload) {
    this.setState((prevState) => {
      return {
        quikStatsPayloadBio: payload
      };
    });
  }

  updateQuikStatsRace(payload, selections) {
    this.setState((prevState) => {
      return {
        characterSelections: selections,
        quikStatsPayloadRace: payload
      };
    });
  }

  updateQuikStatsBackground(payload, selections) {
    if (selections !== null) {
      this.setState((prevState) => {
        return {
          characterSelections: selections,
          quikStatsPayloadBackground: payload
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          quikStatsPayloadBackground: payload
        };
      });
    }
  }

  updateQuikStatsClass(payload, selections) {
    this.setState((prevState) => {
      return {
        characterSelections: selections,
        quikStatsPayloadClass: payload
      };
    });
  }

  updateUpperHpIncrease(hpIncrease) {
    this.setState((prevState) => {
      return {
        hpIncrease: hpIncrease
      };
    });
  }

  togglePopUp(e, buttonType) {
    e.preventDefault();
    if (buttonType) {
      this.setState({
        popUp: !this.state.popUp,
        popUpType: buttonType
      });
    }
  }

  handleClose(e) {
    e.preventDefault();
    this.closePopUp();
  }

  closePopUp() {
    this.setState((prevState) => {
      return {
        popUp: false,
        popUpType: null
      };
    });
  }

  handleDelete(e, value) {
    e.preventDefault();
    this.deleteCharacter();
    this.closePopUp();
  }

  handlePublish(e, value) {
    e.preventDefault();
    this.publishCharacter();
    this.closePopUp();
  }

  deleteCharacter() {
    this.setState((prevState) => {
      return { loading: true };
    });
    let endpoint = this.props.match.params.id ? `/${this.props.match.params.id}` : "";
    fetch(`/api/v1/auto/character${endpoint}`, {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.props.history.push("/character/creation");
        this.resetBuilder(false);
      }
    }));
  }

  publishCharacter() {
    fetch("/api/v1/auto/character", {
      credentials: 'same-origin',
      method: 'POST',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.props.history.push("/characters");
      }
    }));
  }

  // reRouteToCampaignChar() {
  //   const campaignId = localStorage.getItem('campaignId');
  //   this.props.history.push(`/campaigns/${campaignId}`);
  // }

  handleLevelUpClick(e=null) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      isLevelingUp: true,
      selectedSection: 'class'
    });
  }


  onReadMoreClick(e, type, power) {
    e.stopPropagation();
    if (type) {
      let details = {'detailType': type, 'info': power}
      this.setState({
        detailsRequested: details,
        isDetailPoppedUp: true,
      });
    }
  }

  closeDetailPop(e) {
    e.preventDefault();
    this.setState({
      detailsRequested: null,
      isDetailPoppedUp: false,
    });
  }

  render() {
    let renderedInfo;
    // if (localStorage.getItem('toChar') !== 'true') {
      if (this.state.selectedSection === 'bio') {
        renderedInfo = <BioPrompt
                        pop={this.props.pop}
                        lockSectionIn={this.lockSectionIn}
                        throwError={this.throwError}
                        handleGoBack={this.handleGoBack}
                        characterSelections={this.state.displayedCharacter ? this.state.displayedCharacter : null}
                        updateQuikStatPayload={this.updateQuikStatsBio}
                        quikStatsPayload={this.state.quikStatsPayloadBio}
                      />
      }
      else if (this.state.selectedSection === 'race') {
        renderedInfo = <RacePrompt
                        pop={this.props.pop}
                        lockSectionIn={this.lockSectionIn}
                        throwError={this.throwError}
                        handleGoBack={this.handleGoBack}
                        characterSelections={this.state.displayedCharacter ? this.state.displayedCharacter : null}
                        quikStatsPayload={this.state.quikStatsPayloadRace}
                        updateQuikStatPayload={this.updateQuikStatsRace}
                        onReadMoreClick={this.onReadMoreClick}
                        closeDetailPop={this.closeDetailPop}
                      />
      }
      else if (this.state.selectedSection === 'class') {
        renderedInfo = <ClassPrompt
                        pop={this.props.pop}
                        lockSectionIn={this.lockSectionIn}
                        isLevelingUp={this.state.isLevelingUp}
                        throwError={this.throwError}
                        handleGoBack={this.handleGoBack}
                        updateQuikStatPayload={this.updateQuikStatsClass}
                        updateUpperHpIncrease={this.updateUpperHpIncrease}
                        quikStatsPayload={this.state.quikStatsPayloadClass}
                        characterSelections={this.state.displayedCharacter ? this.state.displayedCharacter : null}
                        onReadMoreClick={this.onReadMoreClick}
                        closeDetailPop={this.closeDetailPop}
                      />
      }
      else if (this.state.selectedSection === 'background') {
        renderedInfo =  <BackgroundPrompt
                          pop={this.props.pop}
                          lockSectionIn={this.lockSectionIn}
                          throwError={this.throwError}
                          handleGoBack={this.handleGoBack}
                          updateQuikStatPayload={this.updateQuikStatsBackground}
                          quikStatsPayload={this.state.quikStatsPayloadBackground}
                          characterSelections={this.state.displayedCharacter ? this.state.displayedCharacter : null}
                          onReadMoreClick={this.onReadMoreClick}
                          closeDetailPop={this.closeDetailPop}
                        />
      }
      else {
        let isFinished = false;
        if (
          (this.state.displayedCharacter &&
          this.state.displayedCharacter.bio_done &&
          this.state.displayedCharacter.auto_races_done &&
          this.state.displayedCharacter.auto_classes_done &&
          this.state.displayedCharacter.auto_backgrounds_done) ||
          (this.state.displayedCharacter &&
          this.state.displayedCharacter.is_published)
        ) {
          isFinished = true;
        }
        let isDeletable = this.state.displayedCharacter ? true : false;
        renderedInfo =  <SectionsMenu
                          isNew={!this.props.match.params.id}
                          currentUser={this.props.currentUser}
                          setRequestedPrompt={this.setRequestedPrompt}
                          toRequestedPrompt={this.toRequestedPrompt}
                          handleLevelUpClick={this.handleLevelUpClick}
                          sections={this.state.sections}
                          character={this.state.displayedCharacter}
                          triggerPopUp={this.togglePopUp}
                          isFinished={isFinished}
                          isDeletable={isDeletable}
                          isLevelingUp={this.state.isLevelingUp}
                        />
      }
    // } else if (
    //   this.state.displayedCharacter &&
    //   localStorage.getItem('toChar') === 'true'
    // ) {
    //   renderedInfo = <ClassPrompt
    //                   lockSectionIn={this.lockSectionIn}
    //                   isLevelingUp={true}
    //                   // reRouteToCampaignChar={this.reRouteToCampaignChar}
    //                   throwError={this.throwError}
    //                   handleGoBack={this.handleGoBack}
    //                   updateQuikStatPayload={this.updateQuikStatsClass}
    //                   quikStatsPayload={this.state.quikStatsPayloadClass}
    //                   characterSelections={this.state.displayedCharacter ? this.state.displayedCharacter : null}
    //                   onReadMoreClick={this.onReadMoreClick}
    //                   closeDetailPop={this.closeDetailPop}
    //                 />
    // }

    let shownPopUp;
    if (this.state.popUpType === 'delete') {
      shownPopUp = <ConfirmationPopup
                      msg="Are you sure you want to delete your character draft?"
                      onSubmit={this.handleDelete}
                      closePopUp={this.handleClose}
                    />
    } else if (this.state.popUpType === 'publish') {
      shownPopUp = <ConfirmationPopup
                      msg="Once you finish your character, you may import it into a campaign of which you are a member."
                      onSubmit={this.handlePublish}
                      closePopUp={this.handleClose}
                    />
    }

    // let quikStats = this.state.selectedSection ?
    let quikStats = <QuikStats
                      character={this.state.displayedCharacter}
                      // characterSelections={this.state.displayedCharacter ? this.state.displayedCharacter.selections : null}
                      characterSelections={this.state.characterSelections}
                      bioPayload={this.state.quikStatsPayloadBio}
                      racePayload={this.state.quikStatsPayloadRace}
                      classPayload={this.state.quikStatsPayloadClass}
                      backgroundPayload={this.state.quikStatsPayloadBackground}
                      currentSection={this.state.selectedSection}
                      hpIncrease={this.state.hpIncrease}
                    />
      // : null;
    let detailPop = this.state.isDetailPoppedUp
      ? <CharPromptDetailPop
          closePop={this.closeDetailPop}
          details={this.state.detailsRequested}
        />
      : null;

    if (this.state.loading !== false) return <Loader/>;
    const loader = this.state.loading ? <i className="fa fa-cog fa-spin" /> : null;


    return (
      <div className="wholePage" id="container" >
        <div className="char-prompt-section-container">
          {renderedInfo}
          {quikStats}
          {this.state.popUp ? shownPopUp : null}
          {detailPop}
        </div>
        {loader}
      </div>
    );
  }
}

export default withRouter(CharPromptMenu);
