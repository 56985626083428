import React from 'react';

const CampaignLanguagePopUp = props => {

  let name, descs, script, langType, speakers;

  const dts = props.details;
  if (props.details) {
    // console.log(props.details);
    name = dts.name;
    script = dts.script;
    langType = dts.lang_type;
    speakers = dts.speakers;
    descs = dts.descs.map((desc, index) => {
      if (desc.bullet) {
        if (desc.header_text) {
          let header;
          if (desc.header_text) {
            header = <span className="story-mode-header-text-bullet">{desc.header_text}</span>
          }
          return <div key={index} className="campaign-detail-popup-desc">
                  {header}{desc.object_text}
                 </div>;
        } else {
          return  <div key={index} className="campaign-detail-popup-desc">
                    <span className="bulleted-desc">•</span>{desc.object_text}
                  </div>;
        }
      } else {
        if (desc.header_text) {
          return <div key={index} className="campaign-detail-popup-desc">
                  <div className="headlining-header-text">{desc.header_text}</div>
                  {desc.object_text}
                 </div>;
        } else {
          return <div key={index} className="campaign-detail-popup-desc">
                  {desc.object_text}
                 </div>;
        }
      }
    });
  }

  return(
    <div className='campaign-detail-popup-details-container'>
      <div className='campaign-detail-popup-section-container'>
        <div className='campaign-detail-popup-section-container-inner'>
          <div className='campaign-detail-popup-name'>
            {name}
          </div>
          <div className='campaign-detail-popup-primary-details'>
            <div className='campaign-detail-popup-detail cip-half popup-grand-text'>{script}</div>
            <div className='campaign-detail-popup-detail cip-half popup-grand-text'>{langType}</div>
            <div className='campaign-detail-popup-detail cip-half popup-sub-text'>Script</div>
            <div className='campaign-detail-popup-detail cip-half popup-sub-text'>Language Type</div>
          </div>
          <div className='campaign-detail-popup-primary-one-line-details campaign-popup-descs'>
            <div className='cdp-one-line campaign-detail-popup-desc'>{` - Spoken by ${speakers}`}</div>
          </div>
          <div className='campaign-detail-popup-primary-details campaign-popup-descs'>
            {descs}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignLanguagePopUp
