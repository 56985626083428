import React from 'react'

const CheckEmail = props => {
  return (
    <div className="wholePage" id="container">
      <div className="check-email-page-container">
        <div className="check-email-page-heading">
          Please check your email for a confirmation link.
        </div>
      </div>
    </div>
  )
}

export default CheckEmail

        //
        // <Link className="navText" to='/items'>
        //   <div className="navItem">Items</div>
        // </Link>
