import React from 'react';
import CampaignItemPopUp from './CampaignItemPopUp';
import CampaignFeaturePopUp from './CampaignFeaturePopUp';
import CampaignFeatPopUp from './CampaignFeatPopUp';
import CampaignSpellPopUp from './CampaignSpellPopUp';
import CampaignLanguagePopUp from './CampaignLanguagePopUp';
import CampaignQualityPopUp from './CampaignQualityPopUp';
import CampaignGenericPopUp from './CampaignGenericPopUp';

const CampaignCharDetailPop = props => {

  let render;
  if (props.details) {
    if (props.detailType === 'item') {
      render =  <CampaignItemPopUp
                  details={props.details}
                  pickup={props.detailedPickup}
                  handleItemChange={props.handleItemChange}
                />;
    } else if (props.detailType === 'feature') {
      render =  <CampaignFeaturePopUp
                  details={props.details}
                />;
    } else if (props.detailType === 'feat') {
      render =  <CampaignFeatPopUp
                  details={props.details}
                />;
    } else if (props.detailType === 'spell') {
      render =  <CampaignSpellPopUp
                  details={props.details}
                />;
    } else if (props.detailType === 'language') {
      render =  <CampaignLanguagePopUp
                  details={props.details}
                />;
    } else if (props.detailType === 'quality') {
      render =  <CampaignQualityPopUp
                  name={props.name}
                  details={props.details}
                />;
    } else {
      render =  <CampaignGenericPopUp
                  details={props.details}
                />;
    }
  }
  let dropButton;
  let equipButton;
  let extractButton;

  if (props.optionToDrop) {
    dropButton =  <div
                    className='pop-up-button pop-up-discard'
                    onClick={((e) => props.updateItemRequest(e, props.details, 'delete'))}
                  >
                    DISCARD
                  </div>
    if (props.details && props.details.starting_items.length > 0) {
      extractButton = <div
                      className='pop-up-button pop-up-equip'
                      onClick={((e) => props.updateItemRequest(e, props.details, 'extract'))}
                    >
                      EXTRACT
                    </div>
    }

    if (props.detailedPickup && props.detailedPickup.equipped_body) {
      equipButton = <div
                      className='pop-up-button pop-up-equip'
                      onClick={((e) => props.equipItemRequest(e, props.detailedPickup, false, 'body'))}
                    >
                      UNEQUIP
                    </div>;
    } else if (props.detailedPickup && props.detailedPickup.equipped_main) {
      equipButton = <div
                      className='pop-up-button pop-up-equip'
                      onClick={((e) => props.equipItemRequest(e, props.detailedPickup, false, 'main'))}
                    >
                      UNEQUIP
                    </div>;
    } else if (props.detailedPickup && props.detailedPickup.equipped_2ndy) {
      equipButton = <div
                      className='pop-up-button pop-up-equip'
                      onClick={((e) => props.equipItemRequest(e, props.detailedPickup, false, '2ndy'))}
                    >
                      UNEQUIP
                    </div>;
    } else if (props.details.type === 'Armor' || props.details.type === 'Weapon') {
      equipButton = <div
                      className='pop-up-button pop-up-equip'
                      onClick={((e) => props.equipItemRequest(e, props.detailedPickup, true))}
                    >
                      EQUIP
                    </div>;
    }
  }

  return(
    <div className='campaign-detail-popup'>
      <div className='campaign-detail-popup-inner'>
        {render}
        <div className='campaign-detail-popup-button-container'>
          <div
            className='pop-up-button pop-up-no'
            onClick={props.closePop}
          >
            CLOSE
          </div>
          {dropButton}
          {equipButton}
          {extractButton}
        </div>
      </div>
    </div>
  )
}

export default CampaignCharDetailPop
