import React from 'react';

const QuikAbility = props => {

  let abilities = props.values.map((value,index) => {
    return <div className="quik-ability-name" key={index}>{value}</div>;
  });

  return (
    <div className="quik-ability">
      <div className="quik-ability-title">
        <div className="quik-ability-title-name">{props.title}</div>
      </div>
      <div className="quik-ability-names">{abilities}</div>
    </div>
  );
}
// <div className={`quik-stat ${props.rounded} ${props.sizing}`}>

export default QuikAbility;
