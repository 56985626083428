import React from 'react';
import _ from 'underscore';

const CampaignFeaturePopUp = props => {
  let name, descs, prereqs, prerequisiteWrapper,renderedTables;
  if (props.details) {
    // console.log(props.details);
    name = props.details.name;
    descs = props.details.descs.map((desc, index) => {
      if (desc.bullet) {
        if (desc.header_text) {
          let header;
          if (desc.header_text) {
            header = <span className="story-mode-header-text-bullet">{desc.header_text}</span>
          }
          return <div key={index} className="campaign-detail-popup-desc">
                  {header}{desc.object_text}
                 </div>;
        } else {
          return  <div key={index} className="campaign-detail-popup-desc">
                    <span className="bulleted-desc">•</span>{desc.object_text}
                  </div>;
        }
      } else {
        if (desc.header_text) {
          return <div key={index} className="campaign-detail-popup-desc">
                  <div className="headlining-header-text">{desc.header_text}</div>
                  {desc.object_text}
                 </div>;
        } else {
          return <div key={index} className="campaign-detail-popup-desc">
                  {desc.object_text}
                 </div>;
        }
      }
    });
    if (props.details.prerequisites.length > 0) {
      prereqs = props.details.prerequisites.map((prereq, index) => {
        return <div key={index} className="campaign-detail-popup-desc">
                {`${prereq.type}: ${prereq.name}`}
               </div>;
      });
      prerequisiteWrapper = <div className='cdp-one-line campaign-detail-popup-desc campaign-detail-sub-header popup-sub-text'>
                              Prerequisites:
                              {prereqs}
                            </div>
    }
    let auxTables;
    if (props.details.tables && props.details.tables.length > 0) {
      auxTables = props.details.tables.map((table) => {
        let columns = _.sortBy(table.columns,'position');
        let widths = [];
        let headerColumns = columns.map((column, index) => {
          widths.push(column.width);
          let margin = index > 0 ? 'non-first-column' : null;
          return  <div className={`feature-table-column ${margin} ${column.width}`}>{column.title}</div>
        });
        let headerRow = <div className="feature-table-row">{headerColumns}</div>
        let tableData = table.rows.map((row, index) => {
          let rowType = index % 2 === 0 ? 'odd-row' : 'even-row';
          let value1 = row.value1 !== null ?
            <div className={`feature-table-column ${widths[0]}`}>{row.value1}</div> : null;
          let value2 = row.value2 !== null ?
            <div className={`feature-table-column non-first-column ${widths[1]}`}>{row.value2}</div> : null;
          let value3 = row.value3 !== null ?
            <div className={`feature-table-column non-first-column ${widths[2]}`}>{row.value3}</div> : null;
          let value4 = row.value4 !== null ?
            <div className={`feature-table-column non-first-column ${widths[3]}`}>{row.value4}</div> : null;
          let value5 = row.value5 !== null ?
            <div className={`feature-table-column non-first-column ${widths[4]}`}>{row.value5}</div> : null;
          let value6 = row.value6 !== null ?
            <div className={`feature-table-column non-first-column ${widths[5]}`}>{row.value6}</div> : null;
          let value7 = row.value7 !== null ?
            <div className={`feature-table-column non-first-column ${widths[6]}`}>{row.value7}</div> : null;
          return  <div className={`feature-table-row ${rowType}`}>
                    {value1}{value2}{value3}{value4}{value5}{value6}{value7}
                  </div>
        });
        return  <div className="story-mode-feature-table">
                  <div className="story-mode-feature-table-title">
                    {table.name}
                  </div>
                  {headerRow}
                  {tableData}
                </div>
      });
      renderedTables = <div className="story-mode-feature-tables">{auxTables}</div>
    }
  }

  return(
    <div className='campaign-detail-popup-details-container'>
      <div className='campaign-detail-popup-section-container'>
        <div className='campaign-detail-popup-section-container-inner'>
          <div className='campaign-detail-popup-name'>
            {name}
            {prerequisiteWrapper}
          </div>
          <div className='campaign-detail-popup-primary-details campaign-popup-descs'>
            {descs}
          </div>
          <div className='campaign-detail-popup-primary-details'>
            {renderedTables}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignFeaturePopUp
