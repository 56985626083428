import React from 'react';
import { Link } from 'react-router-dom';

const CharacterRow = props => {

  let clickability = props.campaign || !props.published ? 'is-unclickable' : '';

  let published;
  if (props.published && props.campaign) {
    published = 'Already joined';
  } else if (props.published) {
    published = 'Ready to join';
  } else {
    published = 'Still drafting';
  }
  let details = props.details;


  let importButton = props.header === false ?
    <div className='listed-character-button listed-character-cell'>
      <div
        className={`listed-character-import-button ${clickability}`}
        onClick={((e) => props.handleImportClick(e, props.id))}
      >
        <i className="fa-solid fa-file-import"></i>
      </div>
    </div> :
    <div className='listed-character-button listed-character-cell'>
      Join campaign
    </div>;

  let deleteButton = props.header === false ?
    <div className='listed-character-button listed-character-cell'>
      <div
        className='listed-character-delete-button'
        onClick={((e) => props.handleDelete(e, props.id))}
      >
        <i className="fa-solid fa-trash-can"></i>
      </div>
    </div> :
    <div className='listed-character-button listed-character-cell'>
      Delete Character
    </div>;

  let alignment = '';
  if (props.header === true) {
    alignment = 'character-row-centered';
  }

  let campaign = '';
  if (props.campaign) {
    campaign = <Link to={"/campaigns/" + props.campaign.id}>
                  {props.campaign.name}
               </Link>;
  }

  let name = '';
  if (props.name) {
    name = <Link to={`/character/creation/${props.id}`}>
            {props.name}
           </Link>;
  }

  if (props.header === true) {
    published = 'Completion Status';
    details = 'Details';
    name = 'Character';
    campaign = 'Campaign';
  }

  return (
    <div className='character-row-container'>
      <div className={`listed-character-name listed-character-cell ${alignment}`}>
        <div className='listed-character-cell-content'>{name}</div>
      </div>
      <div className={`listed-character-details listed-character-cell ${alignment}`}>
        <div className='listed-character-cell-content'>{details}</div>
      </div>
      <div className={`listed-character-campaign listed-character-cell ${alignment}`}>
        <div className='listed-character-cell-content'>{campaign}</div>
      </div>
      <div className={`listed-character-status listed-character-cell ${alignment}`}>
        <div className='listed-character-cell-content'>{published}</div>
      </div>
      {importButton}
      {deleteButton}
    </div>
  );
}

export default CharacterRow;
