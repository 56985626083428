import React, {Component} from 'react';

class EmailConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.activateUser();
  }

  async activateUser() {
    const response = await fetch(`api/v1/confirm_email/${this.props.match.params.token}`, {
          credentials: 'same-origin',
          method: 'GET',
          headers: { 'Content-Type':'application/json'}
        });
    const json = await response.json();
    if(response.status === 200) {
      this.props.pop(json.msg, json.status);
      await this.props.displayUser();
      this.props.history.push("/login");
    } else {
      this.props.pop(json.msg, json.status);
      this.props.history.push("/register");
    }
  }

  render() {
    return(
      <div className="wholePage" id="container">
        <div className="check-email-page-container">
          <div className="check-email-page-heading">
            Checking email confirmation.  If you do not redirect in 60 seconds, visit the login page and try logging in.
          </div>
        </div>
      </div>
    )
  }
}

export default EmailConfirmation
