import React from 'react';

const QuikStat = props => {

  let valueStat;
  let shownStat = "-";
  let prefix = "";
  if (props.values.length === 2) {
    if (
        props.groupedStat.prefixable &&
        (
          (props.values[0] || props.values[0] === 0) &&
          props.values[0] >= 0
        )
      ) {
      prefix = "+";
    }
    if (props.values[0] || props.values[0] === 0) {
      shownStat = prefix + (props.values[0]).toString();
    }
    const score = props.values[1]|| props.values[1] === 0 ? props.values[1] : "0";
    valueStat = <div className="quik-stat-field-value">
                  <div className="quik-stat-field-value-text">
                    {shownStat}
                  </div>
                  <div className="quik-stat-field-sub-value">
                    {score}
                  </div>
                </div>;
  } else {
    if (props.values.length === 1) {
      if (props.values[0] !== null) {
        if (
            props.groupedStat.prefixable &&
            props.values[0] >= 0
          ) {
          prefix = "+";
        }
        if (props.values[0] || props.values[0] === 0) {
          shownStat = prefix + (props.values[0]).toString();
        }
      }
    }
    valueStat = <div className="quik-stat-field-value">
                  <div className="quik-stat-field-value-text">
                    {shownStat}
                  </div>
                </div>;
  }

  return (
    <div className={`quik-stat ${props.sizing}`}>
      <div className="quik-stat-field-name">{props.groupedStat.fieldName}</div>
      {valueStat}
    </div>
  );
}
// <div className={`quik-stat ${props.rounded} ${props.sizing}`}>

export default QuikStat;
