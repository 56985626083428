import React from 'react';

const MouseUpLink = props => {


  return (
    <div
      className={`char-prompt-menu-item ${props.borderRadiusLocation}`}
      onMouseUp={((e) => props.handleMouseUp(e, props.section))}
      onMouseDown={((e) => props.handleMouseDown(e, props.section))}
    >
      {props.isDone}
      {props.sectionRender}
    </div>
  );
}

export default MouseUpLink;
