import React from 'react';
import { Link } from 'react-router-dom';


class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
    this.displayUserProfile = this.displayUserProfile.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.displayUserProfile();
  }

  displayUserProfile() {
    fetch("/api/v1/current_user", {
      method: 'GET',
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(body => {
      if (body) {
        this.setState({ user: body });
      }
    });
  }

  render() {
    let user;
    let id;
    if (this.state.user === null) {
      id = null;
    } else {
      id = this.state.user.id.toString();
    }
    if (id === this.props.match.params.id) {
      user =  <div className="profile-page">
                <div className="profile-page-wrapper">
                  <img className="userImage profile-image" src={this.state.user.photo} alt="profile" />
                  <div className="profile-info-container">
                    <div className="profile-page-info">Display Name: {this.state.user.display_name}</div>
                    <div className="profile-page-info">Username: {this.state.user.username}</div>
                    <div className="profile-page-info">Email: {this.state.user.email}</div>
                  </div>
                  <Link className="profile-page-link" to={"/edit_profile/" + this.props.match.params.id}>
                    <div className="">Edit Profile</div>
                  </Link>
                </div>
              </div>
    } else {
      user = null;
    }
    return(
      <div className="wholePage" id="container">
        {user}
      </div>
    )
  }
}

export default Home
