import React from 'react';
import { Link } from 'react-router-dom'
import Loader from './Loader';

const SignInForm = props => {

  if (props.loading !== false) return <Loader/>;
  const loader = props.loading ? <i className="fa fa-cog fa-spin" /> : null;

  return(
    <div className='signup-form-container'>
      {loader}
      <div className="signup-form-header">
        Log In
      </div>
      <form className='signup-form'>
        <div className='signup-form-inner-wrapper'>
          <div className='signup-form-fields-cluster'>
            <label className="inputField signup-form-field">
              <input
                className="field signup-form-field-inner"
                type='text'
                onChange={props.handleEmailChange}
                onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                value={props.emailContent}
                placeholder={'Email Address'}
              />
            </label>
            <label className="inputField signup-form-field">
              <input
                className="field signup-form-field-inner"
                type='password'
                onChange={props.handlePasswordChange}
                onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                value={props.passwordContent}
                placeholder={'Password'}
              />
            </label>
          </div>
          <div className="signup-form-button" onClick={props.handleSubmit} value='Submit'>
            Submit
          </div>
          <Link className="forgot-password-link" to="/password_reset">
            <div className="forgot-password-link">Forgot Password</div>
          </Link>
        </div>
      </form>
    </div>

  )
}

export default SignInForm
