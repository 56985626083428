import React from 'react';
import { Link } from 'react-router-dom'

const SignedOutNavBar = props => {
  return(
    <div className="navMeasurement right credentials">
      <Link className="navText" to="/login">
        <div className="">Log In</div>
      </Link>
      <Link className="navText" to="/register">
        <div>Sign Up</div>
      </Link>
    </div>
  )
}

export default SignedOutNavBar
