import React from "react";
import SubracePrompt from "./SubracePrompt";
import SelectionPrompt from "./SelectionPrompt";
import ProceedButtons from "./ProceedButtons";
import StoryModePopup from "./StoryModePopup";
//import objToQueryString from "../functions/functions";

// TODO, handle character size with traits
class RacePrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      archRaces: [],
      selectedArchRace: null,
      coreRaces: [],
      races: [],
      selectedCoreRace: null,
      selectedSubRace: null,
      selectedTrait: null,
      traitSelections: [],
      scrollTraits: false,
      isStoryModePoppedUp: false,
    };
    this.initializeData = this.initializeData.bind(this);
    this.showRaceDetails = this.showRaceDetails.bind(this);
    this.selectCoreRace = this.selectCoreRace.bind(this);
    this.selectSubRace = this.selectSubRace.bind(this);
    this.selectTrait = this.selectTrait.bind(this);
    this.handleSelectionClick = this.handleSelectionClick.bind(this);
    this.createForcedSelections = this.createForcedSelections.bind(this);
    this.handleSaveData = this.handleSaveData.bind(this);
    this.closePop = this.closePop.bind(this);
    this.onStoryModeClick = this.onStoryModeClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initializeData();
  }

  initializeData() {
    fetch('/api/v1/auto/character/races', {
      credentials: 'same-origin',
      method: 'GET',
      headers: { 'Content-Type':'application/json'}
    })
    .then((response) => response.json())
    .then((body) => {
      this.setState({
        archRaces: body ? body.races : null,
      });
    });
  }

  componentDidUpdate() {
    if (this.state.scrollTraits) {
      var elem = document.getElementById('auto-coreraces');
      elem.scrollTop = elem.scrollHeight;
      this.setState({ scrollTraits: false });
    }
  }

  // return all trace-related data in this API call - including all trait details
  showRaceDetails(e, archrace) {
    e.preventDefault();

    // const promise1 = new Promise((resolve, reject) => {
    //   resolve('Success!');
    // });
    // promise1.then((value) => {
    //   console.log(value);
    //   // expected output: "Success!"
    // });

    fetch(`/api/v1/auto/character/race/${archrace.id}`, {
      method: 'GET',
      credentials: "same-origin",
    })
    .then((response) => response.json())
    .then((body) => {
      if (body) {
        // do similar stuff to character - re-set char sheet in state only
        // it will become a new culmination
        let forced;
        if (body.core_races.length > 1) {
          this.setState((prevState) => {
            return {
              selectedCoreRace: null,
              selectedSubRace: null,
              coreRaces: body.core_races,
              subRaces: body.subraces,
              selectedArchRace: archrace.id,
              selectedTrait: null,
              traitSelections: []
            };
          });
        } else if (body.core_races.length === 1) {
          forced = this.createForcedSelections(body.core_races[0]);
          this.setState((prevState) => {
            return {
              selectedCoreRace: body.core_races[0],
              selectedSubRace: null,
              coreRaces: body.core_races,
              subRaces: body.subraces,
              selectedArchRace: archrace.id,
              selectedTrait: null,
              traitSelections: forced
            };
          });
        }

        let freshlyClickedRace = Object.assign({}, this.props.quikStatsPayload);
        if (body.core_races.length === 1) {
          freshlyClickedRace.race = archrace.name;
          freshlyClickedRace.subrace = null;
          freshlyClickedRace.traits = [];
          body.core_races[0].traits.forEach((trait) => {
            freshlyClickedRace.traits.push(trait);
          });
          this.props.updateQuikStatPayload(freshlyClickedRace, forced);
        } else {
          this.props.updateQuikStatPayload({}, []);
        }

        var elem1 = document.getElementById('auto-coreraces');
        elem1.scrollTop = 0;
      }
    });
  }

  selectCoreRace(e, selectedCoreRace) {
    e.preventDefault();
    const coreRace = this.state.coreRaces.filter((race) => race.id === selectedCoreRace.id)[0];
    const forced = this.createForcedSelections(coreRace);
    this.setState((prevState) => {
      return {
        selectedCoreRace: coreRace,
        selectedTrait: null,
        traitSelections: forced
      };
    });
    var freshlyClickedRace = Object.assign({}, this.props.quikStatsPayload);
    freshlyClickedRace.race = coreRace.name;
    coreRace.traits.forEach((trait) => {
      if (!freshlyClickedRace.traits) {
        freshlyClickedRace.traits = [trait];
      } else {
        freshlyClickedRace.traits.push(trait);
      }
    });
    this.props.updateQuikStatPayload(freshlyClickedRace, forced);
  }

  createForcedSelections(race, currentlyForced = []) {
    let forced = currentlyForced;
    race.traits.forEach((trait) => {
      trait.selections.forEach((selection) => {
        if (selection.force_added) {
          const selectionClone = {
            "feature_id": selection.feature_id,
            "background_feature_id": selection.background_feature_id,
            "force_added": selection.force_added,
            "id": selection.id,
            "increment_type_id": selection.increment_type_id,
            "increment_value": selection.increment_value,
            "is_permutation": selection.is_permutation,
            "num_selections": selection.num_selections,
            "optional_selection": selection.optional_selection,
            "source": selection.source,
            "target": selection.target,
            "trait_id": selection.trait_id
          };
          if (selection.pools.length > 0) {
            selection.pools.forEach((pool) => {
              forced.push({
                "name": pool.name,
                "id": pool.id,
                "value": "",
                "trait_id": selectionClone.trait_id,
                "quantity": pool.quantity ? pool.quantity : null,
                "sourceSection": "races",
                "selection": selectionClone,
                "desc": pool.descs
              });
            });
          } else {
            forced.push({
              "name": null,
              "id": null,
              "quantity": null,
              "value": null,
              "sourceSection": "races",
              "trait_id": selectionClone.trait_id,
              "selection": selectionClone,
              "desc": null
            });
          }
        }
      });
    });
    return forced;
  }

  selectSubRace(obj) {
    var freshlyClickedRace = Object.assign({}, this.props.quikStatsPayload);
    let traits = [];
    let traitSelections = this.state.traitSelections.filter(pool =>
        !pool.selection.force_added
    );
    let currentlyForced = this.state.traitSelections.filter(pool =>
        pool.selection.force_added
    );
    // this gets information from old selected subrace and will overwrite it
    if (this.state.selectedSubRace) {
      let traitIds = this.state.selectedSubRace.traits.map((trait) => {
        return trait.id;
      });
      let forcedCoreTraitIds = [];
      this.state.selectedCoreRace.traits.forEach((trait) => {
        if (trait.selections.some(selection =>
          selection.force_added
        )) {
          forcedCoreTraitIds.push(trait.id);
        }
      });
      currentlyForced = this.state.traitSelections.filter(pool =>
        pool.selection.force_added && (
          !traitIds.includes(pool.selection.trait_id) ||
          forcedCoreTraitIds.includes(pool.selection.trait_id)
        )
      );
      traitSelections = this.state.traitSelections.filter(pool =>
        !pool.selection.force_added &&
        !traitIds.includes(pool.selection.trait_id)
      );
      traits = freshlyClickedRace.traits.filter(trait =>
        !traitIds.includes(trait.id)
      );
    } else {
      traits = freshlyClickedRace.traits;
    }
    let newSelections;
    let selectedSubRace;
    if (
      this.state.selectedSubRace &&
      this.state.selectedSubRace.id === obj.id
    ) {
      newSelections = traitSelections.concat(currentlyForced);
      selectedSubRace = null;
      freshlyClickedRace.traits = traits;
    } else {
      selectedSubRace = this.state.subRaces.filter((race) => race.id === obj.id)[0];
      const forced = this.createForcedSelections(selectedSubRace,currentlyForced);
      newSelections = traitSelections.concat(forced);
      selectedSubRace.traits.forEach((trait) => {
        traits.push(trait)
      });
      freshlyClickedRace.traits = traits;
    }
    this.setState((prevState) => {
      return {
        selectedSubRace: selectedSubRace,
        traitSelections: newSelections,
        scrollTraits: true,
      };
    });
    return [selectedSubRace, newSelections, freshlyClickedRace];
  }

  selectTrait(e, trait) {
    e.preventDefault();
    if (trait.is_disabled === true) {
      return;
    }
    this.setState ({
      selectedTrait: trait,
    });
    var elem2 = document.getElementById('auto-trait-selections');
    elem2.scrollTop = 0;
  }

  closePop(e) {
    e.preventDefault();
    this.setState({
      isStoryModePoppedUp: false
    });
  }

  onStoryModeClick(e) {
    e.preventDefault();
    this.setState({
      isStoryModePoppedUp: true
    });
  }

  handleSelectionClick(e, obj) {
    e.preventDefault();
    if (obj.disabled) {
      return;
    }

    let latestPayload;
    let selectedRace;
    let selections;
    if (obj.selection.source === 'race') {
      [selectedRace, selections, latestPayload] = this.selectSubRace(obj);
    } else {
      selections = this.state.traitSelections;
      selectedRace = this.state.selectedRace;
      latestPayload = Object.assign({}, this.props.quikStatsPayload);
    }
    latestPayload.subrace = selectedRace ? selectedRace.name : selectedRace;

    // hacky, but finds out how many selections have been
    // previously made for specific trait selection
    let tally = 0;
    selections.forEach((selection) => {
      if (selection.selection.id === obj.selection.id) {
        tally += 1;
      }
    });

    let updatedSelections;
    // clicking pool already selected and yet can add another
    if (selections.some(pool =>
        pool.id === obj.id &&
        pool.selection.id === obj.selection.id &&
        pool.selection.num_selections > tally &&
        !pool.selection.is_permutation
    )) {
      updatedSelections = selections.concat(obj);

      this.setState(prevState => ({
        traitSelections: [...selections,obj]
      }));
    }
    // clicking pool already selected and at capacity in some way
    else if (selections.some(pool =>
      pool.id === obj.id &&
      pool.selection.id === obj.selection.id &&
      (
        pool.selection.num_selections <= tally ||
        pool.selection.is_permutation
      )
    )) {
      updatedSelections = selections.filter(pool =>
        pool.selection.id !== obj.selection.id ||
        pool.id !== obj.id
      );

      this.setState(prevState => ({
        traitSelections: [...selections.filter(pool =>
          pool.selection.id !== obj.selection.id ||
          pool.id !== obj.id
        )]
      }));
    }
    // clicking on an unselected pool and at capacity in some way
    else if (selections.some(pool =>
      pool.selection.id === obj.selection.id &&
      pool.selection.num_selections <= tally
    )) {
      updatedSelections = selections.filter(pool =>
        pool.selection.id !== obj.selection.id
      );
      updatedSelections = updatedSelections.concat(obj);

      this.setState(prevState => ({
        traitSelections: [...selections.filter(pool =>
          pool.selection.id !== obj.selection.id
        )]
      }));
      this.setState(prevState => ({
        traitSelections: updatedSelections
      }));
    }
    else {
      updatedSelections = selections.concat(obj);

      this.setState(prevState => ({
        traitSelections: [...selections,obj]
      }));
    }
    // console.log(updatedSelections);
    this.props.updateQuikStatPayload(latestPayload, updatedSelections);
  }

  handleSaveData(e) {
    let traits = [];
    if (this.state.selectedCoreRace) {
      traits = this.state.selectedCoreRace.traits;
    }
    if (this.state.selectedSubRace) {
      traits = traits.concat(this.state.selectedSubRace.traits);
    }
    e.preventDefault();
    const subRace = this.state.selectedSubRace ? this.state.selectedSubRace.id : null;
    let formPayload = new FormData();
    formPayload.append('menuSection', 'race');
    if (this.props.characterSelections) {
      formPayload.append('character_id', this.props.characterSelections.id);
    }
    formPayload.append('archraceId', this.state.selectedArchRace);
    formPayload.append('raceId', subRace);
    formPayload.append('menuSelections', JSON.stringify(this.state.traitSelections));
    formPayload.append('traits', JSON.stringify(traits));
    fetch('/api/v1/auto/character/process', {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if (res.status === 200) {
        this.props.lockSectionIn(
          this.state.traitSelections,
          this.props.quikStatsPayload,
          'race'
        );
      } else {
        this.props.pop(res.body.msg, res.body.status);
        this.props.throwError();
      }
    }));
  }

  render() {
    let archRaces = this.state.archRaces.map((archRace, index) => {
      const selectedIndex = this.state.selectedArchRace === archRace.id ?
        'selectableCharacterQuality selectedCharacterQuality' : 'selectableCharacterQuality';

      return <div
              className={selectedIndex}
              key={index}
              onClick={((e) => this.showRaceDetails(e, archRace))}
             >
              {archRace.name}
             </div>;
    });

    let storyModeButton;
    if (this.state.selectedArchRace !== null) {
      storyModeButton = <div
                          onClick={((e) => this.onStoryModeClick(e))}
                          className="class-prompt-story-mode-button"
                        >
                          <i className="fa-solid fa-book-open"></i>
                        </div>
    }

    let coreRaces;
    if (this.state.coreRaces.length > 1 && this.state.selectedCoreRace == null) {
      coreRaces = this.state.coreRaces.map((coreRace, index) => {
        return <div
                className='selectableCoreRace'
                onClick={((e) => this.selectCoreRace(e, coreRace))}
                key={index}
               >
                {coreRace.name}
               </div>;
        });
    } else if (this.state.coreRaces.length === 1 || this.state.selectedCoreRace != null) {
      const selectedTrait = this.state.selectedTrait ? this.state.selectedTrait.id : null;
      coreRaces = <SubracePrompt
                    coreRace={this.state.selectedCoreRace}
                    subRace={this.state.selectedSubRace}
                    handleTraitClick={this.selectTrait}
                    onReadMoreClick={this.props.onReadMoreClick}
                    selectedTrait={selectedTrait}
                    traitSelections={this.state.traitSelections}
                  />
    } else {
      coreRaces = null
    }

    let selectedCoreRace;
    if (this.state.selectedCoreRace) {
      selectedCoreRace = this.state.selectedCoreRace.name + " Traits";
    } else if (this.state.coreRaces.length > 1) {
      selectedCoreRace = "Select a Core Race";
    } else {
      selectedCoreRace = '';
    }

    let selections;
    if (
      this.state.selectedTrait != null &&
      this.state.selectedTrait.selections.length > 0
    ) {
      selections = this.state.selectedTrait.selections.map((selection, index) => {
        if (!selection.force_added) {
          return <SelectionPrompt
                    key={index}
                    expandedSpells={[]}
                    handleSelectionClick={this.handleSelectionClick}
                    onReadMoreClick={this.props.onReadMoreClick}
                    selection={selection}
                    characterSelections={this.props.characterSelections}
                    selections={this.state.traitSelections}
                    auxiliary_selection={null}
                  />
        } else {
          return null;
        }
      });
    } else {
      selections = <SelectionPrompt
                expandedSpells={[]}
                handleSelectionClick={this.handleSelectionClick}
                onReadMoreClick={this.props.onReadMoreClick}
                selection={null}
                characterSelections={[]}
                selections={[]}
                auxiliary_selection={null}
              />;
    }

    let disabled = true;
    let totalNeeded = 0;
    let totalMet = 0;
    if (this.state.selectedCoreRace) {
      this.state.selectedCoreRace.traits.forEach((trait) => {
        trait.selections.forEach((selection) => {
          if (!selection.force_added) {
            totalNeeded += 1;
            let traitSelections = this.state.traitSelections.filter(pool =>
              !selection.force_added &&
              selection.id === pool.selection.id
            );
            if (traitSelections.length === selection.num_selections) {
              totalMet += 1;
            }
          }
        });
      });
    }
    if (this.state.selectedSubRace) {
      this.state.selectedSubRace.traits.forEach((trait) => {
        trait.selections.forEach((selection) => {
          if (!selection.force_added) {
            totalNeeded += 1;
            let traitSelections = this.state.traitSelections.filter(pool =>
              !selection.force_added &&
              selection.id === pool.selection.id
            );
            if (traitSelections.length === selection.num_selections) {
              totalMet += 1;
            }
          }
        });
      });
    }
    if (totalMet === totalNeeded && this.state.selectedCoreRace) {
      disabled = false;
    }

    const proceedButtons = <ProceedButtons
      handleProceedForward={this.handleSaveData}
      handleGoBack={this.props.handleGoBack}
      isDisabled={disabled}
      proceedText={"Save and Continue"}
    />;

    let storyModePopup = this.state.isStoryModePoppedUp
      ? <StoryModePopup
          closePop={this.closePop}
          selectedArchRace={this.state.selectedArchRace}
        />
      : null;

    return (
      <div className="char-prompt-container">
        {storyModePopup}
        <div className="race-detail-header">
          <div className="auto-archrace-header">
            Choose a Race
          </div>
          <div className="auto-corerace-header">
            {selectedCoreRace}
            {storyModeButton}
          </div>
        </div>
        <div className="race-detail-container">
          <div className="auto-archraces">
            <div className="archraces-container">
              {archRaces}
            </div>
            {proceedButtons}
          </div>

          <div className="auto-coreraces" id="auto-coreraces">
            {coreRaces}
          </div>

          <div className="auto-trait-selections" id="auto-trait-selections">
            {selections}
          </div>
        </div>
      </div>
  );
  }
}
export default RacePrompt;
