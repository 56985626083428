import React from "react";
import ProceedButtons from "./ProceedButtons";
import { alignments, volatilities } from '../../constants/Constants.js';
//import objToQueryString from "../functions/functions";


class BioPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      name: "",
      faith: "",
      alignment: "",
      volatility: "",
      height: "",
      weight: "",
      age: "",
      eyes: "",
      skin: "",
      hair: "",
      strengthScore: "",
      dexterityScore: "",
      constitutionScore: "",
      wisdomScore: "",
      intelligenceScore: "",
      charismaScore: "",
      // selectedVolatility: volatilities[0].name,
      // selectedAlignment: alignments[0].name,
      onCustomAlignment: false,
      onCustomVolatility: false,
    };
    this.state = this.initialState;
    this.handleSaveData = this.handleSaveData.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleFaithChange = this.handleFaithChange.bind(this);
    this.handleAlignmentChange = this.handleAlignmentChange.bind(this);
    this.handleVolatilityChange = this.handleVolatilityChange.bind(this);
    this.handleHeightChange = this.handleHeightChange.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);
    this.handleAgeChange = this.handleAgeChange.bind(this);
    this.handleEyesChange = this.handleEyesChange.bind(this);
    this.handleSkinChange = this.handleSkinChange.bind(this);
    this.handleHairChange = this.handleHairChange.bind(this);
    this.handleStrengthScoreChange = this.handleStrengthScoreChange.bind(this);
    this.handleDexterityScoreChange = this.handleDexterityScoreChange.bind(this);
    this.handleConstitutionScoreChange = this.handleConstitutionScoreChange.bind(this);
    this.handleWisdomScoreChange = this.handleWisdomScoreChange.bind(this);
    this.handleIntelligenceScoreChange = this.handleIntelligenceScoreChange.bind(this);
    this.handleCharismaScoreChange = this.handleCharismaScoreChange.bind(this);
    this.handleDDAlignmentChange = this.handleDDAlignmentChange.bind(this);
    this.handleDDVolatilityChange = this.handleDDVolatilityChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  resetForm() {
    this.setState(this.initialState);
  }

  handleNameChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        name: val
      };
    });
    var freshlyEnteredName = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredName.characterName = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredName);
  }
  handleFaithChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        faith: val
      };
    });
    var freshlyEnteredFaith = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredFaith.faith = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredFaith);
  }
  handleAlignmentChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        alignment: val
      };
    });
    var freshlyEnteredAlignment = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredAlignment.alignment = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredAlignment);
  }
  handleVolatilityChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        volatility: val
      };
    });
    var freshlyEnteredVolatility = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredVolatility.volatility = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredVolatility);
  }
  handleHeightChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        height: val
      };
    });
    var freshlyEnteredHeight = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredHeight.height = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredHeight);
  }
  handleWeightChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        weight: val
      };
    });
    var freshlyEnteredWeight = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredWeight.weight = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredWeight);
  }
  handleAgeChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        age: val
      };
    });
    var freshlyEnteredAge = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredAge.age = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredAge);
  }
  handleEyesChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        eyes: val
      };
    });
    var freshlyEnteredAge = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredAge.eyes = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredAge);
  }
  handleSkinChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        skin: val
      };
    });
    var freshlyEnteredAge = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredAge.skin = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredAge);
  }
  handleHairChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        hair: val
      };
    });
    var freshlyEnteredAge = Object.assign({}, this.props.quikStatsPayload);
    freshlyEnteredAge.hair = event.target.value;
    this.props.updateQuikStatPayload(freshlyEnteredAge);
  }
  handleStrengthScoreChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        strengthScore: val
      };
    });
    const payloadInt = val === "" ? 0 : parseInt(val);
    if (Number.isInteger(payloadInt)) {
      var freshlyEnteredStrengthScore = Object.assign({}, this.props.quikStatsPayload);
      freshlyEnteredStrengthScore.strengthScore = parseInt(val);
      this.props.updateQuikStatPayload(freshlyEnteredStrengthScore);
    }
  }
  handleDexterityScoreChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        dexterityScore: val
      };
    });
    const payloadInt = val === "" ? 0 : parseInt(val);
    if (Number.isInteger(payloadInt)) {
      var freshlyEnteredDexterityScore = Object.assign({}, this.props.quikStatsPayload);
      freshlyEnteredDexterityScore.dexterityScore = parseInt(val);
      this.props.updateQuikStatPayload(freshlyEnteredDexterityScore);
    }
  }
  handleConstitutionScoreChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        constitutionScore: val
      };
    });
    const payloadInt = val === "" ? 0 : parseInt(val);
    if (Number.isInteger(payloadInt)) {
      var freshlyEnteredConstitutionScore = Object.assign({}, this.props.quikStatsPayload);
      freshlyEnteredConstitutionScore.constitutionScore = parseInt(val);
      this.props.updateQuikStatPayload(freshlyEnteredConstitutionScore);
    }
  }
  handleWisdomScoreChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        wisdomScore: val
      };
    });
    const payloadInt = val === "" ? 0 : parseInt(val);
    if (Number.isInteger(payloadInt)) {
      var freshlyEnteredWisdomScore = Object.assign({}, this.props.quikStatsPayload);
      freshlyEnteredWisdomScore.wisdomScore = parseInt(val);
      this.props.updateQuikStatPayload(freshlyEnteredWisdomScore);
    }
  }
  handleIntelligenceScoreChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        intelligenceScore: val
      };
    });
    const payloadInt = val === "" ? 0 : parseInt(val);
    if (Number.isInteger(payloadInt)) {
      var freshlyEnteredIntelligenceScore = Object.assign({}, this.props.quikStatsPayload);
      freshlyEnteredIntelligenceScore.intelligenceScore = parseInt(val);
      this.props.updateQuikStatPayload(freshlyEnteredIntelligenceScore);
    }
  }
  handleCharismaScoreChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        charismaScore: val
      };
    });
    const payloadInt = val === "" ? 0 : parseInt(val);
    if (Number.isInteger(payloadInt)) {
      var freshlyEnteredCharismaScore = Object.assign({}, this.props.quikStatsPayload);
      freshlyEnteredCharismaScore.charismaScore = payloadInt;
      this.props.updateQuikStatPayload(freshlyEnteredCharismaScore);
    }
  }
  handleDDAlignmentChange(event) {
    event.preventDefault();
    let val = parseInt(event.target.value);
    const onCustom = val === -1 ? true : false;
    this.setState((prevState) => {
      return {
        onCustomAlignment: onCustom
      };
    });
    if (!onCustom) {
      var freshlySelectedAlignment = Object.assign({}, this.props.quikStatsPayload);
      freshlySelectedAlignment.alignment = alignments[val].name;
      this.props.updateQuikStatPayload(freshlySelectedAlignment);
    }
  }
  handleDDVolatilityChange(event) {
    event.preventDefault();
    let val = parseInt(event.target.value);
    const onCustom = val === -1 ? true : false;
    this.setState((prevState) => {
      return {
        onCustomVolatility: onCustom
      };
    });
    if (!onCustom) {
      var freshlySelectedVolatility = Object.assign({}, this.props.quikStatsPayload);
      freshlySelectedVolatility.volatility = volatilities[val].name;
      this.props.updateQuikStatPayload(freshlySelectedVolatility);
    }
  }


  handleSaveData(e) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('menuSection', 'bio');
    if (this.props.characterSelections) {
      formPayload.append('character_id', this.props.characterSelections.id);
    }
    formPayload.append('name', this.state.name);
    formPayload.append('faith', this.state.faith);
    formPayload.append('alignment', this.state.alignment);
    formPayload.append('volatility', this.state.volatility);
    formPayload.append('height', this.state.height);
    formPayload.append('weight', this.state.weight);
    formPayload.append('age', this.state.age);
    formPayload.append('eyes', this.state.eyes);
    formPayload.append('skin', this.state.skin);
    formPayload.append('hair', this.state.hair);
    // formPayload.append('typedLanguage', this.state.typedLanguage);
    formPayload.append('strengthScore', this.state.strengthScore);
    formPayload.append('dexterityScore', this.state.dexterityScore);
    formPayload.append('constitutionScore', this.state.constitutionScore);
    formPayload.append('wisdomScore', this.state.wisdomScore);
    formPayload.append('intelligenceScore', this.state.intelligenceScore);
    formPayload.append('charismaScore', this.state.charismaScore);
    fetch('/api/v1/auto/character/process', {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if (res.status === 200) {
        this.props.lockSectionIn(
          this.props.quikStatsPayload,
          'bio'
        );
      } else {
        this.props.pop(res.body.msg, res.body.status);
        this.props.throwError();
      }
    }));
  }

  render() {
    let proceedButtons =
      <ProceedButtons
        handleProceedForward={this.handleSaveData}
        handleGoBack={this.props.handleGoBack}
        isDisabled={false}
        proceedText={"Save and Continue"}
      />;

    let rendered_alignment = this.state.onCustomAlignment ?
      <label className="bio-field-selectable"><input className="bio-field-inner" type="textarea" onChange={this.handleAlignmentChange} value={this.props.quikStatsPayload.alignment} placeholder={"Custom Alignment"}/></label>
      : <select className="bio-input-field" onChange={((e) => this.handleDDAlignmentChange(e))}>{alignments.map((alignment, index) => {return <option key={index} value={alignment.value}>{alignment.name}</option>})}</select>;
    let rendered_volatility = this.state.onCustomVolatility ?
      <label className="bio-field-selectable"><input className="bio-field-inner" type="textarea" onChange={this.handleVolatilityChange} value={this.props.quikStatsPayload.volatility} placeholder={"Custom Volatility"}/></label>
      : <select className="bio-input-field" onChange={((e) => this.handleDDVolatilityChange(e))}>{volatilities.map((volatility, index) => {return <option key={index} value={volatility.value}>{volatility.name}</option>})}</select>;

    return (
      <div className="char-prompt-container">
        <div className="race-detail-header">
          Character Bio & Custom Stats
        </div>
        <div className="bio-detail-container">
          <div className="bio-detail-inner-wrapper">
            <div className="bio-detail-inner">
              <div className="bio-stat-wrapper"><div className="bio-field-label">Character Name</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleNameChange} value={this.state.name} placeholder={'Character Name'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Strength Score</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleStrengthScoreChange} value={this.state.strengthScore} placeholder={'Strength Score'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Dexterity Score</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleDexterityScoreChange} value={this.state.dexterityScore} placeholder={'Dexterity Score'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Constitution Score</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleConstitutionScoreChange} value={this.state.constitutionScore} placeholder={'Constitution Score'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Intelligence Score</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleIntelligenceScoreChange} value={this.state.intelligenceScore} placeholder={'Intelligence Score'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Wisdom Score</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleWisdomScoreChange} value={this.state.wisdomScore} placeholder={'Wisdom Score'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Charisma Score</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleCharismaScoreChange} value={this.state.charismaScore} placeholder={'Charisma Score'}/></label></div>
            </div>
            <div className="bio-detail-inner bio-detail-inner-buttons">
              <div className="bio-proceed-buttons-wrapper">{proceedButtons}</div>
            </div>
          </div>
          <div className="bio-detail-inner-wrapper">
            <div className="bio-detail-inner">
              <div className="bio-stat-wrapper"><div className="bio-field-label">Faith</div><label className="bio-input-field"><input className="bio-field-inner" type="text" onChange={this.handleFaithChange} value={this.state.faith} placeholder={"Faith"}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Volatility</div>{rendered_volatility}</div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Alignment</div>{rendered_alignment}</div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Age</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleAgeChange} value={this.state.age} placeholder={'Age'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Eyes</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleEyesChange} value={this.state.eyes} placeholder={'Eyes'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Skin</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleSkinChange} value={this.state.skin} placeholder={'Skin'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Hair</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleHairChange} value={this.state.hair} placeholder={'Hair'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Height</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleHeightChange} value={this.state.height} placeholder={'Height'}/></label></div>
              <div className="bio-stat-wrapper"><div className="bio-field-label">Weight</div><label className="bio-input-field"><input className="bio-field-inner" type='text' onChange={this.handleWeightChange} value={this.state.weight} placeholder={'Weight'}/></label></div>
            </div>
          </div>
        </div>
      </div>
  );
  }
}
export default BioPrompt;
