import React from 'react';

const CampaignMapCoordinate = props => {

  let coord = props.coordinate;
  let boxShadow = 'none';
  let zIndex = 'inherit';
  let background = 'EAE3D3';
  let iconName = "";
  let icon, indicator, iconExpansion;
  let hoveredElement;

  let iconStyling = {}
  let indicatorIconStyling = {}
  let dynamicInnerStyling = {'background': `#${background}`}
  if (props.height) {
    let largerOfDimensions = props.height > props.width ? props.height : props.width;
    const size = (100 / largerOfDimensions) + ((largerOfDimensions - 2) / 15.2 );
    // const size = (38 * (100 / largerOfDimensions)) / 45;
    iconStyling['fontSize'] = `${size}px`;
    indicatorIconStyling['fontSize'] = `${size}px`;
    // iconStyling['fontSize'] = `calc(${size}px + .5vh)`;
    // indicatorIconStyling['fontSize'] = `calc(${size}px + .5vh)`;

    // 7.5px at 40
    // 75 px at 2
    // dynamicInnerStyling['width'] = "80%";
    // dynamicInnerStyling['height'] = "80%";
    dynamicInnerStyling['width'] = `calc(1.4 * ${size}px)`;
    dynamicInnerStyling['height'] = `calc(1.4 * ${size}px)`;
    dynamicInnerStyling['borderRadius'] = "100px";
  }

  if (coord) {
    if (coord.color_hex_code) {
      background = coord.color_hex_code;
    }
    // 'partial', 'clear' or 'blocked'
    if (coord.fog_of_war_visible === 'partial') {
      boxShadow = `inset #${coord.color_hex_code_fow} 0px 0px 4px 2px`;
    } else if (coord.fog_of_war_visible === 'blocked') {
      background = coord.color_hex_code_fow;
    }

    if (coord.markers && coord.markers.length > 0) {
      if (props.hoveredMarker && props.hoveredMarker.id !== null) {
        coord.markers.forEach((marker) => {
          // add more permission logic here
          if (props.hoveredMarker.id === marker.id) {
            let name = "";
            if (marker && marker.name) {
              name = marker.name;
            } else if (marker && marker.character && marker.character.name) {
              name = marker.character.name;
            }
            hoveredElement =  <div className="map-coordinate-hover-detail">
                                {name}
                              </div>
          }
        });
      }
      // add more permission logic here
      coord.markers.forEach((marker) => {
        if (
          props.boardSelectedMarker &&
          marker.id === props.boardSelectedMarker.id
        ) {
          boxShadow = 'inset rgb(0, 248, 248) 0px 0px 2px 250px, 0px 1px 1px 1px black';
          zIndex = '1';
          background = '#ccc';
          dynamicInnerStyling = {};
        }
      });
      if (coord.markers.length === 1) {
        let coordMarker = coord.markers[0];

        iconName = coordMarker.marker_type.icon_name;
        iconStyling['color'] = `#${coordMarker.marker_type.default_hex_code}`;
      } else if (coord.markers.length > 1) {
        // iconeName = "fa-asterisk";
        iconName = "fa-bars";
        iconStyling['color'] = "#606060";

        if (props.isMultiMarkerMenuPoppedUp) {
          let iconList = coord.markers.map((marker, index) => {
            let listIconStyling = {'color': marker.marker_type.default_hex_code}
            return  <div
                      className="coord-listed-icon"
                      key={index}
                      onClick={((e) => props.handleExpandedIconClick(e, marker))}
                      onMouseOver={((e) => props.handleExpandedIconHover(e, marker))}
                      onMouseLeave={((e) => props.handleExpandedIconLeaveHover(e, marker))}
                      onContextMenu={((e) => props.handleExpandedIconRightClick(e, marker))}
                    >
                      <i style={listIconStyling} className={`fa-solid ${marker.marker_type.icon_name}`}></i>
                    </div>
          });
          iconExpansion = <div className="coord-listed-icons">
                            {iconList}
                          </div>
        }
      }
      icon = <i style={iconStyling} className={`icon-unit fa-solid ${iconName}`}></i>;
    } else {
      // if (
      //   coord.color_hex_code !== null &&
      //   coord.fog_of_war_visible !== "blocked"
      // ) {
      //   background = coord.color_hex_code;
      // }
      dynamicInnerStyling = {};
    }
    if (coord.indicator) {
      indicatorIconStyling['color'] = "default";
      indicator = <div key={coord.indicatorIteration} className="arrow bounce">
                    <i style={indicatorIconStyling} className="fa-solid fa-location-pin fadeout"></i>
                  </div>;
    }
  }

  let dynamicStyling;
  if (props.width) {
    dynamicStyling = {
      // height: `${100/props.width}%`,
      // width: `${100/props.width}%`,
      gridColumn: `${(1/props.width)*100}%`,
      background: `#${background}`,
      // color: `#${color}`,
      boxShadow: boxShadow,
      zIndex: zIndex
    }
  }

  // refactor the css on textareas on
  //  campaign-char-notes-limit,
  //  campaign-char-notes-input and
  //  campaign-char-notes-field


  return (
    <div
      className="map-coordinate-square"
      style={dynamicStyling}
      onClick={((e) => props.handleCoordinateClick(e, props.coordinate))}
      onContextMenu={((e) => props.handleCoordinateRightClick(e, props.coordinate))}
      onMouseOver={((e) => props.handleHover(e, props.coordinate))}
      onMouseLeave={((e) => props.handleLeaveHover(e, props.coordinate))}
      onMouseUp={((e) => props.handleStopFill(e))}
    >
      <div className="map-coordinate-inner"
        style={dynamicInnerStyling}
      >
        {indicator}
        {hoveredElement}
        {icon}
      </div>
      {iconExpansion}
    </div>
  )
}

export default CampaignMapCoordinate
