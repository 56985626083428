import React from 'react';

const CampaignCharSheetInventory = props => {

  let cp, sp, ep, gp, pp;
  let armor, weapons, gear, tools, mounts;
  armor = weapons = gear = tools = mounts = [];

  let encumbranceText = '';
  let currentItemsWeight = 0;
  // on individual item lines I could also use ${item.item.weight_unit} which will read "pounds"

  let equippedBody, equippedLeftHand, equippedRightHand;
  let equippedBodyVal, equippedLeftHandVal, equippedRightHandVal;
  let mainBonus, acBonus, secondaryBonus;
  let totalCountOfPickups = '';
  if (props.character) {
    const i = props.character.inventories[0];
    cp = i.copper_points;
    sp = i.silver_points;
    ep = i.electrum_points;
    gp = i.gold_points;
    pp = i.platinum_points;
    gear = i.gear.map((item, index) => {
      const quantity = item.item.stackable ?
                         <span className='item-quantity'>{`(${item.quantity}) `}</span> :
                         null;
      const weight = item.item.stackable ?
                      item.quantity*item.item.item_weight :
                      item.item.item_weight;
      currentItemsWeight += weight;
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
              >
                <div className='campaign-char-power-text'>{quantity}{item.item.name}</div>
                <div className='tiny-weight-unit'>{`${item.item.item_weight}${item.item.item_weight !== 1 ? 'lbs' : 'lb'}`}</div>
             </li>
    });
    armor = i.armor.map((item, index) => {
      const quantity = item.item.stackable ?
                         <span className='item-quantity'>{`(${item.quantity}) `}</span> :
                         null;
      const weight = item.item.stackable ?
                      item.quantity*item.item.item_weight :
                      item.item.item_weight;
      currentItemsWeight += weight;
      if (item.equipped_body) {
        equippedBodyVal = item.item;
        equippedBody = <div
                          onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
                          className='equipped-item-container'
                        >
                         <div className="equipped-item-text">
                           {item.item.name}
                         </div>
                       </div>
      } else if (item.equipped_2ndy) {
        equippedRightHandVal = item.item;
        equippedRightHand = <div
                              onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
                              className='equipped-item-container'
                            >
                              <div className="equipped-item-text">
                                {item.item.name}
                              </div>
                            </div>
      }
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
              >
                <div className='campaign-char-power-text'>{quantity}{item.item.name}</div>
                <div className='tiny-weight-unit'>{`${item.item.item_weight}${item.item.item_weight !== 1 ? 'lbs' : 'lb'}`}</div>
             </li>
    });
    mounts = i.mounts.map((item, index) => {
      const quantity = item.item.stackable ?
                         <span className='item-quantity'>{`(${item.quantity}) `}</span> :
                         null;
      // const weight = item.item.stackable ?
      //                 item.quantity*item.item.item_weight :
      //                 item.item.item_weight;
      // // currentItemsWeight += weight;
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
              >
                <div className='campaign-char-power-text'>{quantity}{item.item.name}</div>
                <div className='tiny-weight-unit'>{`${item.item.item_weight}${item.item.item_weight !== 1 ? 'lbs' : 'lb'}`}</div>
             </li>
    });
    weapons = i.weapons.map((item, index) => {
      const quantity = item.item.stackable ?
                       <span className='item-quantity'>{`(${item.quantity}) `}</span> :
                       null;
      const weight = item.item.stackable ?
                      item.quantity*item.item.item_weight :
                      item.item.item_weight;
      currentItemsWeight += weight;
      if (item.equipped_main) {
        equippedLeftHandVal = item.item;
        equippedLeftHand = <div
                              onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
                              className='equipped-item-container'
                            >
                             <div className="equipped-item-text">
                               {item.item.name}
                             </div>
                           </div>
      } else if (item.equipped_2ndy) {
        equippedRightHandVal = item.item;
        equippedRightHand = <div
                              onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
                              className='equipped-item-container'
                            >
                             <div className="equipped-item-text">
                               {item.item.name}
                             </div>
                           </div>
      }
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
              >
                <div className='campaign-char-power-text'>{quantity}{item.item.name}</div>
                <div className='tiny-weight-unit'>{`${item.item.item_weight}${item.item.item_weight !== 1 ? 'lbs' : 'lb'}`}</div>
             </li>
    });
    tools = i.tools.map((item, index) => {
      const quantity = item.item.stackable ?
                         <span className='item-quantity'>{`(${item.quantity}) `}</span> :
                         null;
      const weight = item.item.stackable ?
                      item.quantity*item.item.item_weight :
                      item.item.item_weight;
      currentItemsWeight += weight;
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, item.item.id, 'item', true, item))}
              >
                <div className='campaign-char-power-text'>{quantity}{item.item.name}</div>
                <div className='tiny-weight-unit'>{`${item.item.item_weight}${item.item.item_weight !== 1 ? 'lbs' : 'lb'}`}</div>
             </li>
    });
    let sumOfCurrencies = cp + sp + ep + gp + pp;
    currentItemsWeight += (sumOfCurrencies * 0.02);
    if (currentItemsWeight > props.character.str_score * 15) {
      encumbranceText = 'You are beyond carrying capacity! You must remove gear before you may continue.';
    } else if (
      currentItemsWeight > props.character.str_score * 10 &&
      currentItemsWeight <= props.character.str_score * 15
    ) {
      encumbranceText = 'You are heavily encumbered. Your speed drops by 20 and you have disadvantage on ability checks, attack rolls, and saving throws that use Strength, Dexterity, or Constitution.';
    } else if (
      currentItemsWeight > props.character.str_score * 5 &&
      currentItemsWeight <= props.character.str_score * 10
    ) {
      encumbranceText = 'You are encumbered. Your speed drops by 10. (Hint. You may wish to "drop items" before combat (and subtract their weight from your total) if you do not believe you will require them for a fight.)';
    }

    // let equippedLeftHandVal, equippedRightHandVal;
    // let mainBonus, secondaryBonus;
    let armorClass;
    let acFlatAdd =
      equippedRightHandVal &&
      equippedRightHandVal.type === 'Armor' &&
      equippedRightHandVal.ac_flat_add
      ? equippedRightHandVal.ac_base_bonus
      : 0;
    let bodyArmorBonus = 0;
    if (equippedBodyVal) {
      let base = equippedBodyVal.ac_base_bonus;
      let addedDex = 0;
      if (equippedBodyVal.ac_apply_dex) {
        if (equippedBodyVal.ac_max_dex_add === null) {
          addedDex = 0;
        } else if (equippedBodyVal.ac_max_dex_add === 0) {
          addedDex = Math.floor((props.character.dex_score - 10) / 2);
        } else if (equippedBodyVal.ac_max_dex_add >= 0) {
          addedDex = equippedBodyVal.ac_max_dex_add;
        }
      }
      bodyArmorBonus = base + addedDex;
    } else {
      bodyArmorBonus = Math.floor((props.character.dex_score - 10) / 2) + 10;
    }
    armorClass = bodyArmorBonus + acFlatAdd;
    acBonus = <div className="equipped-items-bonus-container">
                <div className="equipped-items-bonus-wrapper">
                  <div className="equipped-items-bonus subtext">
                    AC
                  </div>
                  <div className="equipped-items-bonus">
                    {armorClass}
                  </div>
                </div>
              </div>

    // if (equippedLeftHandVal) {

    // default is Unarmed Strikes
    let oneHandedBonus, twoHandedBonus;
    oneHandedBonus = twoHandedBonus = 0;
    let mainAtkBonus = `1d20 +${Math.floor((props.character.str_score - 10) / 2) + props.character.proficiency_bonus}`;
    let mainDmgBonus = `${Math.floor((props.character.str_score - 10) / 2) + 1} dmg`;
    if (equippedLeftHandVal && equippedLeftHandVal.type === 'Weapon') {
      let usedScore;
      if (
        equippedLeftHandVal.weapon_properties.some(property =>
          property === 'Finesse'
        )
      ) {
        if (props.character.dex_score > props.character.str_score) {
          usedScore = props.character.dex_score;
        } else {
          usedScore = props.character.str_score;
        }
      } else {
        usedScore = props.character.str_score;
      }
      let usedBonus = Math.floor((usedScore - 10) / 2);
      let isApplyingProficiency = false;
      if (
        props.character.item_proficiencies.some(proficiency =>
          proficiency.name === equippedLeftHandVal.name
        ) ||
        props.character.item_type_proficiencies.some(proficiency =>
          equippedLeftHandVal.subtypes.includes(proficiency.name)
        )
      ) {
        isApplyingProficiency = true;
      }
      if (
        equippedLeftHandVal.dmg_bonus
      ) {
        oneHandedBonus = equippedLeftHandVal.dmg_bonus;
      }
      usedBonus += isApplyingProficiency ? props.character.proficiency_bonus : 0;
      mainAtkBonus = `+${usedBonus += oneHandedBonus}`;
      mainDmgBonus = `${equippedLeftHandVal.dmg_dice_count}${equippedLeftHandVal.dmg_dice} +${usedBonus += oneHandedBonus}`;



      // dmg_dice_2h
      // dmg_dice_count_2h
      // dmg_bonus_2h
      if (
        equippedLeftHandVal.weapon_properties.some(property =>
          property === 'Versatile'
        )
      ) {
        if (
          equippedLeftHandVal.dmg_bonus_2h
        ) {
          twoHandedBonus = equippedLeftHandVal.dmg_bonus_2h;
        }
        mainAtkBonus += ` / +${usedBonus += twoHandedBonus}`;
        mainDmgBonus += ` / ${equippedLeftHandVal.dmg_dice_count_2h}${equippedLeftHandVal.dmg_dice_2h} +${usedBonus += twoHandedBonus}`;
      }
    }
    mainBonus = <div className="equipped-items-bonus-container">
                  <div className="equipped-items-bonus-wrapper">
                    <div className="equipped-items-bonus subtext">
                      Atk Bonus
                    </div>
                    <div className="equipped-items-bonus">
                      {mainAtkBonus}
                    </div>
                  </div>
                  <div className="equipped-items-bonus-wrapper">
                    <div className="equipped-items-bonus subtext">
                      Dmg Bonus
                    </div>
                    <div className="equipped-items-bonus">
                      {mainDmgBonus}
                    </div>
                  </div>
                </div>



    // let altAtkBonus = props.character.proficiency_bonus;
    // let altDmgBonus = Math.floor((props.character.dex_score - 10) / 2) + 1;
    if (equippedRightHandVal && equippedRightHandVal.type === 'Weapon') {
      let usedScore;
      if (
        equippedRightHandVal.weapon_properties.some(property =>
          property === 'Finesse'
        )
      ) {
        if (props.character.dex_score > props.character.str_score) {
          usedScore = props.character.dex_score;
        } else {
          usedScore = props.character.str_score;
        }
      } else {
        usedScore = props.character.str_score;
      }
      let usedBonus = Math.floor((usedScore - 10) / 2);
      let isApplyingProficiency = false;
      if (
        props.character.item_proficiencies.some(proficiency =>
          proficiency.name === equippedRightHandVal.name
        ) ||
        props.character.item_type_proficiencies.some(proficiency =>
          equippedRightHandVal.subtypes.includes(proficiency.name)
        )
      ) {
        isApplyingProficiency = true;
      }
      if (
        equippedRightHandVal.dmg_bonus
      ) {
        oneHandedBonus = equippedRightHandVal.dmg_bonus;
      }
      usedBonus += isApplyingProficiency ? props.character.proficiency_bonus : 0;
      mainAtkBonus = `+${usedBonus += oneHandedBonus}`;
      mainDmgBonus = `${equippedRightHandVal.dmg_dice_count}${equippedRightHandVal.dmg_dice} +${usedBonus += oneHandedBonus}`;



      if (
        equippedRightHandVal.weapon_properties.some(property =>
          property === 'Versatile'
        ) &&
        !equippedLeftHandVal
      ) {
        if (
          equippedRightHandVal.dmg_bonus_2h
        ) {
          twoHandedBonus = equippedRightHandVal.dmg_bonus_2h;
        }
        mainAtkBonus += ` / +${usedBonus += twoHandedBonus}`;
        mainDmgBonus += ` / ${equippedRightHandVal.dmg_dice_count_2h}${equippedRightHandVal.dmg_dice_2h} +${usedBonus += twoHandedBonus}`;
      }
      secondaryBonus = <div className="equipped-items-bonus-container">
                        <div className="equipped-items-bonus-wrapper">
                          <div className="equipped-items-bonus subtext">
                            Atk Bonus
                          </div>
                          <div className="equipped-items-bonus">
                            {mainAtkBonus}
                          </div>
                        </div>
                        <div className="equipped-items-bonus-wrapper">
                          <div className="equipped-items-bonus subtext">
                            Dmg Bonus
                          </div>
                          <div className="equipped-items-bonus">
                            {mainDmgBonus}
                          </div>
                        </div>
                      </div>
    }
    const currCount = i.gear.concat(i.armor,i.weapons,i.tools,i.mounts).length;
    totalCountOfPickups = `(x${currCount})`;
  }

  if (!equippedLeftHand) {
    equippedLeftHand = <div className='equipped-item-container-unequipped'>
                        <div className='unequipped-text'>
                          Unarmed Strike
                        </div>
                      </div>
  }
  if (!equippedBody) {
    equippedBody = <div className='equipped-item-container-unequipped'>
                    <div className='unequipped-text'>
                      -
                    </div>
                  </div>
  }
  if (!equippedRightHand) {
    equippedRightHand = <div className='equipped-item-container-unequipped'>
                          <div className='unequipped-text'>
                            -
                          </div>
                        </div>
  }
  let roundedCurrentWeight = Math.round((currentItemsWeight + Number.EPSILON) * 100) / 100;

  return(
    <div className="campaign-char-powers-container">
      <div className="campaign-char-powers-section">
        <div className="campaign-char-equipped-section-content">
          <div className="campaign-char-inventory-section-positioner">
            <div className="campaign-char-inventory-section-header">
              Currently Equipped
            </div>
            <div className="equipped-items-wrapper">
              <div className="equipped-items-container">
                {equippedLeftHand}
                {equippedBody}
                {equippedRightHand}
                <div className="equipped-item subtext">
                  Primary Weapon
                </div>
                <div className="equipped-item subtext">
                  Armor
                </div>
                <div className="equipped-item subtext">
                  Shield/2nd Weapon
                </div>
              </div>
              <div className="equipped-items-bonuses">
                {mainBonus}
                {acBonus}
                {secondaryBonus}
              </div>
            </div>
          </div>
        </div>
        <div className="campaign-char-wallet-section-content">
          <div className="campaign-char-inventory-section-positioner">
            <div className="campaign-char-inventory-section-header">
              Wallet
            </div>
            <div className="campaign-char-wallet-symbols-wrapper">
              <div
                className="campaign-char-wallet-symbol-container"
                onClick={((e) => props.handleCurrencyClick(e, 'cp'))}
              >
                {props.openField === 'cp'
                  ? <div
                      className="campaign-char-inventory-input-wrapper"
                    >
                      <label className="campaign-char-currency-input-field">
                        <input
                          className="campaign-char-currency-field"
                          type='text'
                          onChange={props.handleTypedValueChange}
                          onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                          value={props.typedValue}
                          placeholder={cp}
                        />
                      </label>
                    </div>
                  : <div
                      className="campaign-char-wallet-symbol-wrapper"
                    >
                      <div className="campaign-char-wallet-symbol">
                        {cp}
                      </div>
                    </div>
                }
                <div className="campaign-char-wallet-symbol subtext">
                  CP
                </div>
              </div>
              <div
                className='campaign-char-wallet-symbol-container'
                onClick={((e) => props.handleCurrencyClick(e, 'sp'))}
              >
                {props.openField === 'sp'
                  ? <div
                      className="campaign-char-inventory-input-wrapper"
                    >
                      <label className="campaign-char-currency-input-field">
                        <input
                          className="campaign-char-currency-field"
                          type='text'
                          onChange={props.handleTypedValueChange}
                          onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                          value={props.typedValue}
                          placeholder={sp}
                        />
                      </label>
                    </div>
                  : <div
                      className="campaign-char-wallet-symbol-wrapper"
                    >
                      <div className="campaign-char-wallet-symbol">
                        {sp}
                      </div>
                    </div>
                }
                <div className="campaign-char-wallet-symbol subtext">
                  SP
                </div>
              </div>
              <div
                className='campaign-char-wallet-symbol-container'
                onClick={((e) => props.handleCurrencyClick(e, 'ep'))}
              >
                {props.openField === 'ep'
                  ? <div
                      className="campaign-char-inventory-input-wrapper"
                    >
                      <label className="campaign-char-currency-input-field">
                        <input
                          className="campaign-char-currency-field"
                          type='text'
                          onChange={props.handleTypedValueChange}
                          onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                          value={props.typedValue}
                          placeholder={ep}
                        />
                      </label>
                    </div>
                  : <div
                      className="campaign-char-wallet-symbol-wrapper"
                    >
                      <div className="campaign-char-wallet-symbol">
                        {ep}
                      </div>
                    </div>
                }
                <div className="campaign-char-wallet-symbol subtext">
                  EP
                </div>
              </div>
              <div
                className='campaign-char-wallet-symbol-container'
                onClick={((e) => props.handleCurrencyClick(e, 'gp'))}
              >
                {props.openField === 'gp'
                  ? <div
                      className="campaign-char-inventory-input-wrapper"
                    >
                      <label className="campaign-char-currency-input-field">
                        <input
                          className="campaign-char-currency-field"
                          type='text'
                          onChange={props.handleTypedValueChange}
                          onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                          value={props.typedValue}
                          placeholder={gp}
                        />
                      </label>
                    </div>
                  : <div
                      className="campaign-char-wallet-symbol-wrapper"
                    >
                      <div className="campaign-char-wallet-symbol">
                        {gp}
                      </div>
                    </div>
                }
                <div className="campaign-char-wallet-symbol subtext">
                  GP
                </div>
              </div>
              <div
                className='campaign-char-wallet-symbol-container'
                onClick={((e) => props.handleCurrencyClick(e, 'pp'))}
              >
                {props.openField === 'pp'
                  ? <div
                      className="campaign-char-inventory-input-wrapper"
                    >
                      <label className="campaign-char-currency-input-field">
                        <input
                          className="campaign-char-currency-field"
                          type='text'
                          onChange={props.handleTypedValueChange}
                          onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                          value={props.typedValue}
                          placeholder={pp}
                        />
                      </label>
                    </div>
                  : <div
                      className="campaign-char-wallet-symbol-wrapper"
                    >
                      <div className="campaign-char-wallet-symbol">
                        {pp}
                      </div>
                    </div>
                }
                <div className="campaign-char-wallet-symbol subtext">
                  PP
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="campaign-char-encumbrance-section-content">
          <div className="campaign-char-inventory-section-positioner">
            <div className="campaign-char-inventory-section-header">
              Encumbrance
            </div>
            <div className="encumbrance-info">
              <div className="carrying-capac-breakdown">
                <div className="carrying-capac-line">{`Current Load: ${roundedCurrentWeight}lbs`}</div>
                <div className="carrying-capac-line">{`Encumbered at: ${props.character.str_score*5}lbs`}</div>
                <div className="carrying-capac-line">{`Heavily Encumbered at: ${props.character.str_score*10}lbs`}</div>
                <div className="carrying-capac-line">{`Max Carrying Capacity: ${props.character.str_score*15}lbs`}</div>
              </div>
              <div className="encumbrance-text">
                {encumbranceText}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="campaign-char-powers-section w66pct">
        <div
          onClick={((e) => props.handleItemAddPopUpClick(e, true))}
          className="campaign-char-add-item-button"
        >
          ADD ITEM
        </div>
        <div className="campaign-char-powers-section-header">
          Inventory
          <div className="inventory-subtext">{totalCountOfPickups}</div>
        </div>
        <div className="campaign-char-inventory-section-content">
          <div className="campaign-char-inventories-column">
            <div className="inventory-items-container armor-weapon">
              <div className="campaign-char-power-header">
                Armor
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {armor}
                </ul>
              </div>
            </div>
            <div className="inventory-items-container armor-weapon">
              <div className="campaign-char-power-header">
                Weapons
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {weapons}
                </ul>
              </div>
            </div>
          </div>
          <div className="campaign-char-inventories-column">
            <div className="inventory-items-container gear">
              <div className="campaign-char-power-header">
                Adventuring Gear
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {gear}
                </ul>
              </div>
            </div>
            <div className="inventory-items-container tools">
              <div className="campaign-char-power-header">
                Tools
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {tools}
                </ul>
              </div>
            </div>
            <div className="inventory-items-container mounts">
              <div className="campaign-char-power-header">
                Mounts/Vehicles
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {mounts}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignCharSheetInventory
