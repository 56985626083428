import React from 'react';

const CampaignSpellPopUp = props => {

  const numberRankConversions = {
    '1': '1st',
    '2': '2nd',
    '3': '3rd',
    '4': '4th',
    '5': '5th',
    '6': '6th',
    '7': '7th',
    '8': '8th',
    '9': '9th',
  }

  let spellType, name, castingTime, range,
      components, duration, descs;


  const dts = props.details;
  if (dts) {
    // console.log(dts);
    name = dts.name;
    const magicSchool = dts.level > 0 ? dts.magic_school.name.toLowerCase() : dts.magic_school.name;
    const ritual = dts.ritual ? ' (ritual)' : '';
    if (dts.level > 0) {
      const level = numberRankConversions[dts.level.toString()];
      spellType = `${level}-level ${magicSchool}${ritual}`;
    } else {
      spellType = `${magicSchool} cantrip${ritual}`;
    }
    castingTime = dts.casting_time;
    range = dts.range;
    components = '';
    dts.components.forEach((component, index) => {
      let continuation;
      let material = '';
      if (index + 1 < dts.components.length) {
        continuation = ', '
      } else {
        continuation = '';
        material = dts.material ? ` (${dts.material})` : '';
      }
      components += `${component.abbr}${material}${continuation}`;
    });
    duration = dts.duration;

    descs = dts.descs.map((desc, index) => {
      if (desc.bullet) {
        if (desc.header_text) {
          let header;
          if (desc.header_text) {
            header = <span className="story-mode-header-text-bullet">{desc.header_text}</span>
          }
          return <div key={index} className="campaign-detail-popup-desc">
                  {header}{desc.object_text}
                 </div>;
        } else {
          return  <div key={index} className="campaign-detail-popup-desc">
                    <span className="bulleted-desc">•</span>{desc.object_text}
                  </div>;
        }
      } else {
        if (desc.header_text) {
          return <div key={index} className="campaign-detail-popup-desc">
                  <div className="headlining-header-text">{desc.header_text}</div>
                  {desc.object_text}
                 </div>;
        } else {
          return <div key={index} className="campaign-detail-popup-desc">
                  {desc.object_text}
                 </div>;
        }
      }
    });
  }

  return(
    <div className='campaign-detail-popup-details-container'>
      <div className='campaign-detail-popup-section-container'>
        <div className='campaign-detail-popup-section-container-inner'>
          <div className='campaign-detail-popup-name'>
            {name}
            <div className='cdp-one-line campaign-detail-sub-header campaign-detail-popup-desc popup-sub-text'>
              {spellType}
            </div>
          </div>
          <div className='campaign-detail-popup-primary-details campaign-popup-type-specific-render'>
            <div className='campaign-detail-popup-type-render'>
              <div className="campaign-detail-popup-detail-one-line">
                <div className="popup-bold-text">Casting Time:</div>
                <div className="popup-normal-text">{castingTime}</div>
              </div>
              <div className="campaign-detail-popup-detail-one-line">
                <div className="popup-bold-text">Range:</div>
                <div className="popup-normal-text">{range}</div>
              </div>
              <div className="campaign-detail-popup-detail-one-line">
                <div className="popup-bold-text">Components:</div>
                <div className="popup-normal-text">{components}</div>
              </div>
              <div className="campaign-detail-popup-detail-one-line">
                <div className="popup-bold-text">Duration:</div>
                <div className="popup-normal-text">{duration}</div>
              </div>
            </div>
          </div>
          <div className='campaign-detail-popup-primary-details campaign-popup-descs'>
            {descs}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignSpellPopUp
