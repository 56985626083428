import React from "react";
import { Link } from 'react-router-dom';
import CharacterRow from "./CharacterRow";
import ConfirmationPopup from '../components/ConfirmationPopup';
import CampaignsPopup from './CampaignsPopup';
import Loader from '../components/Loader.js';
//import objToQueryString from "../functions/functions";


class Characters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      characters: [],
      campaigns: [],
      popUpImports: false,
      popUpDelete: false,
      requestedChar: null,
      loading: true,
    };
    this.fetchCharacters = this.fetchCharacters.bind(this);
    this.toggleImportPopUp = this.toggleImportPopUp.bind(this);
    this.togglePopUp = this.togglePopUp.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.importCharacter = this.importCharacter.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchCharacters();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}

  fetchCharacters() {
    fetch('/api/v1/characters', {
      method: "GET",
      credentials: "same-origin",
    })
    .then((response) => response.json())
    .then((body) => {
      if (body) {
        this.setState((prevState) => {
          return {
            characters: body.characters,
            campaigns: body.campaigns,
            loading: false
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            loading: false
          };
        });
      }
    });
  }

  toggleImportPopUp(e, charId) {
    e.preventDefault();
    if (charId !== null) {
      this.setState({
        popUpImports: true,
        requestedChar: charId
      });
    }
  }

  togglePopUp(e, charId) {
    e.preventDefault();
    if (charId) {
      this.setState({
        popUpDelete: true,
        requestedChar: charId
      });
    }
  }

  handleClose(e) {
    e.preventDefault();
    this.closePopUp();
  }

  closePopUp() {
    this.setState((prevState) => {
      return {
        popUpImports: false,
        popUpDelete: false,
        requestedChar: null
      };
    });
  }

  handleDelete(e, value) {
    e.preventDefault();
    this.deleteCharacter();
    this.closePopUp();
  }

  handleImport(e, campaign) {
    e.preventDefault();
    this.importCharacter(campaign);
    this.closePopUp();
  }

  importCharacter(campaign) {
    let formPayload = new FormData();
    formPayload.append('requestedChar', JSON.stringify(this.state.requestedChar));
    formPayload.append('requestedCampaign', JSON.stringify(campaign));
    fetch('/api/v1/characters/import', {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response => {
      if (response.status === 200) {
        this.props.history.push(`/campaigns/${campaign}`);
      } else {
        this.props.throwError();
      }
    });
  }

  deleteCharacter() {
    this.setState((prevState) => {
      return { loading: true };
    });
    fetch(`/api/v1/characters/${this.state.requestedChar}`, {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      this.fetchCharacters();
    }));
  }

  render() {
    let characters;
    let createCharText = 'Start a new Character';
    if (this.state.characters) {
      characters = this.state.characters.length > 0 ?
        this.state.characters.map((character, index) => {
          let level = '';
          if (character.level) {
            level = "Lvl " + character.level.toString() + " ";
          }
          let race = '';
          if (character.archrace) {
            if (character.race) {
              race = character.race + " ";
            } else {
              race = character.archrace + " ";
            }
          }
          let classification = '';
          if (character.classification) {
            classification = character.classification + " ";
          }
          const details = level + race + classification;
          return <CharacterRow
                  key={index+1}
                  id={character.id}
                  name={character.name}
                  published={character.is_published}
                  campaign={character.campaign}
                  details={details}
                  header={false}
                  handleImportClick={this.toggleImportPopUp}
                  handleDelete={this.togglePopUp}
                />
        })
        : null;
      this.state.characters.forEach((char) => {
        if (char.is_published === false) {
          createCharText = `Finish Drafting ${char.name}`;
        }
      });
    } else {
      characters = null;
    }

    let confirmationPopUp;
    if (this.state.popUpDelete) {
      confirmationPopUp = <ConfirmationPopup
                            msg="Are you sure you want to delete your character? If this character is in a campaign, then that character will be removed from it as well."
                            onSubmit={this.handleDelete}
                            closePopUp={this.handleClose}
                          />;
    }

    let campaignsPopUp;
    if (this.state.popUpImports) {
      campaignsPopUp = <CampaignsPopup
                        msg="Select a campaign into which to import your character."
                        onSubmit={this.handleImport}
                        campaigns={this.state.campaigns}
                        closePopUp={this.handleClose}
                      />;
    }

    let renderedCharacters;
    let renderedButton;
    if (this.props.currentUser) {
      renderedCharacters =  <div className='characters-table-list'>
                              <CharacterRow
                                key={0}
                                id={null}
                                name={null}
                                published={null}
                                campaign={null}
                                details={null}
                                header={true}
                                handleImportClick={this.toggleImportPopUp}
                                handleDelete={this.togglePopUp}
                              />
                              {characters}
                            </div>
      if (this.props.currentUser.made_chars.length >= 15) {
        renderedButton =  <Link className='characters-creation-button-container characters-creation-button-link disabled-button' to={"/character/creation"}>
                            <div className="characters-creation-button-text">Max Chars reached (15) - Delete others first</div>
                          </Link>
      } else {
        renderedButton =  <Link className='characters-creation-button-container characters-creation-button-link' to={"/character/creation"}>
                            <div className="characters-creation-button-text">{createCharText}</div>
                          </Link>
      }
    } else {
      renderedCharacters = <div className=''>
                            Please log in to view your characters
                          </div>
    }

    if (this.state.loading !== false) return <Loader/>;
    const loader = this.state.loading ? <i className="fa fa-cog fa-spin" /> : null;

    return (
      <div className="wholePage" id="container">
        <div className="characters-table-container">
          <div className='characters-table-title'>
            Created Characters
          </div>
          {renderedCharacters}
          {renderedButton}
        </div>
        {confirmationPopUp}
        {campaignsPopUp}
        {loader}
      </div>
    );
  }
}

export default Characters;

// <div className="characters-creation-button-advisory">
//   <div className="characters-creation-button-advisory-text">
//     (Only one character may be drafted at a time. If you still have a character in "Drafting" status, you will return to the creation page for that character when you click this button)
//   </div>
// </div>

// <Link className="" to={"/character/create"}>
// <div className="">Create a Character</div>
// </Link>
