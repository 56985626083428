import React, { Component} from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../home/Home';
import Campaigns from '../campaigns/Campaigns';
import CampaignPage from '../campaign/CampaignPage';
import Items from '../containers/Items';
import Characters from '../characters/Characters';
import CharPromptMenu from '../character/charPrompts/CharPromptMenu';
import SignUp from '../containers/SignUp';
import Login from '../containers/Login';
import Profile from '../containers/Profile';
import EditProfile from '../containers/EditProfile';
import Notifications from '../containers/Notifications';
import ErrorAuthorization from '../containers/ErrorAuthorization';
import EmailConfirmation from '../containers/EmailConfirmation';
import PasswordReset from '../containers/PasswordReset';
import ProcessPasswordReset from '../containers/ProcessPasswordReset';
import CheckEmail from './CheckEmail';
import Feedback from '../containers/Feedback';

export default class Main extends Component {
  render() {
    let pop = this.props.pop;
    let currentUser = this.props.currentUser;
    return(
      <Switch>
        <Route exact path="/" render={props => <Home {...props} currentUser={currentUser} /> } />
        <Route exact path="/register" render={() => <SignUp displayUser={this.props.displayUser} attemptLoginSession={this.props.attemptLoginSession} pop={pop} signedIn={this.props.signedIn} />} />
        <Route exact path="/login" render={() => <Login displayUser={this.props.displayUser} attemptLoginSession={this.props.attemptLoginSession} pop={pop} signedIn={this.props.signedIn} />} />
        <Route exact path="/profile/:id" render={props => <Profile {...props} pop={pop} />} />
        <Route exact path="/edit_profile/:id" render={props => <EditProfile {...props} displayUser={this.props.displayUser} pop={pop} />} />
        <Route exact path="/campaigns" render={props => <Campaigns {...props} currentUser={currentUser} pop={pop} />} />
        <Route exact path="/feedback" render={props => <Feedback {...props} displayUser={this.props.displayUser} currentUser={currentUser} pop={pop} />} />
        <Route exact path="/campaigns/:id" render={props => <CampaignPage {...props} pop={pop} />} />
        <Route exact path="/items" component={Items} />
        <Route exact path="/characters" render={props => <Characters {...props} currentUser={currentUser} pop={pop} />} />
        <Route exact path="/character/creation" render={props => <CharPromptMenu {...props} currentUser={currentUser} pop={pop} />} />
        <Route exact path="/character/creation/:id" render={props => <CharPromptMenu {...props} currentUser={currentUser} pop={pop} />} />
        <Route exact path="/notifications" render={props => <Notifications {...props} displayUser={this.props.displayUser} pop={pop} />} />
        <Route exact path="/error_authorization" render={props => <ErrorAuthorization {...props} pop={pop} />} />
        <Route exact path="/check_email" component={CheckEmail} />
        <Route exact path="/password_reset" render={props => <PasswordReset {...props} displayUser={this.props.displayUser} pop={pop} />} />
        <Route exact path="/confirm_email/:token" render={props => <EmailConfirmation {...props} displayUser={this.props.displayUser} pop={pop} />} />
        <Route exact path="/process_password_reset/:token" render={props => <ProcessPasswordReset {...props} displayUser={this.props.displayUser} pop={pop} />} />
      </Switch>
    )
  }
}
