import React from 'react';

const CampaignCharSheetNotes = props => {
  let userPhoto = <div className="campaign-actions-blank-preview"></div>;
  let imgSrc;
  let unclickable = "is-unclickable";
  let unclickableSave = "is-unclickable";
  if (props.preview !== null ) {
    imgSrc = props.preview;
    unclickable = "";
    unclickableSave = "";
  } else if (props.character) {
    imgSrc = props.character.photo;
  }
  if (imgSrc !== null) {
    userPhoto = <img className="campaign-char-notes-img" src={imgSrc} alt="character"/>
  }
  if (
    props.character &&
    (
      (props.typedCharNotesValue.replaceAll('\r','') !== props.character.notes.replaceAll('\r','')) ||
      (props.typedCampaignNotesValue.replaceAll('\r','') !== props.character.campaign_notes.replaceAll('\r',''))
    )
  ) {
    unclickableSave = "";
  }

  let notesCount = JSON.stringify(props.typedCharNotesValue.replaceAll('\r','')).length - 2;
  let campaignNotesCount = JSON.stringify(props.typedCampaignNotesValue.replaceAll('\r','')).length - 2;

  return(
    <div className="campaign-char-notes-container">
      <div className="campaign-char-notes-section char-notes-section-left">
        <div className="campaign-char-notes-header">
          Character Notes/Story
        </div>

        <div className="campaign-char-notes-input-wrapper">
          <div className="campaign-char-notes-limit">{`${notesCount}/3000`}</div>
          <label className="campaign-char-notes-input">
            <textarea
              className="campaign-char-notes-field"
              onChange={props.handleTypedCharNotesValueChange}
              value={props.typedCharNotesValue}
              placeholder="Notes"
            />
          </label>
        </div>
      </div>
      <div className="campaign-char-notes-section char-notes-section-right">
        <div className="campaign-char-notes-photo-section">
          <div className="campaign-char-notes-header">
            Character Image
          </div>
          <div className="campaign-char-notes-photo-container">
            <div className="campaign-char-notes-image-container">
              {userPhoto}
            </div>
            <div className="campaign-char-notes-image-buttons-container-wrapper">
              <div className="campaign-char-notes-image-buttons-container">
                <div className="campaign-char-notes-photo-button-row">
                  <div className="campaign-actions-image-button-file campaign-char-notes-image-button">
                    Choose Image
                    <input
                      type='file'
                      name='file'
                      key={Date.now().toString()}
                      onChange={props.handleChange}
                    />
                  </div>
                </div>
                <div className="campaign-char-notes-photo-button-row">
                  <div
                    className={`campaign-actions-image-button campaign-char-notes-image-button ${unclickable}`}
                    onClick={props.resetFields}
                  >
                    Reset
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="campaign-char-notes-campaign-notes">
          <div className="campaign-char-notes-header">
            Campaign Notes
          </div>
          <div className="campaign-char-notes-input-wrapper">
            <div className="campaign-char-notes-limit">{`${campaignNotesCount}/1000`}</div>
            <label className="campaign-char-notes-input">
              <textarea
                className="campaign-char-notes-field"
                onChange={props.handleTypedCampaignNotesValueChange}
                value={props.typedCampaignNotesValue}
                placeholder="Notes"
              />
            </label>
          </div>
        </div>
      </div>
      <div
        className={`campaign-char-notes-save-container ${unclickableSave}`}
        onClick={props.onSave}
      >
        <div className="campaign-char-notes-save">
          Save
        </div>
      </div>
    </div>
  )
}

export default CampaignCharSheetNotes
