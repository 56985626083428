import React from "react";
// import { Link } from 'react-router-dom';
//import objToQueryString from "../functions/functions";


const DescPrompt = props => {

  // console.log(props)

  return (
    <div className=''>
      {props.desc.object_text}
    </div>
  );
}

export default DescPrompt;
