import React from 'react';
import { withRouter } from 'react-router-dom';

class ProcessPasswordReset extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: "",
      repeatPassword: "",
      loading: false,
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleRepeatPasswordChange = this.handleRepeatPasswordChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.registerUser = this.registerUser.bind(this);
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleRepeatPasswordChange(event) {
    this.setState({ repeatPassword: event.target.value });
  }

  handleKeyPress(e) {
    e.preventDefault();
    if (e.key === 'Enter') {
      this.handleFormSubmit();
    }
  }

  handleFormSubmit(event=null) {
    if (event !== null) {
      event.preventDefault();
    }
    let formPayload = new FormData();
    formPayload.append('type', "reset_pass");
    formPayload.append('token', this.props.match.params.token);
    formPayload.append('new_password', this.state.password);
    formPayload.append('repeat_pass', this.state.repeatPassword);
    this.registerUser(formPayload);
  }

  async registerUser(formPayload) {
    this.setState((prevState) => {
      return {
        loading: true
      };
    });
    let url = '/api/v1/edit_user';
    const response = await fetch(url, {
      method: 'PUT',
      body: formPayload,
      credentials: 'same-origin'
    });
    const json = await response.json();
    this.setState((prevState) => {
      return {
        loading: false
      };
    });
    this.props.pop(json.msg, json.status);
    if(response.status === 200) {
      this.props.history.push("/login");
      await this.props.displayUser();
    }
  }

  render() {
    // let signedIn = this.props.signedIn;

    return(
      <div className="wholePage" id="container">
        <div className="signup-form-container">
          <div className="signup-form-header">
            Reset Password
          </div>
          <form className='signup-form'>
            <div className='signup-form-inner-wrapper'>
              <div className='signup-form-fields-cluster'>
                <label className="inputField signup-form-field">
                  <input
                    className="field signup-form-field-inner"
                    type='password'
                    onChange={this.handlePasswordChange}
                    onKeyPress={event => {if (event.key === 'Enter') {return this.handleKeyPress(event)}}}
                    value={this.passwordContent}
                    placeholder={'Password'}
                  />
                </label>
                <label className="inputField signup-form-field">
                  <input
                    className="field signup-form-field-inner"
                    type='password'
                    onChange={this.handleRepeatPasswordChange}
                    onKeyPress={event => {if (event.key === 'Enter') {return this.handleKeyPress(event)}}}
                    value={this.repeatPasswordContent}
                    placeholder={'Retype Password'}
                  />
                </label>
              </div>
              <div className="signup-form-button" type='submit' onClick={this.handleFormSubmit} value='Submit'>
                Submit
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(ProcessPasswordReset)
