import React from "react";
// import { Link } from 'react-router-dom';
import DescPrompt from "./DescPrompt";
//import objToQueryString from "../functions/functions";
import check from "../../../icons/check.png";
import exclamation from "../../../icons/exclamation.png";

const TraitPrompt = props => {

  let descs = props.trait.descs.map((desc, index) => {
    return <DescPrompt
            key={index}
            desc={desc}
           />
  });

  let status;
  let chosenSelections = [];
  props.traitSelections.forEach((selection) => {
    const val = selection.value ? String(selection.value) : "";
    if (
      props.trait.id === selection.trait_id &&
      !selection.selection.force_added
    ) {
      chosenSelections.push(
        selection.name + val
      );
    }
  });
  let renderedChosenSelections = chosenSelections.map((selection, index) => {
    let plus = selection ? <i className="fa-solid fa-plus chosen-selection-subtext-symbol"></i> : null;
    return <div key={index} className="chosen-selection-subtext">{plus} {selection}</div>
  });

  let totalNeeded = 0;
  let totalMet = 0;
  props.trait.selections.forEach((selection) => {
    if (!selection.force_added) {
      totalNeeded += 1;
      let chosenSelections = props.traitSelections.filter(pool =>
        selection.id === pool.selection.id &&
        !pool.selection.force_added
      );
      if (chosenSelections.length === selection.num_selections) {
        totalMet += 1;
      }
    }
  });

  let disabled = null;
  if (props.trait.is_disabled === true) {
    disabled = "disabled-trait";
  } else {
    disabled = "selectableTrait"
    if (totalMet === totalNeeded) {
      status = <img className="statusIcon" src={check} alt=""/>;
    } else {
      status = <img className="statusIcon" src={exclamation} alt=""/>;
    }
  }

  let readMore = props.trait.descs
  ? <div
      onClick={((e) => props.onReadMoreClick(e, 'trait', props.trait))}
      className='auto-trait-read-more'
    >
      <i className="fa-solid fa-circle-info"></i>
    </div>
  : null;

  return (
    <div className={`auto-traits-and-descs ${disabled} ${props.selectedIndex}`}
      onClick={((e) => props.handleTraitClick(e, props.trait))}
    >
      <div className='auto-trait'>
        <div>
          {props.trait.name}
        </div>
        <div>
          {renderedChosenSelections}
        </div>
        <div>
          {status}
        </div>
      </div>
      <div className='auto-trait-desc'>
        {descs}
      </div>
      {readMore}
    </div>
  );
}

export default TraitPrompt;
