import React from 'react';
import { withRouter } from 'react-router-dom';
import SignUpForm from '../components/SignUpForm';

class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      displayName: "",
      email: "",
      password: "",
      repeatPassword: "",
      loading: false,
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleDisplayNameChange = this.handleDisplayNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleRepeatPasswordChange = this.handleRepeatPasswordChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.registerUser = this.registerUser.bind(this);
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  handleDisplayNameChange(event) {
    this.setState({ displayName: event.target.value });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleRepeatPasswordChange(event) {
    this.setState({ repeatPassword: event.target.value });
  }

  handleKeyPress(e) {
    e.preventDefault();
    if (e.key === 'Enter') {
      this.handleFormSubmit();
    }
  }

  handleFormSubmit(event=null) {
    if (event !== null) {
      event.preventDefault();
    }
    let formPayload = new FormData();
    formPayload.append('username', this.state.username);
    formPayload.append('display_name', this.state.displayName);
    formPayload.append('email', this.state.email);
    formPayload.append('password', this.state.password);
    formPayload.append('repeat_pass', this.state.repeatPassword);
    this.registerUser(formPayload);
  }

  async registerUser(formPayload) {
    this.setState((prevState) => {
      return {
        loading: true
      };
    });
    let url = '/api/v1/register';
    const response = await fetch(url, {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    });
    const json = await response.json();
    this.setState((prevState) => {
      return {
        loading: false
      };
    });
    this.props.pop(json.msg, json.status);
    if(response.status === 200) {
      this.props.history.push("/check_email");
      await this.props.displayUser();
    } else {
      this.props.history.push("/register");
    }
  }

  render() {
    let signedIn = this.props.signedIn;

    return(
      <div className="wholePage" id="container">
        { signedIn === true ? null :
          <SignUpForm
            msg={this.state.msg}
            handleKeyPress={this.handleKeyPress}
            handleSubmit={this.handleFormSubmit}
            usernameContent={this.state.username}
            displayNameContent={this.state.displayName}
            emailContent={this.state.email}
            passwordContent={this.state.password}
            repeatPasswordContent={this.state.repeatPassword}
            handleUsernameChange={this.handleUsernameChange}
            handleDisplayNameChange={this.handleDisplayNameChange}
            handleEmailChange={this.handleEmailChange}
            handlePasswordChange={this.handlePasswordChange}
            handleRepeatPasswordChange={this.handleRepeatPasswordChange}
            loading={this.state.loading}
          />
        }
      </div>
    )
  }
}

export default withRouter(SignUp)
