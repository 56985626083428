import React from 'react';

const QuikInventory = props => {

  let items = props.items.map((item,index) => {
    let quantity = " (x" + item.quantity.toString() + ")";
    return <div className="quik-inventory-item" key={index}>{item.name}{quantity}</div>;
  });

  let cp = props.cp;
  let sp = props.sp;
  let ep = props.ep;
  let gp = props.gp;
  let pp = props.pp;

  return (
    <div className="quik-inventory">
      <div className="quik-inventory-monies">
        <div className="quik-inventory-currency">
          <div className="quik-inventory-currency-name">CP</div>
          <div className="quik-inventory-currency-value">{cp}</div>
        </div>
        <div className="quik-inventory-currency">
          <div className="quik-inventory-currency-name">SP</div>
          <div className="quik-inventory-currency-value">{sp}</div>
        </div>
        <div className="quik-inventory-currency">
          <div className="quik-inventory-currency-name">EP</div>
          <div className="quik-inventory-currency-value">{ep}</div>
        </div>
        <div className="quik-inventory-currency">
          <div className="quik-inventory-currency-name">GP</div>
          <div className="quik-inventory-currency-value">{gp}</div>
        </div>
        <div className="quik-inventory-currency">
          <div className="quik-inventory-currency-name">PP</div>
          <div className="quik-inventory-currency-value">{pp}</div>
        </div>
      </div>
      <div className="quik-inventory-items-title">
        Items
      </div>
      <div className="quik-inventory-items">
        {items}
      </div>
    </div>
  );
}
// <div className={`quik-stat ${props.rounded} ${props.sizing}`}>

export default QuikInventory;
