import React from 'react';

const ConfirmRemoveChar = props => {

  return(
    <div className='pop-up-2nd'>
      <div className='campaign-confirm-drop-popup-inner-2nd'>
        <div className='campaign-confirm-drop-popup-container'>
          <div className='campaign-confirm-drop-popup-title'>
            Remove Character From Campaign?
          </div>
          <div className="campaign-confirm-drop-popup-button-container">
            <div className="popup-button pop-up-yes" type='submit' onClick={((e) => props.onSubmit(e, props.requestedRemoveCharacter))}>
              Submit
            </div>
            <div className="popup-button pop-up-no" type='submit' onClick={((e) => props.closePop(e))}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmRemoveChar
