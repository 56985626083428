import React, { Component } from "react";
import { Link } from 'react-router-dom'
import HomeCampaignRow from "./HomeCampaignRow";
import Loader from '../components/Loader.js';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      loading: true
    };
    this.displayUser = this.displayUser.bind(this);
  }

  componentDidMount() {
    this.displayUser();
  }

  displayUser() {
    fetch('/api/v1/current_user', {
      credentials: 'same-origin',
      method: 'GET',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState({
          user: res.body,
          loading: false
        });
      } else {
        this.setState({
          user: null,
          loading: false
        });
      }
    }));
  }


  render() {

    let campaigns;
    let firstRow;
    let features;
    let campaignsSection;
    if (this.state.user) {
      if (!this.state.user.campaigns || this.state.user.campaigns.length === 0) {
        campaignsSection = <div className="home-campaigns-container">
                              <div className="home-campaigns-container-inner">
                                <div className="home-campaigns-reminder">You're not a member of a campaign yet!</div>
                                <div className="home-campaigns-reminder">Check out the "Campaigns" section above to explore public campaigns, or to create your own as a DM!</div>
                                <div className="home-campaigns-reminder">Still waiting for an invite from your DM? If you haven't found an invite in your account notifications, be sure to remind your DM to send you an invite.</div>
                              </div>
                          </div>
      } else {
        campaigns = this.state.user.campaigns.map((campaign, index) => {
          const dms = campaign.groups.filter(group =>
            group.dm === true
          );
          return  <HomeCampaignRow
                    key={index+1}
                    id={campaign.id}
                    name={campaign.name}
                    groupSize={campaign.groups.length}
                    dms={dms}
                  />
        });
        firstRow = <HomeCampaignRow
                      key={0}
                      id={null}
                      name={null}
                      groupSize={null}
                      dms={null}
                    />
        campaignsSection = <div className="home-campaigns-container">
                            <div className="home-campaigns-container-inner">
                              <div className="home-campaigns-header">Check out your campaigns</div>
                              <div className='home-campaigns-table-container'>
                                {firstRow}
                                {campaigns}
                              </div>
                            </div>
                          </div>
      }
    } else {
      campaignsSection = <div className="home-campaigns-container">
                          <div className="home-campaigns-not-logged-in-header">
                            Log in to see your campaigns!
                          </div>
                        </div>
    }

    // let listOfFutureFeatures = [
    //   'Include a \'spellcasting bonus\' section on character page in campaigns',
    //   'Include more fields (ie. Ki Points) on character page in campaigns.',
    //   'Campaign interactivity between campaign members.',
    //   'Campaign Chat with option to "whisper" in private to other players.',
    //   'Allow outside users to view more details within public campaigns.',
    //   'More settings for DMs of Campaigns to utilize.',
    //   'Other forms of alternate item storage become available as toggle-able inventories in campaigns.',
    //   'Campaign Log of Events (such as dice rolls, added/dropped/given items, etc).',
    //   'Multi-classing.',
    //   'Leveling down a character to undo unwanted/unintended changes.',
    // ]
    //
    // features = listOfFutureFeatures.map((feature, index) => {
    //   return <div className="dream-feature">
    //           {`• ${feature}`}
    //          </div>;
    // });

    if (this.state.loading !== false) return <Loader/>;
    const loader = this.state.loading ? <i className="fa fa-cog fa-spin" /> : null;

    return(
      <div className="wholePage" id="container">
        <div className="home-container">
          <div className="home-welcome-user-header">
            Welcome to Character Tracker (CharTrakr), a desktop-only web application!
          </div>
          <div className="home-sections-wrapper">
            {campaignsSection}
            <div className='home-features-video-section'>
              <div className='home-future-features-container'>
                <div className="dream-feature-title">We are actively working to make this a better site.  If you notice anything or have feedback for us, please let us know!</div>
                {features}
                <Link className="feedback-button-container" to='/feedback'>
                  <div className="feedback-button">Submit Feedback</div>
                </Link>
                <iframe src='https://www.youtube.com/embed/uc6IYOsuaHs'
                  frameBorder='0'
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                  allow='autoplay; fullscreen; encrypted-media'
                  title='video'
                  className='youtube-video'
                />
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    )
  }
}

export default Home
