import React from 'react';

const CampaignCharSheetNav = props => {

  let navBar;
  if (props.sections) {
    navBar = props.sections.map((section, index) => {
      let selected = '';
      if (props.selectedSection === section.code) {
        selected = 'campaign-nav-option-selected';
      }
      return <div
               key={index}
               className={`campaign-char-sheet-nav-option ${selected}`}
               onClick={((e) => props.handleSectionClick(e, section.code))}
             >
               <div className="campaign-nav-option-symbol">
                 <i className={`fa-solid ${section.symbol}`}></i>
               </div>
             </div>
    });
  }

  return(
    <div
      className="campaign-char-sheet-nav-container"
    >
      {navBar}
    </div>
  )
}

export default CampaignCharSheetNav
