import React from 'react';

const Slider = props => {
  return (
    <div className="campaign-actions-dm-action-slider">
      <label className="switch" htmlFor="checkbox">
        <input onChange={props.togglePublicPopUp} type="checkbox" id="checkbox" checked={props.public}/>
        <div className="slider round"></div>
      </label>
    </div>
  );
}

export default Slider;
