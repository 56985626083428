import React from 'react';

const CampaignsPopup = props => {
  // let username = null;
  // let actionType = null;
  // if (props.username) {
  //   username = props.username;
  // }
  // if (props.actionType) {
  //   actionType = props.actionType;
  // }
  let renderedResults;
  if (props.campaigns) {
    const campaigns = props.campaigns.map((campaign, index) => {
      return <div
              key={index}
              className='pop-up-clickable-result'
              onClick={((e) => props.onSubmit(e, campaign.id))}
            >
              {campaign.name}
            </div>
    });
    renderedResults = <div className="pop-up-clickable-results">
                        {campaigns}
                      </div>
  } else {
    renderedResults = null;
  }

  return (
    <div className='pop-up'>
      <div className='pop-up-inner'>
        <div className='pop-up-message'>
          <div className='pop-up-message-text'>
            {props.msg}
          </div>
        </div>
        {renderedResults}
        <div className="pop-up-buttons-container">
          <div
            className='pop-up-button-generic pop-up-no'
            onClick={props.closePopUp}
          >
            Go Back
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignsPopup;


// <div className='pop-up-button-container'>
// <div
// className='pop-up-button pop-up-yes'
// onClick={((e) => props.onSubmit(e))} value={username} name={actionType}
// >
// YES
// </div>
// </div>
