import React from 'react';

const CampaignCharSheetPowers = props => {


  let languages, traits, skillProficiencies,
      itemProficiencies, itemTypeProficiencies,
      savingThrowProficiencies, spellcastingRender,
      spellSlotsRender,
      // ki, rages, sorceryPoints, superiorityDice,
      features, feats, spells, expandedSpells;
  if (props.character) {
    const c = props.character;
    // ki = c.ki_points;
    // rages = c.rage_points;
    // sorceryPoints = c.sorcery_points;
    // superiorityDice = c.superiority_dice;
    languages = c.languages.map((power, index) => {
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, power.id, 'language'))}
              >
                <div className='campaign-char-power-wording'>{power.name}</div>
             </li>
    });
    traits = c.traits.map((power, index) => {
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, power.id, 'trait'))}
              >
                <div className='campaign-char-power-wording'>{power.name}</div>
             </li>
    });
    skillProficiencies = c.skill_proficiencies.map((power, index) => {
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, power.id, 'skill'))}
              >
                <div className='campaign-char-power-wording'>{power.name}</div>
             </li>
    });
    itemProficiencies = c.item_proficiencies.map((power, index) => {
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, power.id, 'item'))}
              >
                <div className='campaign-char-power-wording'>{power.name}</div>
             </li>
    });
    itemTypeProficiencies = c.item_type_proficiencies.map((power, index) => {
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-unclickable non-clickable"
              >
                <div className='campaign-char-power-wording'>{power.name}</div>
             </li>
    });
    savingThrowProficiencies = c.saving_throw_proficiencies.map((power, index) => {
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, power.id, 'ability'))}
              >
                <div className='campaign-char-power-wording'>{power.name}</div>
             </li>
    });
    features = c.features.map((power, index) => {
      if (power.is_visible_to_user) {
        return <li
                  key={index}
                  className="campaign-char-power campaign-char-power-clickable"
                  onClick={((e) => props.handleDetailClick(e, power.id, 'feature'))}
                >
                  <div className='campaign-char-power-wording'>{power.name}</div>
               </li>
      } else {
        return null;
      }
    });
    feats = c.feats.map((power, index) => {
      return <li
                key={index}
                className="campaign-char-power campaign-char-power-clickable"
                onClick={((e) => props.handleDetailClick(e, power.id, 'feat'))}
              >
                <div className='campaign-char-power-wording'>{power.name}</div>
             </li>
    });

    // var unpreparedKnownSpells = c.spells.filter(spell =>
    //   !c.prepared_spells.some(pSpell =>
    //     spell.id === pSpell.id
    //   )
    // )
    // var preparedKnownSpells = c.spells.filter(spell =>
    //   c.prepared_spells.some(pSpell =>
    //     spell.id === pSpell.id
    //   )
    // )
    // var sortedUnpreparedKnownSpells = unpreparedKnownSpells.sort(function (a, b) {
    //   return a.level - b.level || a.name.localeCompare(b.name);
    // });
    // var sortedPreparedKnownSpells = preparedKnownSpells.sort(function (a, b) {
    //   return a.level - b.level || a.name.localeCompare(b.name);
    // });
    // var renderedSortedUnpreparedKnownSpells = sortedUnpreparedKnownSpells.map((power, index) => {
    //   return <li
    //             key={index}
    //             className="campaign-char-power campaign-char-power-clickable"
    //             onClick={((e) => props.handleDetailClick(e, power.id, 'spell'))}
    //             onContextMenu={((e) => props.handlePreparation(e, power.id))}
    //           >
    //             <div className='campaign-char-power-wording'>{`(${power.level}) ${power.name}`}</div>
    //          </li>
    // });
    // var renderedSortedPreparedKnownSpells = sortedPreparedKnownSpells.map((power, index) => {
    //   return <li
    //             key={index}
    //             className="campaign-char-power campaign-char-power-clickable prepared-spell"
    //             onClick={((e) => props.handleDetailClick(e, power.id, 'spell'))}
    //             onContextMenu={((e) => props.handlePreparation(e, power.id))}
    //           >
    //             <div className='campaign-char-power-wording'>{`(${power.level}) ${power.name}`}</div>
    //          </li>
    // });
    // spells = renderedSortedPreparedKnownSpells.concat(renderedSortedUnpreparedKnownSpells);
    //
    //
    var sortedKnownSpells = c.spells.sort(function (a, b) {
          return a.level - b.level || a.name.localeCompare(b.name);
        });
    spells = sortedKnownSpells.map((power, index) => {
      let prepared;
      if (
        c.prepared_spells.some(spell =>
          spell.id === power.id
        )
      ) {
        prepared = 'prepared-spell';
      }
      return <li
                key={index}
                className={`campaign-char-power campaign-char-power-clickable ${prepared}`}
                onClick={((e) => props.handleDetailClick(e, power.id, 'spell'))}
                onContextMenu={((e) => props.handlePreparation(e, power.id))}
              >
                <div className='campaign-char-power-wording'>{`(${power.level}) ${power.name}`}</div>
             </li>
    });

    var totalPoolSpells = c.expanded_spells.concat(c.spell_pool);
    var sortedPoolSpells = totalPoolSpells.sort(function (a, b) {
          return a.level - b.level || a.name.localeCompare(b.name);
        });
    expandedSpells = sortedPoolSpells.map((power, index) => {
      let prepared;
      if (
        c.prepared_spells.some(spell =>
          spell.id === power.id
        )
      ) {
        prepared = 'prepared-spell';
      }
      return <li
                key={index}
                className={`campaign-char-power campaign-char-power-clickable ${prepared}`}
                onClick={((e) => props.handleDetailClick(e, power.id, 'spell'))}
                onContextMenu={((e) => props.handlePreparation(e, power.id))}
              >
                <div className='campaign-char-power-wording'>{`(${power.level}) ${power.name}`}</div>
             </li>
    });


    if (c.classification.ability) {
      spellSlotsRender =  <div className="spell-slots-container">
                            <div className="spell-slot-column">
                              <div className="spell-slot-square-title">Spell Slots</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 1</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_1 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_1}</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 2</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_2 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_2}</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 3</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_3 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_3}</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 4</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_4 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_4}</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 5</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_5 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_5}</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 6</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_6 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_6}</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 7</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_7 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_7}</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 8</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_8 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_8}</div>
                            </div>
                            <div className="spell-slot-column">
                              <div className="spell-slot-square square-top">Lvl 9</div>
                              <div className={`spell-slot-square square-bottom ${c.spell_slots_lvl_9 === 0 ? 'darkened-power' : ''}`}>{c.spell_slots_lvl_9}</div>
                            </div>
                          </div>
      const spellcastingAbility = c.classification.ability.name;
      const abilityString = `${c.classification.ability.abbr.toLowerCase()}_score`;
      const modifier = Math.floor((c[abilityString] - 10) / 2);
      const spellcastingBonus = c.proficiency_bonus + modifier;
      const spellSaveDC = 8 + c.proficiency_bonus + modifier;
      spellcastingRender =  <div className="campaign-char-power-spellcasting-box">
                              <div className="campaign-char-spellcasting-box-container">
                                <div className="campaign-char-spellcasting-box-wrapper subtext">
                                  Spellcasting Bonus
                                </div>
                                <div className="campaign-char-spellcasting-box">
                                  {spellcastingBonus}
                                </div>
                              </div>
                              <div className="campaign-char-spellcasting-box-container">
                                <div className="campaign-char-spellcasting-box-wrapper subtext">
                                  Spellcasting Ability
                                </div>
                                <div className="campaign-char-spellcasting-box">
                                  {spellcastingAbility}
                                </div>
                              </div>
                              <div className="campaign-char-spellcasting-box-container">
                                <div className="campaign-char-spellcasting-box-wrapper subtext">
                                  Spell Save DC
                                </div>
                                <div className="campaign-char-spellcasting-box">
                                  {spellSaveDC}
                                </div>
                              </div>
                            </div>
    } else {
      spellcastingRender = <div className="campaign-char-spellcasting-not-possible">
                             Spellcasting not possible by normal means (it is still possible to acquire spells from other places. Defer to the descriptions of Abilities that grant you spells beyond the normal means).
                           </div>;
    }
  }

  return(
    <div
      className="campaign-char-powers-container"
    >
      <div className="campaign-char-powers-section">
        <div className="campaign-char-powers-section-header">
          Proficiencies
        </div>
        <div className="campaign-char-powers-section-content">
          <div className="campaign-char-powers-box">
            <div className="campaign-char-power-container campaign-char-power-proficiency">
              <div className="campaign-char-power-header">
                Known Languages
              </div>
              <ul className="campaign-char-power-content">
                {languages}
              </ul>
            </div>
            <div className="campaign-char-power-container campaign-char-power-proficiency">
              <div className="campaign-char-power-header">
                Skill Proficiencies
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {skillProficiencies}
                </ul>
              </div>
            </div>
            <div className="campaign-char-power-container campaign-char-power-proficiency">
              <div className="campaign-char-power-header">
                Saving Throw Proficiencies
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {savingThrowProficiencies}
                </ul>
              </div>
            </div>
            <div className="campaign-char-power-container campaign-char-power-proficiency">
              <div className="campaign-char-power-header">
                Item Type Proficiencies
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {itemTypeProficiencies}
                </ul>
              </div>
            </div>
            <div className="campaign-char-power-container campaign-char-power-proficiency">
              <div className="campaign-char-power-header">
                Item Proficiencies
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {itemProficiencies}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="campaign-char-powers-section">
        <div className="campaign-char-powers-section-header">
          Abilities
        </div>
        <div className="campaign-char-powers-section-content">
          <div className="campaign-char-powers-box">
            <div className="campaign-char-power-container campaign-char-traits-box-size">
              <div className="campaign-char-power-header">
                Racial Traits
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {traits}
                </ul>
              </div>
            </div>
            <div className="campaign-char-power-container campaign-char-features-box-size">
              <div className="campaign-char-power-header">
                Class Features
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {features}
                </ul>
              </div>
            </div>
            <div className="campaign-char-power-container campaign-char-feats-box-size">
              <div className="campaign-char-power-header">
                Feats
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {feats}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="campaign-char-powers-section">
        <div className="campaign-char-powers-section-header">
          Spells
        </div>
        <div className="campaign-char-powers-section-content">
          <div className="campaign-char-powers-box">
            <div className="campaign-char-power-container campaign-char-power-spell">
              <div className="campaign-char-power-header">
                Known Spells (right-click to mark)
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {spells}
                </ul>
              </div>
            </div>
            <div className="campaign-char-power-container campaign-char-power-spell">
              <div className="campaign-char-power-header">
                Class Spell Pool (right-click to mark)
              </div>
              <div className="campaign-ul-wrapper">
                <ul className="campaign-char-power-content">
                  {expandedSpells}
                </ul>
              </div>
            </div>
            <div className="campaign-char-power-container campaign-char-bonus-fields-box-size">
              <div className="campaign-char-power-header">
                Spellcasting
              </div>
              {spellSlotsRender}
              {spellcastingRender}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignCharSheetPowers
