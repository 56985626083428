import React, { Component } from 'react';
import QuikStat from "./QuikStat";
import QuikAbility from "./QuikAbility";
import QuikInventory from "./QuikInventory";
import StatTab from "./StatTab";


// README -- how this component should work is it will receive character information (in other words
// it is locked in information) The other, more temporary information this component can receive are
// characterSelections which are being updated in the current selection process (such as Race, or Class)
// When the user saves the selections from that section, those will be added to the character and be re-
// rendered to this component - then the temporary selections will clear.

// the back-end will save this information, and if there is already a completed section which a user wants
// to redo, then the API will find character.selections where selection.trait_id (or .class_level_id, etc)
// is not null and remove those from character when the new selections are saved
class QuikStats extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'Stats',
      tabs: [
        {id: 1, name: 'Stats'},
        {id: 2, name: 'Proficiencies'},
        {id: 3, name: 'Powers'},
        {id: 4, name: 'Inventory'}
      ],
      // statFields: {}
    };
    this.handleClickTab = this.handleClickTab.bind(this);
    // this.parseData = this.parseData.bind(this);
  }

  componentDidMount() {
    // console.log(this.props);
    // this.parseData();
  }

  // parseData() {
  //
  //   this.setState({
  //     statFields: newlyReflectedStats
  //   });
  // }

  handleClickTab(e, name) {
    e.preventDefault();
    if (this.state.selectedTab !== name) {
      this.setState({
        selectedTab: name
      });
    }
  }

  render() {
    // make sure that all character attrs
    // match what is serialized back to React side.

    // payload: what overrides the
    // actual character sheet presentation:

    let character = this.props.character;
    // a different payload comes from each selection process
    let bioPayload = this.props.bioPayload;
    let racePayload = this.props.racePayload;
    let classPayload = this.props.classPayload;
    let backgroundPayload = this.props.backgroundPayload;
    let characterSelections = this.props.characterSelections;

    var newlyReflectedStats = Object.assign({});
    newlyReflectedStats.skill_proficiencies = [];
    newlyReflectedStats.item_proficiencies = [];
    newlyReflectedStats.item_type_proficiencies = [];
    newlyReflectedStats.saving_throw_proficiencies = [];
    newlyReflectedStats.languages = [];
    newlyReflectedStats.spells = [];
    newlyReflectedStats.traits = [];
    newlyReflectedStats.features = [];
    newlyReflectedStats.backgroundFeatures = [];
    newlyReflectedStats.feats = [];
    newlyReflectedStats.expanded_spells = [];
    newlyReflectedStats.inventory = {
      items:[],
      cp:0,
      sp:0,
      ep:0,
      gp:0,
      pp:0
    };


    if (character) {
      if (character.str_score) {newlyReflectedStats.strengthScore = character.str_score;}
      if (character.dex_score) {newlyReflectedStats.dexterityScore = character.dex_score;}
      if (character.con_score) {newlyReflectedStats.constitutionScore = character.con_score;}
      if (character.int_score) {newlyReflectedStats.intelligenceScore = character.int_score;}
      if (character.wis_score) {newlyReflectedStats.wisdomScore = character.wis_score;}
      if (character.cha_score) {newlyReflectedStats.charismaScore = character.cha_score;}
      newlyReflectedStats.speed = character.speed ? character.speed : 0;
      newlyReflectedStats.proficiency = character.proficiency_bonus ? character.proficiency_bonus : 0;
      newlyReflectedStats.hitpoints = character.max_hp ? character.max_hp : 0;
      let variantText = character.is_variant_background ? ` (${character.background.variant_name})` : '';
      newlyReflectedStats.background = character.background ? `${character.background.name}${variantText}` : null;
      newlyReflectedStats.classification = character.classification ? character.classification.name : null;
      newlyReflectedStats.subrace = character.race ? character.race.name : null;
      newlyReflectedStats.race = character.archrace ? character.archrace.name : null;
      newlyReflectedStats.level = character.level ? character.level : 1;
      newlyReflectedStats.characterName = character.name ? character.name : null;
      // newlyReflectedStats.traits = character.traits ? character.traits : [];
      // newlyReflectedStats.features = character.features ? character.features : [];
      // newlyReflectedStats.backgroundFeatures = character.background_features ? character.background_features : [];
      if (character.traits) {
        character.traits.forEach((trait) => {
          newlyReflectedStats.traits.push(trait.name);
        });}
      if (character.features) {
        character.features.forEach((feature) => {
          newlyReflectedStats.features.push(feature.name);
        });}
      if (character.background_features) {
        character.background_features.forEach((backgroundFeature) => {
          newlyReflectedStats.backgroundFeatures.push(backgroundFeature.name);
        });}
      // newlyReflectedStats.personality = character.personality ? character.personality : null;
      // newlyReflectedStats.flaw = character.flaw ? character.flaw : null;
      // newlyReflectedStats.ideal = character.ideal ? character.ideal : null;
      // newlyReflectedStats.bond = character.bond ? character.bond : null;
      // newlyReflectedStats.characteristic = character.characteristic ? character.characteristic : null;
      // newlyReflectedStats.alternate_checked = character.alternate_checked ? character.alternate_checked : null;

      if (character.inventory) {
        let inventory = [];
        if (character.inventory.armor) {
          inventory = inventory.concat(character.inventory.armor)
        }
        if (character.inventory.weapons) {
          inventory = inventory.concat(character.inventory.weapons)
        }
        if (character.inventory.gear) {
          inventory = inventory.concat(character.inventory.gear)
        }
        if (character.inventory.tools) {
          inventory = inventory.concat(character.inventory.tools)
        }
        if (character.inventory.mounts) {
          inventory = inventory.concat(character.inventory.mounts)
        }
        newlyReflectedStats.inventory.items = inventory.map((pickup) => {
          return {
            'name': pickup.item.name,
            'quantity': pickup.quantity
          };
        });
        newlyReflectedStats.inventory.platinum_points = character.inventory.platinum_points ? character.inventory.platinum_points : 0;
        newlyReflectedStats.inventory.gold_points = character.inventory.gold_points ? character.inventory.gold_points : 0;
        newlyReflectedStats.inventory.electrum_points = character.inventory.electrum_points ? character.inventory.electrum_points : 0;
        newlyReflectedStats.inventory.silver_points = character.inventory.silver_points ? character.inventory.silver_points : 0;
        newlyReflectedStats.inventory.copper_points = character.inventory.copper_points ? character.inventory.copper_points : 0;
      } else {
        newlyReflectedStats.inventory.items = [];
        newlyReflectedStats.inventory.platinum_points = 0;
        newlyReflectedStats.inventory.gold_points = 0;
        newlyReflectedStats.inventory.electrum_points = 0;
        newlyReflectedStats.inventory.silver_points = 0;
        newlyReflectedStats.inventory.copper_points = 0;
      }
      if (character.languages) {
        character.languages.forEach((language) => {
          if (!newlyReflectedStats.languages.includes(language.name)) {
            newlyReflectedStats.languages.push(language.name);
          }
        });}
      if (character.item_proficiencies) {
        character.item_proficiencies.forEach((item_proficiency) => {
          if (!newlyReflectedStats.item_proficiencies.includes(item_proficiency.name)) {
            newlyReflectedStats.item_proficiencies.push(item_proficiency.name);
          }
        });}
      if (character.item_type_proficiencies) {
        character.item_type_proficiencies.forEach((item_type_proficiency) => {
          if (!newlyReflectedStats.item_type_proficiencies.includes(item_type_proficiency.name)) {
            newlyReflectedStats.item_type_proficiencies.push(item_type_proficiency.name);
          }
        });}
      if (character.saving_throw_proficiencies) {
        character.saving_throw_proficiencies.forEach((saving_throw_proficiency) => {
          if (!newlyReflectedStats.saving_throw_proficiencies.includes(saving_throw_proficiency.name)) {
            newlyReflectedStats.saving_throw_proficiencies.push(saving_throw_proficiency.name);
          }
        });}
      if (character.skill_proficiencies) {
        character.skill_proficiencies.forEach((skill_proficiency) => {
          if (!newlyReflectedStats.skill_proficiencies.includes(skill_proficiency.name)) {
            newlyReflectedStats.skill_proficiencies.push(skill_proficiency.name);
          }
        });}
      if (character.feats) {
        character.feats.forEach((feat) => {
          if (!newlyReflectedStats.feats.includes(feat.name)) {
            newlyReflectedStats.feats.push(feat.name);
          }
        });}
      if (character.spells) {
        character.spells.forEach((spell) => {
          if (!newlyReflectedStats.spells.includes(spell.name)) {
            newlyReflectedStats.spells.push(spell.name);
          }
        });}
      if (character.expanded_spells) {
        character.expanded_spells.forEach((expanded_spell) => {
          if (!newlyReflectedStats.expanded_spells.includes(expanded_spell.name)) {
            newlyReflectedStats.expanded_spells.push(expanded_spell.name);
          }
        });}
    }
    if (characterSelections.length > 0) {
      // console.log('calc\'d character selections');
      characterSelections.forEach((characterSelection) => {
        if (characterSelection.selection.target === 'ability') {
          if (characterSelection.name === 'Strength') {
            if (newlyReflectedStats.strengthScore) {
              newlyReflectedStats.strengthScore += characterSelection.selection.increment_value;
            } else {
              newlyReflectedStats.strengthScore = characterSelection.selection.increment_value;
            }
          } else if (characterSelection.name === 'Dexterity') {
            if (newlyReflectedStats.dexterityScore) {
              newlyReflectedStats.dexterityScore += characterSelection.selection.increment_value;
            } else {
              newlyReflectedStats.dexterityScore = characterSelection.selection.increment_value;
            }
          } else if (characterSelection.name === 'Constitution') {
            if (newlyReflectedStats.constitutionScore) {
              newlyReflectedStats.constitutionScore += characterSelection.selection.increment_value;
            } else {
              newlyReflectedStats.constitutionScore = characterSelection.selection.increment_value;
            }
          } else if (characterSelection.name === 'Intelligence') {
            if (newlyReflectedStats.intelligenceScore) {
              newlyReflectedStats.intelligenceScore += characterSelection.selection.increment_value;
            } else {
              newlyReflectedStats.intelligenceScore = characterSelection.selection.increment_value;
            }
          } else if (characterSelection.name === 'Wisdom') {
            if (newlyReflectedStats.wisdomScore) {
              newlyReflectedStats.wisdomScore += characterSelection.selection.increment_value;
            } else {
              newlyReflectedStats.wisdomScore = characterSelection.selection.increment_value;
            }
          } else if (characterSelection.name === 'Charisma') {
            if (newlyReflectedStats.charismaScore) {
              newlyReflectedStats.charismaScore += characterSelection.selection.increment_value;
            } else {
              newlyReflectedStats.charismaScore = characterSelection.selection.increment_value;
            }
          }
        } else if (characterSelection.selection.target === 'speed') {
            newlyReflectedStats.speed = characterSelection.selection.increment_value;
        } else if (characterSelection.selection.target === 'pickups') {
          let item = {
            'name':characterSelection.name,
            'quantity': characterSelection.quantity
          };
          if (newlyReflectedStats.inventory.items !== null) {
            newlyReflectedStats.inventory.items.push(item);
          } else {
            newlyReflectedStats.inventory.items = [item];
          }
        } else if (characterSelection.selection.target === 'copper_points') {
          newlyReflectedStats.inventory.copper_points = characterSelection.selection.increment_value;
        } else if (characterSelection.selection.target === 'silver_points') {
          newlyReflectedStats.inventory.silver_points = characterSelection.selection.increment_value;
        } else if (characterSelection.selection.target === 'gold_points') {
          newlyReflectedStats.inventory.gold_points = characterSelection.selection.increment_value;
        } else if (characterSelection.selection.target === 'electrum_points') {
          newlyReflectedStats.inventory.electrum_points = characterSelection.selection.increment_value;
        } else if (characterSelection.selection.target === 'platinum_points') {
          newlyReflectedStats.inventory.platinum_points = characterSelection.selection.increment_value;
          // append this trait/feature/etc to powers -> traits/features/etc if not already appended
          // newlyReflectedStats.platinum += characterSelection.platinum ? characterSelection.platinum : 0;
          // newlyReflectedStats.gold += characterSelection.gold ? characterSelection.gold : 0;
          // newlyReflectedStats.electrum += characterSelection.electrum ? characterSelection.electrum : 0;
          // newlyReflectedStats.silver += characterSelection.silver ? characterSelection.silver : 0;
          // newlyReflectedStats.copper += characterSelection.copper ? characterSelection.copper : 0;
        } else if (characterSelection.selection.target === 'languages') {
          if (!newlyReflectedStats.languages.includes(characterSelection.name)) {
            newlyReflectedStats.languages.push(characterSelection.name);
          }
        } else if (characterSelection.selection.target === 'item_proficiencies') {
          if (!newlyReflectedStats.item_proficiencies.includes(characterSelection.name)) {
            newlyReflectedStats.item_proficiencies.push(characterSelection.name);
          }
        } else if (characterSelection.selection.target === 'item_type_proficiencies') {
          if (!newlyReflectedStats.item_type_proficiencies.includes(characterSelection.name)) {
            newlyReflectedStats.item_type_proficiencies.push(characterSelection.name);
          }
        } else if (characterSelection.selection.target === 'saving_throw_proficiencies') {
          if (!newlyReflectedStats.saving_throw_proficiencies.includes(characterSelection.name)) {
            newlyReflectedStats.saving_throw_proficiencies.push(characterSelection.name);
          }
        } else if (characterSelection.selection.target === 'skill_proficiencies') {
          if (!newlyReflectedStats.skill_proficiencies.includes(characterSelection.name)) {
            newlyReflectedStats.skill_proficiencies.push(characterSelection.name);
          }
        } else if (characterSelection.selection.target === 'feats') {
          if (!newlyReflectedStats.feats.includes(characterSelection.name)) {
            newlyReflectedStats.feats.push(characterSelection.name);
          }
        } else if (characterSelection.selection.target === 'spells') {
          if (!newlyReflectedStats.spells.includes(characterSelection.name)) {
            newlyReflectedStats.spells.push(characterSelection.name);
          }
        } else if (characterSelection.selection.target === 'spells') {
          if (!newlyReflectedStats.expanded_spells.includes(characterSelection.name)) {
            newlyReflectedStats.expanded_spells.push(characterSelection.name);
          }
        // } else if (characterSelection.selection.target === 'dragon_chromatic') {
          // newlyReflectedStats.dragon_chromatics.push(characterSelection.name);
        } else if (characterSelection.selection.target === 'features') {
          if (!newlyReflectedStats.features.includes(characterSelection.name)) {
            newlyReflectedStats.features.push(characterSelection.name);
          }
        }
      });
    }
    if (Object.keys(bioPayload).length > 0) {
      if (bioPayload.characterName) {newlyReflectedStats.characterName = bioPayload.characterName;}
      if (newlyReflectedStats.strengthScore) {
        newlyReflectedStats.strengthScore += bioPayload.strengthScore ? bioPayload.strengthScore : 0;
      } else if (bioPayload.strengthScore || bioPayload.strengthScore === 0) {
        newlyReflectedStats.strengthScore = bioPayload.strengthScore;
      }
      if (newlyReflectedStats.dexterityScore) {
        newlyReflectedStats.dexterityScore += bioPayload.dexterityScore ? bioPayload.dexterityScore : 0;
      } else if (bioPayload.dexterityScore || bioPayload.dexterityScore === 0) {
        newlyReflectedStats.dexterityScore = bioPayload.dexterityScore;
      }
      if (newlyReflectedStats.constitutionScore) {
        newlyReflectedStats.constitutionScore += bioPayload.constitutionScore ? bioPayload.constitutionScore : 0;
      } else if (bioPayload.constitutionScore || bioPayload.constitutionScore === 0) {
        newlyReflectedStats.constitutionScore = bioPayload.constitutionScore;
      }
      if (newlyReflectedStats.intelligenceScore) {
        newlyReflectedStats.intelligenceScore += bioPayload.intelligenceScore ? bioPayload.intelligenceScore : 0;
      } else if (bioPayload.intelligenceScore || bioPayload.intelligenceScore === 0) {
        newlyReflectedStats.intelligenceScore = bioPayload.intelligenceScore;
      }
      if (newlyReflectedStats.wisdomScore) {
        newlyReflectedStats.wisdomScore += bioPayload.wisdomScore ? bioPayload.wisdomScore : 0;
      } else if (bioPayload.wisdomScore || bioPayload.wisdomScore === 0) {
        newlyReflectedStats.wisdomScore = bioPayload.wisdomScore;
      }
      if (newlyReflectedStats.charismaScore) {
        newlyReflectedStats.charismaScore += bioPayload.charismaScore ? bioPayload.charismaScore : 0;
      } else if (bioPayload.charismaScore || bioPayload.charismaScore === 0) {
        newlyReflectedStats.charismaScore = bioPayload.charismaScore;
      }
      if (bioPayload.faith) {newlyReflectedStats.faith = bioPayload.faith;}
      if (bioPayload.alignment) {newlyReflectedStats.alignment = bioPayload.alignment;}
      if (bioPayload.volatility) {newlyReflectedStats.volatility = bioPayload.volatility;}
      if (bioPayload.height) {newlyReflectedStats.height = bioPayload.height;}
      if (bioPayload.weight) {newlyReflectedStats.weight = bioPayload.weight;}
      if (bioPayload.age) {newlyReflectedStats.age = bioPayload.age;}
      // if (bioPayload.selectedVolatility) {newlyReflectedStats.selectedVolatility = bioPayload.selectedVolatility;}
      // if (bioPayload.selectedAlignment) {newlyReflectedStats.selectedAlignment = bioPayload.selectedAlignment;}
    }
    if (Object.keys(racePayload).length > 0) {
      // newlyReflectedStats.strengthScore += racePayload.strengthScore ? racePayload.strengthScore : 0;
      // newlyReflectedStats.dexterityScore += racePayload.dexterityScore ? racePayload.dexterityScore : 0;
      // newlyReflectedStats.constitutionScore += racePayload.constitutionScore ? racePayload.constitutionScore : 0;
      // newlyReflectedStats.intelligenceScore += racePayload.intelligenceScore ? racePayload.intelligenceScore : 0;
      // newlyReflectedStats.wisdomScore += racePayload.wisdomScore ? racePayload.wisdomScore : 0;
      // newlyReflectedStats.charismaScore += racePayload.charismaScore ? racePayload.charismaScore : 0;
      // newlyReflectedStats.platinum += racePayload.platinum ? racePayload.platinum : 0;
      // newlyReflectedStats.gold += racePayload.gold ? racePayload.gold : 0;
      // newlyReflectedStats.electrum += racePayload.electrum ? racePayload.electrum : 0;
      // newlyReflectedStats.silver += racePayload.silver ? racePayload.silver : 0;
      // newlyReflectedStats.copper += racePayload.copper ? racePayload.copper : 0;
      // newlyReflectedStats.hitpoints += racePayload.hitpoints ? racePayload.hitpoints : 0;
      if (racePayload.speed) {newlyReflectedStats.speed = racePayload.speed;}
      if (racePayload.subrace) {newlyReflectedStats.subrace = racePayload.subrace;}
      if (racePayload.race) {newlyReflectedStats.race = racePayload.race;}
      if (racePayload.traits) {
        racePayload.traits.forEach((trait) => {
          newlyReflectedStats.traits.push(trait.name);
        });}
    }
    if (Object.keys(classPayload).length > 0) {
      newlyReflectedStats.proficiency += classPayload.proficiency && !newlyReflectedStats.proficiency ? classPayload.proficiency : 0;
      newlyReflectedStats.hitpoints += classPayload.hitpoints && !newlyReflectedStats.hitpoints ? classPayload.hitpoints : 0;
      if (classPayload.classification && !newlyReflectedStats.classification) {newlyReflectedStats.classification = classPayload.classification;}
      if (classPayload.level && !newlyReflectedStats.level) {newlyReflectedStats.level = classPayload.level;}
      if (classPayload.features) {
        classPayload.features.forEach((feature) => {
          newlyReflectedStats.features.push(feature.name);
        });
      }
    }
    if (Object.keys(backgroundPayload).length > 0) {
      let variantText = backgroundPayload.is_variant_background ? ` (${backgroundPayload.variant_name})` : ''
      if (backgroundPayload.background) {newlyReflectedStats.background = `${backgroundPayload.background}${variantText}`;}
    }
    //
      // bio_done
      // auto_races_done
      // auto_classes_done
      // auto_backgrounds_done
      // final calculations

    //section-dependent calcs
    if (
      (character && character.bio_done) ||
      this.props.currentSection === 'bio'
    ) {
      newlyReflectedStats.strength = newlyReflectedStats.strengthScore || newlyReflectedStats.strengthScore === 0 ? Math.floor((newlyReflectedStats.strengthScore - 10) / 2) : 0;
      newlyReflectedStats.dexterity = newlyReflectedStats.dexterityScore || newlyReflectedStats.dexterityScore === 0 ? Math.floor((newlyReflectedStats.dexterityScore - 10) / 2) : 0;
      newlyReflectedStats.constitution = newlyReflectedStats.constitutionScore || newlyReflectedStats.constitutionScore === 0 ? Math.floor((newlyReflectedStats.constitutionScore - 10) / 2) : 0;
      newlyReflectedStats.intelligence = newlyReflectedStats.intelligenceScore || newlyReflectedStats.intelligenceScore === 0 ? Math.floor((newlyReflectedStats.intelligenceScore - 10) / 2) : 0;
      newlyReflectedStats.wisdom = newlyReflectedStats.wisdomScore || newlyReflectedStats.wisdomScore === 0 ? Math.floor((newlyReflectedStats.wisdomScore - 10) / 2) : 0;
      newlyReflectedStats.charisma = newlyReflectedStats.charismaScore || newlyReflectedStats.charismaScore === 0 ? Math.floor((newlyReflectedStats.charismaScore - 10) / 2) : 0;
      // typically on main character sheet, user would
      // be able to see their AC also affected by their equipped armor
      // if (newlyReflectedStats.dexterity) {newlyReflectedStats.armorClass = 10 + newlyReflectedStats.dexterity;}
      // if (newlyReflectedStats.dexterity) {newlyReflectedStats.initiative = newlyReflectedStats.dexterity;}
      newlyReflectedStats.armorClass = newlyReflectedStats.dexterity ? 10 + newlyReflectedStats.dexterity : 0;
      newlyReflectedStats.initiative = newlyReflectedStats.dexterity ? newlyReflectedStats.dexterity : 0;
    } else {
      newlyReflectedStats.strength = 0;
      newlyReflectedStats.dexterity = 0;
      newlyReflectedStats.constitution = 0;
      newlyReflectedStats.intelligence = 0;
      newlyReflectedStats.wisdom = 0;
      newlyReflectedStats.charisma = 0;
    }






    let hpIncrease = this.props.hpIncrease
      ? this.props.hpIncrease + newlyReflectedStats.constitution
      : 0;

    if (
      Object.keys(classPayload).length === 0 &&
      this.props.currentSection === 'class' &&
      newlyReflectedStats.level === 1
    ) {
      newlyReflectedStats.hitpoints = (newlyReflectedStats.constitution * newlyReflectedStats.level) + hpIncrease;
    } else {
      newlyReflectedStats.hitpoints += (newlyReflectedStats.constitution * newlyReflectedStats.level) + hpIncrease;
    }

    let groupedStats = [];
    let quikStatDisplay;
    if (this.state.selectedTab === 'Stats') {
      quikStatDisplay = quikStats.map((stat,index) => {
        // let rounded;
        const priorElement = quikStats[(index - 1) % quikStats.length];
        if (priorElement) {
          if (priorElement.rowNumber < stat.rowNumber) {
            groupedStats = [];
          }
        }
        // else {
        //   rounded = "first-element-rounded-top";
        // }
        if (stat.page === this.state.selectedTab) {
          groupedStats.push(stat);
        }
        const nextElement = quikStats[(index + 1) % quikStats.length];
        if (nextElement) {
          if (nextElement.rowNumber > stat.rowNumber) {
            let sizing = "width-pct-" + (groupedStats.length).toString();
            let cluster = groupedStats.map((groupedStat, index2) => {
              let packagedStats = [];
              groupedStat.statFieldsKey.forEach((stat) => {
                // pushes the value of the stat field only
                if (stat.name in newlyReflectedStats) {
                  packagedStats.push(newlyReflectedStats[stat.name]);
                }
              });
              return  <QuikStat
                        key={index2}
                        sizing={sizing}
                        groupedStat={groupedStat}
                        values={packagedStats}
                      />
            });
            return <div key={index} className={`quik-stat-wrapper`}>{cluster}</div>;
          }
        }
        return null;
      });
    } else if (this.state.selectedTab === 'Proficiencies') {
      quikStatDisplay = quikProficiencies.map((proficiencyType, index) => {
        let values = [];
        if (proficiencyType.lookupName in newlyReflectedStats) {
          values = newlyReflectedStats[proficiencyType.lookupName];
        }
        return <QuikAbility
                key={index}
                title={proficiencyType.displayName}
                type={proficiencyType.lookupName}
                values={values}
              />
      })
    } else if (this.state.selectedTab === 'Powers') {
      quikStatDisplay = quikPowers.map((powerType, index) => {
        let values = [];
        if (powerType.lookupName in newlyReflectedStats) {
          values = newlyReflectedStats[powerType.lookupName];
        }
        return <QuikAbility
                key={index}
                title={powerType.displayName}
                values={values}
              />
      })
    } else if (this.state.selectedTab === 'Inventory') {
      quikStatDisplay = <QuikInventory
                          items={newlyReflectedStats.inventory.items}
                          cp={newlyReflectedStats.inventory.copper_points}
                          sp={newlyReflectedStats.inventory.silver_points}
                          ep={newlyReflectedStats.inventory.electrum_points}
                          gp={newlyReflectedStats.inventory.gold_points}
                          pp={newlyReflectedStats.inventory.platinum_points}
                        />
    }

    let statTabs = this.state.tabs.map((tab, index) => {
      return  <StatTab
                key={index}
                tab={tab}
                handleTabClick={this.handleClickTab}
              />
    })

    return (
      <div className="quik-stats-container">
        <div className="quik-stats-header-wrapper">
          <div className="quik-stats-header-text">
            Character Sheet Stats
          </div>
        </div>
        <div className="quik-stats-tabs">
          {statTabs}
        </div>
        <div className="quik-stats-details">
          {quikStatDisplay}
        </div>
      </div>
    );
  }
}

const quikProficiencies = [
  {'lookupName':'skill_proficiencies',        'displayName':'Skill Proficiencies'},
  {'lookupName':'item_proficiencies',         'displayName':'Item Proficiencies'},
  {'lookupName':'item_type_proficiencies',    'displayName':'Item Type Proficiencies'},
  {'lookupName':'saving_throw_proficiencies', 'displayName':'Saving Throw Proficiencies'},
  {'lookupName':'languages',                  'displayName':'Languages'}
]
const quikPowers = [
  {'lookupName':'traits',                     'displayName':'Traits'},
  {'lookupName':'features',                   'displayName':'Features'},
  {'lookupName':'backgroundFeatures',         'displayName':'Background Features'},
  {'lookupName':'feats',                      'displayName':'Feats'},
  {'lookupName':'spells',                     'displayName':'Spells'},
  {'lookupName':'expanded_spells',            'displayName':'Expanded Spell Pool'},
]

// each quik stat pertains to a visual piece to the UI
// which renders 1 or more stats from the object above
// a quik stat can have more than one statFieldKey object assigned to it
// as long as every statField is accounted for
const quikStats = [
      {
        'fieldName': 'Character Name',
        'rowNumber': 1,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'characterName',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'Race',
        'rowNumber': 2,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'race',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'Subrace',
        'rowNumber': 2,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'subrace',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'Class',
        'rowNumber': 3,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'classification',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'Background',
        'rowNumber': 3,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'background',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'Level',
        'rowNumber': 4,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'level',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'HP',
        'rowNumber': 4,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'hitpoints',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'AC',
        'rowNumber': 4,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'armorClass',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'Initiative',
        'rowNumber': 5,
        'page': 'Stats',
        'prefixable':true,
        'statFieldsKey':[
          {
            'name':'initiative',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'Speed',
        'rowNumber': 5,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'speed',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'Proficiency',
        'rowNumber': 5,
        'page': 'Stats',
        'prefixable':true,
        'statFieldsKey':[
          {
            'name':'proficiency',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'STR',
        'rowNumber': 6,
        'page': 'Stats',
        'prefixable':true,
        'statFieldsKey':[
          {
            'name':'strength',
            'isSubText':false
          },{
            'name':'strengthScore',
            'isSubText':true
          }
        ]
      },
      {
        'fieldName': 'DEX',
        'rowNumber': 6,
        'page': 'Stats',
        'prefixable':true,
        'statFieldsKey':[
          {
            'name':'dexterity',
            'isSubText':false
          },{
            'name':'dexterityScore',
            'isSubText':true
          }
        ]
      },
      {
        'fieldName': 'CON',
        'rowNumber': 6,
        'page': 'Stats',
        'prefixable':true,
        'statFieldsKey':[
          {
            'name':'constitution',
            'isSubText':false
          },{
            'name':'constitutionScore',
            'isSubText':true
          }
        ]
      },
      {
        'fieldName': 'INT',
        'rowNumber': 7,
        'page': 'Stats',
        'prefixable':true,
        'statFieldsKey':[
          {
            'name':'intelligence',
            'isSubText':false
          },{
            'name':'intelligenceScore',
            'isSubText':true
          }
        ]
      },
      {
        'fieldName': 'WIS',
        'rowNumber': 7,
        'page': 'Stats',
        'prefixable':true,
        'statFieldsKey':[
          {
            'name':'wisdom',
            'isSubText':false
          },{
            'name':'wisdomScore',
            'isSubText':true
          }
        ]
      },
      {
        'fieldName': 'CHA',
        'rowNumber': 7,
        'page': 'Stats',
        'prefixable':true,
        'statFieldsKey':[
          {
            'name':'charisma',
            'isSubText':false
          },{
            'name':'charismaScore',
            'isSubText':true
          }
        ]
      },
      {
        'fieldName': 'PP',
        'rowNumber': 8,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'platinum',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'GP',
        'rowNumber': 8,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'gold',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'EP',
        'rowNumber': 8,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'electrum',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'SP',
        'rowNumber': 8,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'silver',
            'isSubText': false
          }
        ]
      },
      {
        'fieldName': 'CP',
        'rowNumber': 8,
        'page': 'Stats',
        'prefixable':false,
        'statFieldsKey':[
          {
            'name':'copper',
            'isSubText': false
          }
        ]
      }
    ];

export default QuikStats;
