import React from "react";
import SelectionPoolPrompt from "./SelectionPoolPrompt";
import _ from 'underscore';

const SelectionPrompt = props => {

  let beginningPools = [];
  let mainPools = [];
  let auxSelectionPools = [];
  if (props.selection) {
    beginningPools = props.selection.alternate_pools;
    mainPools = props.selection.pools;
    if (props.auxiliary_selection) {
      auxSelectionPools = props.auxiliary_selection.selection.pools;
    }
    let highestSpellLevel = 0;
    let highestAuxSpellLevel = 0;
    if (props.selection.target === 'spells') {
      mainPools.forEach((spell) => {
        if (spell.level > highestSpellLevel) {
          highestSpellLevel = spell.level;
        }
      });
      if (props.characterSelections) {
        props.characterSelections.expanded_spells.forEach((spell) => {
          if (
            spell.level <= highestSpellLevel &&
            !mainPools.some(pool =>
              spell.id === pool.id
            )
          ) {
            mainPools.push(spell);
          }
        });
      }
      props.expandedSpells.forEach((spell) => {
        if (
          spell.level <= highestSpellLevel &&
          !mainPools.some(pool =>
            spell.id === pool.id
          )
        ) {
          mainPools.push(spell);
        }
      });
    } else if (props.auxiliary_selection && props.auxiliary_selection.selection.target === 'spells') {
      auxSelectionPools.forEach((spell) => {
        if (spell.level > highestAuxSpellLevel) {
          highestAuxSpellLevel = spell.level;
        }
      });
      props.characterSelections.expanded_spells.forEach((spell) => {
        if (
          spell.level <= highestAuxSpellLevel &&
          !auxSelectionPools.some(pool =>
            spell.id === pool.id
          )
        ) {
          auxSelectionPools.push(spell);
        }
      });
      props.expandedSpells.forEach((spell) => {
        if (
          spell.level <= highestAuxSpellLevel &&
          !auxSelectionPools.some(pool =>
            spell.id === pool.id
          )
        ) {
          auxSelectionPools.push(spell);
        }
      });
    }
  }

  // sort by alphabetical order
  var sortedBeginningPools = _.sortBy(beginningPools, 'name');
  var sortedMainPools = _.sortBy(mainPools, 'name');
  var sortedSelectionPools = sortedBeginningPools.concat(
    sortedBeginningPools.length > 0 && sortedMainPools.length > 0 ? [null] : [],
    sortedMainPools
  );
  var sortedAuxSelectionPools = _.sortBy(auxSelectionPools, 'name');

  let source;
  if (props.selection) {
    if (props.selection.feature_id) {
      source = 'classes';
    } else if (props.selection.background_feature_id) {
      source = 'backgrounds';
    } else if (props.selection.trait_id) {
      source = 'races';
    }
  }

  let keyTally = 0;
  let pools = sortedSelectionPools.map((pool, index) => {
    if (pool === null) {
      return <div className="selection-prompt-visible-line-break"></div>;
    } else {
      keyTally += 1;
      return <SelectionPoolPrompt
              key={index}
              order={index}
              pool={pool}
              source={source}
              selections={props.selections}
              handleSelectionClick={props.handleSelectionClick}
              selection={props.selection}
              characterSelections={props.characterSelections}
              onReadMoreClick={props.onReadMoreClick}
            />
    }
  });

  let auxPools;
  if (props.auxiliary_selection && props.auxiliary_selection.selection.num_selections) {
    auxPools = sortedAuxSelectionPools.map((pool, index) => {
      return <SelectionPoolPrompt
              key={keyTally+index}
              order={index}
              pool={pool}
              source={source}
              selections={props.selections}
              handleSelectionClick={props.handleSelectionClick}
              selection={props.auxiliary_selection.selection}
              characterSelections={props.characterSelections}
              onReadMoreClick={props.onReadMoreClick}
             />
    });
  }

  let divider;
  if (auxPools) {
    divider = <div className="selection-prompt-visible-line-break"></div>;
    // divider = <div>-------------------------</div>;
  }

  let deductedSelections;
  if (
    props.selection &&
    props.characterSelections &&
    props.selection.optional_selection === true
    // [1,2].includes(props.selection.acquisition_type_id)
  ) {
    let powers = props.characterSelections[props.selection.target].filter(feature =>
      props.selection.search_criteria.some(criterion =>
        criterion.specific_id === feature[criterion.col_name]
      )
    )
    deductedSelections = powers.map((power, index) => {
      let selected = null;
      if (props.selectedDeductions.some(deduction =>
        // _.isEqual(deduction,power) &&
        deduction.target === props.selection.target &&
        deduction.selection_id === props.selection.id &&
        deduction.power.id === power.id
      )) {
        selected = "selected";
      }
      let newDeduction = {
        'selection_id': props.selection.id,
        'target': props.selection.target,
        'source': props.selection.source,
        'power': power
      }

      let descs = power.descs.map((desc, index) => {
        return <div key={index} className="campaign-detail-popup-desc">
                {desc.object_text}
               </div>;
      });


      return <div
              className={`auto-selection selectableSelection ${selected}`}
              key={index}
              onClick={((e) => props.onDeductionClick(e, newDeduction))}
             >
              <div className='auto-trait'>
                {power.name}
              </div>
              <div className='auto-trait-desc'>
                {descs}
              </div>
              <div
                onClick={((e) => props.onReadMoreClick(e, props.selection.source, power))}
                className='auto-trait-read-more'
              >
                <i className="fa-solid fa-circle-info"></i>
              </div>
             </div>
    })
  }

  let deductionDivider;
  let additionDivider;
  if (deductedSelections) {
    deductionDivider = <div className="choose-banner">CHOOSE ONE TO LOSE</div>;
    additionDivider = <div className="choose-banner">CHOOSE ONE TO GAIN</div>;
  }

  return (
    <div className='selection-row'>
      {deductionDivider}
      {deductedSelections}
      {additionDivider}
      {pools}
      {divider}
      {auxPools}
    </div>
  );
}

export default SelectionPrompt;
