import React from 'react';

const StatTab = props => {


  return (
    <div onClick={((e) => props.handleTabClick(e, props.tab.name))} className="quik-stats-tab">
      <div className="quik-stats-tab-text">
        {props.tab.name}
      </div>
    </div>
  );
}
export default StatTab;
