import React from 'react';

const ConfirmItemEquip = props => {

  let secondaryCondition;
  let main, secondary, body;

  if (
    props.requestedItem &&
    props.character &&
    (
      (
        props.requestedItem.item.weapon_properties.every(property =>
          property !== 'Two-Handed'
        ) &&
        props.character.inventories[0].weapons.some(pickup =>
          pickup.equipped_main === true
        ) &&
        props.requestedItem.item.type === 'Weapon'
      ) ||
      props.requestedItem.item.subtypes.some(subtype =>
        subtype === 'Shields'
      )
    )
  ) {
    secondaryCondition = true;
  }


  main = props.requestedItem.item.type === 'Weapon'
    ? <div className="popup-button pop-up-yes" type='submit' onClick={((e) => props.onSubmit(e, props.requestedItem, true, 'main'))}>
        Main Hand
      </div>
    : null;
  secondary = secondaryCondition
    ? <div className="popup-button pop-up-yes" type='submit' onClick={((e) => props.onSubmit(e, props.requestedItem, true, '2ndy'))}>
        Alt Hand
      </div>
    : null;
  body =
    props.requestedItem.item.type === 'Armor' &&
    !props.requestedItem.item.subtypes.some(subtype =>
      subtype === 'Shields'
    )
    ? <div className="popup-button pop-up-yes" type='submit' onClick={((e) => props.onSubmit(e, props.requestedItem, true, 'body'))}>
        Submit
      </div>
    : null;

  return(
    <div className='pop-up-2nd'>
      <div className='campaign-confirm-drop-popup-inner-2nd'>
        <div className='campaign-confirm-drop-popup-container'>
          <div className='campaign-confirm-drop-popup-title'>
            Equip Item?
          </div>
          <div className="campaign-confirm-drop-popup-button-container">
            {main}
            {secondary}
            {body}
            <div className="popup-button pop-up-no" type='submit' onClick={((e) => props.closePop(e))}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmItemEquip
