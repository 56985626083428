import React from 'react';

const CampaignFeatPopUp = props => {
  let name, descs, prereqs;
  if (props.details) {
    // console.log(props.details);
    name = props.details.name;
    descs = props.details.descs.map((desc, index) => {
      if (desc.bullet) {
        if (desc.header_text) {
          let header;
          if (desc.header_text) {
            header = <span className="story-mode-header-text-bullet">{desc.header_text}</span>
          }
          return <div key={index} className="campaign-detail-popup-desc">
                  {header}{desc.object_text}
                 </div>;
        } else {
          return  <div key={index} className="campaign-detail-popup-desc">
                    <span className="bulleted-desc">•</span>{desc.object_text}
                  </div>;
        }
      } else {
        if (desc.header_text) {
          return <div key={index} className="campaign-detail-popup-desc">
                  <div className="headlining-header-text">{desc.header_text}</div>
                  {desc.object_text}
                 </div>;
        } else {
          return <div key={index} className="campaign-detail-popup-desc">
                  {desc.object_text}
                 </div>;
        }
      }
    });
    prereqs = props.details.prerequisite;
  }

  return(
    <div className='campaign-detail-popup-details-container'>
      <div className='campaign-detail-popup-section-container'>
        <div className='campaign-detail-popup-section-container-inner'>
          <div className='campaign-detail-popup-name'>
            {name}
            <div className='cdp-one-line campaign-detail-popup-desc campaign-detail-sub-header popup-sub-text'>
              {`prerequisites: ${prereqs}`}
            </div>
          </div>
          <div className='campaign-detail-popup-primary-details campaign-popup-descs'>
            {descs}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignFeatPopUp
