import React, { Component } from 'react';
import { io } from 'socket.io-client';

import ConfirmationPopup from '../components/ConfirmationPopup';
import PlayerPopUp from '../containers/PlayerPopUp';
import CampaignActions from './CampaignActions';
import CampaignFieldView from './CampaignFieldView';
// import CampaignInfo from './CampaignInfo';
import CampaignHome from './CampaignHome';
import CampaignCharSheet from './CampaignCharSheet';
import CampaignNav from './CampaignNav';

let socket;

class CampaignPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedSection: null,
      popUp: false,
      pcPopUp: false,
      pcRequestPopUp: false,
      pcRemovePopUp: false,
      publicPopUp: false,
      campaign: null,
      users: null,
      currentUser: null,
      allUsers: null,
      msg: "",
      level: null,
      actionType: null,
      selectedUser: null,
      file: null,
      preview: null,
      navCollapsed: true,
      navHovered: false,
      typedQuestNotesValue: "",
      campaignMap: null,
      controlsMarkers: [],
      colorHexes: [],
      fowHexes: [],
      markerHexes: [],
      markerTypes: [],
      indicatorIteration: 0,
      typedNewHeight: "",
      typedNewWidth: "",
      boardSelectedMarker: null,
      controlsSelectedMarker: null,
      hoveredMarker: null,
      dragFilledCoordinates: [],
      loading: true,
      // ===================================================================
      // ==================   From Original Map   ==========================
      // ===================================================================
      selectedColor: null,
      controlsSelectedIndicator: false,
      rightClickedMarker: null,
      isMenuPoppedUp: false,
      isNewMapRequestPoppedUp: false,
      isNewMarkerRequestPoppedUp: false,
      isMultiMarkerMenuPoppedUp: false,
      nameContent: null,
      typedPublicNotesValue: null,
      typedPrivateNotesValue: null,
      selectedMenuHex: null,
      isDeleteToggled: false,
      isDeleteControlToggled: false,
      isHideToggled: false,
      isColorOptionsMenuPoppedUp: false,
      awareUsers: [],
      markerType: null,
      loadingMap: true
    };
    this.togglePopUp = this.togglePopUp.bind(this);
    this.toggleChangePCPopUp = this.toggleChangePCPopUp.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.handleChangePlayer = this.handleChangePlayer.bind(this);
    this.changeCampaign = this.changeCampaign.bind(this);
    this.togglePublicPopUp = this.togglePublicPopUp.bind(this);
    this.handleChangePublic = this.handleChangePublic.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.handleSectionClick = this.handleSectionClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCollapseButtonClick = this.onCollapseButtonClick.bind(this);
    this.handleCollapseHover = this.handleCollapseHover.bind(this);
    this.handleCollapseUnHover = this.handleCollapseUnHover.bind(this);
    this.handleImageReset = this.handleImageReset.bind(this);
    this.handleImageSave = this.handleImageSave.bind(this);
    this.handleTypedQuestNotesValueChange = this.handleTypedQuestNotesValueChange.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
    this.connectToSocket = this.connectToSocket.bind(this);
    // ===================================================================
    // ==================   From Original Map   ==========================
    // ===================================================================
    this.selectHexColor = this.selectHexColor.bind(this);
    this.selectMapMarker = this.selectMapMarker.bind(this);
    this.selectMapIndicator = this.selectMapIndicator.bind(this);
    this.requestNewMap = this.requestNewMap.bind(this);
    this.submitNewMap = this.submitNewMap.bind(this);
    this.createNewMarkerClick = this.createNewMarkerClick.bind(this);
    this.deleteControlsMarker = this.deleteControlsMarker.bind(this);
    this.closePopUpMap = this.closePopUpMap.bind(this);
    this.handlePopUpSave = this.handlePopUpSave.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleLeaveHover = this.handleLeaveHover.bind(this);
    this.handleStopFill = this.handleStopFill.bind(this);
    this.handleCoordinateClick = this.handleCoordinateClick.bind(this);
    this.handleCoordinateRightClick = this.handleCoordinateRightClick.bind(this);
    this.handleControlMarkerRightClick = this.handleControlMarkerRightClick.bind(this);
    this.handleExpandedIconClick = this.handleExpandedIconClick.bind(this);
    this.handleExpandedIconRightClick = this.handleExpandedIconRightClick.bind(this);
    this.handleExpandedIconHover = this.handleExpandedIconHover.bind(this);
    this.handleExpandedIconLeaveHover = this.handleExpandedIconLeaveHover.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTypedPublicNotesValueChange = this.handleTypedPublicNotesValueChange.bind(this);
    this.handleTypedPrivateNotesValueChange = this.handleTypedPrivateNotesValueChange.bind(this);
    this.handleTypedNewHeightChange = this.handleTypedNewHeightChange.bind(this);
    this.handleTypedNewWidthChange = this.handleTypedNewWidthChange.bind(this);
    this.handleUserInclusionClick = this.handleUserInclusionClick.bind(this);
    this.handleMarkerTypeClick = this.handleMarkerTypeClick.bind(this);
    this.toggleDeleteMarker = this.toggleDeleteMarker.bind(this);
    this.toggleDeleteControlMarker = this.toggleDeleteControlMarker.bind(this);
    this.toggleHideMarker = this.toggleHideMarker.bind(this);
    this.setStateToMarkerSettings = this.setStateToMarkerSettings.bind(this);

  }

  async componentDidMount() {
    await this.getCampaign();
    if (this.state.campaign) {
      await this.connectToSocket();

      socket.on("connect", () => {
        console.log("attempting to join a room")
        socket.emit('join', { campaignId: this.state.campaign.id });
      });

      socket.on('reconnect', (attemptNumber) => {
        console.log('Reconnected on attempt: ', attemptNumber);
        socket.emit('join', { campaignId: this.state.campaign.id });
      });

      socket.on('connect_error', (error) => {
        console.error('Connection error:', error);
      });

      socket.on('error', (error) => {
        console.error('Socket error:', error);
      });

      socket.on("joined room", (data) => {
        console.log(data);
        socket.emit("get map", { campaignId: this.state.campaign.id });
      })

      socket.on("left room", (data) => {
        console.log(data);
        console.log("left room");
      })

      socket.on("get map", (serverData) => {
        let data = JSON.parse(serverData);
        console.log("getting map")
        console.log(data)
        this.setState({
          campaignMap: data.campaign_map,
          controlsMarkers: data.markers,
          markerHexes: data.marker_hexes,
          colorHexes: data.color_hexes,
          fowHexes: data.fow_hexes,
          markerTypes: data.marker_types,
          loadingMap: false
        });
      })

      socket.on("update map", (serverData) => {
        let data = JSON.parse(serverData);
        this.setState((prevState) => {
          return {
            campaignMap: data.campaign_map,
            controlsMarkers: data.markers,
            loadingMap: false,
            typedNewHeight: "",
            typedNewWidth: ""
          };
        });
      })

      // "map placement marker" ws event returns to this websocket
      socket.on("map placement marker", (serverData) => {
        let data = JSON.parse(serverData);
        if (data.type === 'new_marker') {
          this.setState((prevState) => {
            if (data.affected_coordinates && data.affected_coordinates.length > 0) {
              const campaignMap = {...prevState.campaignMap};
              campaignMap.coordinates.forEach((coordinate, index) => {
                data.affected_coordinates.forEach((affected_coordinate) => {
                  if (coordinate.id === affected_coordinate.id) {
                    campaignMap.coordinates[index] = affected_coordinate;
                  }
                });
              });
              return { campaignMap, controlsMarkers: data.controls_markers };
            } else {
              return { controlsMarkers: data.controls_markers };
            }
          });
        } else if (data.type === 'place_marker') {
          this.setState((prevState) => {
            const campaignMap = {...prevState.campaignMap};
            campaignMap.coordinates.forEach((coordinate, index) => {
              data.affected_coordinates.forEach((affected_coordinate) => {
                if (coordinate.id === affected_coordinate.id) {
                  campaignMap.coordinates[index] = affected_coordinate;
                }
              });
            });
            return { campaignMap, controlsMarkers: data.controls_markers, controlsSelectedMarker: null };
          });
        } else if (data.type === 'move_marker') {
          this.setState((prevState) => {
            const campaignMap = {...prevState.campaignMap};
            const indicatorIteration = prevState.indicatorIteration + 1
            campaignMap.coordinates.forEach((coordinate, index) => {
              data.affected_coordinates.forEach((affected_coordinate) => {
                if (coordinate.id === affected_coordinate.id) {
                  campaignMap.coordinates[index] = affected_coordinate;
                }
              });
              if (this.state.boardSelectedMarker && coordinate.id === this.state.boardSelectedMarker.map_coordinate_id) {
                coordinate.indicatorIteration = indicatorIteration;
              }
            });
            return { campaignMap, boardSelectedMarker: null, indicatorIteration };
          });
        }
      })

      // "update marker", "delete coord marker", and "delete map marker"
      // ws events all return to this websocket
      socket.on("marker updates", (serverData) => {
        let data = JSON.parse(serverData);
        this.setState((prevState) => {
          if (data.affected_coordinates && data.affected_coordinates.length > 0) {
            const campaignMap = {...prevState.campaignMap};
            campaignMap.coordinates.forEach((coordinate, index) => {
              data.affected_coordinates.forEach((affected_coordinate) => {
                if (coordinate.id === affected_coordinate.id) {
                  campaignMap.coordinates[index] = affected_coordinate;
                }
              });
            });
            return { campaignMap, controlsMarkers: data.controls_markers };
          } else {
            return { controlsMarkers: data.controls_markers };
          }
        });
      })


      socket.on("update coordinate", (serverData) => {
        let data = JSON.parse(serverData);
        this.setState((prevState) => {
          const campaignMap = {...prevState.campaignMap};
          const dragFilledCoordinates = [...prevState.dragFilledCoordinates];
          campaignMap.coordinates.forEach((coordinate, index) => {
            data.affected_coordinates.forEach((affected_coordinate) => {
              if (coordinate.id === affected_coordinate.id) {
                campaignMap.coordinates[index].color_hex_code = affected_coordinate.color_hex_code;
                if (data.is_from_fow) {
                  campaignMap.coordinates[index].color_hex_code_fow = affected_coordinate.color_hex_code_fow;
                  campaignMap.coordinates[index].is_fog_of_war = affected_coordinate.is_fog_of_war;
                  if (this.state.currentUser.role) {
                    if (affected_coordinate.is_fog_of_war) {
                      campaignMap.coordinates[index].fog_of_war_visible = "partial";
                    } else {
                      campaignMap.coordinates[index].fog_of_war_visible = "clear";
                    }
                  } else {
                    if (affected_coordinate.markers.some(marker =>
                      marker.character && marker.character.creator_id === this.state.currentUser.id
                    )) {
                      campaignMap.coordinates[index].fog_of_war_visible = "clear";
                    } else {
                      campaignMap.coordinates[index].fog_of_war_visible = "blocked";
                    }
                  }
                }
              }
            });
          });
          dragFilledCoordinates.push(data.coord_id)
          return { campaignMap, dragFilledCoordinates: dragFilledCoordinates, hoveredMarker: null };
        });
      })

      socket.on("show map indicator", (serverData) => {
        this.setState((prevState) => {
          const campaignMap = {...prevState.campaignMap};
          const indicatorIteration = prevState.indicatorIteration + 1
          campaignMap.coordinates.forEach((coordinate, index) => {
            if (coordinate.id === serverData) {
              coordinate.indicatorIteration = indicatorIteration;
              coordinate.indicator = true;
            }
            else if (coordinate.indicator) {
              coordinate.indicator = false;
            }
          });
          return { campaignMap, indicatorIteration };
        });
      })

    }
  }

  componentWillUnmount() {
    // when component unmounts, disconnect
    console.log("closing websocket...");
    // socket.disconnect()
    console.log(socket);
    socket.emit("leave", { campaignId: this.state.campaign.id });
  }

  async getCampaign() {
    const response = await fetch(`/api/v1/campaigns/${this.props.match.params.id}`, {
          credentials: 'same-origin',
          method: 'GET',
          headers: { 'Content-Type':'application/json'}
        });
    const json = await response.json();
    if(response.status === 200) {
      this.setState((prevState) => {
        return {
          campaign: json.campaign,
          level: json.level,
          users: json.users,
          currentUser: json.current_user,
          allUsers: json.all_users,
          file: null,
          preview: null,
          typedQuestNotesValue: json.campaign.quest_notes,
          loading: false
        };
      });
    } else {
      this.props.pop(json.msg, json.status);
    }
  }

  async connectToSocket() {
    let server = undefined;
    const domain = window.location.origin;
    if (window.location.hostname === 'localhost') {
      server = `http://${window.location.hostname}:3000`;
    }
    socket = io(server, {
      cors: {
          origin: domain,
          methods: ["GET", "POST"]
      },
      transports: ['websocket', 'polling'],
      withCredentials: true,
      allowEIO3: true,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000
    });
  }

  togglePopUp() {
    this.setState({popUp: !this.state.popUp});
  }

  handleClose(e) {
    e.preventDefault();
    this.closePopUp();
  }

  closePopUp() {
    this.setState({
      popUp: false,
      pcPopUp: false,
      pcRequestPopUp: false,
      pcRemovePopUp: false,
      publicPopUp: false
    });
  }

  toggleChangePCPopUp(e, actionType, user) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        selectedUser: user,
        actionType: actionType
      };
    });
    if (actionType === "remove") {
      this.setState((prevState) => {
        return {
          pcRemovePopUp: !this.state.pcRemovePopUp
        };
      });
    } else if (actionType === "force_add" || actionType === "request_add") {
      this.setState((prevState) => {
        return {
          pcPopUp: !this.state.pcPopUp
        };
      });
    }
  }

  handleChangePlayer(e, value) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('username', value.username);
    formPayload.append('type', value.actionType);
    this.changeCampaign(formPayload);
    this.closePopUp();
  }

  changeCampaign(formPayload) {
    fetch(`/api/v1/campaigns/${this.props.match.params.id}`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload,
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.getCampaign();
      }
    }));
  }

  togglePublicPopUp(e) {
    e.preventDefault();
    // console.log("inside ::: togglePublicPopUp");
    let action;
    if (this.state.campaign.public === true) {
      action = "make_private";
    } else {
      action = "make_public";
    }
    this.setState({
      publicPopUp: !this.state.publicPopUp,
      actionType: action
    });
  }

  handleChangePublic(e, value) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('type', value.actionType);
    this.changeCampaign(formPayload);
    this.closePopUp();
  }

  handleDelete(e, value) {
    e.preventDefault();
    this.deleteCampaign();
    this.closePopUp();
  }

  deleteCampaign() {
    fetch(`/api/v1/campaigns/${this.state.campaign.id}`, {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.props.history.push("/campaigns");
      }
    }));
  }

  handleSectionClick(e, sectionName) {
    e.preventDefault();
    this.getCampaign();
    this.setState({
      selectedSection: sectionName
    });
  }

  handleChange(e) {
    let file = e.target.files[0];
    this.setState({file: file});
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      this.setState({
        preview: [reader.result]
      });
    }.bind(this);
  }

  handleImageReset(e) {
    e.preventDefault();
    // this.imgRef.current.value = "";
    this.setState((preState) => {
      return {
        file: null,
        preview: null,
      };
    });
    this.getCampaign();
  }

  handleImageSave(e) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('type', "upload_photo");
    formPayload.append('file', this.state.file);
    formPayload.append('genre', 'campaigns');
    formPayload.append('category', 'campaign');
    formPayload.append('id', this.state.campaign.id);
    fetch(`/api/v1/campaigns/${this.props.match.params.id}`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        // console.log("success");
        this.getCampaign();
      } else {
        // this.props.history.push("/edit_profile/" + this.props.match.params.id);
      }
    }));
  }

  handleCollapseHover(e) {
    e.preventDefault();
    this.setState({ navHovered: true });
  }

  handleCollapseUnHover(e) {
    e.preventDefault();
    this.setState({ navHovered: false });
  }


  onCollapseButtonClick(e) {
    e.preventDefault();
    this.setState({ navCollapsed: !this.state.navCollapsed });
  }

  handleTypedQuestNotesValueChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 4000) {
      this.setState((prevState) => {
        return {
          typedQuestNotesValue: val
        };
      });
    }
  }

  saveNotes(e) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        loading: true
      };
    });
    let formPayload = new FormData();
    formPayload.append('campaignNotes', this.state.typedQuestNotesValue);
    fetch(`/api/v1/campaign/${this.state.campaign.id}`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState((prevState) => {
          let campaign = Object.assign({}, prevState.campaign);
          campaign.quest_notes = res.body.campaign.quest_notes
          return {
            campaign: campaign,
            loading: false
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            loading: false
          };
        });
        this.props.pop(res.body.msg, res.body.status);
      }
    }));

  }

  // ======================================================
  // ======================================================
  // ======================================================

  handleTypedPublicNotesValueChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 300) {
      this.setState((prevState) => {
        return {
          typedPublicNotesValue: val
        };
      });
    }
  }

  handleTypedPrivateNotesValueChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 300) {
      this.setState((prevState) => {
        return {
          typedPrivateNotesValue: val
        };
      });
    }
  }

  handleNameChange(e) {
    e.preventDefault();
    this.setState({ nameContent: e.target.value });
  }

  selectHexColor(e, hex) {
    if(this.state.currentUser.role) {
      if (this.state.selectedColor && hex.code === this.state.selectedColor.code) {
        this.setState({
          selectedColor: null
        });
      } else {
        this.setState({
          selectedColor: hex
        });
      }
    }
  }

  selectMapMarker(e, marker) {
    e.preventDefault();
    if (
      marker.map_coordinate_id === null &&
      (
        this.state.currentUser.role ||
        (marker.character && this.state.currentUser.id === marker.character.creator_id)
      )
    ) {
      if (this.state.controlsSelectedMarker && marker.id === this.state.controlsSelectedMarker.id) {
        this.setState({
          controlsSelectedMarker: null
        });
      } else {
        this.setState({
          controlsSelectedMarker: marker,
          controlsSelectedIndicator: false
        });
      }
    }
  }

  selectMapIndicator(e) {
    e.preventDefault();
    if (this.state.controlsSelectedIndicator) {
      this.setState({
        controlsSelectedIndicator: false
      });
    } else {
      this.setState({
        controlsSelectedMarker: null,
        controlsSelectedIndicator: true
      });
    }
  }

  requestNewMap(e) {
    e.preventDefault();
    this.closePopUpMap();
    this.setState({
      isNewMapRequestPoppedUp: true
    });
  }

  createNewMarkerClick(e) {
    e.preventDefault();
    this.closePopUpMap();
    this.setState({
      isNewMarkerRequestPoppedUp: true
    });
  }

  async deleteControlsMarker(e, marker) {
    e.preventDefault();
    if(this.state.currentUser.role) {
      socket.emit("delete map marker", {
        campaignId: this.state.campaign.id,
        mapId: this.state.campaignMap.id,
        markerId: marker.id,
      });
      this.closePopUpMap();
    }
  }

  async submitNewMap(e) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        isNewMapRequestPoppedUp: false,
        loadingMap: true
      };
    });
    let campaignId = this.state.campaign ? this.state.campaign.id : null;
    if (campaignId === null) {
      this.setState((prevState) => {
        return {
          loadingMap: false
        };
      });
      return;
    }
    socket.emit("update map", {
      campaignId: campaignId,
      rows: this.state.typedNewHeight,
      columns: this.state.typedNewWidth,
    });
    this.closePopUpMap();
  }

  closePopUpMap(e = null) {
    if (e) {
      e.preventDefault();
    }
    this.setState((prevState) => {
      return {
        isMenuPoppedUp: false,
        isNewMapRequestPoppedUp: false,
        isNewMarkerRequestPoppedUp: false,
        nameContent: null,
        typedPublicNotesValue: null,
        typedPrivateNotesValue: null,
        selectedMenuHex: null,
        isDeleteToggled: false,
        isDeleteControlToggled: false,
        isColorOptionsMenuPoppedUp: false,
        rightClickedMarker: null,
        hoveredMarker: null,
        dragFilledCoordinates: [],
        awareUsers: [],
        markerType: null,
        boardSelectedMarker: null,
        controlsSelectedMarker: null,
      }
    });
  }

  async handlePopUpSave(e = null, marker) {
    if (e) {
      e.preventDefault();
    }
    if(this.state.currentUser.role) {
      // Updating marker details or removing from map
      let requestType = this.state.isDeleteToggled || this.state.isDeleteControlToggled ? 'delete coord marker' : 'update marker';
      let markerId, coordinateId, type;
      if (marker) {
        markerId = marker.id;
        if (marker.map_coordinate_id !== null) {
          coordinateId = marker.map_coordinate_id;
        } else {
          coordinateId = null;
        }
      } else {
        requestType = 'map placement marker';
        type = 'new_marker';
        markerId = null;
        coordinateId = null;
      }
      socket.emit(requestType, {
        campaignId: this.state.campaign.id,
        mapId: this.state.campaignMap.id,
        type: type,
        markerId: markerId,
        coordinateId: coordinateId,
        origCoordinateId: null,
        destCoordinateId: null,
        name: this.state.nameContent,
        isDeleteToggled: this.state.isDeleteToggled,
        isDeleteControlToggled: this.state.isDeleteControlToggled,
        publicNotes: this.state.typedPublicNotesValue,
        privateNotes: this.state.typedPrivateNotesValue,
        isHiding: this.state.isHideToggled,
        markerType: this.state.markerType,
        awareUsers: JSON.stringify(this.state.awareUsers)
      });
      this.closePopUpMap();
    }
  }

  async handleCoordinateClick(e, coord) { // handles when coordinates are clicked
    e.preventDefault();
    e = e || window.event;

    // consider also using ("which" in e) (values are 1 & 3 instead) for browsers: Gecko (Firefox), WebKit (Safari/Chrome), IE & Opera
    // if ("button" in e && coordinate) {
    //  if (e.button === 0) {
    if (coord) {
      let coordMarker;
      let coordMarkersLength = 0;
      // DO PERMISSIONS HERE - it is possible that what may be multi-markers
      // on a coord for DM will be just one marker for PC
      if (coord.markers) {
        coordMarkersLength = coord.markers.length;
        coord.markers.forEach((marker) => {
          if (['PC','NPC','Enemy'].includes(marker.marker_type.name)) {
            coordMarker = marker;
          }
        });
      }
      // ======= before anything - close any open menus if a non-menu element was clicked on (a coordinate) =======
      if (this.state.isMenuPoppedUp || this.state.isMultiMarkerMenuPoppedUp) {
        this.setState((prevState) => {
          return {
            isMenuPoppedUp: false,
            rightClickedMarker: null,
            isMultiMarkerMenuPoppedUp: false
          };
        });
      }
      // ======= create an indicator on map coordinate first - if indicator is selected =======
      if (this.state.controlsSelectedIndicator) {
        socket.emit("map ping", {
          campaignId: this.state.campaign.id,
          coordId: coord.id
        });
      }
      // ======= move board-selected marker to new space =======
      // MAKE SURE THAT THIS CHECK IS ALSO IN THE API (PC NPC or ENEMY)
      else if (
        this.state.boardSelectedMarker &&
        this.state.boardSelectedMarker.map_coordinate_id !== coord.id &&
        (
          this.state.currentUser.role ||
          (
            !this.state.currentUser.role &&
            this.state.boardSelectedMarker.marker_type.name === 'PC' &&
            this.state.boardSelectedMarker.character &&
            this.state.boardSelectedMarker.character.creator_id === this.state.currentUser.id
          )
        ) &&
        (
          !coordMarker ||
          (
            coordMarker &&
            !['PC','NPC','Enemy'].includes(this.state.boardSelectedMarker.marker_type.name)
          )
        )
      ) {
        socket.emit("map placement marker", {
          campaignId: this.state.campaign.id,
          mapId: this.state.campaignMap.id,
          type: "move_marker",
          markerId: this.state.boardSelectedMarker.id,
          markerType: this.state.markerType,
          origCoordinateId: this.state.boardSelectedMarker.map_coordinate_id,
          destCoordinateId: coord.id,
          name: null,
          publicNotes: null,
          privateNotes: null,
          isHiding: null,
          awareUsers: null,
        });
        this.setState((prevState) => { return { boardSelectedMarker: null };});
      }
      // ======= place controls-selected marker on unoccupied space =======
      else if (
        this.state.controlsSelectedMarker &&
        (
          !coordMarker ||
          (
            coordMarker &&
            !['PC','NPC','Enemy'].includes(this.state.controlsSelectedMarker.marker_type.name)
          )
        )
      ) {
        if (!this.state.controlsSelectedMarker.coordinate) {
          if (
            this.state.currentUser.role ||
            (this.state.controlsSelectedMarker.character && this.state.currentUser.id === this.state.controlsSelectedMarker.character.creator_id)
          ) {
            socket.emit("map placement marker", {
              campaignId: this.state.campaign.id,
              mapId: this.state.campaignMap.id,
              type: "place_marker",
              markerId: this.state.controlsSelectedMarker.id,
              markerType: this.state.markerType,
              origCoordinateId: null,
              destCoordinateId: coord.id,
              name: null,
              publicNotes: null,
              privateNotes: null,
              isHiding: null,
              awareUsers: null,
            });
            this.setState((prevState) => { return { controlsSelectedMarker: null };});
          }
        }
      }
      // ======= when there are multiple coord marker, show available markers =======
      else if (coordMarkersLength > 1) {
        // close pop up if it is open
        if (this.state.isMultiMarkerMenuPoppedUp) {
          this.setState((prevState) => {
            return {
              isMultiMarkerMenuPoppedUp: false
            };
          });
        }
        // pop up a mini menu of icons
        else {
          this.setState((prevState) => {
            return {
              isMultiMarkerMenuPoppedUp: true
            };
          });
        }
      }
      // ======= when there is a marker in a coord, select a marker when nothing is selected =======
      else if (coordMarkersLength === 1) {
        // unselect currently-selected marker
        if (
          this.state.boardSelectedMarker &&
          this.state.boardSelectedMarker.id === coord.markers[0].id &&
          (
            this.state.currentUser.role ||
            (
              this.state.boardSelectedMarker.character &&
              this.state.boardSelectedMarker.character.creator_id === this.state.currentUser.id
            )
          )
        ) {
          this.setState((prevState) => {
            return {
              boardSelectedMarker: null,
              controlsSelectedMarker: null
            };
          });
        }
        // select a newly clicked marker
        else {
          if (
            this.state.currentUser.role ||
            (
              coord.markers[0] &&
              coord.markers[0].character &&
              coord.markers[0].character.creator_id === this.state.currentUser.id
            )
          ) {
            this.setState((prevState) => {
              return {
                boardSelectedMarker: coord.markers[0],
                controlsSelectedMarker: null
              };
            });
          }
        }
      }
    }
  }

  async handleStopFill(e) {
    e.preventDefault();
    if (this.state.dragFilledCoordinates) {
      this.setState({ dragFilledCoordinates: [] });
    }
  }

  async handleCoordinateRightClick(e, coord) { // handles when coordinates are clicked
    e.preventDefault();
    console.log('Right clicked')
    // ======= right click can fill in environment with different types of boundaries if nothing fills that spot =======
    if (
      this.state.currentUser.role &&
      (
        !coord.markers ||
        (
          coord.markers &&
          coord.markers.length === 0
        )
      )
      && this.state.selectedColor
    ) {
      socket.emit("update coordinate", {
        campaignId: this.state.campaign.id,
        mapId: this.state.campaignMap.id,
        colorHex: this.state.selectedColor.code,
        colorHexUsage: this.state.selectedColor.usage,
        coordinateId: coord.id,
      });
    }
    // ======= right click can provide a drop-down menu for a marker (and close any other previously open menus) =======
    else {
      this.closePopUpMap();
      if (coord.markers) {
        if (coord.markers.length === 1) {
          this.setStateToMarkerSettings(coord.markers[0]);
          this.setState((prevState) => {
            return {
              rightClickedMarker: coord.markers[0],
              isMultiMarkerMenuPoppedUp: false,
              isMenuPoppedUp: true
            };
          });
        } else if (coord.markers.length > 1) {
          if (this.state.isMultiMarkerMenuPoppedUp) {
            this.setState((prevState) => {
              return {
                isMultiMarkerMenuPoppedUp: false
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                isMultiMarkerMenuPoppedUp: true
              };
            });
          }
        }
      }
    }
  }

  setStateToMarkerSettings(marker) {
    if (marker) {
      if (marker.marker_type !== null) {
        this.setState((prevState) => {
          return {
            markerType: marker.marker_type.id
          };
        });
      }
      if (marker.aware_users) {
        this.setState((prevState) => {
          let users = [...prevState.awareUsers];
          marker.aware_users.forEach((user) => {
            users.push(user.id);
          });
          return {
            awareUsers: users
          };
        });
      }
      if (marker.public_notes) {
        this.setState((prevState) => {
          return {
            typedPublicNotesValue: marker.public_notes
          };
        });
      }
      if (marker.private_notes) {
        this.setState((prevState) => {
          return {
            typedPrivateNotesValue: marker.private_notes
          };
        });
      }
      if (marker.is_hiding) {
        this.setState((prevState) => {
          return {
            isHideToggled: true
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            isHideToggled: false
          };
        });
      }
    }
  }

  handleControlMarkerRightClick(e, marker) {
    e.preventDefault();
    this.closePopUpMap();
    this.setStateToMarkerSettings(marker);
    this.setState((prevState) => {
      return {
        rightClickedMarker: marker,
        isMultiMarkerMenuPoppedUp: false,
        isMenuPoppedUp: true
      };
    });
  }

  handleExpandedIconRightClick(e, marker) { // handles when expanded icons are clicked
    e.stopPropagation();
    e.preventDefault();
    this.closePopUpMap();
    this.setStateToMarkerSettings(marker);
    if (marker) {
      this.setState((prevState) => {
        return {
          rightClickedMarker: marker,
          isMenuPoppedUp: true
        };
      });
    }
  }


  handleExpandedIconClick(e, marker) {
    e.stopPropagation();
    if (
      this.state.boardSelectedMarker &&
      this.state.boardSelectedMarker.id === marker.id &&
      (
        this.state.currentUser.role ||
        (
          this.state.boardSelectedMarker.character &&
          this.state.boardSelectedMarker.character.creator_id === this.state.currentUser.id
        )
      )
    ) {
      this.setState((prevState) => {
        return {
          boardSelectedMarker: null,
          controlsSelectedMarker: null
        };
      });
    }
    // select a newly clicked marker
    else {
      if (
        this.state.currentUser.role ||
        (
          marker &&
          marker.character &&
          marker.character.creator_id === this.state.currentUser.id
        )
      ) {
        this.setState((prevState) => {
          return {
            boardSelectedMarker: marker,
            controlsSelectedMarker: null,
          };
        });
      }
    }
  }

  handleExpandedIconLeaveHover(e, marker) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({hoveredMarker: null});
  }

  handleExpandedIconHover(e, marker) {
    e.stopPropagation();
    e.preventDefault();
    if (marker) {
      if (
        (marker.name) ||
        (marker.character && marker.character.name)
      ) {
        this.setState({hoveredMarker: marker});
      }
    }
  }

  handleUserInclusionClick(e, userId) {
    e.preventDefault();
    // FUTURE FOR PERMISSIONS
    this.setState((prevState) => {
      let users = [...prevState.awareUsers];
      if (!users.includes(userId)) {
        users.push(userId)
      } else {
        users = users.filter(usr =>
          usr !== userId
        );
      }
      return {
        awareUsers: users,
      };
    });
  }

  handleMarkerTypeClick(e, markerType) {
    e.preventDefault();
    // FUTURE FOR PERMISSIONS
    this.setState((prevState) => {
      return {
        markerType: markerType,
      };
    });
  }

  toggleHideMarker() {
    this.setState({
      isHideToggled: !this.state.isHideToggled
    });
  }

  toggleDeleteMarker() {
    this.setState({
      isDeleteToggled: !this.state.isDeleteToggled
    });
  }

  toggleDeleteControlMarker() {
    this.setState({
      isDeleteControlToggled: !this.state.isDeleteControlToggled
    });
  }

  // handleMarkerColorPopUpClick(e) {
  //   e.preventDefault();
  //   this.setState({
  //     isColorOptionsMenuPoppedUp: true
  //   });
  // }

  // handleMarkerColorClick(e = null, code) {
  //   if (e !== null) {
  //     e.preventDefault();
  //   }
  //   this.setState({
  //     isColorOptionsMenuPoppedUp: false,
  //     selectedMenuHex: code
  //   });
  // }

  async handleLeaveHover(e, coord) {
    e.preventDefault();
    this.setState({hoveredMarker: null});
  }

  async handleHover(e, coord) {
    e.preventDefault();
    let hoveredMarker = null;
    // DO PERMISSIONS HERE
    if (coord.markers && coord.markers.length === 1) {
      let marker = coord.markers[0];
      if (
        (marker.name) ||
        (marker.character && marker.character.name)
      ) {
        hoveredMarker = marker;
      }
    }
    if (e.button === 2 || e.buttons === 2) {
      hoveredMarker = null;
      if (
        !this.state.dragFilledCoordinates.includes(coord.id) &&
        this.state.selectedColor
      ) {
        socket.emit("update coordinate", {
          campaignId: this.state.campaign.id,
          mapId: this.state.campaignMap.id,
          colorHex: this.state.selectedColor.code,
          colorHexUsage: this.state.selectedColor.usage,
          coordinateId: coord.id,
        });
      }
    }
    this.setState({hoveredMarker: hoveredMarker});
  }

  handleTypedNewHeightChange(e) {
    e.preventDefault();
    if (
      (
        parseInt(e.target.value) &&
        parseInt(e.target.value) > 0 &&
        parseInt(e.target.value) <= 40
      ) ||
      e.target.value === ""
    ) {
      this.setState({
        typedNewHeight: e.target.value
      });
    }
  }

  handleTypedNewWidthChange(e) {
    e.preventDefault();
    if (
      (
        parseInt(e.target.value) &&
        parseInt(e.target.value) > 0 &&
        parseInt(e.target.value) <= 40
      ) ||
      e.target.value === ""
    ) {
      this.setState({
        typedNewWidth: e.target.value
      });
    }
  }



  render() {
    let excludedNames;
    if (this.state.allUsers) {
      excludedNames = this.state.allUsers.map(user => user.username);
    }

    let publicMessage = this.state.campaign && this.state.campaign.public ?
      "private?" :
      "public? Your campaign will become visible to all other users."

    let shownPopUp =  <ConfirmationPopup
                        msg="Are you sure you want to delete this Campaign? All members of this Campaign will also be removed."
                        onSubmit={this.handleDelete}
                        closePopUp={this.handleClose}
                      />

    let shownRemovePcPopUp =  <ConfirmationPopup
                                actionType={this.state.actionType}
                                username={this.state.selectedUser}
                                msg={`Are you sure you want to remove ${this.state.selectedUser} from the campaign?`}
                                onSubmit={this.handleChangePlayer}
                                closePopUp={this.handleClose}
                              />

    // In the future, disable the search capabilities to protect users and processing speed
    let shownPcPopUp =  <PlayerPopUp
                          message='Invite a user'
                          users={excludedNames}
                          onSubmit={this.handleChangePlayer}
                          actionType={this.state.actionType}
                          closePopUp={this.handleClose}
                        />

    let shownPublicPopUp =  <ConfirmationPopup
                              msg={`Are you sure you want to make this campign ${publicMessage}`}
                              onSubmit={this.handleChangePublic}
                              actionType={this.state.actionType}
                              closePopUp={this.handleClose}
                            />

    let section;
    if (this.state.selectedSection === 'campaignActions') {
      section = <CampaignActions
                  collapsed={this.state.collapsed}
                  campaign={this.state.campaign}
                  currentUser={this.state.currentUser}
                  preview={this.state.preview}
                  users={this.state.users}
                  allUsers={this.state.allUsers}
                  level={this.state.level}
                  togglePopUp={this.togglePopUp}
                  toggleChangePCPopUp={this.toggleChangePCPopUp}
                  toggleRequestPCPopUp={this.toggleRequestPCPopUp}
                  togglePublicPopUp={this.togglePublicPopUp}
                  handleChange={this.handleChange}
                  onSave={this.handleImageSave}
                  resetFields={this.handleImageReset}
                  ref={this.imgRef}
                />
    } else if (this.state.selectedSection === 'fieldView') {
      section = <CampaignFieldView
                  campaign={this.state.campaign}
                  currentUser={this.state.currentUser}
                  allUsers={this.state.allUsers}
                  campaignMap={this.state.campaignMap}
                  controlsMarkers={this.state.controlsMarkers}
                  colorHexes={this.state.colorHexes}
                  fowHexes={this.state.fowHexes}
                  markerHexes={this.state.markerHexes}
                  markerTypes={this.state.markerTypes}
                  indicatorIteration={this.state.indicatorIteration}
                  typedNewHeight={this.state.typedNewHeight}
                  typedNewWidth={this.state.typedNewWidth}
                  boardSelectedMarker={this.state.boardSelectedMarker}
                  controlsSelectedMarker={this.state.controlsSelectedMarker}
                  hoveredMarker={this.state.hoveredMarker}
                  dragFilledCoordinates={this.state.dragFilledCoordinates}
                  loading={this.state.loadingMap}
                  socket={socket}
                  handleTypedNewHeightChange={this.handleTypedNewHeightChange}
                  handleTypedNewWidthChange={this.handleTypedNewWidthChange}
                  submitNewMap={this.submitNewMap}
                  closePopUp={this.closePopUpMap}
                  deleteControlsMarker={this.deleteControlsMarker}
                  handlePopUpSave={this.handlePopUpSave}
                  handleCoordinateClick={this.handleCoordinateClick}
                  handleCoordinateRightClick={this.handleCoordinateRightClick}
                  handleExpandedIconClick={this.handleExpandedIconClick}
                  handleExpandedIconRightClick={this.handleExpandedIconRightClick}
                  handleExpandedIconHover={this.handleExpandedIconHover}
                  handleExpandedIconLeaveHover={this.handleExpandedIconLeaveHover}
                  handleHover={this.handleHover}
                  handleLeaveHover={this.handleLeaveHover}
                  handleStopFill={this.handleStopFill}
                  selectHexColor={this.selectHexColor}
                  selectMapMarker={this.selectMapMarker}
                  selectMapIndicator={this.selectMapIndicator}
                  requestNewMap={this.requestNewMap}
                  createNewMarkerClick={this.createNewMarkerClick}
                  handleControlMarkerRightClick={this.handleControlMarkerRightClick}
                  handleNameChange={this.handleNameChange}
                  handleTypedPublicNotesValueChange={this.handleTypedPublicNotesValueChange}
                  handleTypedPrivateNotesValueChange={this.handleTypedPrivateNotesValueChange}
                  handleUserInclusionClick={this.handleUserInclusionClick}
                  handleMarkerTypeClick={this.handleMarkerTypeClick}
                  toggleDeleteMarker={this.toggleDeleteMarker}
                  toggleDeleteControlMarker={this.toggleDeleteControlMarker}
                  toggleHideMarker={this.toggleHideMarker}
                  setStateToMarkerSettings={this.setStateToMarkerSettings}

                  selectedColor={this.state.selectedColor}
                  controlsSelectedIndicator={this.state.controlsSelectedIndicator}
                  rightClickedMarker={this.state.rightClickedMarker}
                  isMenuPoppedUp={this.state.isMenuPoppedUp}
                  isNewMapRequestPoppedUp={this.state.isNewMapRequestPoppedUp}
                  isNewMarkerRequestPoppedUp={this.state.isNewMarkerRequestPoppedUp}
                  isMultiMarkerMenuPoppedUp={this.state.isMultiMarkerMenuPoppedUp}
                  nameContent={this.state.nameContent}
                  typedPublicNotesValue={this.state.typedPublicNotesValue}
                  typedPrivateNotesValue={this.state.typedPrivateNotesValue}
                  selectedMenuHex={this.state.selectedMenuHex}
                  isDeleteToggled={this.state.isDeleteToggled}
                  isDeleteControlToggled={this.state.isDeleteControlToggled}
                  isHideToggled={this.state.isHideToggled}
                  isColorOptionsMenuPoppedUp={this.state.isColorOptionsMenuPoppedUp}
                  awareUsers={this.state.awareUsers}
                  markerType={this.state.markerType}
                />
    } else if (
      this.state.campaign &&
      (
        this.state.selectedSection === 'charSheet'
        // ||
        // localStorage.getItem('toChar') === 'true'
      )
    ) {
      section = <CampaignCharSheet
                  currentUser={this.state.currentUser}
                  getCampaign={this.getCampaign}
                  campaign={this.state.campaign}
                  users={this.state.allUsers}
                  level={this.state.level}
                  pop={this.props.pop}
                />
    // } else if (this.state.selectedSection === 'logs') {
    //   section = <CampaignInfo />
    } else {
      section = <CampaignHome
                  campaign={this.state.campaign}
                  users={this.state.allUsers}
                  level={this.state.level}
                  loading={this.state.loading}
                  onSave={this.saveNotes}
                  typedQuestNotesValue={this.state.typedQuestNotesValue}
                  handleTypedQuestNotesValueChange={this.handleTypedQuestNotesValueChange}
                />
    }

    const sections = [
      // {'code': 'home', 'name': 'Home', 'symbol': 'fa-house' },
      {'code': 'home', 'name': 'Home', 'symbol': 'fa-users' },
      {'code': 'charSheet', 'name': 'Char Sheet', 'symbol': 'fa-user' },
      // {'code': 'logs', 'name': 'Campaign Info', 'symbol': 'fa-users' },
      {'code': 'fieldView', 'name': 'Field View', 'symbol': 'fa-street-view' },
      // {'code': 'fieldView', 'name': '**Future Section**', 'symbol': 'fa-question' },
      // {'code': 'campaignChat', 'name': 'Chat', 'symbol': 'fa-comment-dots' }
    ]
    if (this.state.level === 'dm') {
      sections.push({'code': 'campaignActions', 'name': 'Settings', 'symbol': 'fa-gear' });
    }

    let collapsedNav = this.state.navCollapsed ? "campaign-nav-collapsed" : "";
    let collapsedSection = this.state.navCollapsed ? "campaign-section-collapsed" : "";

    let nav = <CampaignNav
                handleCollapseHover={this.handleCollapseHover}
                handleCollapseUnHover={this.handleCollapseUnHover}
                sections={sections}
                hovered={this.state.navHovered}
                collapsed={this.state.navCollapsed}
                level={this.state.level}
                onCollapseButtonClick={this.onCollapseButtonClick}
                selectedSection={this.state.selectedSection}
                handleSectionClick={this.handleSectionClick}
              />

    return(
      <div className="wholePage campaign-page" id="container">
        <div className="campaign-page">
          <div className={`campaign-nav ${collapsedNav}`}>
            {nav}
          </div>
          <div className={`campaign-section-container ${collapsedSection}`}>
            {section}
          </div>
        </div>
        {this.state.popUp ? shownPopUp : null}
        {this.state.pcPopUp ? shownPcPopUp : null}
        {this.state.pcRemovePopUp ? shownRemovePcPopUp : null}
        {this.state.publicPopUp ? shownPublicPopUp : null}
      </div>
    )
  }
}

export default CampaignPage
