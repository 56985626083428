import React from "react";
import DescPrompt from "./DescPrompt";

class SelectionPoolPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false
    };
    this.handleDescHover = this.handleDescHover.bind(this);
    this.handleDescUnHover = this.handleDescUnHover.bind(this);
  }

  handleDescHover(e) {
    e.preventDefault();
    this.setState({
      hovered: true
    });
  }

  handleDescUnHover(e) {
    e.preventDefault();
    this.setState({
      hovered: false
    });
  }

  render() {

    //purely for cosmetic purposes
    let sel = this.props.selection;
    let value;
    if (sel.increment_type_id === 1) {
      value = " +" + String(sel.increment_value);
    } else if (
      sel.increment_type_id === 3 &&
      sel.increment_value
    ) {
        value = ": " + String(sel.increment_value);
    }

    let descs;
    let displayedDescs;
    if (this.props.selection.source === 'language') {
      displayedDescs =
        <div className="languageDesc">
          <div>
            <div>Speakers</div>
            <div>
              {this.props.pool.speakers}
            </div>
          </div>
          <div>
            <div>Script</div>
            <div>
              {this.props.pool.script}
            </div>
          </div>
          <div>
            <div>Language Quality</div>
            <div>
              {this.props.pool.lang_type}
            </div>
          </div>
        </div>
    } else if (this.props.selection.source === 'spell') {
      descs = this.props.pool.descs ?
        this.props.pool.descs.map((desc, index) => {
          return <DescPrompt
                  key={index}
                  desc={desc}
                 />
        }) :
        null;
      displayedDescs = descs;
    } else if (this.props.selection.source === 'dragon_chromatic') {
      displayedDescs =
        <div>
          <div>
            <div>Chromatic Color</div>
            <div>{this.props.pool.name}</div>
          </div>
          <div>
            <div>Dragon</div>
            <div>{this.props.pool.dragon}</div>
          </div>
          <div>
            <div>Breath Weapon</div>
            <div>{this.props.pool.breath_weapon}</div>
          </div>
          <div>
            <div>Damage Type</div>
            <div>{this.props.pool.damage_type.name}</div>
          </div>
        </div>
    } else {
      descs = displayedDescs = this.props.pool.descs ?
        this.props.pool.descs.map((desc, index) => {
          return <DescPrompt
                  key={index}
                  desc={desc}
                 />
        }) :
        null;
    }

    let disabled = false;
    let selectable = "selectableSelection";
    try {
      if (
        (
          this.props.characterSelections &&
          (
            this.props.selection.target !== 'ability' &&
            this.props.selection.is_permutation &&
            this.props.characterSelections[this.props.selection.target].some(selection =>
                selection.id === this.props.pool.id
            )
          )
        ) ||
        this.props.selections.some(pool =>
          pool.selection.force_added &&
          pool.id === this.props.pool.id &&
          pool.selection.is_permutation &&
          pool.selection.source === this.props.selection.source &&
          pool.selection.target !== 'expanded_spells'
        ) ||
        this.props.selections.some(pool =>
          // pool.fromPrimary === this.props.pool.is_primary &&
          pool.selection.num_selections &&
          pool.selection.id !== this.props.selection.id &&
          pool.selection.source === this.props.selection.source &&
          pool.selection.target === this.props.selection.target &&
          pool.id === this.props.pool.id
        )
      ) {
        selectable = "disabled";
        disabled = true;
      }
    } catch {
      // character field not found
    }

    // THIS IS THE TEMPLATE FOR
    // chosenSelections AND forcedSelections
    const selection = this.props.selection;
    const selectionClone = {
      "acquisition_type_id": selection.acquisition_type_id,
      "feature_id": selection.feature_id,
      "background_feature_id": selection.background_feature_id,
      "trait_id": selection.trait_id,
      "force_added": selection.force_added,
      "id": selection.id,
      "increment_type_id": selection.increment_type_id,
      "increment_value": selection.increment_value,
      "is_permutation": selection.is_permutation,
      "num_selections": selection.num_selections,
      "optional_selection": selection.optional_selection,
      "source": selection.source,
      "target": selection.target
    }
    let alphabet = ['A','B','C','D','E','F','G','H','I'];
    let name = this.props.pool.name;
    if (selection.source === 'selection') {
      name = `Option ${alphabet[this.props.order]}`;
    }
    let quantity = this.props.pool.quantity ? this.props.pool.quantity : null;
    let displayedQuantity = quantity && quantity > 1 ? ` (x${quantity})` : null;
    let obj = {
      "name": name,
      "id": this.props.pool.id,
      "quantity": quantity,
      "value": value,
      "trait_id": selection.trait_id,
      "background_feature_id": selection.background_feature_id,
      "feature_id": selection.feature_id,
      "selection": selectionClone,
      "sourceSection": this.props.source,
      "desc": this.props.pool.descs,
      "disabled": disabled,
      "fromPrimary": this.props.pool.is_primary
    };

    let selected = null;
    if (
      this.props.selections.some(pool =>
        pool.selection.num_selections &&
        pool.selection.id === obj.selection.id &&
        pool.id === obj.id &&
        pool.fromPrimary === obj.fromPrimary
      )
    ) {
      selected = "selected";
    }

    let status;
    let tally = 1;
    let checkedPools = [];
    this.props.selections.forEach((pool) => {
      if (
        checkedPools.some(checkedPool =>
          checkedPool.name === pool.name &&
          checkedPool.selectionId === pool.selection.id &&
          checkedPool.id === pool.id &&
          checkedPool.featureId === pool.feature_id
        ) &&
        this.props.pool.id === pool.id &&
        this.props.pool.name === pool.name &&
        this.props.selection.id === pool.selection.id
      ) {
        tally += 1;
      } else {
        let checkedPool = {
          name: pool.name,
          selectionId: pool.selection.id,
          id: pool.id,
          featureId: pool.feature_id
        };
        checkedPools.push(checkedPool);
      }
    });
    if (tally > 1) {
      status = `x${tally}`;
    }

    let readMore = this.props.pool.descs
    ? <div
        onClick={((e) => this.props.onReadMoreClick(e, this.props.selection.source, this.props.pool))}
        className='auto-trait-read-more'
      >
        <i className="fa-solid fa-circle-info"></i>
      </div>
    : null;

    return (
      <div
        className={`auto-selection ${selectable} ${selected}`}
        onClick={((e) => this.props.handleSelectionClick(e, obj))}
      >
        <div className='auto-trait'>
          {name}{displayedQuantity}
        </div>
        <div className='auto-trait-desc'>
          {displayedDescs}
        </div>
        {readMore}
        <div>
          {status}
        </div>
      </div>
    );
  }
}

export default SelectionPoolPrompt;
