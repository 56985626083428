import React from 'react';

const CampaignNav = props => {

  let navBar;
  if (props.sections) {
    navBar = props.sections.map((section, index) => {
      let selected = '';
      if (props.selectedSection === section.code) {
        selected = 'campaign-nav-option-selected';
      }
      let name;
      if (!props.collapsed) {
        name = <div className="campaign-nav-option-name">{section.name}</div>;
      }
      return <div
               key={index}
               className={`campaign-nav-option ${selected}`}
               onClick={((e) => props.handleSectionClick(e, section.code))}
             >
               <div className="campaign-nav-option-symbol">
                 <i className={`fa-solid ${section.symbol}`}></i>
               </div>
                {name}
             </div>
    });
  }

  let collapseButtonSymbol = props.collapsed ?
    <i className="fa-solid fa-chevron-right"></i> :
    <i className="fa-solid fa-chevron-left"></i>;
  let collapsedState = props.collapsed ? 'campaign-nav-collapsed-button' : '';

  let collapseButton, placement;
  if (props.level === 'dm') {
    placement = 'campaign-four-nav-options';
  } else {
    placement = 'campaign-three-nav-options';
  }
  if (props.hovered) {
    collapseButton =  <div
                        className={`campaign-nav-collapse-button-container ${collapsedState} ${placement}`}
                        onClick={props.onCollapseButtonClick}
                      >
                        <div className="campaign-nav-collapse-symbol">
                          {collapseButtonSymbol}
                        </div>
                      </div>;
  }

  return(
    <div
      onMouseEnter={props.handleCollapseHover}
      onMouseLeave={props.handleCollapseUnHover}
      className="campaign-nav-container"
    >
      {collapseButton}
      {navBar}
    </div>
  )
}

export default CampaignNav
