import React from 'react';
const ProceedButtons = props => {

  const isDisabled = props.isDisabled ? "disabled-button" : null;

  return (
    <div className='proceed-buttons-container'>
      <div
        className={`proceed-classes-button ${isDisabled}`}
        onClick={props.handleProceedForward}
      >
        <div
          className={`proceed-classes-button-text ${isDisabled}`}
        >
        {props.proceedText}
        </div>
      </div>

      <div
        className={`go-back-button`}
        onClick={props.handleGoBack}
      >
        <div
          className={`go-back-button-text`}
        >
        Go Back
        </div>
      </div>
    </div>
  );
}

export default ProceedButtons;
