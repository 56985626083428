import React from 'react';

const ConfirmationPopup = props => {
  let username = null;
  let actionType = null;
  if (props.username) {
    username = props.username;
  }
  if (props.actionType) {
    actionType = props.actionType;
  }

  let value = {
    username: username,
    actionType: actionType
  };

  return (
    <div className='pop-up'>
      <div className='pop-up-inner-confirmation'>
        <div className='pop-up-message'>
          <div className='pop-up-message-text'>
            {props.msg}
          </div>
        </div>
        <div className='pop-up-buttons-wrapper'>
          <div className='pop-up-button-container'>
            <div
              className='pop-up-button pop-up-yes'
              onClick={((e) => props.onSubmit(e, value))}
            >
              YES
            </div>
            <div
              className='pop-up-button pop-up-no'
              onClick={props.closePopUp}
            >
              NO
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPopup;
