import React, {Component} from 'react';

class ErrorAuthorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }


  render() {
    return(
      <div className="wholePage" id="container">
        Error with Authorization
      </div>
    )
  }
}

export default ErrorAuthorization
