import React from "react";
import DescPrompt from "./DescPrompt";
import check from "../../../icons/check.png";
import exclamation from "../../../icons/exclamation.png";

const FeaturePrompt = props => {

  let descs = props.feature.descs.map((desc, index) => {
    return <DescPrompt
            key={index}
            desc={desc}
           />
  });

  let status;
  let setAsideName = {};
  let setAsideDescriptors = [];
  let chosenSelections = [];
  props.featureSelections.forEach((selection) => {
    const val = selection.value ? String(selection.value) : "";
    const quantity = selection.quantity && selection.quantity > 1 ? ` (x${selection.quantity})` : '';
    if (
      props.feature.id === selection.feature_id &&
      !selection.selection.force_added
    ) {
      if (selection.selection.source === 'selection') {
        setAsideName = {'id':selection.id, 'name':selection.name};
      } else {
        chosenSelections.push(
          selection.name + val + quantity
        );
      }
    } else if (
      selection.feature_id === null &&
      selection.name !== null
    ) {
      setAsideDescriptors.push(selection);
    }
  });
  let finalRendering;
  setAsideDescriptors.forEach((descriptor, index)=> {
    const quantity = descriptor.quantity && descriptor.quantity > 1 ? ` (x${descriptor.quantity})` : '';
    if (
      descriptor &&
      descriptor.selection.id === setAsideName.id
    ) {
      if (index === 0) {
        finalRendering = setAsideName.name + ": " + descriptor.name + quantity;
      } else {
        finalRendering = finalRendering + ", " + descriptor.name + quantity;
      }
    }
  });
  if (!finalRendering) {
    finalRendering = setAsideName.name ? setAsideName.name : '';
  }
  chosenSelections.push(finalRendering);


  let renderedChosenSelections = chosenSelections.map((selection, index) => {
    let plus = selection ? <i className="fa-solid fa-plus chosen-selection-subtext-symbol"></i> : null;
    return <div key={index} className="chosen-selection-subtext">{plus} {selection}</div>
  });

  let selectedAuxiliary = [];
  let totalNeeded = 0;
  let totalMet = 0;
  props.feature.selections.forEach((selection) => {
    if (selection.num_selections) {
      totalNeeded += 1;
      if (
        props.auxiliary_selections.some(auxSel =>
          selection.id === auxSel.parent
        )
      ) {
        selectedAuxiliary.push(selection.id);
      }
      let chosenSelectionsTally = props.featureSelections.filter(pool =>
        pool.selection.source !== 'selection' &&
        selection.id === pool.selection.id &&
        selection.num_selections
      );
      if (chosenSelectionsTally.length === selection.num_selections) {
        totalMet += 1;
      }
    }
  });
  if (
    props.feature.selections.some(selection =>
      selection.source === 'selection'
    )
  ) {
    props.auxiliary_selections.forEach((auxPool) => {
      if (selectedAuxiliary.includes(auxPool.parent)) {
        if (
          auxPool.selection.num_selections &&
          selectedAuxiliary.length > 0
        ) {
          let chosenSelectionsTally = props.featureSelections.filter(pool =>
            pool.selection.id === auxPool.id &&
            pool.feature_id === null
          );
          if (chosenSelectionsTally.length === auxPool.selection.num_selections) {
            totalMet += 1;
          }
        }
        else if (
          auxPool.selection.force_added &&
          selectedAuxiliary.length > 0
        ) {
          totalMet += 1;
        }
      }
    });
  }

  let disabled = null;
  if (props.feature.is_disabled === true) {
    disabled = "disabled-trait";
  } else {
    disabled = "selectableTrait"
    if (
      props.feature.selections.every(selection =>
        // [1,2].includes(selection.acquisition_type_id)
        selection.optional_selection === true
      )
    ) {
      status = <i className="fa-solid fa-circle-chevron-right statusIcon"></i>
    } else if (totalMet === totalNeeded) {
      status = <img className="statusIcon" src={check} alt=""/>;
    } else {
      status = <img className="statusIcon" src={exclamation} alt=""/>;
    }
  }

  let readMore = props.feature.descs
  ? <div
      onClick={((e) => props.onReadMoreClick(e, 'feature', props.feature))}
      className='auto-trait-read-more'
    >
      <i className="fa-solid fa-circle-info"></i>
    </div>
  : null;

  return (
    <div className={`auto-traits-and-descs ${disabled} ${props.selectedIndex}`}
      onClick={((e) => props.handleFeatureClick(e, props.feature))}
    >
      <div className='auto-trait'>
        <div>
          {props.feature.name}
        </div>
        <div>
          {props.deductionText}
          {renderedChosenSelections}
        </div>
        <div>
          {status}
        </div>
      </div>
      <div className='auto-trait-desc'>
        {descs}
      </div>
      {readMore}
    </div>
  );
}

export default FeaturePrompt;
