import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ConfirmationPopup from '../components/ConfirmationPopup';
import PlayerPopUp from './PlayerPopUp';


class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      oldPass: "",
      newPass: "",
      repeat: "",
      password: "",
      user: null,
      allUsers: null,
      selectedUser: null,
      file: null,
      preview: null,
      popUp: false,
      passPop: false,
      shownPopUp: false,
      shownUsersPopUp: false,
    };
    this.displayUserProfile = this.displayUserProfile.bind(this);
    this.handleNameFormSubmit = this.handleNameFormSubmit.bind(this);
    this.handlePassFormSubmit = this.handlePassFormSubmit.bind(this);
    this.changeUserInfo = this.changeUserInfo.bind(this);
    this.handleDisplayNameChange = this.handleDisplayNameChange.bind(this);
    this.handleOldPassChange = this.handleOldPassChange.bind(this);
    this.handleNewPassChange = this.handleNewPassChange.bind(this);
    this.handleRepeatChange = this.handleRepeatChange.bind(this);
    this.handleDeleteOwnUser = this.handleDeleteOwnUser.bind(this);
    this.handleDeleteOtherUser = this.handleDeleteOtherUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.onSave = this.onSave.bind(this);
    this.togglePopUp = this.togglePopUp.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.resetFields = this.resetFields.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.displayUserProfile();
  }

  displayUserProfile() {
    fetch("/api/v1/current_user", {
      method: 'GET',
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(body => {
      if (body) {
        let name = "";
        if (body.display_name) {
          name = body.display_name;
        }
        this.setState({ user: body, displayName: name });
      }
    });
  }

  handleDisplayNameChange(event) {
    this.setState({ displayName: event.target.value });
  }

  handleOldPassChange(event) {
    this.setState({ oldPass: event.target.value });
  }

  handleNewPassChange(event) {
    this.setState({ newPass: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleRepeatChange(event) {
    this.setState({ repeat: event.target.value });
  }

  handleNameFormSubmit(event) {
    event.preventDefault();
    let transactionType = "name_change";
    let formPayload = new FormData();
    formPayload.append('type', transactionType);
    formPayload.append('display_name', this.state.displayName);
    this.changeUserInfo(formPayload, transactionType);
  }

  handlePassFormSubmit(event) {
    event.preventDefault();
    let transactionType = "pass_change";
    let formPayload = new FormData();
    formPayload.append('type', "pass_change");
    formPayload.append('old_password', this.state.oldPass);
    formPayload.append('new_password', this.state.newPass);
    formPayload.append('repeat_pass', this.state.repeat);
    this.changeUserInfo(formPayload, transactionType);
  }

  async changeUserInfo(formPayload, transactionType) {
    let url = `/api/v1/edit_user/${this.props.match.params.id}`;
    const response = await fetch(url, {
      method: 'PUT',
      body: formPayload,
      credentials: 'same-origin'
    });
    const json = await response.json();
    this.props.pop(json.msg, json.status);
    if(response.status === 200) {
      if (transactionType === 'pass_change') {
        this.props.history.push("/login");
      }
      await this.props.displayUser();
    } else {
      this.props.history.push("/edit_profile/" + this.props.match.params.id);
    }
  }

  handleDeleteOwnUser(e, value) {
    e.preventDefault();
    let formPayload = new FormData();
    let userId = this.props.match.params.id;
    if (this.state.user.owner) {
      formPayload.append('password', this.state.password);
    }
    this.deleteUser(formPayload, userId);
  }

  handleDeleteOtherUser(e, value) {
    e.preventDefault();
    let formPayload = new FormData();
    let userId = value.username;
    if (this.state.user.owner) {
      formPayload.append('password', this.state.password);
    }
    this.deleteUser(formPayload, userId);
  }

  async deleteUser(formPayload, userId) {
    let url = `/api/v1/edit_user/${userId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      body: formPayload,
      credentials: 'same-origin'
    });
    this.props.pop("Your account has been deleted.", response.status);
    if(response.status === 200) {
      this.props.history.push("/");
      await this.props.displayUser();
    } else {
      this.props.history.push("/edit_profile/" + this.props.match.params.id);
    }
  }

  handleChange(e) {
    this.setState({file: e.target.files[0]});
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      this.setState({preview: [reader.result]});
    }.bind(this);
  }

  async onSave(e) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('type', "upload_photo");
    formPayload.append('file', this.state.file);
    formPayload.append('genre', 'users');
    formPayload.append('category', 'user');
    formPayload.append('id', this.state.user.id);
    let url = `/api/v1/edit_user/${this.props.match.params.id}`;
    const response = await fetch(url, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload
    });
    const json = await response.json();
    this.props.pop(json.msg, json.status);
    if(response.status === 200) {
      // console.log("success");
      this.displayUserProfile();
      await this.props.displayUser();
    } else {
      this.props.history.push("/edit_profile/" + this.props.match.params.id);
    }
  }

  resetFields(e) {
    e.preventDefault();
    this.setState((preState) => {
      return {
        file: null,
        preview: null,
      };
    });
    this.displayUserProfile();
  }


  togglePopUp() {
    this.setState({popUp: !this.state.popUp});
  }

  handleClose(e) {
    e.preventDefault();
    this.closePopUp();
  }

  closePopUp() {
    this.setState({
      popUp: false,
      passPop: false,
      shownPopUp: false,
      shownUsersPopUp: false,
    });
  }

  getUsersList() {
    fetch(`/api/v1/users_except/${this.props.match.params.id}`, {
      credentials: 'same-origin',
      method: 'GET',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState({
          campaign: res.body.campaign,
          level: res.body.level,
          users: res.body.users,
          currentUser: res.body.current_user,
          allUsers: res.body.all_users});
      } else {
        this.props.pop(res.body.msg, res.body.status);
      }
    }));
  }


  render() {
    let excludedNames;
    if (this.state.allUsers) {
      excludedNames = this.state.allUsers.map(user => user.username);
    }
    // let user;
    // let imgSrc;
    // if (this.state.user === null) {
    //   user = null;
    // } else {
    //   if (this.state.preview !== null) {
    //     imgSrc = this.state.preview;
    //   } else {
    //     imgSrc = this.state.user.photo;
    //   }
    //   user = <div className="edit-profile-image-container">
    //            <img className="userImage edit-profile-image" src={imgSrc} alt="preview" />
    //          </div>
    // }

    let selectDeleteUser = null;
    // if (this.state.user.admin) {
    //   selectDeleteUser = <div className="image-button pop-up-no" onClick={this.handleDeleteUser} name="delete" value="">Delete a user</div>
    // }

    let shownPopUp =  <ConfirmationPopup
                        msg='Are you sure you want to delete your account?'
                        onSubmit={this.handleDeleteOwnUser}
                        closePopUp={this.handleClose}
                      />

    let shownUsersPopUp =  <PlayerPopUp
                            message='Delete an account'
                            users={excludedNames}
                            onSubmit={this.handleDeleteOtherUser}
                            closePopUp={this.handleClose}
                          />

    let deleteButton = null;
    deleteButton = <div className="edit-profile-finish-button pop-up-no" onClick={this.togglePopUp}>Delete your account</div>

    let userPhoto = <div className="campaign-actions-blank-preview"></div>;
    let imgSrc;
    let unclickable = "is-unclickable";
    if (this.state.preview !== null ) {
      imgSrc = this.state.preview;
      unclickable = "";
    } else if (this.state.user) {
      imgSrc = this.state.user.photo;
    }
    if (imgSrc !== null) {
      userPhoto = <img className="campaign-actions-image" src={imgSrc} alt="campaign"/>
    }

    return(
      <div className="wholePage" id="container">
        <div className="edit-profile-page">
          <div className="edit-profile-wrapper">

            <div className="edit-profile-section">
              <div className="campaign-actions-image-header">
                Profile Image
              </div>
              <div className="campaign-actions-image-container">
                {userPhoto}
              </div>
              <div className="campaign-actions-image-buttons-container">
                <div className="campaign-actions-photo-button-row">
                  <div className="campaign-actions-image-button-file">
                    Choose Image
                    <input
                      type='file'
                      name='file'
                      key={Date.now().toString()}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="campaign-actions-photo-button-row">
                  <div
                    className={`campaign-actions-image-button ${unclickable}`}
                    onClick={this.resetFields}
                  >
                    Reset
                  </div>
                </div>
                <div className="campaign-actions-photo-button-row">
                  <div
                    className={`campaign-actions-image-button ${unclickable}`}
                    onClick={this.onSave}
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>


            <div className="edit-profile-section">
              <div className="campaign-actions-image-header">
                Update Password
              </div>
              <form className="edit-profile-credentials-wrapper">
                <label className="edit-profile-field">
                  <input className="edit-profile-input" type='password' onChange={this.handleOldPassChange} value={this.state.oldPass} placeholder={'Old password'}/>
                </label>
                <label className="edit-profile-field">
                  <input className="edit-profile-input" type='password' onChange={this.handleNewPassChange} value={this.state.newPass} placeholder={'New password'}/>
                </label>
                <label className="edit-profile-field">
                  <input className="edit-profile-input" type='password' onChange={this.handleRepeatChange} value={this.state.repeat} placeholder={'Retype new password'}/>
                </label>
              </form>
              <div className="edit-profile-button-container">
                <div className="edit-profile-finish-button pop-up-yes" type='submit' onClick={this.handlePassFormSubmit} value='Submit'>Submit</div>
                {deleteButton}
                {selectDeleteUser}
              </div>
            </div>
            <div className="edit-profile-section">
              <div className="campaign-actions-image-header">
                Change Display Name
              </div>
              <form className="edit-profile-credentials-wrapper">
                <label className="edit-profile-field">
                  <input className="edit-profile-input" type='text' onChange={this.handleDisplayNameChange} value={this.state.displayName} placeholder={this.state.displayName}/>
                </label>
              </form>
              <div className="edit-profile-button-container">
                <div className="edit-profile-finish-button pop-up-yes" type='submit' onClick={this.handleNameFormSubmit} value='Submit'>Submit</div>
              </div>
            </div>
          </div>
        </div>
        {this.state.popUp ? shownPopUp : null}
        {this.state.usersPopUp ? shownUsersPopUp : null}
      </div>
    )
  }
}

export default withRouter(EditProfile)

//
// let shownOtherPopUp =  <ConfirmationPopup
//                         msg={`Are you sure you want to delete the account of ${this.state.selectedUser.username}, who will lose access to everything?.`}
//                         onSubmit={this.handleDeleteOtherUser}
//                         closePopUp={this.handleClose}
//                       />





// <div className="edit-profile-image-section">
//   {user}
//   <div className="edit-profile-input-container">
//     <div className="edit-profile-input-inner-wrapper">
//       <input className="edit-profile-input" type='file' name='file' onChange={this.handleChange} />
//     </div>
//   </div>
//   <div className="edit-profile-buttons-container">
//     <div className="edit-profile-image-button pop-up-yes" type='submit' onClick={this.onSave} value='Submit'>Save</div>
//     <div className="edit-profile-image-button pop-up-no" type='submit' onClick={this.resetFields} value='Submit'>Reset</div>
//   </div>
// </div>
