import React from "react";

const QualitySelectionPrompt = props => {

  const qualityConversion = {
    'personality': 'Personality',
    'ideal': 'Ideal',
    'bond': 'Bond',
    'flaw': 'Flaw'
  }
  let render;
  let selected, desc, extra, bolded, dieResult;
  if (props.selectedQuality && props.quality) {
    selected = props.quality.id === props.selectedQuality.id ? 'selected' : null;
  }
  dieResult = props.diceHeader;
  if (props.quality === null) {
    bolded = 'bolded-header';
    if (props.extraHeader) {
      desc = props.extraHeader;
    } else {
      desc = `${qualityConversion[props.source]} Descriptions`;
    }
    render =  <div
                className="auto-selection"
               >
                <div className={`auto-trait ${bolded}`}>
                  {dieResult}
                </div>
                <div className={`auto-trait-desc ${bolded}`}>
                  {desc}
                </div>
              </div>
  } else {
    desc = props.quality.desc;
    dieResult = props.quality.die_result;
    if (props.source === 'ideal') {
      extra = props.quality.header_text;
    }
    render =  <div
                className={`auto-selection selectableSelection ${selected}`}
                onClick={((e) => props.handleBackgroundCharacteristicSelectionClick(e, props.source, props.quality))}
               >
                <div className={`auto-trait ${bolded}`}>
                  {dieResult}
                </div>
                <div className={`auto-trait-desc ${bolded}`}>
                  <div className='story-mode-header-text-bullet'>
                    {extra}
                  </div>
                  {desc}
                </div>
                <div
                  onClick={((e) => props.onReadMoreClick(e, props.source, props.quality))}
                  className='auto-trait-read-more'
                >
                  <i className="fa-solid fa-circle-info"></i>
                </div>
              </div>
  }

  return (
    <div className='selection-row'>
      {render}
    </div>
  );
}

export default QualitySelectionPrompt;
