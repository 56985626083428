import React from 'react';
import Loader from './Loader';

const SignUpForm = props => {

  if (props.loading !== false) return <Loader/>;
  const loader = props.loading ? <i className="fa fa-cog fa-spin" /> : null;

  return(
    <div className="signup-form-container">
      {loader}
      <div className="signup-form-header">
        Sign Up
      </div>
      <form className='signup-form'>
        <div className='signup-form-inner-wrapper'>
          <div className='signup-form-fields-cluster'>
            <label className="inputField signup-form-field">
              <input
                className="field signup-form-field-inner"
                type='text'
                onChange={props.handleUsernameChange}
                onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                value={props.usernameContent}
                placeholder={'Username'}
              />
            </label>
            <label className="inputField signup-form-field">
              <input
                className="field signup-form-field-inner"
                type='text'
                onChange={props.handleDisplayNameChange}
                onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                value={props.displayNameContent}
                placeholder={'Display Name'}
              />
            </label>
            <label className="inputField signup-form-field">
              <input
                className="field signup-form-field-inner"
                type='text'
                onChange={props.handleEmailChange}
                onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                value={props.emailContent}
                placeholder={'Email Address'}
              />
            </label>
            <label className="inputField signup-form-field">
              <input
                className="field signup-form-field-inner"
                type='password'
                onChange={props.handlePasswordChange}
                onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                value={props.passwordContent}
                placeholder={'Password'}
              />
            </label>
            <label className="inputField signup-form-field">
              <input
                className="field signup-form-field-inner"
                type='password'
                onChange={props.handleRepeatPasswordChange}
                onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                value={props.repeatPasswordContent}
                placeholder={'Retype Password'}
              />
            </label>
          </div>
          <div className="signup-form-button" type='submit' onClick={props.handleSubmit} value='Submit'>
            Submit
          </div>
        </div>
      </form>
    </div>

  )
}

export default SignUpForm
