import React from 'react';
import { Link } from 'react-router-dom';

const HomeCampaignRow = props => {
  let headerStyling = '';
  let name;
  let groupSize;
  let dms = '';
  let dmDiv;

  if (props.name && props.id) {
    name = <Link to={"/campaigns/" + props.id} className={`home-campaign-row-cell home-campaign-row-name ${headerStyling}`}>
            {props.name}
           </Link>
  } else {
    headerStyling = 'home-campaign-row-header';
    name = <div className={`home-campaign-row-cell home-campaign-row-name ${headerStyling}`}>
            Campaign Name
           </div>
  }
  if (props.groupSize) {
    groupSize = <div className={`home-campaign-row-cell home-campaign-row-group-size ${headerStyling}`}>
            {props.groupSize}
           </div>;
  } else {
    groupSize = <div className={`home-campaign-row-cell home-campaign-row-group-size ${headerStyling}`}>
            # of Members
           </div>
  }

  if (props.dms) {
    props.dms.forEach((dm) => {
      if (dms !== '') {
        dms += ", ";
      }
      dms += dm.username;
    });
    dmDiv = <div className={`home-campaign-row-cell home-campaign-row-dm ${headerStyling}`}>
              {dms}
            </div>
  } else {
    dmDiv = <div className={`home-campaign-row-cell home-campaign-row-dm ${headerStyling}`}>
              Dungeon Master(s)
            </div>
  }

  return (
    <div className='home-campaign-row-container'>
      {name}
      {groupSize}
      {dmDiv}
    </div>
  );
}

export default HomeCampaignRow;
