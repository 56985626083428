import React from "react";
import MouseUpLink from "../MouseUpLink";
import check from "../../../icons/check.png";
import exclamation from "../../../icons/exclamation.png";

const SectionsMenu = props => {

  const modulusBorderRadius = [
    'border-radius-top-left-4',
    'border-radius-top-right-4',
    'border-radius-bottom-left-4',
    'border-radius-bottom-right-4'
  ];


  let warningText, disabled;
  disabled = '';
  if (props.currentUser && props.currentUser.made_chars.length >= 15 && props.isNew) {
    warningText = <div className="char-prompt-menu-warning">
                    <img className="char-prompt-menu-warning-icon" src={exclamation} alt=""/>
                    Maximum number of characters created reached (15). Delete other characters first to create another.
                    <img className="char-prompt-menu-warning-icon" src={exclamation} alt=""/>
                  </div>
    disabled = 'disabled-button';
  }


  let sections = props.sections.map((section, index) => {
    let isDone;
    let borderRadiusLocation;
    let sectionText;
    let sectionAccentName;
    let sectionAccent;
    let sectionRender;
    borderRadiusLocation = modulusBorderRadius[index];
    if (section === 'bio') {
      sectionAccentName = 'accented-heading';
      sectionAccent = <div className="accented-word">(Recommended first)</div>;
      sectionText = 'Create Bio ';
      if (props.character) {
        isDone = props.character.bio_done
                  ? <img className="menuIcon" src={check} alt=""/>
                  : <img className="menuIcon" src={exclamation} alt=""/>;
      }
    } else if (section === 'race') {
      sectionText = 'Choose Race';
      if (props.character) {
        isDone = props.character.auto_races_done
                  ? <img className="menuIcon" src={check} alt=""/>
                  : <img className="menuIcon" src={exclamation} alt=""/>;
      }
    } else if (section === 'class') {
      if (!props.isLevelingUp) {
        sectionText = 'Choose Class';
        if (props.character) {
          isDone = props.character.auto_classes_done
                    ? <img className="menuIcon" src={check} alt=""/>
                    : <img className="menuIcon" src={exclamation} alt=""/>;
        }
      } else {
        sectionText = 'Level-up Class';
      }
    } else if (section === 'background') {
      sectionText = 'Choose Background';
      if (props.character) {
        isDone = props.character.auto_backgrounds_done
                  ? <img className="menuIcon" src={check} alt=""/>
                  : <img className="menuIcon" src={exclamation} alt=""/>;
      }
    }
    sectionRender = <div className={`char-prompt-menu-item-text ${sectionAccentName}`}>
                {sectionText}{sectionAccent}
              </div>

    return <MouseUpLink
            key={index}
            handleMouseDown={props.setRequestedPrompt}
            handleMouseUp={props.toRequestedPrompt}
            section={section}
            sectionRender={sectionRender}
            isDone={isDone}
            isLevelingUp={props.isLevelingUp}
            borderRadiusLocation={borderRadiusLocation}
            character={props.character}
          />
  });

  // let deleteButton = <button onClick={props.triggerPopUp}>Delete Character</button>
  // let publishButton = <button onClick={props.triggerPopUp}>Finish Character</button>
  //
  //
  let deletability = !props.isDeletable ? 'is-unclickable' : '';
  let deleteButton = <div
                      className={`char-prompt-delete-button ${deletability}`}
                      onClick={((e) => props.triggerPopUp(e, 'delete'))}
                     >
                      Delete Character
                     </div>

  let clickability = !props.isFinished || (props.character && props.character.is_published)
    ? 'is-unclickable'
    : '';
  let levelUpClickability = props.character && props.character.level === 20
    ? 'is-unclickable'
    : '';
  let publishButton = props.character && props.character.is_published
    ? <div
        className={`char-prompt-publish-button ${levelUpClickability} ${disabled}`}
        onClick={((e) => props.handleLevelUpClick(e))}
      >
        Level Up Character
      </div>
    : <div
        className={`char-prompt-publish-button ${clickability} ${disabled}`}
        onClick={((e) => props.triggerPopUp(e, 'publish'))}
      >
        Finish Character
      </div>
  // let levelUpButton = props.character && props.character.is_published && props.character.classification !== null
  //     ? <div
  //         className={`char-prompt-publish-button ${levelUpClickability}`}
  //         onClick={((e) => props.handleLevelUpClick(e))}
  //       >
  //         Level Up Character
  //       </div>
  //     : null;

  return (
      <div className="char-prompt-menu-container">
        <div className="char-prompt-menu-title">
          <div className="char-prompt-menu-title-inner">
            <div className="char-prompt-menu-title-text">
              Create your Character
            </div>
            {warningText}
          </div>
        </div>
        <div className="char-prompt-menu-wrapper">
          <div className="char-prompt-menu">
            {sections}
          </div>
          <div className='section-menu-button-container'>
            {deleteButton}
            {publishButton}
          </div>
        </div>
      </div>
  );
}

export default SectionsMenu;
