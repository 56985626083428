import React from 'react';

const CampaignItemPopUp = props => {

  let typeSpecificRender, descs, quantityRender;
  let name, cost, weight, type;
  // image,
  let subtypes = '';

  const dts = props.details;
  if (dts) {
    // console.log(dts);
    name = dts.name;
    cost = `${dts.item_cost.toString()} ${dts.cost_unit}`;
    weight = `${dts.item_weight.toString()} ${dts.weight_unit}`;
    type = dts.type;
    dts.subtypes.forEach((subtype, index) => {
      let continuation = index + 1 < dts.subtypes.length ? ', ' : '';
      subtypes += `${subtype}${continuation}`;
    });
    if (dts.type === 'Gear') {
      // let contentsSection;
      let contents;
      if (dts.starting_items.length > 0) {
        contents = dts.starting_items.map((item, index) => {
          return <div key={index} className="starting-content-row">{`${item.name} (x${item.quantity})`}</div>;
        });
        typeSpecificRender = <div className='campaign-detail-popup-type-render'>
                              <div className="campaign-detail-popup-element-container">
                                <div className="campaign-detail-popup-element-header">
                                  Contents
                                </div>
                                <div className="campaign-detail-popup-element-data">
                                  {contents}
                                </div>
                              </div>
                             </div>;
      }
    } else if (dts.type === 'Weapon') {
      let propertiesSection, damageSection;
      let properties = '';
      let damageTypes = '';
      dts.damage_types.forEach((type, index) => {
        let continuation = index + 1 < dts.damage_types.length ? ', ' : '';
        damageTypes += `${type}${continuation}`;
      });
      let damage = dts.dmg_dice ? `${dts.dmg_dice_count.toString()}${dts.dmg_dice} ${damageTypes}` : '';
      damageSection = <div className="campaign-detail-popup-element-container">
                        <div className="campaign-detail-popup-element-data">
                          {damage}
                        </div>
                        <div className="campaign-detail-popup-element-header popup-sub-text">
                          Damage
                        </div>
                      </div>;
      if (dts.weapon_properties) {
        dts.weapon_properties.forEach((property, index) => {
          let propertyText;
          let continuation = index + 1 < dts.weapon_properties.length ? ', ' : '';
          if (property === 'Versatile') {
            propertyText = `${property} (${dts.dmg_dice_count_2h.toString()}${dts.dmg_dice_2h})`;
          } else if (property === 'Thrown' || property === 'Ammunition') {
            propertyText = `${property} (range ${dts.range_basic}/${dts.range_long})`;
          } else {
            propertyText = property;
          }
          properties += `${propertyText}${continuation}`;
        });
        propertiesSection = <div className="campaign-detail-popup-element-container">
                              <div className="campaign-detail-popup-element-data">
                                {properties}
                              </div>
                              <div className="campaign-detail-popup-element-header popup-sub-text">
                                Properties
                              </div>
                            </div>;
      }
      typeSpecificRender = <div className='campaign-detail-popup-type-render'>
                            {damageSection}
                            {propertiesSection}
                           </div>;
    } else if (dts.type === 'Armor') {
      let ac, strMin, stealthDis;
      if (!dts.ac_flat_add) {
        let apply = dts.ac_apply_dex ? ` + Dex Modifier` : '';
        let max = dts.ac_max_dex_add > 0 ? ` (max ${dts.ac_max_dex_add})` : '';
        ac = <div className="campaign-detail-popup-element-container">
              <div className="campaign-detail-popup-element-data">
                {`${dts.ac_base_bonus.toString()}${apply}${max}`}
              </div>
              <div className="campaign-detail-popup-element-header popup-sub-text">
                Armor Class (AC)
              </div>
             </div>;
        strMin = <div className="campaign-detail-popup-element-container">
              <div className="campaign-detail-popup-element-data">
                {dts.str_min > 0 ? `Str ${dts.str_min.toString()}` : '-'}
              </div>
              <div className="campaign-detail-popup-element-header popup-sub-text">
                Strength
              </div>
             </div>;
        stealthDis =  <div className="campaign-detail-popup-element-container">
                        <div className="campaign-detail-popup-element-data">
                          {dts.stealth_dis ? 'Disadvantage' : '-'}
                        </div>
                        <div className="campaign-detail-popup-element-header popup-sub-text">
                          Stealth
                        </div>
                      </div>;
      } else {
        ac = <div className="campaign-detail-popup-element-container">
              <div className="campaign-detail-popup-element-data">
                {`+${dts.ac_base_bonus.toString()}`}
              </div>
              <div className="campaign-detail-popup-element-header popup-sub-text">
                Armor Class (AC)
              </div>
             </div>;
      }
      typeSpecificRender =  <div className='campaign-detail-popup-type-render'>
                              {ac}
                              {strMin}
                              {stealthDis}
                            </div>;
    } else if (dts.type === 'Mount/Vehicle') {
      let speed, carryCapac;
      const speedQuant = dts.speed_quant ? `${dts.speed_quant.toString()}${dts.speed_unit}` : '-';
      speed = <div className="campaign-detail-popup-element-container">
                <div className="campaign-detail-popup-element-data">
                  {speedQuant}
                </div>
                <div className="campaign-detail-popup-element-header popup-sub-text">
                  Speed
                </div>
               </div>;
      const carryQuant = dts.carry_capac_quant ? `${dts.carry_capac_quant.toString()}${dts.carry_capac_unit}` : '-';
      carryCapac = <div className="campaign-detail-popup-element-container">
                    <div className="campaign-detail-popup-element-data">
                      {carryQuant}
                    </div>
                    <div className="campaign-detail-popup-element-header popup-sub-text">
                      Carrying Capacity
                    </div>
                   </div>;
      typeSpecificRender =  <div className='campaign-detail-popup-type-render'>
                              {speed}
                              {carryCapac}
                            </div>;
    }
    descs = dts.descs.map((desc, index) => {
      if (desc.bullet) {
        if (desc.header_text) {
          let header;
          if (desc.header_text) {
            header = <span className="story-mode-header-text-bullet">{desc.header_text}</span>
          }
          return <div key={index} className="campaign-detail-popup-desc">
                  {header}{desc.object_text}
                 </div>;
        } else {
          return  <div key={index} className="campaign-detail-popup-desc">
                    <span className="bulleted-desc">•</span>{desc.object_text}
                  </div>;
        }
      } else {
        if (desc.header_text) {
          return <div key={index} className="campaign-detail-popup-desc">
                  <div className="headlining-header-text">{desc.header_text}</div>
                  {desc.object_text}
                 </div>;
        } else {
          return <div key={index} className="campaign-detail-popup-desc">
                  {desc.object_text}
                 </div>;
        }
      }
    });

    if (dts.stackable) {
      // let quantityButtons;
      let leftButton =  <div className="quantity-button pop-up-discard" onClick={((e) => props.handleItemChange(e, dts, 1, 'delete', false))}>
                          <i className="fa-solid fa-minus"></i>
                        </div>
      let rightButton = <div className="quantity-button pop-up-yes" onClick={((e) => props.handleItemChange(e, dts, 1, 'add', false))}>
                          <i className="fa-solid fa-plus"></i>
                        </div>
      quantityRender =  <div className="campaign-detail-popup-quantities">
                          <div className="campaign-detail-popup-quantities-header">
                            Quantity
                          </div>
                          <div className="campaign-detail-popup-quantity-buttons">
                            {leftButton}
                            <div className="quantity-display">{props.pickup ? props.pickup.quantity : 0}</div>
                            {rightButton}
                          </div>
                        </div>
    }
  }

  return(
    <div className='campaign-detail-popup-details-container'>
      <div className='campaign-detail-popup-section-container'>
        <div className='campaign-detail-popup-section-container-inner'>
          <div className='campaign-detail-popup-header-wrapper'>
            <div className='campaign-detail-popup-name'>
              {name}
            </div>
            {quantityRender}
          </div>
          <div className='campaign-detail-popup-primary-details'>
            <div className='campaign-detail-popup-detail popup-grand-text'>{cost}</div>
            <div className='campaign-detail-popup-detail popup-grand-text'>{weight}</div>
            <div className='campaign-detail-popup-detail popup-grand-text'>{type}</div>
            <div className='campaign-detail-popup-detail popup-sub-text'>Cost</div>
            <div className='campaign-detail-popup-detail popup-sub-text'>Weight</div>
            <div className='campaign-detail-popup-detail popup-sub-text'>Item Type</div>
          </div>
          <div className='campaign-detail-popup-primary-details'>
            <div className='campaign-detail-popup-detail cip-whole popup-grand-text'>{subtypes}</div>
            <div className='campaign-detail-popup-detail cip-whole popup-sub-text'>Subtypes</div>
          </div>
          <div className='campaign-detail-popup-primary-details campaign-popup-type-specific-render'>
            {typeSpecificRender}
          </div>
          <div className='campaign-detail-popup-primary-details campaign-popup-descs'>
            {descs}
          </div>
        </div>
      </div>
    </div>
  )
}

// <div className='campaign-detail-popup-section-container'>
//
// </div>
export default CampaignItemPopUp
