import React from 'react';
import CampaignMember from './CampaignMember';
import Slider from '../components/Slider';


const CampaignActions = props => {

  let deleteButton = null;
  // let addPlayerButton;
  let requestPlayerButton;
  let slider;

  if (props.level === "dm") {
    deleteButton = <div className="campaign-actions-dm-action-button campaign-actions-dm-action-button-delete" onClick={props.togglePopUp}>Delete Campaign</div>;
    // addPlayerButton = <div className="campaign-actions-dm-action-button" onClick={((e) => props.toggleChangePCPopUp(e, "force_add"))} name="force_add" value="">Force-add a user (to be deprecated)</div>
    requestPlayerButton =
      <div className="campaign-actions-dm-action-button" onClick={((e) => props.toggleChangePCPopUp(e, "request_add"))}>
        Invite a user
        <div className="small-disclaimer">
          (invited users will need to refresh their screen to see this invite)
        </div>
      </div>;
    slider = <Slider public={props.campaign.public} togglePublicPopUp={props.togglePublicPopUp}/>;

  } else if (props.level === "pc") {
    //addPlayerButton = <button onClick={props.toggleAddPCPopUp}>Invite a player to join</button>
  }

  let members;
  let firstRow;
  if (props.allUsers && props.allUsers.length > 0) {
    members = props.allUsers.map((member, index) => {
      return  <CampaignMember
                key={index+1}
                currentUser={props.currentUser}
                user={member}
                level={props.level}
                toggleChangePCPopUp={props.toggleChangePCPopUp}
              />
    });
    firstRow = <CampaignMember
                  key={0}
                  currentUser={null}
                  user={null}
                  level={null}
                  toggleChangePCPopUp={props.toggleChangePCPopUp}
                />
  }

  let campaignPhoto = <div className="campaign-actions-blank-preview"></div>;
  let imgSrc;
  let unclickable = "is-unclickable";
  if (props.preview !== null ) {
    imgSrc = props.preview;
    unclickable = "";
  } else if (props.campaign) {
    imgSrc = props.campaign.photo;
  }
  if (imgSrc !== null) {
    campaignPhoto = <img className="campaign-actions-image" src={imgSrc} alt="campaign"/>
  }

  let sliderText;
  let currentSliderStateText;
  if (props.campaign && props.campaign.public) {
    sliderText = "Private";
    currentSliderStateText = "Public";
  } else {
    currentSliderStateText = "Private";
    sliderText = "Public";
  }

  return(
    <div className="campaign-actions-container">
      <div className="campaign-actions-members-container">
        <div className="campaign-actions-members-header">
          MEMBERS:
        </div>
        <div className="campaign-actions-members-table">
          {firstRow}
          {members}
        </div>
        <div className="campaign-actions-dm-action-buttons-container">
          <div className="campaign-actions-dm-action-button-container">
            {requestPlayerButton}
          </div>
          <div className="campaign-actions-dm-action-button-container">
            {deleteButton}
          </div>
          <div className="campaign-actions-dm-action-button-container">
            <div className="campaign-actions-dm-action-slider-container">
              This is a {currentSliderStateText} campaign
              {slider}
              Set to {sliderText}?
            </div>
          </div>
        </div>
      </div>
      <div className="campaign-actions-photo-container">
        <div className="campaign-actions-image-header">
          Campaign Image
        </div>
        <div className="campaign-actions-image-container">
          {campaignPhoto}
        </div>
        <div className="campaign-actions-image-buttons-container">
          <div className="campaign-actions-photo-button-row">
            <div className="campaign-actions-image-button-file">
              Choose Image
              <input
                type='file'
                name='file'
                key={Date.now().toString()}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="campaign-actions-photo-button-row">
            <div
              className={`campaign-actions-image-button ${unclickable}`}
              onClick={props.resetFields}
            >
              Reset
            </div>
          </div>
          <div className="campaign-actions-photo-button-row">
            <div
              className={`campaign-actions-image-button ${unclickable}`}
              onClick={props.onSave}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignActions

// <div className="campaign-actions-dm-action-button-container">
//   {addPlayerButton}
// </div>


// <div className="campaign-actions-members-list">
//   {users}
// </div>


//
// let campaign_render;
// if (props.campaign !== null) {
//   campaign_render = <CampaignDetails
//                       campaign={props.campaign}
//                       currentUser={props.currentUser}
//                       users={props.users}
//                       allUsers={props.allUsers}
//                       level={props.level}
//                       togglePopUp={props.togglePopUp}
//                       toggleChangePCPopUp={props.toggleChangePCPopUp}
//                       toggleRequestPCPopUp={props.toggleRequestPCPopUp}
//                       togglePublicPopUp={props.togglePublicPopUp}
//                     />
// } else {
//   campaign_render = <div>{props.msg}</div>
// }
