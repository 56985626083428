import React from "react";
import SelectionPrompt from "./SelectionPrompt";
import QualitySelectionPrompt from "./QualitySelectionPrompt";
import BackgroundDetails from "./BackgroundDetails";
import ProceedButtons from "./ProceedButtons";
import StoryModePopup from "./StoryModePopup";

class BackgroundPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgrounds: [],
      selectedBackground: null,
      isAlternateBackgroundChecked: false,
      selectedBackgroundFeature: null,
      selectedPersonality: null,
      selectedFlaw: null,
      selectedIdeal: null,
      selectedBond: null,
      selectedCharacteristic: null,
      backgroundFeatureSelections: [],
      isPersonalitySelected: false,
      isIdealSelected: false,
      isBondSelected: false,
      isFlawSelected: false,
      isCharacteristicSelected: false,
      isStoryModePoppedUp: false,
    };
    this.initializeData = this.initializeData.bind(this);
    this.selectBackground = this.selectBackground.bind(this);
    this.selectBackgroundFeature = this.selectBackgroundFeature.bind(this);
    this.selectPersonality = this.selectPersonality.bind(this);
    this.selectIdeal = this.selectIdeal.bind(this);
    this.selectBond = this.selectBond.bind(this);
    this.selectFlaw = this.selectFlaw.bind(this);
    this.selectCharacteristic = this.selectCharacteristic.bind(this);
    this.handleSelectionClick = this.handleSelectionClick.bind(this);
    this.createForcedSelections = this.createForcedSelections.bind(this);
    this.handleSaveData = this.handleSaveData.bind(this);
    this.handleBackgroundCharacteristicClick = this.handleBackgroundCharacteristicClick.bind(this);
    this.handleBackgroundCharacteristicSelectionClick = this.handleBackgroundCharacteristicSelectionClick.bind(this);
    this.closePop = this.closePop.bind(this);
    this.onStoryModeClick = this.onStoryModeClick.bind(this);
    this.handleVariantBackgroundToggle = this.handleVariantBackgroundToggle.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initializeData();
  }

  initializeData() {
    fetch("/api/v1/auto/character/backgrounds", {
      method: 'GET',
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((body) => {
        if (body) {
          this.setState({
            backgrounds: body.backgrounds
          });
        }
      });
  }

  selectBackground(e, background) {
    e.preventDefault();
    fetch(`/api/v1/auto/character/background/${background.id}`, {
      method: 'GET',
      credentials: "same-origin",
    })
    .then((response) => response.json())
    .then((body) => {
      if (body) {
        let forced = this.createForcedSelections(body.background);
        // let forced = [];
        // body.background.background_features.selections.forEach((selection) => {
        //   if (selection.force_added) {
        //     if (selection.pools.length > 0) {
        //       selection.pools.forEach((pool) => {
        //         forced.push({
        //           "name": pool.name,
        //           "id": pool.id,
        //           "value": "",
        //           "background_id": selection.background_id,
        //           "selection": selection,
        //           "desc": pool.descs
        //         });
        //       });
        //     } else {
        //       forced.push({
        //         "name": null,
        //         "id": null,
        //         "value": null,
        //         "background_id": selection.background_id,
        //         "selection": selection,
        //         "desc": null
        //       });
        //     }
        //   }
        // });
        this.setState((prevState) => {
          return {
            selectedBackground: body.background,
            backgroundFeatureSelections: forced,
            selectedBackgroundFeature: null,
            selectedPersonality: null,
            selectedFlaw: null,
            selectedIdeal: null,
            selectedBond: null,
            selectedCharacteristic: null,
            isAlternateBackgroundChecked: false
          };
        });

        let freshlyClickedBackground = Object.assign({}, this.props.quikStatsPayload);
        freshlyClickedBackground.background = background.name;
        this.props.updateQuikStatPayload(freshlyClickedBackground, forced);

        var elem1 = document.getElementById('auto-coreraces');
        elem1.scrollTop = 0;
      }
    });
  }

  handleBackgroundCharacteristicClick(e, qualityType) {
    e.preventDefault();
    if (qualityType === 'personality') {
      const value = this.state.isPersonalitySelected ? false : true;
      this.setState({
        isPersonalitySelected: value,
        isIdealSelected: false,
        isBondSelected: false,
        isFlawSelected: false,
        isCharacteristicSelected: false,
        selectedBackgroundFeature: null
      });
    } else if (qualityType === 'ideal') {
      const value = this.state.isIdealSelected ? false : true;
      this.setState({
        isPersonalitySelected: false,
        isIdealSelected: value,
        isBondSelected: false,
        isFlawSelected: false,
        isCharacteristicSelected: false,
        selectedBackgroundFeature: null
      });
    } else if (qualityType === 'bond') {
      const value = this.state.isBondSelected ? false : true;
      this.setState({
        isPersonalitySelected: false,
        isIdealSelected: false,
        isBondSelected: value,
        isFlawSelected: false,
        isCharacteristicSelected: false,
        selectedBackgroundFeature: null
      });
    } else if (qualityType === 'flaw') {
      const value = this.state.isFlawSelected ? false : true;
      this.setState({
        isPersonalitySelected: false,
        isIdealSelected: false,
        isBondSelected: false,
        isFlawSelected: value,
        isCharacteristicSelected: false,
        selectedBackgroundFeature: null
      });
    } else if (qualityType === 'characteristic') {
      const value = this.state.isCharacteristicSelected ? false : true;
      this.setState({
        isPersonalitySelected: false,
        isIdealSelected: false,
        isBondSelected: false,
        isFlawSelected: false,
        isCharacteristicSelected: value,
        selectedBackgroundFeature: null
      });
    }
  }

  handleBackgroundCharacteristicSelectionClick(e, qualityType, requestedQuality) {
    e.preventDefault();
    if (qualityType === 'personality') {
      const selectedQuality = this.state.selectedPersonality && requestedQuality.id === this.state.selectedPersonality.id ? null : requestedQuality;
      this.setState({
        selectedPersonality: selectedQuality
      });
    } else if (qualityType === 'ideal') {
      const selectedQuality = this.state.selectedIdeal && requestedQuality.id === this.state.selectedIdeal.id ? null : requestedQuality;
      this.setState({
        selectedIdeal: selectedQuality
      });
    } else if (qualityType === 'bond') {
      const selectedQuality = this.state.selectedBond && requestedQuality.id === this.state.selectedBond.id ? null : requestedQuality;
      this.setState({
        selectedBond: selectedQuality
      });
    } else if (qualityType === 'flaw') {
      const selectedQuality = this.state.selectedFlaw && requestedQuality.id === this.state.selectedFlaw.id ? null : requestedQuality;
      this.setState({
        selectedFlaw: selectedQuality
      });
    } else if (qualityType === 'characteristic') {
      const selectedQuality = this.state.selectedCharacteristic && requestedQuality.id === this.state.selectedCharacteristic.id ? null : requestedQuality;
      this.setState({
        selectedCharacteristic: selectedQuality
      });
    }
  }

  closePop(e) {
    e.preventDefault();
    this.setState({
      isStoryModePoppedUp: false
    });
  }

  onStoryModeClick(e) {
    e.preventDefault();
    this.setState({
      isStoryModePoppedUp: true
    });
  }

  createForcedSelections(background, currentlyForced = []) {
    let forced = currentlyForced;
    background.background_features.forEach((background_feature) => {
      background_feature.selections.forEach((selection) => {
        if (selection.force_added) {
          const selectionClone = {
            "feature_id": selection.feature_id,
            "background_feature_id": selection.background_feature_id,
            "force_added": selection.force_added,
            "id": selection.id,
            "increment_type_id": selection.increment_type_id,
            "increment_value": selection.increment_value,
            "is_permutation": selection.is_permutation,
            "num_selections": selection.num_selections,
            "optional_selection": selection.optional_selection,
            "source": selection.source,
            "target": selection.target,
            "trait_id": selection.trait_id
          };
          if (selection.pools.length > 0) {
            selection.pools.forEach((pool) => {
              forced.push({
                "name": pool.name,
                "id": pool.id,
                "quantity": pool.quantity ? pool.quantity : null,
                "sourceSection": 'backgrounds',
                "value": "",
                "background_feature_id": selectionClone.background_feature_id,
                "selection": selectionClone,
                "desc": pool.descs
              });
            });
          } else {
            forced.push({
              "name": null,
              "id": null,
              "quantity": null,
              "sourceSection": 'backgrounds',
              "value": null,
              "background_feature_id": selectionClone.background_feature_id,
              "selection": selectionClone,
              "desc": null
            });
          }
        }
      });
    });
    return forced;
  }

  selectBackgroundFeature(e, backgroundFeatureId) {
    e.preventDefault();
    let newSelection;
    if (this.state.selectedBackgroundFeature && this.state.selectedBackgroundFeature.id === backgroundFeatureId.id) {
      newSelection = null;
    } else {
      newSelection = backgroundFeatureId;
    }
    this.setState ({
      selectedBackgroundFeature: newSelection
    });
    // var elem2 = document.getElementById('auto-trait-selections');
    // elem2.scrollTop = 0;
  }

  selectPersonality(e, personality) {
    e.preventDefault();
    let newSelection;
    if (
      this.state.selectedPersonality &&
      this.state.selectedPersonality.id === personality.id
    ) {
      newSelection = null;
    } else {
      newSelection = personality;
    }
    this.setState ({
      selectedPersonality: newSelection
    });
    // var elem2 = document.getElementById('auto-trait-selections');
    // elem2.scrollTop = 0;
  }

  selectIdeal(e, ideal) {
    e.preventDefault();
    let newSelection;
    if (
      this.state.selectedIdeal &&
      this.state.selectedIdeal.id === ideal.id
    ) {
      newSelection = null;
    } else {
      newSelection = ideal;
    }
    this.setState ({
      selectedIdeal: newSelection
    });
    // var elem2 = document.getElementById('auto-trait-selections');
    // elem2.scrollTop = 0;
  }

  selectBond(e, bond) {
    e.preventDefault();
    let newSelection;
    if (
      this.state.selectedBond &&
      this.state.selectedBond.id === bond.id
    ) {
      newSelection = null;
    } else {
      newSelection = bond;
    }
    this.setState ({
      selectedBond: newSelection
    });
    // var elem2 = document.getElementById('auto-trait-selections');
    // elem2.scrollTop = 0;
  }

  selectFlaw(e, flaw) {
    e.preventDefault();
    let newSelection;
    if (
      this.state.selectedFlaw &&
      this.state.selectedFlaw.id === flaw.id
    ) {
      newSelection = null;
    } else {
      newSelection = flaw;
    }
    this.setState ({
      selectedFlaw: newSelection
    });
    // var elem2 = document.getElementById('auto-trait-selections');
    // elem2.scrollTop = 0;
  }

  selectCharacteristic(e, characteristic) {
    e.preventDefault();
    let newSelection;
    if (
      this.state.selectedCharacteristic &&
      this.state.selectedCharacteristic.id === characteristic.id
    ) {
      newSelection = null;
    } else {
      newSelection = characteristic;
    }
    this.setState ({
      selectedCharacteristic: newSelection
    });
    // var elem2 = document.getElementById('auto-trait-selections');
    // elem2.scrollTop = 0;
  }

  handleSelectionClick(e, obj) {
    e.preventDefault();
    if (obj.disabled) {
      return;
    }

    var latestPayload = Object.assign({}, this.props.quikStatsPayload);
    let selections = this.state.backgroundFeatureSelections;
    let selectedBackground = this.state.selectedBackground;
    latestPayload.background = selectedBackground ? selectedBackground.name : selectedBackground;

    // hacky, but finds out how many selections have been
    // previously made for specific offering selection
    let tally = 0;
    selections.forEach((selection) => {
      if (selection.selection.id === obj.selection.id) {
        tally += 1;
      }
    });

    let updatedSelections;
    // clicking pool already selected and yet can add another
    if (selections.some(pool =>
        pool.id === obj.id &&
        pool.selection.id === obj.selection.id &&
        pool.selection.num_selections > tally &&
        !pool.selection.is_permutation
    )) {
      updatedSelections = selections.concat(obj);

      this.setState(prevState => ({
        backgroundFeatureSelections: [...selections,obj]
      }));
    }
    // clicking pool already selected and at capacity in some way
    else if (selections.some(pool =>
      pool.id === obj.id &&
      pool.selection.id === obj.selection.id &&
      (
        pool.selection.num_selections <= tally ||
        pool.selection.is_permutation
      )
    )) {
      updatedSelections = selections.filter(pool =>
        pool.selection.id !== obj.selection.id ||
        pool.id !== obj.id
      );

      this.setState(prevState => ({
        backgroundFeatureSelections: [...selections.filter(pool =>
          pool.selection.id !== obj.selection.id ||
          pool.id !== obj.id
        )]
      }));
    }
    // clicking on an unselected pool and at capacity in some way
    else if (selections.some(pool =>
      pool.selection.id === obj.selection.id &&
      pool.selection.num_selections <= tally
    )) {
      updatedSelections = selections.filter(pool =>
        pool.selection.id !== obj.selection.id
      );
      updatedSelections = updatedSelections.concat(obj);

      this.setState(prevState => ({
        backgroundFeatureSelections: [...selections.filter(pool =>
          pool.selection.id !== obj.selection.id
        )]
      }));
      this.setState(prevState => ({
        backgroundFeatureSelections: updatedSelections
      }));
    }
    else {
      updatedSelections = selections.concat(obj);

      this.setState(prevState => ({
        backgroundFeatureSelections: [...selections,obj]
      }));
    }
    this.props.updateQuikStatPayload(latestPayload, updatedSelections);
  }

  handleVariantBackgroundToggle(e) {
    e.stopPropagation();
    var latestPayload = Object.assign({}, this.props.quikStatsPayload);
    latestPayload.is_variant_background = !this.state.isAlternateBackgroundChecked;
    latestPayload.variant_name = this.state.selectedBackground.variant_name;
    this.setState((prevState) => {
      return {
        isAlternateBackgroundChecked: !this.state.isAlternateBackgroundChecked
      };
    });
    this.props.updateQuikStatPayload(latestPayload, null);
  }

  handleSaveData(e) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('menuSection', 'background');
    if (this.props.characterSelections) {
      formPayload.append('character_id', this.props.characterSelections.id);
    }
    formPayload.append('backgroundId', this.state.selectedBackground.id);
    formPayload.append('menuSelections', JSON.stringify(this.state.backgroundFeatureSelections));
    formPayload.append('personality', this.state.selectedPersonality ? this.state.selectedPersonality.id : null);
    formPayload.append('flaw', this.state.selectedFlaw ? this.state.selectedFlaw.id : null);
    formPayload.append('ideal', this.state.selectedIdeal ? this.state.selectedIdeal.id : null);
    formPayload.append('bond', this.state.selectedBond ? this.state.selectedBond.id : null);
    formPayload.append('characteristic', this.state.selectedCharacteristic ? this.state.selectedCharacteristic.id : null);
    formPayload.append('isAlternateBackground', this.state.isAlternateBackgroundChecked);
    formPayload.append('backgroundFeatures', JSON.stringify(this.state.selectedBackground.background_features));
    fetch('/api/v1/auto/character/process', {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if (res.status === 200) {
        this.props.lockSectionIn(
          this.state.backgroundFeatureSelections,
          this.props.quikStatsPayload,
          'background'
        );
      } else {
        this.props.pop(res.body.msg, res.body.status);
        this.props.throwError();
      }
    }));
  }




  render() {
    let backgrounds = this.state.backgrounds.map((background, index) => {
      const selectedIndex = this.state.selectedBackground && this.state.selectedBackground.id === background.id ?
        'selectableCharacterQuality selectedCharacterQuality' : 'selectableCharacterQuality';

      return <div
              className={selectedIndex}
              key={index}
              onClick={((e) => this.selectBackground(e, background))}
             >
              {background.name}
             </div>;
    });

    let selectedBackground;
    let selectedBackgroundDetails;
    let storyModeButton;
    if (this.state.selectedBackground) {
      selectedBackground = this.state.selectedBackground.name + " Details";
      selectedBackgroundDetails = <BackgroundDetails
                                        handleVariantBackgroundToggle={this.handleVariantBackgroundToggle}
                                        isAlternateBackgroundChecked={this.state.isAlternateBackgroundChecked}
                                        isPersonalitySelected={this.state.isPersonalitySelected}
                                        selectedPersonality={this.state.selectedPersonality}
                                        isIdealSelected={this.state.isIdealSelected}
                                        selectedIdeal={this.state.selectedIdeal}
                                        isBondSelected={this.state.isBondSelected}
                                        selectedBond={this.state.selectedBond}
                                        isFlawSelected={this.state.isFlawSelected}
                                        selectedFlaw={this.state.selectedFlaw}
                                        isCharacteristicSelected={this.state.isCharacteristicSelected}
                                        selectedCharacteristic={this.state.selectedCharacteristic}
                                        background={this.state.selectedBackground}
                                        handleBackgroundFeatureClick={this.selectBackgroundFeature}
                                        handleBackgroundCharacteristicClick={this.handleBackgroundCharacteristicClick}
                                        onReadMoreClick={this.props.onReadMoreClick}
                                        selectedBackgroundFeature={this.state.selectedBackgroundFeature}
                                        backgroundFeatureSelections={this.state.backgroundFeatureSelections}
                                      />;
      storyModeButton = <div
                          onClick={((e) => this.onStoryModeClick(e))}
                          className="class-prompt-story-mode-button"
                        >
                          <i className="fa-solid fa-book-open"></i>
                        </div>
    } else {
      selectedBackground = '';
    }


    let selections;
    if (
      this.state.selectedBackgroundFeature != null
    ) {
      selections = this.state.selectedBackgroundFeature.selections.map((selection, index) => {
        if (!selection.force_added) {
          return  <SelectionPrompt
                    key={index}
                    expandedSpells={[]}
                    handleSelectionClick={this.handleSelectionClick}
                    onReadMoreClick={this.props.onReadMoreClick}
                    selection={selection}
                    characterSelections={this.props.characterSelections}
                    selections={this.state.backgroundFeatureSelections}
                    auxiliary_selection={null}
                  />;
        } else {
          return null;
        }
      });
    } else {
      selections = <SelectionPrompt
                    expandedSpells={[]}
                    handleSelectionClick={this.handleSelectionClick}
                    onReadMoreClick={this.props.onReadMoreClick}
                    selection={null}
                    characterSelections={[]}
                    selections={[]}
                    auxiliary_selection={null}
                  />;
    }
    let renderedQualitySelections, qualitySelections, selectedQuality, source, diceHeader, extraHeader;
    if (this.state.isPersonalitySelected) {
      source = 'personality';
      selectedQuality = this.state.selectedPersonality;
      qualitySelections = this.state.selectedBackground.personalities;
      diceHeader = this.state.selectedBackground.personality_die;
    } else if (this.state.isIdealSelected) {
      source = 'ideal';
      selectedQuality = this.state.selectedIdeal;
      qualitySelections = this.state.selectedBackground.ideals;
      diceHeader = this.state.selectedBackground.ideal_die;
    } else if (this.state.isBondSelected) {
      source = 'bond';
      selectedQuality = this.state.selectedBond;
      qualitySelections = this.state.selectedBackground.bonds;
      diceHeader = this.state.selectedBackground.bond_die;
    } else if (this.state.isFlawSelected) {
      source = 'flaw';
      selectedQuality = this.state.selectedFlaw;
      qualitySelections = this.state.selectedBackground.flaws;
      diceHeader = this.state.selectedBackground.flaw_die;
    } else if (this.state.isCharacteristicSelected) {
      source = 'characteristic';
      selectedQuality = this.state.selectedCharacteristic;
      qualitySelections = this.state.selectedBackground.characteristics;
      diceHeader = this.state.selectedBackground.characteristic_die;
      extraHeader = this.state.selectedBackground.characteristic_name;
    } else {
      qualitySelections = [];
    }
    let renderedHeaderRow;
    if (qualitySelections.length > 0 ) {
      renderedHeaderRow = <QualitySelectionPrompt
                            key={0}
                            quality={null}
                            source={source}
                            onReadMoreClick={this.props.onReadMoreClick}
                            characterSelections={this.props.characterSelections}
                            handleBackgroundCharacteristicSelectionClick={this.handleBackgroundCharacteristicSelectionClick}
                            selectedQuality={selectedQuality}
                            extraHeader={extraHeader}
                            diceHeader={diceHeader}
                          />
      renderedQualitySelections = qualitySelections.map((quality, index) => {
          return  <QualitySelectionPrompt
                    key={index+1}
                    quality={quality}
                    source={source}
                    onReadMoreClick={this.props.onReadMoreClick}
                    characterSelections={this.props.characterSelections}
                    handleBackgroundCharacteristicSelectionClick={this.handleBackgroundCharacteristicSelectionClick}
                    selectedQuality={selectedQuality}
                    extraHeader={extraHeader}
                    diceHeader={diceHeader}
                  />;
      });
    }

    let disabled = true;
    let totalNeeded = 0;
    let totalMet = 0;
    if (this.state.selectedBackground) {
      this.state.selectedBackground.background_features.forEach((background_feature) => {
        background_feature.selections.forEach((selection) => {
          if (!selection.force_added) {
            totalNeeded += 1;
            let backgroundFeatureSelections = this.state.backgroundFeatureSelections.filter(pool =>
              !selection.force_added &&
              selection.id === pool.selection.id
            );
            if (backgroundFeatureSelections.length === selection.num_selections) {
              totalMet += 1;
            }
          }
        });
      });
    }
    if (totalMet === totalNeeded && this.state.selectedBackground) {
      disabled = false;
    }

    const proceedButtons = <ProceedButtons
      handleProceedForward={this.handleSaveData}
      handleGoBack={this.props.handleGoBack}
      isDisabled={disabled}
      proceedText={"Save and Continue"}
    />;

    let storyModePopup = this.state.isStoryModePoppedUp
      ? <StoryModePopup
          closePop={this.closePop}
          selectedBackground={this.state.selectedBackground}
        />
      : null;

    return (
      <div className="char-prompt-container">
        {storyModePopup}
        <div className="race-detail-header">
          <div className="auto-archrace-header">
            Choose a Background
          </div>
          <div className="auto-corerace-header">
            {selectedBackground}
            {storyModeButton}
          </div>
        </div>
        <div className="race-detail-container">
          <div className="auto-archraces">
            <div className="archraces-container">
              {backgrounds}
            </div>
            {proceedButtons}
          </div>

          <div className="auto-coreraces" id="auto-coreraces">
            {selectedBackgroundDetails}
          </div>

          <div className="auto-trait-selections">
            {selections}
            {renderedHeaderRow}
            {renderedQualitySelections}
          </div>
        </div>
      </div>
    );
  }
}
export default BackgroundPrompt;
