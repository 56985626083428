import React from "react";
import SelectionPrompt from "./SelectionPrompt";
import ClassDetails from "./ClassDetails";
import ProceedButtons from "./ProceedButtons";
import IncreaseHpPopUp from "./IncreaseHpPopUp";
import StoryModePopup from "./StoryModePopup";
import _ from 'underscore';

class ClassPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      actionableFeatures: [],
      lockedFeatures: [],
      selectedClass: null,
      selectedFeature: null,
      featureSelections: [],
      auxiliary_selections: [],
      expandedSpells: [],
      loading: false,
      hpPopUp: false,
      hpIncrease: null,
      selectedDeductions: [],
      isStoryModePoppedUp: false,
    };
    this.initializeData = this.initializeData.bind(this);
    this.selectClass = this.selectClass.bind(this);
    this.createForcedSelections = this.createForcedSelections.bind(this);
    this.selectFeature = this.selectFeature.bind(this);
    this.checkForPrerequisites = this.checkForPrerequisites.bind(this);
    this.handleSelectionClick = this.handleSelectionClick.bind(this);
    this.evaluateClickedConditions = this.evaluateClickedConditions.bind(this);
    this.handleExpandedSpells = this.handleExpandedSpells.bind(this);
    this.handleSaveData = this.handleSaveData.bind(this);
    this.handleHpIncreaseClick = this.handleHpIncreaseClick.bind(this);
    this.closePop = this.closePop.bind(this);
    this.handleHpChange = this.handleHpChange.bind(this);
    this.onDeductionClick = this.onDeductionClick.bind(this);
    this.onStoryModeClick = this.onStoryModeClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initializeData();
  }

  initializeData() {
    if (this.props.isLevelingUp && this.props.characterSelections) {
      this.setState((prevState) => {
        return {
          classes: [this.props.characterSelections.classification],
          auxiliary_selections: []
        };
      });
      this.selectClass(null, this.props.characterSelections.classification, this.props.characterSelections.level+1)
    } else {
      fetch("/api/v1/auto/character/classes", {
        method: 'GET',
        credentials: "same-origin",
      })
      .then((response) => response.json())
      .then((body) => {
        if (body) {
          this.setState({
            classes: body.classes,
            auxiliary_selections: []
          });
        }
      });
    }
  }

  selectClass(e, classification, requestedLevel=1) {
    if (e !== null) {
      e.preventDefault();
    }
    if (
      (
        this.state.selectedClass &&
        classification.id !== this.state.selectedClass.id
      ) ||
      (
        !this.state.selectedClass
      )
    ) {
      this.setState((prevState) => {
        return {
          loading: true,
          selectedClass: null,
          featureSelections: [],
          selectedFeature: null,
          actionableFeatures: [],
          lockedFeatures: [],
          auxiliary_selections: [],
          expandedSpells: []
        };
      });
      fetch(`/api/v1/auto/character/class/${classification.id}/${requestedLevel}`, {
        method: 'GET',
        credentials: "same-origin",
      })
      .then((response) => response.json())
      .then((body) => {
        if (body) {
          let forced = this.createForcedSelections(body.classification.default_features, true);
          this.setState((prevState) => {
            return {
              selectedClass: body.classification,
              featureSelections: forced,
              selectedFeature: null,
              actionableFeatures: body.classification.default_features,
              lockedFeatures: body.classification.locked_features,
              auxiliary_selections: [],
              expandedSpells: [],
              loading: false
            };
          });

          let freshlyClickedClass = Object.assign({}, this.props.quikStatsPayload);
          freshlyClickedClass.classification = classification.name;
          freshlyClickedClass.proficiency = 2;
          freshlyClickedClass.hitpoints = this.state.selectedClass.hp_at_lvl_1;
          freshlyClickedClass.level = 1;
          freshlyClickedClass.features = body.classification.default_features
          this.props.updateQuikStatPayload(freshlyClickedClass, forced);

          var elem1 = document.getElementById('auto-coreraces');
          elem1.scrollTop = 0;
        }
      });
    }
  }

  createForcedSelections(features, fromInitialLoad = false, currentlyForced = []) {
    let forced = currentlyForced;
    let expandedSpells = this.state.expandedSpells;
    let triggerExpandedSpells = false;
    features.forEach((feature) => {
      feature.selections.forEach((selection) => {
        if (selection.force_added) {
          const selectionClone = {
            "feature_id": selection.feature_id,
            "background_feature_id": selection.background_feature_id,
            "force_added": selection.force_added,
            "id": selection.id,
            "acquisition_type_id": selection.acquisition_type_id,
            "increment_type_id": selection.increment_type_id,
            "increment_value": selection.increment_value,
            "is_permutation": selection.is_permutation,
            "num_selections": selection.num_selections,
            "optional_selection": selection.optional_selection,
            "source": selection.source,
            "target": selection.target,
            "trait_id": selection.trait_id
          };
          if (selection.pools.length > 0) {
            if (selection.target === 'expanded_spells') {
              triggerExpandedSpells = true;
              selection.pools.forEach((pool) => {
                expandedSpells.push(pool);
              });
            }
            selection.pools.forEach((pool) => {
              forced.push({
                "name": pool.name,
                "id": pool.id,
                "quantity": pool.quantity ? pool.quantity : null,
                "sourceSection": 'classes',
                "value": "",
                "feature_id": selectionClone.feature_id,
                "selection": selectionClone,
                "desc": pool.descs
              });
            });
          }
          if (selection.alternate_pools.length > 0) {
            if (selection.target === 'expanded_spells') {
              selection.alternate_pools.forEach((pool) => {
                expandedSpells.push(pool);
              });
            }
            selection.alternate_pools.forEach((pool) => {
              forced.push({
                "name": pool.name,
                "id": pool.id,
                "quantity": pool.quantity ? pool.quantity : null,
                "sourceSection": 'classes',
                "value": "",
                "feature_id": selectionClone.feature_id,
                "selection": selectionClone,
                "desc": pool.descs
              });
            });
          }
          if (selection.pools.length === 0 && selection.alternate_pools.length === 0) {
            forced.push({
              "name": null,
              "id": null,
              "quantity": null,
              "value": null,
              "sourceSection": 'classes',
              "feature_id": selectionClone.feature_id,
              "selection": selectionClone,
              "desc": null
            });
          }
        }
      });
    });
    if (triggerExpandedSpells && fromInitialLoad) {
      this.handleExpandedSpells(expandedSpells);
    }
    return forced;
  }

  selectFeature(e, featureId) {
    e.preventDefault();
    let newSelection;
    if (this.state.selectedFeature && this.state.selectedFeature.id === featureId.id) {
      newSelection = null;
    } else {
      newSelection = featureId;
    }
    this.setState ({
      selectedFeature: newSelection,
    });
    // var elem2 = document.getElementById('auto-trait-selections');
    // elem2.scrollTop = 0;
  }

  checkForPrerequisites(obj, scenario) {
    let forced = [];
    let updatedFeatures = [];
    let remainingLockedFeatures = this.state.lockedFeatures;
    if (scenario !== 2) {
      this.state.lockedFeatures.forEach((feature) => {
        let isReady = true;
        feature.displayed_prerequisites.forEach((prerequisite) => {
          if (
            !(
              prerequisite.name === obj.name &&
              prerequisite.value === obj.id &&
              prerequisite.title === obj.selection.source &&
              (
                prerequisite.is_cosmetic === true ||
                (
                  prerequisite.is_cosmetic === false &&
                  feature.class_level !== null
                  // feature.selections &&
                  // feature.selections[0] &&
                  // feature.selections[0].target === 'expanded_spells'
                )
              )
            )
          ) {
            isReady = false;
          }
        });
        if (isReady) {
          updatedFeatures.push(feature);
          forced = this.createForcedSelections([feature], false, forced);
          remainingLockedFeatures = remainingLockedFeatures.filter(lockedFeature =>
            lockedFeature.id !== feature.id
          );
        }
      });
    }
    return { updatedFeatures, forced, remainingLockedFeatures };
  }

  evaluateClickedConditions(obj, selections) {
    let scenario;
    // hacky, but finds out how many selections have been
    // previously made for specific offering selection
    let tally = 0;
    selections.forEach((selection) => {
      if (selection.selection.id === obj.selection.id) {
        tally += 1;
      }
    });
    // clicking a pool already selected and yet can add another
    if  (
          selections.some(pool =>
            pool.id === obj.id &&
            pool.selection.id === obj.selection.id &&
            pool.selection.num_selections > tally &&
            !pool.selection.is_permutation
          )
    ) {scenario = 1;}
    // clicking a pool already selected and at capacity in some way
    else if (
        selections.some(pool =>
          pool.id === obj.id &&
          pool.selection.id === obj.selection.id &&
          (
            pool.selection.num_selections <= tally ||
            pool.selection.is_permutation
          )
        )
    ) {scenario = 2;}
    // clicking on an unselected pool and at capacity in some way
    else if (selections.some(pool =>
      (
        pool.feature_id === obj.feature_id &&
        pool.selection.id === obj.selection.id &&
        pool.id !== obj.id &&
        obj.name !== null
      ) &&
      pool.selection.num_selections <= tally
    )) {scenario = 3;}
    return {tally, scenario};
  }

  handleExpandedSpells(expandedSpells = []) {
    if (expandedSpells.length > 0) {
      let formPayload = new FormData();
      formPayload.append('expandedSpells', JSON.stringify(expandedSpells));
      fetch('/api/v1/auto/character/expanded_spells', {
        method: 'POST',
        body: formPayload,
        credentials: 'same-origin'
      })
      .then((response) => response.json())
      .then((body) => {
        if (body) {
          this.setState(prevState => ({
            expandedSpells: body.expanded_spells
          }));
        }
      });
    } else {
      this.setState(prevState => ({
        expandedSpells: []
      }));
    }
  }

  handleSelectionClick(e, obj) {
    e.preventDefault();
    if (obj.disabled) {
      return;
    }

    let selections = this.state.featureSelections;
    let scenarioInformation = this.evaluateClickedConditions(obj, selections);
    let tally = scenarioInformation.tally,
        scenario = scenarioInformation.scenario;

    var latestPayload = Object.assign({}, this.props.quikStatsPayload);
    let selectedClass = this.state.selectedClass;
    latestPayload.class = selectedClass ? selectedClass.name : selectedClass;
    // ^^^^ MAYBE TRACK FEATURES IN LATESTPAYLOAD


    // handles prerequisites
    let checkedFeatures = this.checkForPrerequisites(obj, scenario);
    let remainingUnlockedFeatures = checkedFeatures.updatedFeatures,
        remainingLockedFeatures = checkedFeatures.remainingLockedFeatures,
        forcedFromPrereqs = checkedFeatures.forced;



    // selecting a pool from a selection that offers other selections
    let forced = [];
    let auxiliary_selection;
    let auxiliary_selections = this.state.auxiliary_selections;
    if (
      obj.selection.source === 'selection' &&
      !selections.some(pool =>
        pool.id === obj.id &&
        pool.selection.id === obj.selection.id &&
        (
          pool.selection.num_selections <= tally ||
          pool.selection.is_permutation
        )
      )
    ) {
      if (obj.selection.id === this.state.selectedFeature.auxiliary_choices.selection_id) {
        let chosen_selection = this.state.selectedFeature.auxiliary_choices.selections.find(selection =>
          selection.id === obj.id
        );
        auxiliary_selection = {
          "parent": obj.selection.id,
          "name": null,
          "id": obj.id,
          "value": null,
          "quantity": null,
          "sourceSection": 'classes',
          "trait_id": null,
          "background_feature_id": null,
          "feature_id": null,
          "selection": chosen_selection,
          "desc": null,
          "disabled": null,
          "fromPrimary": obj.fromPrimary
        };
        if (
          chosen_selection.force_added &&
          !chosen_selection.num_selections &&
          chosen_selection.pools.length > 0
        ) {
          chosen_selection.pools.forEach((pool) => {
            forced.push({
              "name": pool.name,
              "id": pool.id,
              "quantity": pool.quantity ? pool.quantity : null,
              "value": "",
              "sourceSection": 'classes',
              "selection": chosen_selection,
              "desc": pool.descs
            });
          });
        }
        if (
          chosen_selection.force_added &&
          chosen_selection.alternate_pools.length > 0
        ) {
          chosen_selection.alternate_pools.forEach((alternate_pool) => {
            forced.push({
              "name": alternate_pool.name,
              "id": alternate_pool.id,
              "quantity": alternate_pool.quantity ? alternate_pool.quantity : null,
              "value": "",
              "sourceSection": 'classes',
              "selection": chosen_selection,
              "desc": alternate_pool.descs
            });
          });
        }
      }
    }

    let expandedSpells = this.state.expandedSpells;
    let triggerExpandedSpells = false;
    let updatedSelections;
    let updatedFeatures;
    let lockedFeatures;
    let updatedAuxiliarySelections;
    // clicking a pool already selected and yet can add another
    if  (scenario === 1) {
      updatedFeatures = this.state.actionableFeatures;
      remainingUnlockedFeatures.forEach((feature) => {
        updatedFeatures.push(feature);
      });
      updatedSelections = selections.concat(obj);
      // unlikely for this to be hit.  Why would a user be able to
      // select an option designator more than once?
      // if (auxiliary_selection) {
      //   updatedSelections = updatedSelections.concat(auxiliary_selection);
        forced.forEach((forcedSelection) => {
          updatedSelections.push(forcedSelection);
        });
        forcedFromPrereqs.forEach((forcedSelection) => {
          updatedSelections.push(forcedSelection);
          if (forcedSelection.selection.target === 'expanded_spells') {
            triggerExpandedSpells = true;
            expandedSpells.push(forcedSelection);
          }
        });
      // }
      this.setState(prevState => ({
        featureSelections: updatedSelections,
        actionableFeatures: updatedFeatures,
        lockedFeatures: remainingLockedFeatures,
      }));
    }
    // clicking a pool already selected and at capacity in some way
    else if (scenario === 2) {
      if (obj.feature_id !== null) {
        updatedSelections = selections.filter(pool =>
          pool.selection.id !== obj.selection.id ||
          pool.id !== obj.id
        );
        if (obj.selection.source === 'selection') {
          let object = selections.filter(pool =>
            pool.selection.id === obj.selection.id &&
            pool.id === obj.id
          );
          updatedSelections = updatedSelections.filter(pool =>
            pool.selection.feature_id ||
            pool.selection.id !== object[0].id
          );
        }
      } else {
        updatedSelections = selections.filter(pool =>
          pool.selection.id !== obj.selection.id ||
          pool.id !== obj.id ||
          pool.selection.feature_id
        );
      }

      let featuresRemoving = [];
      let featuresRemaining = [];
      lockedFeatures = this.state.lockedFeatures;
      updatedFeatures = this.state.actionableFeatures;
      updatedFeatures.forEach((feature) => {
        if (
          !feature.displayed_prerequisites.every(prerequisite =>
            updatedSelections.some(pool =>
              prerequisite.name === pool.name &&
              prerequisite.value === pool.id &&
              prerequisite.title === pool.selection.source
            ) || prerequisite.is_cosmetic
            // maybe also have an OR statement checking character stats too.
          )
        ) {
          feature.selections.forEach((selection) => {
            if (selection.target === 'expanded_spells') {
              updatedSelections = updatedSelections.filter(updatedSelection =>
                !(
                  updatedSelection.selection.target === 'spells' &&
                  selection.pools.some(pool =>
                    updatedSelection.id === pool.id
                  )
                )
              );
              triggerExpandedSpells = true;
              expandedSpells = expandedSpells.filter(spell =>
                !selection.pools.some(pool =>
                  pool.id === spell.id
                )
              );
              updatedFeatures.forEach((checkedFeature) => {
                checkedFeature.selections.forEach((checkedSelection) => {
                  if (checkedSelection.target === 'spells') {
                    checkedSelection.pools = checkedSelection.pools.filter(spell =>
                      !selection.pools.some(pool =>
                        pool.id === spell.id
                      )
                    );
                  }
                });
              });
            }
          });



          featuresRemoving.push(feature);
          updatedSelections = updatedSelections.filter(pool =>
            pool.feature_id !== feature.id
          );
          lockedFeatures.push(feature);
        } else {
          featuresRemaining.push(feature);
        }
      });
      updatedFeatures = featuresRemaining;


      if (
        auxiliary_selections.some(auxiliary_sel =>
          obj.id === auxiliary_sel.selection.id
        ) || obj.selection.source === 'selection'
      ) {
        this.setState(prevState => ({
          featureSelections: updatedSelections,
          actionableFeatures: updatedFeatures,
          lockedFeatures: lockedFeatures,
          auxiliary_selections: [...auxiliary_selections.filter(pool =>
            pool.parent !== obj.selection.id
          )]
        }));
      } else {
        this.setState(prevState => ({
          featureSelections: updatedSelections,
          lockedFeatures: lockedFeatures,
          actionableFeatures: updatedFeatures
        }));
      }
    }
    // clicking on an unselected pool and at capacity in some way
    else if (scenario === 3) {
      if (obj.feature_id !== null) {
        updatedSelections = selections.filter(pool =>
          pool.selection.id !== obj.selection.id
        );
        if (obj.selection.source === 'selection') {
          let object = selections.filter(pool =>
            pool.selection.id === obj.selection.id
          );
          updatedSelections = updatedSelections.filter(pool =>
            pool.selection.feature_id ||
            pool.selection.id !== object[0].id
          );
        }
      } else {
        updatedSelections = selections.filter(pool =>
          pool.selection.id !== obj.selection.id &&
          pool.selection.feature_id
        );
      }
      updatedAuxiliarySelections = auxiliary_selections.filter(pool =>
        pool.parent !== obj.selection.id
      );

      let remainingFeatures = [];
      lockedFeatures = remainingLockedFeatures;
      updatedFeatures = this.state.actionableFeatures;
      updatedFeatures.forEach((feature) => {
        if (
          !feature.displayed_prerequisites.every(prerequisite =>
            updatedSelections.some(pool =>
              prerequisite.name === pool.name &&
              prerequisite.value === pool.id &&
              prerequisite.title === pool.selection.source
            ) || prerequisite.is_cosmetic
            // maybe also have an OR statement checking character stats too.
          )
        ) {
          feature.selections.forEach((selection) => {
            if (selection.target === 'expanded_spells') {
              updatedSelections = updatedSelections.filter(updatedSelection =>
                !(
                  updatedSelection.selection.target === 'spells' &&
                  selection.pools.some(pool =>
                    updatedSelection.id === pool.id
                  )
                )
              );
              triggerExpandedSpells = true;
              expandedSpells = expandedSpells.filter(spell =>
                !selection.pools.some(pool =>
                  pool.id === spell.id
                )
              );
              updatedFeatures.forEach((checkedFeature) => {
                checkedFeature.selections.forEach((checkedSelection) => {
                  if (checkedSelection.target === 'spells') {
                    checkedSelection.pools = checkedSelection.pools.filter(spell =>
                      !selection.pools.some(pool =>
                        pool.id === spell.id
                      )
                    );
                  }
                });
              });
            }
          });

          updatedSelections = updatedSelections.filter(pool =>
            pool.feature_id !== feature.id
          );
          lockedFeatures.push(feature);
        } else {
          remainingFeatures.push(feature);
        }
      });
      updatedFeatures = remainingFeatures;

      remainingUnlockedFeatures.forEach((feature) => {
        updatedFeatures.push(feature);
      });

      updatedSelections.push(obj);
      updatedAuxiliarySelections.push(auxiliary_selection);
      forcedFromPrereqs.forEach((forcedSelection) => {
        updatedSelections.push(forcedSelection);
        if (forcedSelection.selection.target === 'expanded_spells') {
          triggerExpandedSpells = true;
          // expandedSpells.push(forcedSelection);
        }
      });
      if (auxiliary_selection) {
        forced.forEach((forcedSelection) => {
          updatedSelections.push(forcedSelection);
        });
        this.setState(prevState => ({
          featureSelections: updatedSelections,
          lockedFeatures: remainingLockedFeatures,
          actionableFeatures: updatedFeatures,
          auxiliary_selections: updatedAuxiliarySelections
        }));
      } else {
        this.setState(prevState => ({
          featureSelections: updatedSelections,
          lockedFeatures: remainingLockedFeatures,
          actionableFeatures: updatedFeatures
        }));
      }
    }
    else {
      updatedFeatures = this.state.actionableFeatures;
      remainingUnlockedFeatures.forEach((feature) => {
        updatedFeatures.push(feature);
      });
      updatedSelections = selections.concat(obj);
      forcedFromPrereqs.forEach((forcedSelection) => {
        updatedSelections.push(forcedSelection);
        if (forcedSelection.selection.target === 'expanded_spells') {
          triggerExpandedSpells = true;
          expandedSpells.push(forcedSelection);
        }
      });
      if (auxiliary_selection) {
        forced.forEach((forcedSelection) => {
          updatedSelections.push(forcedSelection);
        });
        this.setState(prevState => ({
          featureSelections: updatedSelections,
          lockedFeatures: remainingLockedFeatures,
          actionableFeatures: updatedFeatures,
          auxiliary_selections: [...auxiliary_selections,auxiliary_selection]
        }));
      } else {
        this.setState(prevState => ({
          featureSelections: updatedSelections,
          lockedFeatures: remainingLockedFeatures,
          actionableFeatures: updatedFeatures
        }));
      }
    }
    if (triggerExpandedSpells) {
      this.handleExpandedSpells(expandedSpells);
    }
    this.props.updateQuikStatPayload(latestPayload, updatedSelections);
  }




  // DEFINE EACH SAVED DEDUCTION WITH
  // MORE INFO BASED ON FEATURE ID
  onDeductionClick(e, power) {
    e.preventDefault();
    let deductions = this.state.selectedDeductions;
    if (
      deductions.some(deduction =>
        _.isEqual(deduction,power)
      )
    ) {
      deductions = deductions.filter(deduction =>
        !_.isEqual(deduction,power)
      )
      this.setState((prevState) => {
        return {
          selectedDeductions: deductions
        }
      });
    } else {
      let oldDeduction = deductions.find(deduction =>
        deduction.target === power.target &&
        deduction.selection_id === power.selection_id
      );
      deductions = deductions.filter(deduction =>
        !_.isEqual(deduction,oldDeduction)
      );
      deductions.push(power);
      this.setState((prevState) => {
        return {
          selectedDeductions: deductions
        }
      });
    }
  }

  handleSaveData(e) {
    e.preventDefault();
    let level = 1;
    let proficiency = 2;
    if (this.props.characterSelections) {
      if (this.props.isLevelingUp) {
        level = this.props.characterSelections.level + 1;
        const classLevel = this.state.selectedClass.levels.find(lvl => lvl.level === level);
        proficiency = classLevel.proficiency;
      }
    }
    let formPayload = new FormData();
    formPayload.append('menuSection', 'class');
    if (this.props.characterSelections) {
      formPayload.append('character_id', this.props.characterSelections.id);
    }
    formPayload.append('classificationId', this.state.selectedClass.id);
    formPayload.append('menuSelections', JSON.stringify(this.state.featureSelections));
    formPayload.append('features', JSON.stringify(this.state.actionableFeatures));
    formPayload.append('deductions', JSON.stringify(this.state.selectedDeductions));
    formPayload.append('level', level);
    formPayload.append('hitpoints', this.state.selectedClass.hp_at_lvl_1);
    formPayload.append('hpIncrease', this.state.hpIncrease);
    formPayload.append('hitDice', this.state.selectedClass.hit_die);
    formPayload.append('proficiency', proficiency);
    fetch('/api/v1/auto/character/process', {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if (res.status === 200) {
        // && localStorage.getItem('toChar') !== 'true'
        this.props.lockSectionIn(
          this.state.featureSelections,
          this.props.quikStatsPayload,
          'class'
        );
      // } else if (localStorage.getItem('toChar') === 'true') {
      //   this.props.reRouteToCampaignChar();
      } else {
        this.props.pop(res.body.msg, res.body.status);
        this.props.throwError();
      }
    }));
  }

  handleHpIncreaseClick(e) {
    e.preventDefault();
    this.setState({
      hpPopUp: true
    });
  }

  handleHpChange(e, hp) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        hpIncrease: hp,
        hpPopUp: false
      };
    });
    this.props.updateUpperHpIncrease(hp);
  }

  closePop(e) {
    e.preventDefault();
    this.setState({
      hpPopUp: false,
      isStoryModePoppedUp: false
    });
  }

  onStoryModeClick(e) {
    e.preventDefault();
    this.setState({
      isStoryModePoppedUp: true
    });
  }

  render() {
    let level = this.props.characterSelections && this.props.isLevelingUp
      ? ` lvl ${this.props.characterSelections.level + 1}`
      : ' lvl 1';

    let classes = this.state.classes.map((classification, index) => {
      const selectedIndex = this.state.selectedClass && this.state.selectedClass.id === classification.id ?
        'selectableCharacterQuality selectedCharacterQuality' : 'selectableCharacterQuality';

      return <div
              className={selectedIndex}
              key={index}
              onClick={((e) => this.selectClass(e, classification))}
             >
              {`${classification.name}${level}`}
             </div>;
    });

    let selectedClass;
    let selectedClassDetails;
    let storyModeButton;
    if (this.state.selectedClass) {
      selectedClass = this.state.selectedClass.name + " Details";
      selectedClassDetails = <ClassDetails
                                loading={this.state.loading}
                                features={this.state.actionableFeatures}
                                handleFeatureClick={this.selectFeature}
                                onReadMoreClick={this.props.onReadMoreClick}
                                selectedFeature={this.state.selectedFeature}
                                featureSelections={this.state.featureSelections}
                                auxiliary_selections={this.state.auxiliary_selections}
                                hpIncrease={this.state.hpIncrease}
                                isLevelingUp={this.props.isLevelingUp}
                                handleHpIncreaseClick={this.handleHpIncreaseClick}
                                selectedClass={this.state.selectedClass}
                                selectedDeductions={this.state.selectedDeductions}
                              />;
      storyModeButton = <div
                          onClick={((e) => this.onStoryModeClick(e))}
                          className="class-prompt-story-mode-button"
                        >
                          <i className="fa-solid fa-book-open"></i>
                        </div>
    } else {
      selectedClass = '';
      selectedClassDetails = <ClassDetails
                        loading={this.state.loading}
                        features={[]}
                        handleHpIncreaseClick={this.handleHpIncreaseClick}
                        handleFeatureClick={this.selectFeature}
                        onReadMoreClick={this.props.onReadMoreClick}
                        selectedFeature={null}
                        featureSelections={[]}
                        auxiliary_selections={[]}
                        selectedDeductions={[]}
                        selectedClass={null}
                        hpIncrease={null}
                        isLevelingUp={null}
                      />;
    }


    let selections;
    if (
      this.state.selectedFeature != null
    ) {
      selections = this.state.selectedFeature.selections.map((selection, index) => {
        if (!selection.force_added) {
          let chosen_selection;
          this.state.auxiliary_selections.forEach((auxSelection) => {
            if (this.state.selectedFeature.auxiliary_choices.selections.some(choice =>
              choice.id === auxSelection.id
            )){
              chosen_selection = auxSelection;
            }
          });
          return  <SelectionPrompt
                    key={index}
                    expandedSpells={this.state.expandedSpells}
                    handleSelectionClick={this.handleSelectionClick}
                    selection={selection}
                    characterSelections={this.props.characterSelections}
                    selections={this.state.featureSelections}
                    auxiliary_selection={chosen_selection}
                    onDeductionClick={this.onDeductionClick}
                    onReadMoreClick={this.props.onReadMoreClick}
                    selectedDeductions={this.state.selectedDeductions}
                  />;
        } else {
          return null;
        }
      });
    } else {
      selections = <SelectionPrompt
                expandedSpells={[]}
                handleSelectionClick={this.handleSelectionClick}
                onDeductionClick={this.onDeductionClick}
                onReadMoreClick={this.props.onReadMoreClick}
                selection={null}
                characterSelections={[]}
                selections={[]}
                auxiliary_selection={null}
                selectedDeductions={[]}
              />;
    }

    let disabled = true;
    let totalNeeded = 0;
    let totalMet = 0;
    if (this.state.selectedClass) {
      this.state.actionableFeatures.forEach((feature) => {
        // TODO: include a check if feature is permitted based on prereqs
        if (
          feature.selections.every(selection =>
            selection.optional_selection === false
            // selection.acquisition_type_id === 0
          )
        ) {
          feature.selections.forEach((selection) => {
            if (selection.num_selections) {
              totalNeeded += 1;
              let featureSelections = this.state.featureSelections.filter(pool =>
                pool.selection.source !== 'selection' &&
                selection.num_selections &&
                selection.id === pool.selection.id
              );
              if (featureSelections.length === selection.num_selections) {
                totalMet += 1;
              }
            }
          });
        }
      });
      this.state.auxiliary_selections.forEach((auxPool) => {
        if (auxPool.selection.num_selections) {
          let featureSelections = this.state.featureSelections.filter(pool =>
            pool.selection.id === auxPool.id &&
            pool.feature_id === null
          );
          if (featureSelections.length === auxPool.selection.num_selections) {
            totalMet += 1;
          }
        } else {
          totalMet += 1;
        }
      });
    }
    if (
      (
        totalMet === totalNeeded &&
        this.state.selectedClass &&
        !this.props.isLevelingUp
      ) ||
      (
        this.props.characterSelections &&
        this.props.isLevelingUp &&
        this.state.hpIncrease &&
        (
          totalMet === totalNeeded &&
          this.state.selectedClass
        )
      )
    ) {
      disabled = false;
    }

    const proceedButtonText = this.props.isLevelingUp
    ? "Finish Level Up"
    : "Save and Continue";
    const proceedButtons = <ProceedButtons
                            handleProceedForward={this.handleSaveData}
                            handleGoBack={this.props.handleGoBack}
                            isDisabled={disabled}
                            proceedText={proceedButtonText}
                          />;

    let hpPop = this.state.hpPopUp
      ? <IncreaseHpPopUp
          closePop={this.closePop}
          onSubmit={this.handleHpChange}
          char={this.props.characterSelections}
          selectedClass={this.state.selectedClass}
        />
      : null;

    let storyModePopup = this.state.isStoryModePoppedUp
      ? <StoryModePopup
          closePop={this.closePop}
          selectedClass={this.state.selectedClass}
        />
      : null;

    return (
      <div className="char-prompt-container">
        {storyModePopup}
        {hpPop}
        <div className="race-detail-header">
          <div className="auto-archrace-header">
            {`${this.props.isLevelingUp ? 'Leveling up as' : 'Choose a Class'}`}
          </div>
          <div className="auto-corerace-header">
            {selectedClass}
            {storyModeButton}
          </div>
        </div>
        <div className="race-detail-container">
          <div className="auto-archraces">
            <div className="archraces-container">
              {classes}
            </div>
            {proceedButtons}
          </div>

          <div className="auto-coreraces" id="auto-coreraces">
            {selectedClassDetails}
          </div>

          <div className="auto-trait-selections">
            {selections}
          </div>
        </div>
      </div>
    );
  }
}
export default ClassPrompt;
