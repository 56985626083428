import React from 'react';

const CampaignMapMarkerPopUp = props => {

  let isDm = props.currentUser && props.currentUser.role === true ? true : false;
  let marker = props.rightClickedMarker ? props.rightClickedMarker : null;

  let nameValue = "";
  if (props.nameContent === null) {
    if (marker && marker.name) {
      nameValue = marker.name;
    } else if (marker && marker.character && marker.character.name) {
      nameValue = marker.character.name;
    } else {
      nameValue = ""
    }
  } else {
    nameValue = props.nameContent;
  }

  // for whether the submit button is clickable
  let clickStatus = 'is-unclickable';

  if (
    (
      props.isNewRequest &&
      !marker &&
      props.nameContent &&
      props.markerType !== null
    ) ||
    (
      !props.isNewRequest &&
      marker &&
      (
        props.isDeleteToggled ||
        props.isDeleteControlToggled ||
        props.isHideToggled !== marker.is_hiding ||
        (
          props.markerType &&
          props.markerType !== marker.marker_type.id
        ) ||
        (
          !marker.aware_users.every(dBUser =>
            props.awareUsers.some(uIUser =>
              dBUser.id === uIUser
            )
          ) ||
          !props.awareUsers.every(uIUser =>
            marker.aware_users.some(dBUser =>
              dBUser.id === uIUser
            )
          )
        ) ||
        (
          props.nameContent !== null &&
          (
            (
              !marker.character &&
              marker.name !== props.nameContent
            ) ||
            (
              marker.character &&
              marker.character.name !== props.nameContent
            )
          )
        ) ||
        (
          props.rightClickedMarker.public_notes !== undefined &&
          props.rightClickedMarker.public_notes !== null &&
          props.typedPublicNotesValue !== null &&
          props.typedPublicNotesValue.replaceAll('\r','') !== props.rightClickedMarker.public_notes.replaceAll('\r','')
        ) ||
        (
          props.rightClickedMarker.private_notes !== undefined &&
          props.rightClickedMarker.private_notes !== null &&
          props.typedPrivateNotesValue !== null &&
          props.typedPrivateNotesValue.replaceAll('\r','') !== props.rightClickedMarker.private_notes.replaceAll('\r','')
        ) ||
        (
          (props.rightClickedMarker.public_notes === undefined ||
          props.rightClickedMarker.public_notes === null) &&
          props.typedPublicNotesValue !== null
        ) ||
        (
          (props.rightClickedMarker.private_notes === undefined ||
          props.rightClickedMarker.private_notes === null) &&
          props.typedPrivateNotesValue !== null
        )
      )
    )

  ) {
    clickStatus = "";
  }

  let submitButton, verbage;
  if (isDm) {
    verbage = props.isNewRequest ? "Create Marker" : "Save Changes";
    submitButton =  <div className={`popup-button pop-up-yes ${clickStatus}`} onClick={((e) => props.onSubmit(e, marker))}>
                      {verbage}
                    </div>
  }

  let setToHidden =
    <div className="marker-pop-up-menu-option">
      <label className="delete-marker">
        <input
          onChange={props.toggleHideMarker}
          type="checkbox"
          checked={props.isHideToggled}
        />
        <span className="delete-marker-title">Fully Hide</span>
      </label>
    </div>;

  let boardRemoval = props.rightClickedMarker && props.rightClickedMarker.map_coordinate_id !== null
    ? <div className="marker-pop-up-menu-option">
        <label className="delete-marker">
          <input
            onChange={props.toggleDeleteMarker}
            type="checkbox"
            checked={props.isDeleteToggled}
          />
          <span className="delete-marker-title">Remove marker from board?</span>
        </label>
      </div>
    : null;

  let controlsRemoval = props.rightClickedMarker && props.rightClickedMarker.marker_type.name !== 'PC'
    ? <div className="marker-pop-up-menu-option">
        <label className="delete-marker">
          <input
            onChange={props.toggleDeleteControlMarker}
            type="checkbox"
            checked={props.isDeleteControlToggled}
          />
          <span className="delete-marker-title">Delete marker entirely?</span>
        </label>
      </div>
    : null;

  let checkBoxWrapper = isDm
    ? <div className='marker-pop-up-menu-options-inner'>
        {setToHidden}
        {boardRemoval}
        {controlsRemoval}
      </div>
    : null;


  // let hex = props.hex ? props.hex : '000';
  // let colors = props.colorHexes.map((color, index) => {
  //   if (!props.hex && hex === '000') {
  //     hex = color.code;
  //   }
  //   let colorBoxStyling = {
  //     background: `#${color.code}`
  //   }
  //   return <div
  //            className="color-option"
  //            style={colorBoxStyling}
  //            onClick={((e) => props.handleMarkerColorClick(e, color.code))}
  //           ></div>
  // });
  // let colorOptionsPopUp = props.isColorOptionsMenuPoppedUp ?
  //   <div className="color-options-container">{colors}</div> :
  //   null;
  //
  // let defaultNewHex = props.selectedMenuHex ? props.selectedMenuHex : hex;
  // let hexStyling = {
  //   background: `#${defaultNewHex}`
  // }
  let markerTypesSection;
  let userVisibilitiesSection = null;
  if (props.isNewRequest || (marker && marker.marker_type.name !== 'PC')) {
    if (isDm && props.markerTypes && props.markerTypes.length > 0) {
      let markerTypes = props.markerTypes.map((markerType, index) => {
        let highlighted = '';
        if (
          markerType.id === props.markerType ||
          (
            !props.markerType &&
            marker &&
            marker.marker_type &&
            marker.marker_type.name === markerType.name
          )
        ) {
          highlighted = 'highlighted-icon'
        }
        return  <div
                  className={`marker-type-square ${highlighted}`}
                  onClick={((e) => props.handleMarkerTypeClick(e, markerType.id))}
                  key={index}
                >
                  <i className={`fa-solid ${markerType.icon_name}`}></i>
                  <div className='marker-type-name'>{markerType.name}</div>
                </div>
      });
      markerTypesSection =  <div className="marker-types-container">
                              <div className="marker-types-header">Choose Marker Type</div>
                              {markerTypes}
                            </div>
    }
    if (isDm && props.allUsers && props.allUsers.length > 0) {
      let listUsers = props.allUsers.filter(user =>
        user.role !== true
      );
      let users = listUsers.map((user, index) => {
        let highlighted = '';
        if (props.awareUsers.includes(user.id)) {
          highlighted = 'highlighted-icon'
        }
        return  <div
                  className={`aware-user-square ${highlighted}`}
                  onClick={((e) => props.handleUserInclusionClick(e, user.id))}
                  key={index}
                >
                  {user.display_name}
                </div>
      });
      userVisibilitiesSection = <div className="aware-users-container">
                                  <div className="aware-users-header">Toggle Visibilites</div>
                                  {users}
                                </div>
    }
  }

  let togglesSection = checkBoxWrapper !== null || userVisibilitiesSection !== null
    ? <div className="marker-pop-up-menu-options">
        {checkBoxWrapper}
        {userVisibilitiesSection}
      </div>
    : null;

  let nameSection;
  if (
    isDm &&
    (
      !marker ||
      (marker && marker.marker_type.name !== 'PC')
    )
  ) {
    nameSection = <div className="marker-pop-up-menu-label-wrapper">
                    <label className="marker-pop-up-menu-label">
                      <input
                        className="marker-pop-up-menu-input"
                        type='text'
                        onChange={props.handleNameChange}
                        value={nameValue}
                        placeholder={'Name'}
                      />
                    </label>
                  </div>
  } else {
    if (nameValue !== "") {
      nameSection = <div className="marker-pop-up-menu-label-wrapper">
                      <div className="">
                        {`${nameValue} (${marker.marker_type.name})`}
                      </div>
                    </div>
    } else {
      let altName = marker && marker.marker_type.name ? marker.marker_type.name : "";
      nameSection = <div className="marker-pop-up-menu-label-wrapper">
                      <div className="">
                        {`Unnamed ${altName}`}
                      </div>
                    </div>
    }
  }

  let privateNotes = props.typedPrivateNotesValue === null ? "" : props.typedPrivateNotesValue;
  let publicNotes = props.typedPublicNotesValue === null ? "" : props.typedPublicNotesValue;

  let publicFinalNotes = publicNotes !== ""
    ? <div className="marker-pop-up-menu-non-dm-notes">
        {publicNotes}
      </div>
    : null;

  let privateNotesCount = isDm && privateNotes !== null
    ? JSON.stringify(privateNotes.replaceAll('\r','')).length - 2
    : null
  let publicNotesCount = isDm && publicNotes !== null
    ? JSON.stringify(publicNotes.replaceAll('\r','')).length - 2
    : null

  let publicNotesSection = isDm
    ? <div className="marker-pop-up-menu-label-wrapper">
        <div className="marker-pop-up-menu-label-header-wrapper">
          <div className="marker-pop-up-menu-label-header">Marker notes visible to all campaign members (ie. Full health/Bloodied)</div>
          <div className="marker-pop-up-menu-notes-limit">{`${publicNotesCount}/300`}</div>
        </div>
        <label className="marker-pop-up-menu-notes-wrapper">
          <textarea
            className="marker-pop-up-menu-notes-text"
            onChange={props.handleTypedPublicNotesValueChange}
            value={publicNotes}
            placeholder="Public Notes"
          />
        </label>
      </div>
    : publicFinalNotes;

  let privateNotesSection = isDm
    ? <div className="marker-pop-up-menu-label-wrapper">
        <div className="marker-pop-up-menu-label-header-wrapper">
          <div className="marker-pop-up-menu-label-header">Private notes visible only to DM (ie. Exact health, List of abilities)</div>
          <div className="marker-pop-up-menu-notes-limit">{`${privateNotesCount}/300`}</div>
        </div>
        <label className="marker-pop-up-menu-notes-wrapper">
          <textarea
            className="marker-pop-up-menu-notes-text"
            onChange={props.handleTypedPrivateNotesValueChange}
            value={privateNotes}
            placeholder="Private Notes"
          />
        </label>
      </div>
    : null;


  // {colorOptionsPopUp}
  // <div
  //   className="marker-pop-up-menu-option"
  //   onClick={((e) => props.handleMarkerColorPopUpClick(e))}
  // >
  //   <div className="marker-pop-up-menu-label-text">
  //     Change Color
  //   </div>
  //   <div className="change-marker-color" style={hexStyling}>
  //   </div>
  // </div>



  return(
    <div className="pop-up">
      <div className="marker-pop-up-menu pop-up-inner-confirmation">
        <div className="marker-pop-up-inner">
          {markerTypesSection}
          <div className="marker-pop-up-menu-labels">
            {nameSection}
            {publicNotesSection}
            {privateNotesSection}
          </div>
          {togglesSection}
          <div className="campaign-confirm-drop-popup-button-container">
            {submitButton}
            <div className="popup-button pop-up-no" type='submit' onClick={((e) => props.closePopUp(e))}>
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignMapMarkerPopUp
