import React from 'react';

const CharacterToolTip = props => {

  return(
    <div className='pop-up-2nd'>
      <div className='campaign-confirm-drop-popup-inner-tool-tip'>
        <div className='tool-tips-popup-container'>
          <div className="tool-tips-container">
            <div className="tool-tips-summary">
              <div className='tool-tips-summary-header'>
                Tips for Combat
              </div>
              <div className="tool-tips-action-desc">Each combat round is about 6 seconds of in-world time for each turn player in that round.  For Example, all players, including enemies, would have 10 rounds of actions before a minute of in-world time passes.</div>
              <div className="tool-tips-action-desc">Each round you are allotted use of each of the following:</div>
              <div className="tool-tips-summary-items">
                <div className="tool-tips-summary-item">1 Bonus Action</div>
                <div className="tool-tips-summary-item">1 Action</div>
                <div className="tool-tips-summary-item">1 Reaction</div>
                <div className="tool-tips-summary-item">1 Interaction*</div>
              </div>
              <div className="tool-tips-action-desc">On your turn, you can move a distance up to your speed and take one action. You decide whether to move first or take your action first. Your speed, sometimes called your walking speed is noted on your character sheet. The most common actions you can take are described in the Actions section to the right. Many class features and other abilities provide additional options for your action.</div>
              <div className="tool-tips-interactions">
                <div className="tool-tips-summary-header">Interactions</div>
                <div className="tool-tips-action-desc">Here are a few examples of the sorts of thing you can do in tandem with your movement and action:</div>
                <div className="tool-tips-summary-items">
                  <div className="tool-tips-summary-item">• draw or sheathe a sword</div>
                  <div className="tool-tips-summary-item">• open or close a door</div>
                  <div className="tool-tips-summary-item">• withdraw a potion from your backpack</div>
                  <div className="tool-tips-summary-item">• pick up a dropped axe</div>
                  <div className="tool-tips-summary-item">• take a bauble from a table</div>
                  <div className="tool-tips-summary-item">• remove a ring from your finger</div>
                  <div className="tool-tips-summary-item">• stuff some food into your mouth</div>
                  <div className="tool-tips-summary-item">• plant a banner in the ground</div>
                  <div className="tool-tips-summary-item">• fish a few coins from your belt pouch</div>
                  <div className="tool-tips-summary-item">• drink all the ale in a flagon</div>
                  <div className="tool-tips-summary-item">• throw a lever or a switch</div>
                  <div className="tool-tips-summary-item">• pull a torch from a sconce</div>
                  <div className="tool-tips-summary-item">• take a book from a shelf you can reach</div>
                  <div className="tool-tips-summary-item">• extinguish a small flame</div>
                  <div className="tool-tips-summary-item">• don a mask</div>
                  <div className="tool-tips-summary-item">• pull the hood of your cloak up and over your head put your ear to a door</div>
                  <div className="tool-tips-summary-item">• kick a small stone</div>
                  <div className="tool-tips-summary-item">• turn a key in a lock</div>
                  <div className="tool-tips-summary-item">• tap the floor with a 10-foot pole</div>
                  <div className="tool-tips-summary-item">• hand an item to another character</div>
                </div>
              </div>
              <div className="tool-tips-cover">
                <div className="tool-tips-summary-header">Cover</div>
                <div className="tool-tips-action-desc">
                  Walls, trees, creatures, and other obstacles can provide cover during combat, making a target more difficult to harm. A target can benetit from cover only when an attack or other effect originates on the opposite side of the cover. There are three degrees of cover. If a target is behind multiple sources of cover, only the most protective degree of cover applies; the degrees aren't added together. For example, if a target is behind a creature that gives half cover and a tree trunk that gives three-quarters cover, the target has three-quarters cover.
                </div>
                <div className="tool-tips-action-desc">
                  • A target with half cover has a +2 bonus to AC and Dexterity saving throws. A target has half cover if an obstacle blocks at least half of its body. The obstacle might be a low wall. a large piece of furniture, a narrow tree trunk, or a creature, whether that creature is an enemy or a friend.
                </div>
                <div className="tool-tips-action-desc">
                  • A target with three-quarters cover has a +5 bonus to AC and Dexterity saving throws. A target has three-quarters cover if about three-quarters of it is covered by an obstacle. The obstacle might be a portcullis. an arrow slit, or a thick tree trunk.
                </div>
                <div className="tool-tips-action-desc">
                  • A target with total cover can't be targeted directly by an attack or a spell, although some spells can reach such a target by including it in an area of effect. A target has total cover if it is completely concealed by an obstacle.
                </div>
              </div>
            </div>
            <div className="tool-tips-actions">
              <div className="tool-tips-summary-header">Actions</div>
              <ul className="tool-tips-actions-uls">
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Attack</div>
                  <div className="tool-tips-action-desc">The most common action to take in combat is the Attack action, whether you are swinging a sword, firing an arrow from a bow, or brawling with your fists.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Cast a Spell</div>
                  <div className="tool-tips-action-desc">Spellcasters such as wizards and clerics, as well as many monsters, have access to spells and can use them to great effect in combat. Each spell has a casting time, which specilies whether the caster must use an action, a reaction, minutes, or even hours to cast the spell. Casting a spell is, therefore, not necessarily an action. Most spells do have a casting time of 1 action, so a spellcaster often uses his or her action in combat to cast such a spell.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Dash</div>
                  <div className="tool-tips-action-desc">When you take the Dash action, you gain extra movement for the current turn. The increase equals your speed, after applying any modifiers. With a speed of 30 feet, for example, you can move up to 60 feet on your turn if you dash.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Disengage</div>
                  <div className="tool-tips-action-desc">lf you take the Disengage action, your movement doesn't provoke opportunity attacks for the rest of the turn.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Dodge</div>
                  <div className="tool-tips-action-desc">Until the start of your next turn, any attack roll made against you has disadvantage if you can see the attacker, and you make Dexterity saving throws with advantage. You lose this benefit if you are incapacitated or if your speed drops to 0.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Grapple</div>
                  <div className="tool-tips-action-desc">When you want to grab a creature or wrestle with it, you can use the Attack action to make a special melee attack, a grapple. If you're able to make multiple attacks with the Attack action, this attack replaces one of them. The target of your grapple must be no more than one size larger than you, and it must be within your reach. Using at least one free hand, you try to seize the target by making a grapple check, a Strength (Athletics) check contested by the target's Strength (Athletics) or Dexterity (Acrobatics) check (the target chooses the ability to use).</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Help</div>
                  <div className="tool-tips-action-desc">When you take the Help action, the creature you aid gains advantage on the next ability check/attack it makes to perform the task you are helping with, provided that it makes the check/attack before the start of your next turn.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Hide</div>
                  <div className="tool-tips-action-desc">You can attempt to hide to make yourself less likely to be hit, or unnoticeable.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Ready</div>
                  <div className="tool-tips-action-desc">Sometimes you want to get the jump on a foe or wait for a particular circumstance before you act. To do so, you can take the Ready action on your turn so that you can act later in the round using your reaction. First, you decide what perceivable circumstance will trigger your reaction. Then, you choose the action you will take in response to that trigger, or you choose to move up to your speed in response to it. Examples include "lf the cultist steps on the trapdoor, I'll pull the lever that opens it," and "If the goblin steps next to me, I move away." When the Trigger occurs, you can either take your reaction right after the trigger finishes or ignore the trigger. Remember that you can take only one reaction per round.  You can also ready spells with a casting time of 1 action and must concentrate until you wish to execute the spell. If you concentration is interrupted, then your readying will be nullified.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Search</div>
                  <div className="tool-tips-action-desc">When you take the Search action, you devote your attention to finding something. Depending on the nature of your search, the DM might have you make a Wisdom (Perception) check or an lntelligence (Investigation) check.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Shove</div>
                  <div className="tool-tips-action-desc">Using the Attack action, you can make a special melee attack to shove a creature, either to knock it prone or push it away from you. If you're able to make multiple attacks with the Attack action, this attack replaces one of them. The target of your shove must be no more than one size larger than you, and it must be within your reach. You make a Strength (Athletics) check contested by the target's Strength (Athletics) or Dexterity (Acrobatics) check (the target chooses the ability to use). If you win the contest, you either knock the target prone or push it 5 feet away from you.</div>
                </li>
                <li className="tool-tips-action">
                  <div className="tool-tips-action-header">Use an Object</div>
                  <div className="tool-tips-action-desc">Sometimes objects require a full action for its use.</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-confirm-drop-popup-button-container">
            <div className="popup-button pop-up-no" type='submit' onClick={((e) => props.closePop(e))}>
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CharacterToolTip
