import React from "react";
// import { Link } from 'react-router-dom';
import TraitPrompt from "./TraitPrompt";
//import objToQueryString from "../functions/functions";


const SubracePrompt = props => {

  let coreTraits = props.coreRace.traits.map((trait, index) => {
    var selectedIndex = props.selectedTrait === trait.id ?
      'selectedTrait' : null;
    return <TraitPrompt
            key={index}
            selectedIndex={selectedIndex}
            trait={trait}
            handleTraitClick={props.handleTraitClick}
            traitSelections={props.traitSelections}
            onReadMoreClick={props.onReadMoreClick}
           />
  });

  let subTraits = props.subRace ?
    props.subRace.traits.map((trait, index) => {
      var selectedIndex = props.selectedTrait === trait.id ?
        'selectedTrait' : null;
      return <TraitPrompt
              key={index}
              selectedIndex={selectedIndex}
              trait={trait}
              handleTraitClick={props.handleTraitClick}
              traitSelections={props.traitSelections}
              onReadMoreClick={props.onReadMoreClick}
             />
    }) :
    null;

  return (
    <div className=''>
      {coreTraits}
      {subTraits}
    </div>
  );
}

export default SubracePrompt;
