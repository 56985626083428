import React from 'react';
import { withRouter } from 'react-router-dom';

class Feedback extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      loading: false,
    };
    this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
  }

  handleFeedbackChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 1000) {
      this.setState((prevState) => {
        return {
          feedback: val
        };
      });
    }
  }

  handleFormSubmit(event=null) {
    this.setState((prevState) => {
      return {
        loading: true
      };
    });
    if (event !== null) {
      event.preventDefault();
    }
    let formPayload = new FormData();
    formPayload.append('feedback', this.state.feedback);
    this.submitFeedback(formPayload);
  }

  async submitFeedback(formPayload) {
    let url = '/api/v1/feedback';
    const response = await fetch(url, {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    });
    const json = await response.json();
    this.setState((prevState) => {
      return {
        loading: false
      };
    });
    if (json && json.status != null) {
      this.props.pop(json.msg, json.status);
    }
    this.props.history.push("/");
    await this.props.displayUser('notifications');
  }

  render() {

    let feedbackCount = JSON.stringify(this.state.feedback.replaceAll('\r','')).length - 2;

    return(
      <div className="wholePage" id="container">
        <div className="feedback-notes">
          <div className="campaign-char-notes-header">
            How are we doing?
          </div>
          <div className="feedback-notes-input-wrapper">
            <div className="campaign-char-notes-limit">{`${feedbackCount}/1000`}</div>
            <label className="campaign-char-notes-input">
              <textarea
                className="feedback-notes-field"
                onChange={this.handleFeedbackChange}
                value={this.state.feedback}
                placeholder="Got suggestions, comments, or anything on mind? Let us know!"
              />
            </label>
          </div>
          <div className="feedback-subtmit-button-container">
            <div className="edit-profile-finish-button pop-up-yes" type='submit' onClick={this.handleFormSubmit} value='Submit'>Submit</div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Feedback)
