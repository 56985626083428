import React, { Component } from 'react';
import Loader from '../../components/Loader.js';


class CampaignAddItemPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      items: [],
      originalItems: [],
      selectedItem: null,
      quantity: 1,
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleItemChoice = this.handleItemChoice.bind(this);
  }

  componentDidMount() {
    fetch(`/api/v1/campaign/items`, {
      credentials: 'same-origin',
      method: 'GET',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.setState({
        loading: false,
        items: res.body.items,
        originalItems: res.body.items
      });
    }));
  }

  handleQuantityChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        quantity: val
      };
    });
  }

  handleChange(event) {
    // let formPayload = new FormData();
    // formPayload.append('item', event.target.value);
    // formPayload.append('items', this.state.items);
    // fetch("/api/v1/search_items", {
    //   method: 'POST',
    //   body: formPayload,
    //   credentials: 'same-origin'
    // })
    // .then(response =>
    //   response.json().then(body => ({
    //     body: body,
    //     status: response.status
    //   })
    // ).then(res => {
    //   if(res.status === 200) {
    //     this.setState({ items: res.body.items});
    //   }
    // }));
    let items = this.state.originalItems.filter(item =>
      item.name.toLowerCase().includes(event.target.value)
    );
    this.setState({
      searchTerm: event.target.value,
      selectedItem: null,
      items: items
    });
  }

  handleItemChoice(e, item) {
    this.setState({
      selectedItem: item,
      items: [],
      searchTerm: item.name
    });
  }

  render() {
    let results;
    if (this.state.items && this.state.items.length > 0) {
      results = this.state.items.map((item, index) => {
        return  <div onClick={((e) => this.handleItemChoice(e, item))} value={item.name} key={index}>
                  {item.name}
                </div>
      })
    } else {
      results = null;
    }


    let render;
    if (this.state.loading !== false) {
      render = <div className='campaign-detail-popup-dropdown-results'>
                 <Loader/>
                 <i className="fa fa-cog fa-spin" />
               </div>
    } else if (!this.state.selectedItem) {
      render = <div className='campaign-detail-popup-dropdown-results'>
                 {results}
               </div>
    } else {
      let typeSpecificRender, descs;
      // let image
      let cost, weight, type;
      let subtypes = '';
      const dts = this.state.selectedItem;
      if (dts) {
        cost = `${dts.item_cost.toString()} ${dts.cost_unit}`;
        weight = `${dts.item_weight.toString()} ${dts.weight_unit}`;
        type = dts.type;
        dts.subtypes.forEach((subtype, index) => {
          let continuation = index + 1 < dts.subtypes.length ? ', ' : '';
          subtypes += `${subtype}${continuation}`;
        });
        if (dts.type === 'Weapon') {
          let propertiesSection, damageSection;
          let properties = '';
          let damageTypes = '';
          dts.damage_types.forEach((type, index) => {
            let continuation = index + 1 < dts.damage_types.length ? ', ' : '';
            damageTypes += `${type}${continuation}`;
          });
          let damage = dts.dmg_dice ? `${dts.dmg_dice_count.toString()}${dts.dmg_dice} ${damageTypes}` : '';
          damageSection = <div className="campaign-detail-popup-element-container">
                            <div className="campaign-detail-popup-element-data">
                              {damage}
                            </div>
                            <div className="campaign-detail-popup-element-header popup-sub-text">
                              Damage
                            </div>
                          </div>;
          if (dts.weapon_properties) {
            dts.weapon_properties.forEach((property, index) => {
              let propertyText;
              let continuation = index + 1 < dts.weapon_properties.length ? ', ' : '';
              if (property === 'Versatile') {
                propertyText = `${property} (${dts.dmg_dice_count_2h.toString()}${dts.dmg_dice_2h})`;
              } else if (property === 'Thrown' || property === 'Ammunition') {
                propertyText = `${property} (range ${dts.range_basic}/${dts.range_long})`;
              } else {
                propertyText = property;
              }
              properties += `${propertyText}${continuation}`;
            });
            propertiesSection = <div className="campaign-detail-popup-element-container">
                                  <div className="campaign-detail-popup-element-data">
                                    {properties}
                                  </div>
                                  <div className="campaign-detail-popup-element-header popup-sub-text">
                                    Properties
                                  </div>
                                </div>;
          }
          typeSpecificRender = <div className='campaign-detail-popup-type-render'>
                                {damageSection}
                                {propertiesSection}
                               </div>;
        } else if (dts.type === 'Armor') {
          let ac, strMin, stealthDis;
          if (!dts.ac_flat_add) {
            let apply = dts.ac_apply_dex ? ` + Dex Modifier` : '';
            let max = dts.ac_max_dex_add > 0 ? ` (max ${dts.ac_max_dex_add})` : '';
            ac = <div className="campaign-detail-popup-element-container">
                  <div className="campaign-detail-popup-element-data">
                    {`${dts.ac_base_bonus.toString()}${apply}${max}`}
                  </div>
                  <div className="campaign-detail-popup-element-header popup-sub-text">
                    Armor Class (AC)
                  </div>
                 </div>;
            strMin = <div className="campaign-detail-popup-element-container">
                  <div className="campaign-detail-popup-element-data">
                    {dts.str_min > 0 ? `Str ${dts.str_min.toString()}` : '-'}
                  </div>
                  <div className="campaign-detail-popup-element-header popup-sub-text">
                    Strength
                  </div>
                 </div>;
            stealthDis =  <div className="campaign-detail-popup-element-container">
                            <div className="campaign-detail-popup-element-data">
                              {dts.stealth_dis ? 'Disadvantage' : '-'}
                            </div>
                            <div className="campaign-detail-popup-element-header popup-sub-text">
                              Stealth
                            </div>
                          </div>;
          } else {
            ac = <div className="campaign-detail-popup-element-container">
                  <div className="campaign-detail-popup-element-data">
                    {`+${dts.ac_base_bonus.toString()}`}
                  </div>
                  <div className="campaign-detail-popup-element-header popup-sub-text">
                    Armor Class (AC)
                  </div>
                 </div>;
          }
          typeSpecificRender =  <div className='campaign-detail-popup-type-render'>
                                  {ac}
                                  {strMin}
                                  {stealthDis}
                                </div>;
        } else if (dts.type === 'Mount/Vehicle') {
          let speed, carryCapac;
          const speedQuant = dts.speed_quant ? `${dts.speed_quant.toString()}${dts.speed_unit}` : '-';
          speed = <div className="campaign-detail-popup-element-container">
                    <div className="campaign-detail-popup-element-data">
                      {speedQuant}
                    </div>
                    <div className="campaign-detail-popup-element-header popup-sub-text">
                      Speed
                    </div>
                   </div>;
          const carryQuant = dts.carry_capac_quant ? `${dts.carry_capac_quant.toString()}${dts.carry_capac_unit}` : '-';
          carryCapac = <div className="campaign-detail-popup-element-container">
                        <div className="campaign-detail-popup-element-data">
                          {carryQuant}
                        </div>
                        <div className="campaign-detail-popup-element-header popup-sub-text">
                          Carrying Capacity
                        </div>
                       </div>;
          typeSpecificRender =  <div className='campaign-detail-popup-type-render'>
                                  {speed}
                                  {carryCapac}
                                </div>;
        }
        descs = dts.descs.map((desc, index) => {
          return <div key={index} className="campaign-detail-popup-desc">
                  {desc.object_text}
                 </div>;
        });
      }

      render = <div className='campaign-detail-popup-item-desc'>
                <div className='campaign-detail-popup-primary-details'>
                  <div className='campaign-detail-popup-detail popup-grand-text'>{cost}</div>
                  <div className='campaign-detail-popup-detail popup-grand-text'>{weight}</div>
                  <div className='campaign-detail-popup-detail popup-grand-text'>{type}</div>
                  <div className='campaign-detail-popup-detail popup-sub-text'>Cost</div>
                  <div className='campaign-detail-popup-detail popup-sub-text'>Weight</div>
                  <div className='campaign-detail-popup-detail popup-sub-text'>Item Type</div>
                </div>
                <div className='campaign-detail-popup-primary-details'>
                  <div className='campaign-detail-popup-detail cip-whole popup-grand-text'>{subtypes}</div>
                  <div className='campaign-detail-popup-detail cip-whole popup-sub-text'>Subtypes</div>
                </div>
                <div className='campaign-detail-popup-primary-details campaign-popup-type-specific-render'>
                  {typeSpecificRender}
                </div>
                <div className='campaign-detail-popup-primary-details campaign-popup-descs'>
                  {descs}
                </div>
              </div>

    }

    return (
      <div className='pop-up'>
        <div className='campaign-detail-popup-inner'>
          <div className='campaign-detail-popup-title'>
            Add Item
          </div>
          <form className="campaign-detail-popup-form">
            <div className="campaign-detail-popup-param-wrapper">
              <label className="campaign-detail-popup-input-field">
                <input className="campaign-detail-popup-field" type='text' onChange={this.handleChange} value={this.state.searchTerm || ""} placeholder={'Start typing item'}/>
              </label>
              {render}
            </div>
            <div className="campaign-detail-popup-param-wrapper">
              <label className="campaign-detail-popup-input-field">
                <input className="campaign-detail-popup-field" type='text' onChange={this.handleQuantityChange} value={this.state.quantity} placeholder={'Quantity'}/>
              </label>
            </div>
            <div className='campaign-detail-popup-button-container'>
              <div className="popup-button pop-up-yes" type='submit' onClick={((e) => this.props.onSubmit(e, this.state.selectedItem, this.state.quantity, 'add'))}>
                Submit
              </div>
              <div className="popup-button pop-up-no" type='submit' onClick={((e) => this.props.closePop(e))}>
                Cancel
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default CampaignAddItemPopUp
