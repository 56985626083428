import React, { Component } from 'react';
import _ from 'underscore';

class StoryModePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    let url;
    if (this.props.selectedClass !== undefined) {
      url = `/api/v1/class/story/${this.props.selectedClass.id}`;
    } else if (this.props.selectedBackground !== undefined) {
      url = `/api/v1/background/story/${this.props.selectedBackground.id}`;
    } else if (this.props.selectedArchRace !== undefined) {
      url = `/api/v1/race/story/${this.props.selectedArchRace}`;
    }
    const response = await fetch(url, {
          credentials: 'same-origin',
          method: 'GET',
          headers: { 'Content-Type':'application/json'}
        });
    const json = await response.json();
    if(response.status === 200) {
      this.setState((prevState) => {
        if (this.props.selectedClass !== undefined) {
          return { classification: json.classification, loading: false };
        } else if (this.props.selectedBackground !== undefined) {
          return { background: json.background, loading: false };
        } else if (this.props.selectedArchRace !== undefined) {
          return { race: json.race, loading: false };
        } else {
          return { loading: false };
        }
      });
    } else {
      this.props.pop(json.msg, json.status);
      this.setState((prevState) => {
        return {
          loading: false
        };
      });
    }
  }

  render() {
    let backButton =  <div
                        onClick={((e) => this.props.closePop(e))}
                        className="story-mode-popup-go-back-button"
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </div>

    let classTable;
    let features;
    // let backgroundFeatures;
    let backgroundDescs;
    let races;
    if (this.state.classification) {
      var c = this.state.classification;
      var mainTable = c.tables[0];
      var columns = mainTable.columns;
      // var columns = _.sortBy(mainTable.columns, 'id');
      // var firstColumn = <div className="story-mode-class-table-column">Level</div>
      var headerColumns = columns.map((column, index) => {
        return <div className={`story-mode-class-table-column ${column.width}`} key={index}>{column.display_name}</div>
      });
      var sortedLevels = _.sortBy(c.levels,'level');
      var classTableData = sortedLevels.map((level, lIndex) => {
        var row = columns.map((column, cIndex) => {
          let data = '';
          if (column.lookup_name === 'features') {
            level[column.lookup_name].forEach((feature, fIndex) => {
              if (feature.is_visible_on_class_table) {
                let continuation = fIndex + 1 < level[column.lookup_name].length ? ', ' : '';
                data += `${feature.name}${continuation}`;
              }
            });
          } else if (column.lookup_name === 'proficiency') {
            data = `+${level[column.lookup_name].toString()}`;
          } else {
            data = level[column.lookup_name];
          }
          return <div key={cIndex} className={`story-mode-class-table-column story-mode-class-table-data-row-piece ${column.width}`}>{data}</div>
        });
        return <div key={lIndex} className="story-mode-class-table-row">
                 <div className="story-mode-class-table-row-inner">
                   {row}
                 </div>
               </div>
      });
      classTable = <div className="story-mode-class-level-table">
                     <div className="story-mode-class-table-row">
                       <div className="story-mode-class-table-row-inner story-mode-class-table-row-inner-border">
                         {headerColumns}
                       </div>
                     </div>
                     {classTableData}
                   </div>





      var f = c.features;
      features = f.map((feature, fIndex) => {
        var descs = feature.descs.map((desc) => {
          if (desc.bullet && desc.header_text !== null) {
            return  <div className="story-mode-description-wrapper">
                      <div className="story-mode-description">
                        <span className="story-mode-header-text-bullet">
                          {desc.header_text}
                        </span>
                        {desc.object_text}
                      </div>
                    </div>
          } else if (desc.bullet && desc.header_text === null) {
            return <div className="story-mode-description-wrapper">
                      <div className="story-mode-description story-mode-bulleted-desc">
                        {desc.object_text}
                      </div>
                    </div>
          } else if (!desc.bullet && desc.header_text !== null) {
            return <div className="story-mode-description-wrapper">
                      <div className="story-mode-header-text">
                        {desc.header_text}
                      </div>
                      <div className="story-mode-description">
                        {desc.object_text}
                      </div>
                    </div>
          } else {
            return <div className="story-mode-description-wrapper">
                      <div className="story-mode-description">
                        {desc.object_text}
                      </div>
                    </div>
          }
        });
        let renderedTables;
        let auxTables;
        if (feature.tables.length > 0) {
          auxTables = feature.tables.map((table) => {
            let columns = _.sortBy(table.columns,'position');
            let widths = [];
            let headerColumns = columns.map((column, index) => {
              widths.push(column.width);
              let margin = index > 0 ? 'non-first-column' : null;
              return  <div className={`feature-table-column ${margin} ${column.width}`}>{column.title}</div>
            });
            let headerRow = <div className="feature-table-row">{headerColumns}</div>
            let tableData = table.rows.map((row, index) => {
              let rowType = index % 2 === 0 ? 'odd-row' : 'even-row';
              let value1 = row.value1 !== null ?
                <div className={`feature-table-column ${widths[0]}`}>{row.value1}</div> : null;
              let value2 = row.value2 !== null ?
                <div className={`feature-table-column non-first-column ${widths[1]}`}>{row.value2}</div> : null;
              let value3 = row.value3 !== null ?
                <div className={`feature-table-column non-first-column ${widths[2]}`}>{row.value3}</div> : null;
              let value4 = row.value4 !== null ?
                <div className={`feature-table-column non-first-column ${widths[3]}`}>{row.value4}</div> : null;
              let value5 = row.value5 !== null ?
                <div className={`feature-table-column non-first-column ${widths[4]}`}>{row.value5}</div> : null;
              let value6 = row.value6 !== null ?
                <div className={`feature-table-column non-first-column ${widths[5]}`}>{row.value6}</div> : null;
              let value7 = row.value7 !== null ?
                <div className={`feature-table-column non-first-column ${widths[6]}`}>{row.value7}</div> : null;
              return  <div className={`feature-table-row ${rowType}`}>
                        {value1}{value2}{value3}{value4}{value5}{value6}{value7}
                      </div>
            });
            return  <div className="story-mode-feature-table">
                      <div className="story-mode-feature-table-title">
                        {table.name}
                      </div>
                      {headerRow}
                      {tableData}
                    </div>
          });
          renderedTables = <div className="story-mode-feature-tables">{auxTables}</div>
        }

        return <div key={fIndex} className="story-mode-class-features">
                 <div className="story-mode-class-feature-name">{feature.name}</div>
                 <div className="story-mode-class-feature-descs">{descs}</div>
                 {renderedTables}
               </div>
      });
    } else if (this.state.background) {
      var b = this.state.background;
      // var bf = b.background_features;
      // backgroundFeatures = bf.map((backgroundFeature, fIndex) => {
      backgroundDescs = b.descs.map((desc) => {
        let characteristicsRender, suggestedCharacteristics;
        if (desc.background_section.id === 1) {
          if (b.characteristics.length > 0) {
            var characteristicsHeader =  <div className="background-table-row">
                                           <div className="background-table-column story-mode-background-table-header">{b.characteristic_die}</div>
                                           <div className="background-table-column story-mode-background-table-header">{b.characteristic_name}</div>
                                         </div>
            var characteristics = b.characteristics.map((characteristic, cIndex) => {
              let rowType = cIndex % 2 === 0 ? 'odd-row' : 'even-row';
              return <div key={cIndex} className={`background-table-row ${rowType}`}>
                       <div className="background-table-column">{characteristic.die_result}</div>
                       <div className="background-table-column">{characteristic.desc}</div>
                     </div>
            });
            characteristicsRender = <div className="story-mode-background-characteristic-table">
                                      {characteristicsHeader}
                                      {characteristics}
                                    </div>;
          }
        } else if (desc.background_section.id === 3) {
          var personalitiesHeader =  <div className="background-table-row">
                                   <div className="background-table-column story-mode-background-table-header">{b.personality_die}</div>
                                   <div className="background-table-column story-mode-background-table-header">Personality</div>
                                 </div>
          var personalities = b.personalities.map((personality, cIndex) => {
            let rowType = cIndex % 2 === 0 ? 'odd-row' : 'even-row';
            return <div key={cIndex} className={`background-table-row ${rowType}`}>
                     <div className="background-table-column">{personality.die_result}</div>
                     <div className="background-table-column">{personality.desc}</div>
                   </div>
          });

          var idealsHeader =  <div className="background-table-row">
                                   <div className="background-table-column story-mode-background-table-header">{b.ideal_die}</div>
                                   <div className="background-table-column story-mode-background-table-header">Ideal</div>
                                 </div>
          var ideals = b.ideals.map((ideal, cIndex) => {
            let rowType = cIndex % 2 === 0 ? 'odd-row' : 'even-row';
            return <div key={cIndex} className={`background-table-row ${rowType}`}>
                     <div className="background-table-column">{ideal.die_result}</div>
                     <div className="background-table-column">{ideal.desc}</div>
                   </div>
          });

          var bondsHeader =  <div className="background-table-row">
                                   <div className="background-table-column story-mode-background-table-header">{b.bond_die}</div>
                                   <div className="background-table-column story-mode-background-table-header">Bond</div>
                                 </div>
          var bonds = b.bonds.map((bond, cIndex) => {
            let rowType = cIndex % 2 === 0 ? 'odd-row' : 'even-row';
            return <div key={cIndex} className={`background-table-row ${rowType}`}>
                     <div className="background-table-column">{bond.die_result}</div>
                     <div className="background-table-column">{bond.desc}</div>
                   </div>
          });

          var flawsHeader =  <div className="background-table-row">
                                   <div className="background-table-column story-mode-background-table-header">{b.flaw_die}</div>
                                   <div className="background-table-column story-mode-background-table-header">Flaw</div>
                                 </div>
          var flaws = b.flaws.map((flaw, cIndex) => {
            let rowType = cIndex % 2 === 0 ? 'odd-row' : 'even-row';
            return <div key={cIndex} className={`background-table-row ${rowType}`}>
                     <div className="background-table-column">{flaw.die_result}</div>
                     <div className="background-table-column">{flaw.desc}</div>
                   </div>
          });
          suggestedCharacteristics = <div className="story-mode-background-characteristic-tables">
                                        <div className="story-mode-background-characteristic-table">
                                          {personalitiesHeader}
                                          {personalities}
                                        </div>
                                        <div className="story-mode-background-characteristic-table">
                                          {idealsHeader}
                                          {ideals}
                                        </div>
                                        <div className="story-mode-background-characteristic-table">
                                          {bondsHeader}
                                          {bonds}
                                        </div>
                                        <div className="story-mode-background-characteristic-table">
                                          {flawsHeader}
                                          {flaws}
                                        </div>
                                      </div>
        }
        if (desc.bullet && desc.header_text !== null) {
          return  <div className="story-mode-description-wrapper">
                    <div className="story-mode-description">
                      <span className="story-mode-header-text-bullet">
                        {desc.header_text}
                      </span>
                      {desc.object_text}
                    </div>
                    {suggestedCharacteristics}
                    {characteristicsRender}
                  </div>
        } else if (desc.bullet && desc.header_text === null) {
          return <div className="story-mode-description-wrapper">
                    <div className="story-mode-description story-mode-bulleted-desc">
                      {desc.object_text}
                    </div>
                    {suggestedCharacteristics}
                    {characteristicsRender}
                  </div>
        } else if (!desc.bullet && desc.header_text !== null) {
          return <div className="story-mode-description-wrapper">
                    <div className="story-mode-header-text">
                      {desc.header_text}
                    </div>
                    <div className="story-mode-description">
                      {desc.object_text}
                    </div>
                    {suggestedCharacteristics}
                    {characteristicsRender}
                  </div>
        } else {
          return <div className="story-mode-description-wrapper">
                    <div className="story-mode-description">
                      {desc.object_text}
                    </div>
                    {suggestedCharacteristics}
                    {characteristicsRender}
                  </div>
        }
        // });
        //
        // return  <div key={fIndex} className="story-mode-class-features">
        //           <div className="story-mode-class-feature-name">{backgroundFeature.name}</div>
        //           <div className="story-mode-class-feature-descs">{descs}</div>
        //         </div>
      });
    } else if (this.state.race) {
      races = this.state.race.races.map((race, rIndex) => {
        let traits = race.traits.map((trait, fIndex) => {
          var descs = trait.descs.map((desc) => {
            if (desc.bullet && desc.header_text !== null) {
              return  <div className="story-mode-description-wrapper">
                        <div className="story-mode-description">
                          <span className="story-mode-header-text-bullet">
                            {desc.header_text}
                          </span>
                          {desc.object_text}
                        </div>
                      </div>
            } else if (desc.bullet && desc.header_text === null) {
              return <div className="story-mode-description-wrapper">
                        <div className="story-mode-description story-mode-bulleted-desc">
                          {desc.object_text}
                        </div>
                      </div>
            } else if (!desc.bullet && desc.header_text !== null) {
              return <div className="story-mode-description-wrapper">
                        <div className="story-mode-header-text">
                          {desc.header_text}
                        </div>
                        <div className="story-mode-description">
                          {desc.object_text}
                        </div>
                      </div>
            } else {
              return <div className="story-mode-description-wrapper">
                        <div className="story-mode-description">
                          {desc.object_text}
                        </div>
                      </div>
            }
          });
          return <div key={fIndex} className="story-mode-class-features">
                   <div className="story-mode-class-feature-name">{trait.name}</div>
                   <div className="story-mode-class-feature-descs">{descs}</div>
                 </div>
        });
        return <div key={rIndex} className="story-mode-class-races">
                <div className="story-mode-class-feature-name">{race.name}</div>
                 <div className="story-mode-class-feature-descs">{traits}</div>
               </div>
      });
    }

    let title, name;
    if (this.props.selectedClass) {
      title = 'Class Features';
      name = `The ${this.props.selectedClass.name}`;
    } else if (this.props.selectedBackground) {
      name = 'Character Background';
      title = this.props.selectedBackground.name;
    } else if (this.props.selectedRace) {
      name = 'Character Race';
      title = this.props.selectedClass.name;
    }

    return (
      <div className='story-mode-pop-up'>
        <div className='story-mode-popup-inner'>
        {backButton}
          <div className='story-mode-inner-wrapper'>
            <div className='story-mode-header-container'>
              <div className='story-mode-popup-title'>
                {name}
              </div>
            </div>
            <div className="story-mode-popup-class-table-container">
              {classTable}
            </div>
            <div className="story-mode-popup-class-features-container">
              <div className='story-mode-popup-title'>
                {title}
              </div>
              {features}
              {backgroundDescs}
              {races}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default StoryModePopup
