import React, { Component } from 'react';

class ConfirmItemDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: null
    };
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
  }

  handleQuantityChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        quantity: val
      };
    });
  }

  render() {
    // let name, descs, prereqs;
    let render, confirmText;
    if (this.props.itemRequestType === 'extract') {
      confirmText = 'Extract all items?';
      render =  <div className="campaign-confirm-drop-popup-param-wrapper">
                  <div className="confirm-extract-paragraph">
                    Once you extract items from this, you will no longer be able to use this item.
                  </div>
                </div>
    } else if (this.props.itemRequestType === 'delete') {
      confirmText = 'Discard Item?';
      render =  <div className="campaign-confirm-drop-popup-param-wrapper">
                  <label className="campaign-detail-popup-input-field">
                    <input className="campaign-detail-popup-field" type='text' onChange={this.handleQuantityChange} value={this.state.quantity} placeholder={'Quantity'}/>
                  </label>
                </div>
    }


    return(
      <div className='pop-up-2nd'>
        <div className='campaign-confirm-drop-popup-inner-2nd'>
          <div className='campaign-confirm-drop-popup-container'>
            <div className='campaign-confirm-drop-popup-title'>
              {confirmText}
            </div>
            <div className="campaign-confirm-drop-popup-params-container">
              {render}
              <div className="campaign-confirm-drop-popup-button-container">
                <div className="popup-button pop-up-yes" type='submit' onClick={((e) => this.props.onSubmit(e, this.props.requestedItem, this.state.quantity, this.props.itemRequestType))}>
                  Submit
                </div>
                <div className="popup-button pop-up-no" type='submit' onClick={((e) => this.props.closePop(e))}>
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmItemDrop
