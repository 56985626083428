import React, { Component } from 'react';

export default class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
   this.props.toggle();
  }

  render() {
    const status = this.props.messageStatus;
    let style;
    if (status === true) {
      style = "green";
    } else {
      style = "red";
    }

    return (
      <div className={`modal ${style}`}>
        <div className="modalText modalObj left">{this.props.messageText}</div>
        <div className={`parentCentering modalClose modalObj right ${style}`} onClick={this.handleClick}>
          <div className="childCentering">
            X
          </div>
        </div>
      </div>
    );
  }
}
