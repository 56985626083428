import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


class CampaignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      desc: ""
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.createCampaign = this.createCampaign.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescChange = this.handleDescChange.bind(this);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleDescChange(event) {
    this.setState({ desc: event.target.value });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    let formPayload = new FormData();
    formPayload.append('name', this.state.name);
    formPayload.append('desc', this.state.desc);
    this.createCampaign(formPayload);
  }

  createCampaign(formPayload) {
    fetch("/api/v1/campaigns", {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.props.history.push("/campaigns/"+res.body.id);
      } else {
        this.props.history.push("/campaigns");
      }
    }));
  }


  render() {
    let disabled = !this.state.name ? 'disabled-button' : null;

    return(
      <div className="pop-up">
        <div className="campaigns-create-popup">
          <div className="campaigns-create-popup-headings-container">
            <div className="campaigns-create-popup-main-heading">
              Create a Campaign
            </div>
            <div className="campaigns-create-popup-sub-heading">
              (You will become the DM)
            </div>
          </div>
          <form className="campaigns-create-popup-inner">
            <label className="campaigns-create-popup-field">
              <input className="field" type='text' onChange={this.handleNameChange} value={this.state.name} placeholder={'Campaign Name'}/>
            </label>
            <label className="campaigns-create-popup-field">
              <input className="field" type='textarea' onChange={this.handleDescChange} value={this.state.desc} placeholder={'Quick Description'}/>
            </label>
            <div className="campaigns-create-popup-button-container">
              <div className={`pop-up-button pop-up-yes ${disabled}`} onClick={this.handleFormSubmit}>Submit</div>
              <div className="pop-up-button pop-up-no" onClick={this.props.closePopUp}>Cancel</div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(CampaignForm)
