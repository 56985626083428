import React from 'react';

const Item = props => {



  return (
    <div className='item-square'>
      <div className='item-square-text-container'>
        <div className='item-square-text'>
          {props.item.name}
        </div>
      </div>
    </div>
  );
}

export default Item;
