import React from 'react';
import Loader from '../components/Loader.js';

const CampaignHome = props => {

  let title, players, nonCharPlayers, image, playersSection, desc, questDetails, saveButton;
  if (props.campaign) {
    title = props.campaign.name;
    desc = props.campaign.desc ? `(${props.campaign.desc})` : "";
    let unclickableSave = "is-unclickable";
    let users = props.users;
    let nonCharUsers = users.filter(u =>
      !props.campaign.characters.some(c =>
        c.creator_id === u.id
      )
    )
    players = props.campaign.characters.map((character, index) => {
      let user = users.filter(u =>
        u.id === character.creator_id
      )[0]
      return <div
                key={index}
                className="campaign-home-character-row"
                // onClick={((e) => this.selectCharacter(e, character))}
              >
               {`${character.name} (${user.display_name}), ${character.archrace} ${character.classification} | level ${character.level}`}
             </div>;
    });
    nonCharPlayers = nonCharUsers.map((user, index) => {
      return <div
                key={index}
                className="campaign-home-character-row"
              >
               {`${user.display_name} (no chars)`}
             </div>;
    });
    playersSection = <div className="campaign-home-characters-container">
                       <div className="campaign-home-characters">
                         <div className="campaign-home-characters-inner">
                           {players}
                           {nonCharPlayers}
                         </div>
                       </div>
                     </div>

    if (props.campaign.photo) {
      image = <img className="campaign-home-image" src={props.campaign.photo} alt="campaign"/>;
    }

    if (props.level === "dm") {
      let questNotes = props.campaign.quest_notes;
      if (questNotes === null) {
        questNotes = "";
      }
      if (
        props.typedQuestNotesValue !== null &&
        props.typedQuestNotesValue.replaceAll('\r','') !== questNotes.replaceAll('\r','')
      ) {
        unclickableSave = "";
      }
      let campaignNotesCount = props.typedQuestNotesValue !== null
        ? JSON.stringify(props.typedQuestNotesValue.replaceAll('\r','')).length - 2
        : 0;
      saveButton =  <div
                      className={`campaign-quest-notes-save-container ${unclickableSave}`}
                      onClick={props.onSave}
                    >
                      <div className="campaign-quest-notes-save">
                        Save
                      </div>
                    </div>
      questDetails =  <div className="campaign-home-section-box campaign-quest-notes-campaign-notes">
                        <div className="campaign-char-notes-header">
                          Campaign Notes
                        </div>
                        {saveButton}
                        <div className="campaign-char-notes-input-wrapper">
                          <div className="campaign-char-notes-limit">{`${campaignNotesCount}/4000`}</div>
                          <label className="campaign-char-notes-input">
                            <textarea
                              className="campaign-char-notes-field"
                              onChange={props.handleTypedQuestNotesValueChange}
                              value={props.typedQuestNotesValue}
                              placeholder="Notes"
                            />
                          </label>
                        </div>
                      </div>;

    } else {
      questDetails =  <div className="campaign-home-section-box">
                        <div className="campaign-char-notes-header">
                          Campaign Notes
                        </div>

                        <div className="campaign-home-section-box-content">
                          {props.typedQuestNotesValue}
                        </div>
                      </div>;
    }
  }

  if (props.loading !== false) return <Loader/>;
  const loader = props.loading ? <i className="fa fa-cog fa-spin" /> : null;

  // <div className="campaign-home-box campaign-home-bottom-right">
  //   <div className="campaign-home-section-box">
  //     <div className="campaign-home-section-box-content campaign-home-coming-soon">
  //       Campaign Actions Log Coming Soon
  //     </div>
  //   </div>
  // </div>

  return(
    <div className="campaign-home-container">
      {loader}
      <div className="campaign-home-left-section">
        <div className="campaign-home-title-section">
          <div className="campaign-home-title">
            {title}
            <div className="campaign-home-desc">
              {desc}
            </div>
          </div>
        </div>
        <div className="campaign-home-quest-section">
          {questDetails}
        </div>
      </div>
      <div className="campaign-home-right-section">
        <div className="campaign-home-box campaign-home-top-right">
          <div className="campaign-home-section-box">
            <div className="campaign-home-section-box-image">
              {image}
            </div>
          </div>
        </div>
        <div className="campaign-home-box campaign-home-users">
          <div className="campaign-home-section-box">
            <div className="campaign-home-chars-box-content">
              <div className="campaign-home-players-header">
                Campaign Members
              </div>
              {playersSection}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignHome
