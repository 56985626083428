import React from 'react'
import { Link } from 'react-router-dom'

const Nav = props => {
  return (
    <div className="navBar" id="nav">
      <div className="navMeasurement links left">
        <Link className="navText" to='/'>
          <div className="navLogo">
            <div className="navLogoText">CharTrakr</div>
          </div>
        </Link>

        <Link className="navText" to='/campaigns'>
          <div className="navItem">Campaigns</div>
        </Link>

        <Link className="navText" to='/characters'>
          <div className="navItem">Characters</div>
        </Link>
      </div>
      {props.user_signed_in}
    </div>
  )
}

export default Nav

        //
        // <Link className="navText" to='/items'>
        //   <div className="navItem">Items</div>
        // </Link>
