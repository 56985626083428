import React from 'react';
import CampaignItemPopUp from '../../campaign/popups/CampaignItemPopUp';
import CampaignFeaturePopUp from '../../campaign/popups/CampaignFeaturePopUp';
import CampaignFeatPopUp from '../../campaign/popups/CampaignFeatPopUp';
import CampaignSpellPopUp from '../../campaign/popups/CampaignSpellPopUp';
import CampaignLanguagePopUp from '../../campaign/popups/CampaignLanguagePopUp';
import CampaignGenericPopUp from '../../campaign/popups/CampaignGenericPopUp';

const CharPromptDetailPop = props => {

  let render;
  let dts = props.details;
  if (dts) {
    if (dts.detailType === 'item') {
      render =  <CampaignItemPopUp
                  details={dts.info}
                />;
    } else if (dts.detailType === 'feature') {
      render =  <CampaignFeaturePopUp
                  details={dts.info}
                />;
    } else if (dts.detailType === 'feat') {
      render =  <CampaignFeatPopUp
                  details={dts.info}
                />;
    } else if (dts.detailType === 'spell') {
      render =  <CampaignSpellPopUp
                  details={dts.info}
                />;
    } else if (dts.detailType === 'language') {
      render =  <CampaignLanguagePopUp
                  details={dts.info}
                />;
    } else {
      render =  <CampaignGenericPopUp
                  details={dts.info}
                />;
    }
  }

  return(
    <div className='campaign-detail-popup'>
      <div className='campaign-detail-popup-inner'>
        {render}
        <div className='campaign-detail-popup-button-container'>
          <div
            className='pop-up-button pop-up-no'
            onClick={props.closePop}
          >
            CLOSE
          </div>
        </div>
      </div>
    </div>
  )
}

export default CharPromptDetailPop
