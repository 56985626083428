import React from 'react';

const Notification = props => {

  let buttons =
    <div className="accept-deny-container">
      <div
        className="notification-button pop-up-yes"
        onClick={((e) => props.handleClick(e, true, props.notification.id))}
      >
        Accept
      </div>
      <div
        className="notification-button pop-up-no"
        onClick={((e) => props.handleClick(e, false, props.notification.id))}
      >
        Decline
      </div>
    </div>;

  let deleteButton =
    <div
      className="notification-delete-button pop-up-discard"
      onClick={((e) => props.handleDelete(e, props.notification.id))}
    >
      <i className="notification-delete-button-icon fa-solid fa-trash-can"></i>
    </div>

  return (
    <div className='notification-square'>
      <div className='notification-square-text-outer-wrapper'>
        <div className='notification-square-text-container'>
          <div className='notification-square-text'>
            {props.notification.object_text}
          </div>
        </div>
        {props.notification.notification_type.name === 'request' ? buttons : null }
      </div>
      {deleteButton}
    </div>
  );
}

export default Notification;
