import React, { useState, useEffect } from 'react';

const diceChart = {
  'd4' : 4,
  'd6' : 6,
  'd8' : 8,
  'd10' : 10,
  'd12' : 12,
  'd20' : 20
}

function IncreaseHpPopUp({
  onSubmit,
  closePop,
  selectedClass,
  char
}) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     amount: null
  //   };
  //   this.handleHpIncreaseSelection = this.handleHpIncreaseSelection.bind(this);
  //   this.handleDieRoll = this.handleDieRoll.bind(this);
  // }
  // const [isActive, setIsActive] = useState(false);
  // const [amount, setAmount] = useState(null);
  // const [amount, setDie] = useState(null);

  // handleHpIncreaseSelection(e) {
  //   e.preventDefault();
  //   this.setState({
  //     amount: this.props.selectedClass.hp_at_higher_lvls
  //   });
  // }

  // useEffect(() => {
  //   function handleHpIncreaseSelection(status) {
  //     setAmount(status.isOnline);
  //   }
  // }
  //
  // handleDieRoll(e) {
  //   e.preventDefault();
  //   for(var i = 0; i < 20; i++){
  //     let roll = Math.floor(Math.random() * diceChart[this.props.selectedClass.hit_die]) + 1;
  //     setTimeout(function() {
  //       this.setState((prevState) => {
  //         return {
  //           amount: roll
  //         };
  //       })
  //     }, 100)
  //   }
  // }

  const con = char && char.con_score !== null ? Math.floor((char.con_score - 10) / 2) : null;
  const faces = diceChart[selectedClass.hit_die];
  const maxRollTimes = 20;

  const [intrvl, setIntrvl] = useState();
  const [diceFace, setDiceFace] = useState(0);
  const [amount, setAmount] = useState(null);
  // const [btnDisabled, setBtnDisabled] = useState(false);
  const [rollTimes, setRollTimes] = useState();
  useEffect(() => {
    if(rollTimes === 0) {
      clearInterval(intrvl);
      // setBtnDisabled(false);
    }
  });

  function rollDie() {
    setAmount(null)
    // setBtnDisabled(true);
    clearInterval(intrvl);
    // let counter = Math.floor((Math.random() * maxRollTimes) + 1);
    let counter = maxRollTimes;
    setRollTimes(counter);
    const interval = setInterval(()=>{
      setDiceFace(Math.floor(Math.random() * faces) + 1);
      counter--;
      setRollTimes(counter);
    }, 50);
    setIntrvl(interval);
  }

  function chooseAmount() {
    setAmount(selectedClass.hp_at_higher_lvls)
  }


  let plusCon = con !== null
    ? <div className="hp-increase-popup-final-con-add">
        +{con}
      </div>
    : null;

  // let finalResult;
  // if (amount === null && diceFace === 0) {
  //   finalResult = 0;
  // } else if (amount === null) {
  //   finalResult = diceFace - con;
  // } else {
  //   finalResult = amount;
  // }

  return (
    <div className='pop-up'>
      <div className='campaign-detail-popup-inner'>
        <div className='hp-increase-popup-title'>
          Increase HP
        </div>
        <div className="hp-increase-popup-final-selection-wrapper">
          <div className="hp-increase-popup-final-selection">
            <div className="hp-increase-popup-final-selection-text">
              {amount === null ? diceFace : amount}
            </div>
          </div>
          {plusCon}
        </div>
        <div className="hp-increase-popup-options-wrapper">
          <div className="hp-increase-popup-option-wrapper">
            <div className="hp-increase-popup-option-header">
              Roll for HP increase
            </div>
            <div
              onClick={rollDie}
              className="hp-increase-popup-option"
            >
              <div className="hp-increase-number-text-wrapper">
                {selectedClass.hit_die}
              </div>
            </div>
          </div>
          <div className="hp-increase-popup-option-wrapper">
            <div className="hp-increase-popup-option-header">
              Take the auto-increase
            </div>
            <div
              onClick={chooseAmount}
              className="hp-increase-popup-option"
            >
              <div className="hp-increase-number-text-wrapper">
                {selectedClass.hp_at_higher_lvls}
              </div>
            </div>
          </div>
        </div>
        <div className="increase-hp-button-container">
          <div className="pop-up-button pop-up-yes" type='submit' onClick={((e) => onSubmit(e, amount === null ? diceFace : amount))}>
            Submit
          </div>
          <div className="pop-up-button pop-up-no" type='submit' onClick={((e) => closePop(e))}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncreaseHpPopUp
