import React from "react";
import FeaturePrompt from "./FeaturePrompt";
import Loader from '../../components/Loader.js';
import check from "../../../icons/check.png";
import exclamation from "../../../icons/exclamation.png";

const ClassDetails = props => {

  // let count = 0;
  let features = props.features.map((feature, index) => {
    var selectedIndex = props.selectedFeature && props.selectedFeature.id === feature.id ?
      'selectedTrait' : null;
    // if (!backgroundFeature.force_added) {
    //   count += 1;
      if (
        feature.is_visible_for_selections
        // &&
        // feature.visible_by_prereqs
      ) {
        let deductionText = null;
        feature.selections.forEach((selection) => {
          props.selectedDeductions.forEach((deduction) => {
            if (
              deduction.selection_id === selection.id &&
              deduction.target === selection.target
            ) {
              deductionText = <div><i className="fa-solid fa-minus"></i>{` ${deduction.power.name}`}</div>;
            }
          });
        });
        return <FeaturePrompt
                key={index}
                selectedIndex={selectedIndex}
                feature={feature}
                deductionText={deductionText}
                handleFeatureClick={props.handleFeatureClick}
                featureSelections={props.featureSelections}
                auxiliary_selections={props.auxiliary_selections}
                onReadMoreClick={props.onReadMoreClick}
               />
      } else {
        return null;
      }
  });

  let status = props.hpIncrease
    ? <img className="statusIcon" src={check} alt=""/>
    : <img className="statusIcon" src={exclamation} alt=""/>;
  let die, hp;
  if (props.selectedClass) {
    die = props.selectedClass.hit_die.toString();
    hp = props.selectedClass.hp_at_higher_lvls.toString();
  }
  let hpIncrease = props.isLevelingUp
    ? <div className='auto-traits-and-descs selectableTrait'
        onClick={((e) => props.handleHpIncreaseClick(e))}
      >
        <div className='auto-trait'>
          <div>
            Increase HP
          </div>
          <div>
            {props.hpIncrease ? props.hpIncrease : null}
          </div>
          <div>
            {status}
          </div>
        </div>
        <div className='auto-trait-desc'>
          {`Increase by ${hp} or roll 1${die}`}
        </div>
      </div>
    : null;

  if (props.loading !== false) return <Loader/>;
  const loader = props.loading ? <i className="fa fa-cog fa-spin" /> : null;

  let promptStyling = props.features && props.features.length > 0
    ? 'char-prompt-details'
    : '';
  return (
    <div className={promptStyling}>
      {features}
      {hpIncrease}
      {loader}
    </div>
  );
}

export default ClassDetails;
