import React from 'react';
import { withRouter } from 'react-router-dom';
import SignInForm from '../components/SignInForm';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isChecked: false,
      loading: false
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.startSession = this.startSession.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleKeyPress(e) {
    e.preventDefault();
    if (e.key === 'Enter') {
      this.handleFormSubmit();
    }
  }

  handleFormSubmit(event=null) {
    this.props.attemptLoginSession();
    this.setState((prevState) => {
      return {
        loading: true
      };
    });
    if (event !== null) {
      event.preventDefault();
    }
    let formPayload = new FormData();
    formPayload.append('email', this.state.email);
    formPayload.append('password', this.state.password);
    this.startSession(formPayload);
  }

  async startSession(formPayload) {
    let url = "/api/v1/login";
    const response = await fetch(url, {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    });
    const json = await response.json();
    this.setState((prevState) => {
      return {
        loading: false
      };
    });
    if (json && json.status != null) {
      this.props.pop(json.msg, json.status);
    }
    if(response.status === 200) {
      this.props.history.push("/");
      await this.props.displayUser();
    } else {
      this.props.history.push("/login");
    }
  }

  render() {
    let signedIn = this.props.signedIn;

    return(
      <div className="wholePage" id="container">
        { signedIn === true ? null :
          <SignInForm
            handleKeyPress={this.handleKeyPress}
            handleSubmit={this.handleFormSubmit}
            emailContent={this.state.email}
            passwordContent={this.state.password}
            handleEmailChange={this.handleEmailChange}
            handlePasswordChange={this.handlePasswordChange}
            loading={this.state.loading}
          />
        }
      </div>
    )
  }
}

export default withRouter(Login)
