import React, {Component} from 'react';
import Notification from "../components/Notification";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      newNotifications: []
    };
    this.loadData = this.loadData.bind(this);
    this.handleClicked = this.handleClicked.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    await this.loadData();
    await this.props.displayUser();
    this.props.history.push("/notifications");
  }

  async loadData() {
    window.scrollTo(0, 0);
    let url = "/api/v1/notifications";
    const response = await fetch(url, {
      method: 'GET',
      credentials: "same-origin",
    });
    const json = await response.json();
    if (json !== null) {
      this.setState((prevState) => {
        return {
          notifications: json.notifications,
          newNotifications: json.new_notifications
        };
      });
    }
  }

  handleClicked(e, is_approved, notification) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('is_approved', is_approved);
    fetch(`/api/v1/notification/${notification}`, {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200 && is_approved) {
        this.props.pop(res.body.msg, res.body.status);
        this.props.history.push("/campaigns/"+res.body.campaign_id);
      } else {
        this.props.history.push("/notifications");
        this.loadData();
      }
    }));
  }

  handleDelete(e, notification) {
    e.preventDefault();
    fetch(`/api/v1/notification/${notification}`, {
      method: 'DELETE',
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.props.history.push("/notifications");
        this.loadData();
      }
    }));
  }

  render() {
    let notifications = this.state.notifications.map((notification, index) => {
      return <Notification
              key={index}
              notification={notification}
              handleClick={this.handleClicked}
              handleDelete={this.handleDelete}
            />;
    });

    let newNotifications = this.state.newNotifications.map((notification, index) => {
      return <Notification
              key={index}
              notification={notification}
              handleClick={this.handleClicked}
              handleDelete={this.handleDelete}
            />;
    });

    return(
      <div className="wholePage" id="container">
        <div className="notifications-page">
          <div className="notifications-heading">
            Notifications
          </div>
          <div className="notification-read-unread">
            Unread
          </div>
          <div className="notifications-container">
            {newNotifications}
          </div>
          <div className="notification-read-unread">
            Read
          </div>
          <div className="notifications-container">
            {notifications}
          </div>
        </div>
      </div>
    )
  }
}

export default Notifications
// export default withRouter(Notifications)
