import React from 'react';

const CampaignMember = props => {

  let headerStyling = '';
  let name;
  let level;

  if (props.user) {
    name = <div className={`campaign-member-row-cell campaign-member-row-name ${headerStyling}`}>
            {props.user.username}
           </div>
  } else {
    headerStyling = 'campaign-member-row-header';
    name = <div className={`campaign-member-row-cell campaign-member-row-name ${headerStyling}`}>
            Member Name
           </div>
  }
  if (props.user) {
    let roleColor = props.user.role ? "circle-dm" : "circle-pc";
    let roleText = props.user.role ? "DM" : "PC";
    level = <div className={`campaign-member-row-cell campaign-member-row-level ${headerStyling}`}>
              <div className="campaign-member-role-circle">
                <div className={`campaign-member-role-circle-text ${roleColor}`}>
                  {roleText}
                </div>
              </div>
            </div>;
  } else {
    level = <div className={`campaign-member-row-cell campaign-member-row-level ${headerStyling}`}>
            Role
           </div>
  }

  let button = null;
  if (props.currentUser && props.user.username) {
    if (props.level === 'dm' && props.currentUser.username !== props.user.username) {
      button =  <div
                  className="campaign-member-row-button campaign-member-row-cell"
                  onClick={((e) => props.toggleChangePCPopUp(e, "remove", props.user.username))}
                >
                  <div className="campaign-member-remove-button">
                    <i className="fa-solid fa-xmark"></i>
                  </div>
                </div>;
    } else {
      button = <div className="campaign-member-row-button campaign-member-row-cell"></div>;
    }
  } else {
    button = <div className={`campaign-member-row-cell campaign-member-row-button ${headerStyling}`}>
              Remove
            </div>
  }


  return (
    <div className='campaign-member-container'>
      {name}
      {level}
      {button}
    </div>
  );
}

export default CampaignMember;
