import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
// import { createStore } from 'redux';
import * as serviceWorker from './serviceWorker';

// import { reducer } from './store'
import App from './app/containers/App';

// const store = createStore(reducer);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('app')
  )
})

serviceWorker.unregister();

// <Provider store={store}>
// </Provider>,
