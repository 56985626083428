import React from "react";
import BackgroundFeaturePrompt from "./BackgroundFeaturePrompt";
import Slider from "../../components/Slider";
import check from "../../../icons/check.png";
import exclamation from "../../../icons/exclamation.png";

const BackgroundDetails = props => {

  // let count = 0;
  let background_features = props.background.background_features.map((backgroundFeature, index) => {
    var selectedIndex = props.selectedBackgroundFeature && props.selectedBackgroundFeature.id === backgroundFeature.id ?
      'selectedTrait' : null;
    // if (!backgroundFeature.force_added) {
    //   count += 1;
      return <BackgroundFeaturePrompt
              key={index}
              // count={count}
              selectedIndex={selectedIndex}
              background={props.background}
              backgroundFeature={backgroundFeature}
              handleBackgroundFeatureClick={props.handleBackgroundFeatureClick}
              backgroundFeatureSelections={props.backgroundFeatureSelections}
              onReadMoreClick={props.onReadMoreClick}
             />
    // } else {
    //   return null;
    // }
  });

  let slider;
  if (props.background.variant_name) {
    slider = <div className='auto-traits-and-descs auto-background-variant-section-container'>
                <div className='auto-trait'>
                  <div>
                    Variant Background:
                  </div>
                </div>
                <div className='auto-trait-desc auto-background-variant-container'>
                  <div className='auto-background-variant-name'>{props.background.variant_name}</div>
                  <div className="auto-background-slider">
                    <Slider public={props.isAlternateBackgroundChecked} togglePublicPopUp={props.handleVariantBackgroundToggle}/>
                  </div>
                </div>
              </div>
  }


  let statusPersonality = props.selectedPersonality
    ? <img className="statusIcon" src={check} alt=""/>
    : <img className="statusIcon" src={exclamation} alt=""/>;
  let statusIdeal = props.selectedIdeal
    ? <img className="statusIcon" src={check} alt=""/>
    : <img className="statusIcon" src={exclamation} alt=""/>;
  let statusBond = props.selectedBond
    ? <img className="statusIcon" src={check} alt=""/>
    : <img className="statusIcon" src={exclamation} alt=""/>;
  let statusFlaw = props.selectedFlaw
    ? <img className="statusIcon" src={check} alt=""/>
    : <img className="statusIcon" src={exclamation} alt=""/>;
  let statusCharacteristic = props.selectedCharacteristic
    ? <img className="statusIcon" src={check} alt=""/>
    : <img className="statusIcon" src={exclamation} alt=""/>;

  let background_personalities =  <div className={`auto-traits-and-descs ${props.isPersonalitySelected ? 'selectedTrait' : ''}`}
                                    onClick={((e) => props.handleBackgroundCharacteristicClick(e, 'personality'))}
                                  >
                                    <div className='auto-trait'>
                                      <div>
                                        Personality
                                      </div>
                                      <div>
                                        {statusPersonality}
                                      </div>
                                    </div>
                                    <div className='auto-trait-desc'>
                                    </div>
                                  </div>
  let background_ideals = <div className={`auto-traits-and-descs ${props.isIdealSelected ? 'selectedTrait' : ''}`}
                            onClick={((e) => props.handleBackgroundCharacteristicClick(e, 'ideal'))}
                          >
                            <div className='auto-trait'>
                              <div>
                                Ideal
                              </div>
                              <div>
                                {statusIdeal}
                              </div>
                            </div>
                            <div className='auto-trait-desc'>
                            </div>
                          </div>
  let background_bonds = <div className={`auto-traits-and-descs ${props.isBondSelected ? 'selectedTrait' : ''}`}
              onClick={((e) => props.handleBackgroundCharacteristicClick(e, 'bond'))}
            >
              <div className='auto-trait'>
                <div>
                  Bond
                </div>
                <div>
                  {statusBond}
                </div>
              </div>
              <div className='auto-trait-desc'>
              </div>
            </div>
  let background_flaws = <div className={`auto-traits-and-descs ${props.isFlawSelected ? 'selectedTrait' : ''}`}
                            onClick={((e) => props.handleBackgroundCharacteristicClick(e, 'flaw'))}
                          >
                            <div className='auto-trait'>
                              <div>
                                Flaw
                              </div>
                              <div>
                                {statusFlaw}
                              </div>
                            </div>
                            <div className='auto-trait-desc'>
                            </div>
                          </div>
  let background_characteristics;
  if (props.background.characteristics.length > 0) {
    background_characteristics = <div className={`auto-traits-and-descs ${props.isCharacteristicSelected ? 'selectedTrait' : ''}`}
                                      onClick={((e) => props.handleBackgroundCharacteristicClick(e, 'characteristic'))}
                                    >
                                      <div className='auto-trait'>
                                        <div>
                                          Characteristic
                                        </div>
                                        <div>
                                          {statusCharacteristic}
                                        </div>
                                      </div>
                                      <div className='auto-trait-desc'>
                                      </div>
                                    </div>
  }

  return (
    <div className=''>
      {background_features}
      {background_personalities}
      {background_ideals}
      {background_bonds}
      {background_flaws}
      {background_characteristics}
      {slider}
    </div>
  );
}

export default BackgroundDetails;
