import React from 'react';

const CampaignQualityPopUp = props => {

  let name, desc;
  if (props.details) {
    // console.log(props.details);
    name = props.name;
    let header = props.details.header_text
      ? <div className="story-mode-header-text-bullet">
          {props.details.header_text}
        </div>
      : null;
    desc =  <div className="campaign-detail-popup-desc">
              {header}
              <div className="">
                {props.details.desc}
              </div>
             </div>;
  }

  return(
    <div className='campaign-detail-popup-details-container'>
      <div className='campaign-detail-popup-section-container'>
        <div className='campaign-detail-popup-section-container-inner'>
          <div className='campaign-detail-popup-name'>
            {name}
          </div>
          <div className='campaign-detail-popup-primary-details campaign-popup-descs'>
            {desc}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignQualityPopUp
