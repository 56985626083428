import React from 'react';
import { Link } from 'react-router-dom';
import exclamation from "../../icons/exclamation.png";

const UserDropdown = props => {

  let unreadElement = props.user.is_unread
    ? <img className="unread-notifications-icon" src={exclamation} alt=""/>
    : null

  return(
    <div className="user-dropdown">
      <Link
        className="user-dropdown-item"
        to={"/profile/" + props.user.id}
        onClick={props.handleClick}
      >
        Profile
      </Link>
      <Link
        className="user-dropdown-item user-drop-down-notification"
        to={"/notifications"}
        onClick={props.handleClick}
      >
        Notifications
        {unreadElement}
      </Link>
      <Link className="user-dropdown-item" to="/" onClick={props.handleSubmit}>
        <div className="">Sign Out</div>
      </Link>
    </div>

  )
}

export default UserDropdown
