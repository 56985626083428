import React from 'react';
// import { io } from 'socket.io-client';

// import { connect } from 'react-redux';
//
// import { actions } from '../../store'
import CampaignMap from './CampaignMappingSandbox/CampaignMap';
import Loader from '../components/Loader';

const CampaignFieldView = props => {
    let mapRender = props.currentUser
      ? <div className="campaign-field-view-map-section">
          <CampaignMap
            campaign={props.campaign}
            campaignMap={props.campaignMap}
            currentUser={props.currentUser}
            allUsers={props.allUsers}
            controlsMarkers={props.controlsMarkers}
            markerHexes={props.markerHexes}
            colorHexes={props.colorHexes}
            fowHexes={props.fowHexes}
            markerTypes={props.markerTypes}
            indicatorIteration={props.indicatorIteration}
            typedNewHeight={props.typedNewHeight}
            typedNewWidth={props.typedNewWidth}
            boardSelectedMarker={props.boardSelectedMarker}
            controlsSelectedMarker={props.controlsSelectedMarker}
            hoveredMarker={props.hoveredMarker}
            dragFilledCoordinates={props.dragFilledCoordinates}
            loading={props.loading}
            socket={props.socket}
            handleTypedNewHeightChange={props.handleTypedNewHeightChange}
            handleTypedNewWidthChange={props.handleTypedNewWidthChange}
            submitNewMap={props.submitNewMap}
            closePopUp={props.closePopUp}
            handleCoordinateClick={props.handleCoordinateClick}
            handleCoordinateRightClick={props.handleCoordinateRightClick}
            handleExpandedIconClick={props.handleExpandedIconClick}
            handleExpandedIconRightClick={props.handleExpandedIconRightClick}
            handleExpandedIconHover={props.handleExpandedIconHover}
            handleExpandedIconLeaveHover={props.handleExpandedIconLeaveHover}
            handleHover={props.handleHover}
            handleLeaveHover={props.handleLeaveHover}
            handleStopFill={props.handleStopFill}
            selectHexColor={props.selectHexColor}
            selectMapMarker={props.selectMapMarker}
            selectMapIndicator={props.selectMapIndicator}
            requestNewMap={props.requestNewMap}
            createNewMarkerClick={props.createNewMarkerClick}
            handleControlMarkerRightClick={props.handleControlMarkerRightClick}
            handleNameChange={props.handleNameChange}
            handleTypedPublicNotesValueChange={props.handleTypedPublicNotesValueChange}
            handleTypedPrivateNotesValueChange={props.handleTypedPrivateNotesValueChange}
            handleUserInclusionClick={props.handleUserInclusionClick}
            handleMarkerTypeClick={props.handleMarkerTypeClick}
            toggleDeleteMarker={props.toggleDeleteMarker}
            toggleDeleteControlMarker={props.toggleDeleteControlMarker}
            toggleHideMarker={props.toggleHideMarker}
            setStateToMarkerSettings={props.setStateToMarkerSettings}
            deleteControlsMarker={props.deleteControlsMarker}
            handlePopUpSave={props.handlePopUpSave}
            selectedColor={props.selectedColor}
            controlsSelectedIndicator={props.controlsSelectedIndicator}
            rightClickedMarker={props.rightClickedMarker}
            isMenuPoppedUp={props.isMenuPoppedUp}
            isNewMapRequestPoppedUp={props.isNewMapRequestPoppedUp}
            isNewMarkerRequestPoppedUp={props.isNewMarkerRequestPoppedUp}
            isMultiMarkerMenuPoppedUp={props.isMultiMarkerMenuPoppedUp}
            nameContent={props.nameContent}
            typedPublicNotesValue={props.typedPublicNotesValue}
            typedPrivateNotesValue={props.typedPrivateNotesValue}
            selectedMenuHex={props.selectedMenuHex}
            isDeleteToggled={props.isDeleteToggled}
            isDeleteControlToggled={props.isDeleteControlToggled}
            isHideToggled={props.isHideToggled}
            isColorOptionsMenuPoppedUp={props.isColorOptionsMenuPoppedUp}
            awareUsers={props.awareUsers}
            markerType={props.markerType}
          />
        </div>
      : <div className="campaign-char-sheet-characters-wrapper">
          <div className="campaign-char-sheet-characters-alt">
            <div className="campaign-char-sheet-characters-heading-alt">
              Only members of this campaign can access this information.
            </div>
          </div>
        </div>;

    if (props.loading !== false) return <Loader/>;
    const loader = props.loading ? <i className="fa fa-cog fa-spin" /> : null;


    return (
      <div className="campaign-field-view-container">
        {loader}
        {mapRender}
      </div>
    )
  }

  export default CampaignFieldView


// class CampaignFieldView extends Component {
//
//   constructor(props) {
//     super(props);
//     this.state = {
//       // campaignMap: null,
//       // controlsMarkers: [],
//       // colorHexes: [],
//       // markerHexes: [],
//       // markerTypes: [],
//       // loading: true
//     }
//     // this.connectToSocket = this.connectToSocket.bind(this);
//     // this.getMapDetails = this.getMapDetails.bind(this);
//   }

  // componentDidMount() {
    // this.connectToSocket();
    //
    // socket.on("connect", () => {
    //   console.log("attempting to join a room")
    //   socket.emit('join', { campaignId: this.props.campaign.id });
    // });
    //
    // socket.on('reconnect', (attemptNumber) => {
    //   console.log('Reconnected on attempt: ', attemptNumber);
    //   socket.emit('join', { campaignId: this.props.campaign.id });
    // });
    //
    // socket.on('connect_error', (error) => {
    //   console.error('Connection error:', error);
    // });
    //
    // socket.on('error', (error) => {
    //   console.error('Socket error:', error);
    // });
    //
    // socket.on("joined room", (data) => {
    //   console.log(data);
    //   socket.emit("get map", { campaignId: this.props.campaign.id });
    // })
    //
    // socket.on("left room", (data) => {
    //   console.log(data);
    //   console.log("left room");
    // })
    //
    // socket.on("get map", (serverData) => {
    //   let data = JSON.parse(serverData);
    //   this.setState({
    //     campaignMap: data.campaign_map,
    //     controlsMarkers: data.markers,
    //     markerHexes: data.marker_hexes,
    //     colorHexes: data.color_hexes,
    //     markerTypes: data.marker_types,
    //     loading: false
    //   });
    // })

  // }

  // componentWillUnmount() {
    // // when component unmounts, disconnect
    // console.log("closing websocket...");
    // // socket.disconnect()
    // console.log(socket);
    // socket.emit("leave", { campaignId: this.props.campaign.id });
  // }

  // connectToSocket() {
  //   let server = undefined;
  //   const domain = window.location.origin;
  //   if (window.location.hostname === 'localhost') {
  //     server = `http://${window.location.hostname}:3000`;
  //   }
  //   socket = io(server, {
  //     cors: {
  //         origin: domain,
  //         methods: ["GET", "POST"]
  //     },
  //     transports: ['websocket', 'polling'],
  //     withCredentials: true,
  //     allowEIO3: true,
  //     reconnection: true,
  //     reconnectionAttempts: 5,
  //     reconnectionDelay: 1000
  //   });
  // }

  // getMapDetails() {
  //   socket.emit("get map", { campaignId: this.props.campaign.id });
  // }

  // async componentDidMount() {
  //   await this.getMapDetails();
  // }
  //
  // async getMapDetails() {
  //   const response = await fetch(`api/v1/campaign_map_details/${this.props.campaign.id}`, {
  //         credentials: 'same-origin',
  //         method: 'POST',
  //         headers: { 'Content-Type':'application/json'}
  //       });
  //   const json = await response.json();
  //   if(response.status === 200) {
  //     this.setState({
  //       campaignMap: json.campaign_map,
  //       controlsMarkers: json.markers,
  //       markerHexes: json.marker_hexes,
  //       colorHexes: json.color_hexes,
  //       markerTypes: json.marker_types,
  //       loading: false
  //     });
  //   } else {
  //     this.setState({
  //       campaignMap: json.campaign_map,
  //       controlsMarkers: [],
  //       markerHexes: [],
  //       colorHexes: [],
  //       markerTypes: [],
  //       loading: false
  //     });
  //   }
  // }


//   render() {
//     let mapRender = props.currentUser
//       ? <div className="campaign-field-view-map-section">
//           <CampaignMap
//             campaign={props.campaign}
//             campaignMap={props.campaignMap}
//             currentUser={props.currentUser}
//             allUsers={props.allUsers}
//             controlsMarkers={props.controlsMarkers}
//             markerHexes={props.markerHexes}
//             colorHexes={props.colorHexes}
//             markerTypes={props.markerTypes}
//             socket={props.socket}
//           />
//         </div>
//       : <div className="campaign-char-sheet-characters-wrapper">
//           <div className="campaign-char-sheet-characters-alt">
//             <div className="campaign-char-sheet-characters-heading-alt">
//               Only members of this campaign can access this information.
//             </div>
//           </div>
//         </div>;
//
//
//     if (props.loading !== false) return <Loader/>;
//     const loader = this.state.loading ? <i className="fa fa-cog fa-spin" /> : null;
//     return (
//       <div className="campaign-field-view-container">
//         {loader}
//         {mapRender}
//       </div>
//     )
//   }
// }
//
// export default CampaignFieldView

// getMapDetails={this.getMapDetails}

//   async componentDidMount() {
//     await this.props.getMapDetails(this.props.campaign.id);
//   }
//
//   render() {
//     if (this.props.loading !== false) return <Loader/>;
//     const loader = this.props.loading ? <i className="fa fa-cog fa-spin" /> : null;
//
//     return(
//       <div className="campaign-field-view-container">
//         {loader}
//         <div className="campaign-field-view-map-section">
//           <CampaignMap
//             campaign={this.props.campaign}
//             campaignMap={this.props.campaignMap}
//             controlsMarkers={this.props.controlsMarkers}
//             markerHexes={this.props.markerHexes}
//             colorHexes={this.props.colorHexes}
//           />
//         </div>
//       </div>
//     )
//   }
// }
//
// function mapStateToProps(state) {
//   return {
//     campaignMap: state.campaignMap,
//     colorHexes: state.colorHexes,
//     controlsMarkers: state.controlsMarkers,
//     markerHexes: state.markerHexes,
//     loading: state.loading
//   };
// }
//
// function mapDispatchToProps(dispatch) {
//   return {
//     async getMapDetails(campaignId) {
//       const response = await fetch(`api/v1/campaign_map_details/${campaignId}`, {
//             credentials: 'same-origin',
//             method: 'POST',
//             headers: { 'Content-Type':'application/json'}
//           });
//       const json = await response.json();
//       const mapDetails = response.status === 200 ?
//         {
//           campaignMap: json.campaign_map,
//           controlsMarkers: json.markers,
//           markerHexes: json.marker_hexes,
//           colorHexes: json.color_hexes,
//           loading: false
//         } : {
//           campaignMap: null,
//           controlsMarkers: [],
//           markerHexes: [],
//           colorHexes: [],
//           loading: false
//         };
//       dispatch(actions.getMapDetails(mapDetails));
//     }
//   }
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(CampaignFieldView);
