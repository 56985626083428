import React, { Component } from 'react';

class PlayerPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      users: [],
      selectedUser: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePlayerChoice = this.handlePlayerChoice.bind(this);
  }

  handleChange(event) {
    this.setState({searchTerm: event.target.value});
    let formPayload = new FormData();
    formPayload.append('username', event.target.value);
    formPayload.append('users', this.props.users);
    fetch("/api/v1/search_users", {
      method: 'POST',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState({ users: res.body.usernames});
      }
    }));
  }

  handlePlayerChoice(e, username) {
    this.setState({
      selectedUser: username,
      users: [],
      searchTerm: username
    });
  }

  render() {
    let renderedResults;
    if (this.state.users.length > 0) {
      const results = this.state.users.map((username, index) => {
        return  <div className="pop-up-clickable-result" onClick={((e) => this.handlePlayerChoice(e, username))} value={username} key={index}>
                  {username}
                </div>
      })
      renderedResults = <div className="pop-up-clickable-results">
                          {results}
                        </div>
    } else {
      renderedResults = null;
    }

    let value = {};
    if (this.props.actionType) {
      value.actionType = this.props.actionType;
    }
    if (this.state.selectedUser) {
      value.username = this.state.selectedUser;
    }

    let message;
    if (this.props.message) {
      message = <div className="pop-up-heading">
                  {this.props.message}
                </div>
    }

    return (
      <div className='pop-up'>
        <div className='pop-up-inner'>
          {message}
          <form className='pop-up-form'>
              <label className="pop-up-field">
                <input className="field" type='text' onChange={this.handleChange} value={this.state.searchTerm || ""} placeholder={'Start typing username'}/>
              </label>
              {renderedResults}
            <div className="pop-up-buttons-container">
              <div className="pop-up-button-generic pop-up-yes" type='submit' onClick={((e) => this.props.onSubmit(e, value))}>Submit</div>
              <div className="pop-up-button-generic pop-up-no" type='submit' onClick={this.props.closePopUp}>Cancel</div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default PlayerPopUp
