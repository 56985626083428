import React from 'react';
import CampaignMapCoordinate from './CampaignMapCoordinate';
import CampaignMapControls from './CampaignMapControls';
import CampaignMapMarkerPopUp from './CampaignMapMarkerPopUp';
import Loader from '../../components/Loader';

const CampaignMap = props => {

// class CampaignMap extends Component {

  // constructor(props) {
  //   super(props);
  //   props.state = {
      // campaignMap: props.campaignMap,
      // controlsMarkers: props.controlsMarkers,
      // selectedColor: null,
      // boardSelectedMarker: props.boardSelectedMarker,
      // controlsSelectedMarker: props.controlsSelectedMarker,
      // controlsSelectedIndicator: false,
      // indicatorIteration: props.indicatorIteration,
      // rightClickedMarker: null,
      // isMenuPoppedUp: false,
      // isNewMapRequestPoppedUp: false,
      // isNewMarkerRequestPoppedUp: false,
      // isMultiMarkerMenuPoppedUp: false,
      // nameContent: null,
      // typedPublicNotesValue: null,
      // typedPrivateNotesValue: null,
      // selectedMenuHex: null,
      // isDeleteToggled: false,
      // isDeleteControlToggled: false,
      // isHideToggled: false,
      // isColorOptionsMenuPoppedUp: false,
      // hoveredMarker: props.hoveredMarker,
      // dragFilledCoordinates: props.dragFilledCoordinates,
      // typedNewHeight: props.typedNewHeight,
      // typedNewWidth: props.typedNewWidth,
      // awareUsers: [],
      // markerType: null,
      // loading: false
    // }
    // props.selectHexColor = props.selectHexColor.bind(props);
    // props.selectMapMarker = props.selectMapMarker.bind(props);
    // props.selectMapIndicator = props.selectMapIndicator.bind(props);
    // props.requestNewMap = props.requestNewMap.bind(props);
    // props.submitNewMap = props.submitNewMap.bind(props);
    // props.createNewMarkerClick = props.createNewMarkerClick.bind(props);
    // props.deleteControlsMarker = props.deleteControlsMarker.bind(props);
    // props.closePopUp = props.closePopUp.bind(props);
    // props.handlePopUpSave = props.handlePopUpSave.bind(props);
    // props.handleHover = props.handleHover.bind(props);
    // props.handleLeaveHover = props.handleLeaveHover.bind(props);
    // props.handleStopFill = props.handleStopFill.bind(props);
    // props.handleCoordinateClick = props.handleCoordinateClick.bind(props);
    // props.handleCoordinateRightClick = props.handleCoordinateRightClick.bind(props);
    // props.handleControlMarkerRightClick = props.handleControlMarkerRightClick.bind(props);
    // props.handleExpandedIconClick = props.handleExpandedIconClick.bind(props);
    // props.handleExpandedIconRightClick = props.handleExpandedIconRightClick.bind(props);
    // props.handleExpandedIconHover = props.handleExpandedIconHover.bind(props);
    // props.handleExpandedIconLeaveHover = props.handleExpandedIconLeaveHover.bind(props);
    // props.handleNameChange = props.handleNameChange.bind(props);
    // props.handleTypedPublicNotesValueChange = props.handleTypedPublicNotesValueChange.bind(props);
    // props.handleTypedPrivateNotesValueChange = props.handleTypedPrivateNotesValueChange.bind(props);
    // props.handleTypedNewHeightChange = props.handleTypedNewHeightChange.bind(props);
    // props.handleTypedNewWidthChange = props.handleTypedNewWidthChange.bind(props);
    // props.handleUserInclusionClick = props.handleUserInclusionClick.bind(props);
    // props.handleMarkerTypeClick = props.handleMarkerTypeClick.bind(props);
    // props.toggleDeleteMarker = props.toggleDeleteMarker.bind(props);
    // props.toggleDeleteControlMarker = props.toggleDeleteControlMarker.bind(props);
    // props.toggleHideMarker = props.toggleHideMarker.bind(props);
    // props.setStateToMarkerSettings = props.setStateToMarkerSettings.bind(props);
    // props.createNewMarker = props.createNewMarker.bind(props);
    // props.handleMarkerColorClick = props.handleMarkerColorClick.bind(props);
    // props.handleMarkerColorPopUpClick = props.handleMarkerColorPopUpClick.bind(props);
    // props.checkIfPermitted = props.checkIfPermitted.bind(props);
  // }

  // static getDerivedStateFromProps(props) {
  //   return {
  //     boardSelectedMarker: props.boardSelectedMarker,
  //     controlsSelectedMarker: props.controlsSelectedMarker,
  //     indicatorIteration: props.indicatorIteration,
  //     hoveredMarker: props.hoveredMarker,
  //     dragFilledCoordinates: props.dragFilledCoordinates,
  //     typedNewHeight: props.typedNewHeight,
  //     typedNewWidth: props.typedNewWidth,
  //   };
  // }

  // componentDidMount() {
    // props.socket.on("update map", (serverData) => {
    //   let data = JSON.parse(serverData);
    //   props.setState((prevState) => {
    //     return {
    //       campaignMap: data.campaign_map,
    //       controlsMarkers: data.markers,
    //       loading: false,
    //       typedNewHeight: "",
    //       typedNewWidth: ""};
    //   });
    // })
    //
    // // "map placement marker" ws event returns to props websocket
    // props.socket.on("map placement marker", (serverData) => {
    //   let data = JSON.parse(serverData);
    //   if (data.type === 'new_marker') {
    //     props.setState((prevState) => {
    //       if (data.affected_coordinates && data.affected_coordinates.length > 0) {
    //         const campaignMap = {...prevState.campaignMap};
    //         campaignMap.coordinates.forEach((coordinate, index) => {
    //           data.affected_coordinates.forEach((affected_coordinate) => {
    //             if (coordinate.id === affected_coordinate.id) {
    //               campaignMap.coordinates[index] = affected_coordinate;
    //             }
    //           });
    //         });
    //         return { campaignMap, controlsMarkers: data.controls_markers };
    //       } else {
    //         return { controlsMarkers: data.controls_markers };
    //       }
    //     });
    //   } else if (data.type === 'place_marker') {
    //     props.setState((prevState) => {
    //       const campaignMap = {...prevState.campaignMap};
    //       campaignMap.coordinates.forEach((coordinate, index) => {
    //         data.affected_coordinates.forEach((affected_coordinate) => {
    //           if (coordinate.id === affected_coordinate.id) {
    //             campaignMap.coordinates[index] = affected_coordinate;
    //           }
    //         });
    //       });
    //       return { campaignMap, controlsMarkers: data.controls_markers, controlsSelectedMarker: null };
    //     });
    //   } else if (data.type === 'move_marker') {
    //     props.setState((prevState) => {
    //       const campaignMap = {...prevState.campaignMap};
    //       const indicatorIteration = prevState.indicatorIteration + 1
    //       campaignMap.coordinates.forEach((coordinate, index) => {
    //         data.affected_coordinates.forEach((affected_coordinate) => {
    //           if (coordinate.id === affected_coordinate.id) {
    //             campaignMap.coordinates[index] = affected_coordinate;
    //           }
    //         });
    //         if (props.state.boardSelectedMarker && coordinate.id === props.state.boardSelectedMarker.map_coordinate_id) {
    //           coordinate.indicatorIteration = indicatorIteration;
    //         }
    //       });
    //       return { campaignMap, boardSelectedMarker: null, indicatorIteration };
    //     });
    //   }
    // })
    //
    // // "update marker", "delete coord marker", and "delete map marker"
    // // ws events all return to props websocket
    // props.socket.on("marker updates", (serverData) => {
    //   let data = JSON.parse(serverData);
    //   props.setState((prevState) => {
    //     if (data.affected_coordinates && data.affected_coordinates.length > 0) {
    //       const campaignMap = {...prevState.campaignMap};
    //       campaignMap.coordinates.forEach((coordinate, index) => {
    //         data.affected_coordinates.forEach((affected_coordinate) => {
    //           if (coordinate.id === affected_coordinate.id) {
    //             campaignMap.coordinates[index] = affected_coordinate;
    //           }
    //         });
    //       });
    //       return { campaignMap, controlsMarkers: data.controls_markers };
    //     } else {
    //       return { controlsMarkers: data.controls_markers };
    //     }
    //   });
    // })
    //
    //
    // props.socket.on("update coordinate", (serverData) => {
    //   let data = JSON.parse(serverData);
    //   props.setState((prevState) => {
    //     const campaignMap = {...prevState.campaignMap};
    //     const dragFilledCoordinates = [...prevState.dragFilledCoordinates];
    //     campaignMap.coordinates.forEach((coordinate, index) => {
    //       data.affected_coordinates.forEach((affected_coordinate) => {
    //         if (coordinate.id === affected_coordinate.id) {
    //           campaignMap.coordinates[index].color_hex_code = affected_coordinate.color_hex_code;
    //         }
    //       });
    //     });
    //     dragFilledCoordinates.push(data.coord_id)
    //     return { campaignMap, dragFilledCoordinates: dragFilledCoordinates, hoveredMarker: null };
    //   });
    // })
    //
    // props.socket.on("show map indicator", (serverData) => {
    //   props.setState((prevState) => {
    //     const campaignMap = {...prevState.campaignMap};
    //     const indicatorIteration = prevState.indicatorIteration + 1
    //     campaignMap.coordinates.forEach((coordinate, index) => {
    //       if (coordinate.id === serverData) {
    //         coordinate.indicatorIteration = indicatorIteration;
    //         coordinate.indicator = true;
    //       }
    //       else if (coordinate.indicator) {
    //         coordinate.indicator = false;
    //       }
    //     });
    //     return { campaignMap, indicatorIteration };
    //   });
    // })
  // }

  // checkIfPermitted() {
  //   return props.currentUser && props.currentUser.role ? true : false;
  // }

  // handleTypedPublicNotesValueChange(e) {
  //   e.preventDefault();
  //   const val = e.target.value;
  //   if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 300) {
  //     props.setState((prevState) => {
  //       return {
  //         typedPublicNotesValue: val
  //       };
  //     });
  //   }
  // }
  //
  // handleTypedPrivateNotesValueChange(e) {
  //   e.preventDefault();
  //   const val = e.target.value;
  //   if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 300) {
  //     props.setState((prevState) => {
  //       return {
  //         typedPrivateNotesValue: val
  //       };
  //     });
  //   }
  // }
  //
  // handleNameChange(e) {
  //   e.preventDefault();
  //   props.setState({ nameContent: e.target.value });
  // }
  //
  // selectHexColor(e, hex) {
  //   if(props.currentUser.role) {
  //     if (props.state.selectedColor && hex.code === props.state.selectedColor.code) {
  //       props.setState({
  //         selectedColor: null
  //       });
  //     } else {
  //       props.setState({
  //         selectedColor: hex
  //       });
  //     }
  //   }
  // }
  //
  // selectMapMarker(e, marker) {
  //   e.preventDefault();
  //   if (
  //     marker.map_coordinate_id === null &&
  //     (
  //       props.currentUser.role ||
  //       (marker.character && props.currentUser.id === marker.character.creator_id)
  //     )
  //   ) {
  //     if (props.state.controlsSelectedMarker && marker.id === props.state.controlsSelectedMarker.id) {
  //       props.setState({
  //         controlsSelectedMarker: null
  //       });
  //     } else {
  //       props.setState({
  //         controlsSelectedMarker: marker,
  //         controlsSelectedIndicator: false
  //       });
  //     }
  //   }
  // }
  //
  // selectMapIndicator(e) {
  //   e.preventDefault();
  //   if (props.state.controlsSelectedIndicator) {
  //     props.setState({
  //       controlsSelectedIndicator: false
  //     });
  //   } else {
  //     props.setState({
  //       controlsSelectedMarker: null,
  //       controlsSelectedIndicator: true
  //     });
  //   }
  // }
  //
  // requestNewMap(e) {
  //   e.preventDefault();
  //   props.closePopUp();
  //   props.setState({
  //     isNewMapRequestPoppedUp: true
  //   });
  // }
  //
  // createNewMarkerClick(e) {
  //   e.preventDefault();
  //   props.closePopUp();
  //   props.setState({
  //     isNewMarkerRequestPoppedUp: true
  //   });
  // }

  // createNewMarker(e, marker=null) {
  //   e.preventDefault();
  //   let formPayload = new FormData();
  //   formPayload.append('name', props.state.nameContent);
  //   formPayload.append('public_notes', props.state.typedPublicNotesValue);
  //   formPayload.append('private_notes', props.state.typedPrivateNotesValue);
  //   formPayload.append('selected_hex', props.state.selectedMenuHex);
  //   let url = `/api/v1/campaign_map/${props.state.campaignMap.id}/outright_marker`;
  //   // const response = await fetch(url, { method: 'POST', body: formPayload, credentials: 'same-origin'});
  //
  //   fetch(url, {
  //     method: 'POST',
  //     body: formPayload,
  //     credentials: 'same-origin'
  //   })
  //   .then(response =>
  //     response.json().then(body => ({
  //       body: body,
  //       status: response.status
  //     })
  //   ).then(res => {
  //     if (res.status === 200) {
  //       props.setState((prevState) => {
  //         return {
  //           controlsMarkers: res.body.controls_markers
  //         };
  //       });
  //     } else {
  //       console.log('Problem Creating Marker')
  //     }
  //   }));
  //   props.closePopUp();
  //   props.getMapDetails();
  //
  //   // const json = await response.json();
  //   // props.setState((prevState) => {
  //   //   return {
  //   //     controlsMarkers: json.controls_markers
  //   //   };
  //   // });
  //   // props.closePopUp();
  // }

  // async deleteControlsMarker(e, marker) {
  //   e.preventDefault();
  //   if(props.currentUser.role) {
  //     props.socket.emit("delete map marker", {
  //       campaignId: props.campaign.id,
  //       mapId: props.state.campaignMap.id,
  //       markerId: marker.id,
  //     });
  //     props.closePopUp();
  //   }
  // }
  //
  // async submitNewMap(e) {
  //   e.preventDefault();
  //   props.setState((prevState) => {
  //     return {
  //       isNewMapRequestPoppedUp: false,
  //       loading: true
  //     };
  //   });
  //   let campaignId = props.campaign ? props.campaign.id : null;
  //   if (campaignId === null) {
  //     props.setState((prevState) => {
  //       return {
  //         loading: false
  //       };
  //     });
  //     return;
  //   }
  //   props.socket.emit("update map", {
  //     campaignId: campaignId,
  //     rows: props.state.typedNewHeight,
  //     columns: props.state.typedNewWidth,
  //   });
  //   props.closePopUp();
  // }
  //
  // closePopUp(e = null) {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   props.setState((prevState) => {
  //     return {
  //       isMenuPoppedUp: false,
  //       isNewMapRequestPoppedUp: false,
  //       isNewMarkerRequestPoppedUp: false,
  //       nameContent: null,
  //       typedPublicNotesValue: null,
  //       typedPrivateNotesValue: null,
  //       selectedMenuHex: null,
  //       isDeleteToggled: false,
  //       isDeleteControlToggled: false,
  //       isColorOptionsMenuPoppedUp: false,
  //       rightClickedMarker: null,
  //       hoveredMarker: null,
  //       dragFilledCoordinates: [],
  //       awareUsers: [],
  //       markerType: null,
  //       boardSelectedMarker: null,
  //       controlsSelectedMarker: null,
  //     }
  //   });
  // }
  //
  // async handlePopUpSave(e = null, marker) {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   if(props.currentUser.role) {
  //     // Updating marker details or removing from map
  //     let requestType = props.state.isDeleteToggled || props.state.isDeleteControlToggled ? 'delete coord marker' : 'update marker';
  //     let markerId, coordinateId, type;
  //     if (marker) {
  //       markerId = marker.id;
  //       if (marker.map_coordinate_id !== null) {
  //         coordinateId = marker.map_coordinate_id;
  //       } else {
  //         coordinateId = null;
  //       }
  //     } else {
  //       requestType = 'map placement marker';
  //       type = 'new_marker';
  //       markerId = null;
  //       coordinateId = null;
  //     }
  //     props.socket.emit(requestType, {
  //       campaignId: props.campaign.id,
  //       mapId: props.state.campaignMap.id,
  //       type: type,
  //       markerId: markerId,
  //       coordinateId: coordinateId,
  //       origCoordinateId: null,
  //       destCoordinateId: null,
  //       name: props.state.nameContent,
  //       isDeleteToggled: props.state.isDeleteToggled,
  //       isDeleteControlToggled: props.state.isDeleteControlToggled,
  //       publicNotes: props.state.typedPublicNotesValue,
  //       privateNotes: props.state.typedPrivateNotesValue,
  //       isHiding: props.state.isHideToggled,
  //       markerType: props.state.markerType,
  //       awareUsers: JSON.stringify(props.state.awareUsers)
  //     });
  //     props.closePopUp();
  //   }
  // }

  // async handleCoordinateClick(e, coord) { // handles when coordinates are clicked
  //   e.preventDefault();
  //   e = e || window.event;
  //
  //   // consider also using ("which" in e) (values are 1 & 3 instead) for browsers: Gecko (Firefox), WebKit (Safari/Chrome), IE & Opera
  //   // if ("button" in e && coordinate) {
  //   //  if (e.button === 0) {
  //   if (coord) {
  //     let coordMarker;
  //     let coordMarkersLength = 0;
  //     // DO PERMISSIONS HERE - it is possible that what may be multi-markers
  //     // on a coord for DM will be just one marker for PC
  //     if (coord.markers) {
  //       coordMarkersLength = coord.markers.length;
  //       coord.markers.forEach((marker) => {
  //         if (['PC','NPC','Enemy'].includes(marker.marker_type.name)) {
  //           coordMarker = marker;
  //         }
  //       });
  //     }
  //     // ======= before anything - close any open menus if a non-menu element was clicked on (a coordinate) =======
  //     if (props.state.isMenuPoppedUp || props.state.isMultiMarkerMenuPoppedUp) {
  //       props.setState((prevState) => {
  //         return {
  //           isMenuPoppedUp: false,
  //           rightClickedMarker: null,
  //           isMultiMarkerMenuPoppedUp: false
  //         };
  //       });
  //     }
  //     // ======= create an indicator on map coordinate first - if indicator is selected =======
  //     if (props.state.controlsSelectedIndicator) {
  //       props.socket.emit("map ping", {
  //         campaignId: props.campaign.id,
  //         coordId: coord.id
  //       });
  //     }
  //     // ======= move board-selected marker to new space =======
  //     // MAKE SURE THAT THIS CHECK IS ALSO IN THE API (PC NPC or ENEMY)
  //     else if (
  //       props.state.boardSelectedMarker &&
  //       props.state.boardSelectedMarker.map_coordinate_id !== coord.id &&
  //       (
  //         props.currentUser.role ||
  //         (
  //           !props.currentUser.role &&
  //           props.state.boardSelectedMarker.marker_type.name === 'PC' &&
  //           props.state.boardSelectedMarker.character &&
  //           props.state.boardSelectedMarker.character.creator_id === props.currentUser.id
  //         )
  //       ) &&
  //       (
  //         !coordMarker ||
  //         (
  //           coordMarker &&
  //           !['PC','NPC','Enemy'].includes(props.state.boardSelectedMarker.marker_type.name)
  //         )
  //       )
  //     ) {
  //       props.socket.emit("map placement marker", {
  //         campaignId: props.campaign.id,
  //         mapId: props.state.campaignMap.id,
  //         type: "move_marker",
  //         markerId: props.state.boardSelectedMarker.id,
  //         markerType: props.state.markerType,
  //         origCoordinateId: props.state.boardSelectedMarker.map_coordinate_id,
  //         destCoordinateId: coord.id,
  //         name: null,
  //         publicNotes: null,
  //         privateNotes: null,
  //         isHiding: null,
  //         awareUsers: null,
  //       });
  //       props.setState((prevState) => { return { boardSelectedMarker: null };});
  //     }
  //     // ======= place controls-selected marker on unoccupied space =======
  //     else if (
  //       props.state.controlsSelectedMarker &&
  //       (
  //         !coordMarker ||
  //         (
  //           coordMarker &&
  //           !['PC','NPC','Enemy'].includes(props.state.controlsSelectedMarker.marker_type.name)
  //         )
  //       )
  //     ) {
  //       if (!props.state.controlsSelectedMarker.coordinate) {
  //         if (
  //           props.currentUser.role ||
  //           (props.state.controlsSelectedMarker.character && props.currentUser.id === props.state.controlsSelectedMarker.character.creator_id)
  //         ) {
  //           props.socket.emit("map placement marker", {
  //             campaignId: props.campaign.id,
  //             mapId: props.state.campaignMap.id,
  //             type: "place_marker",
  //             markerId: props.state.controlsSelectedMarker.id,
  //             markerType: props.state.markerType,
  //             origCoordinateId: null,
  //             destCoordinateId: coord.id,
  //             name: null,
  //             publicNotes: null,
  //             privateNotes: null,
  //             isHiding: null,
  //             awareUsers: null,
  //           });
  //           props.setState((prevState) => { return { controlsSelectedMarker: null };});
  //         }
  //       }
  //     }
  //     // ======= when there are multiple coord marker, show available markers =======
  //     else if (coordMarkersLength > 1) {
  //       // close pop up if it is open
  //       if (props.state.isMultiMarkerMenuPoppedUp) {
  //         props.setState((prevState) => {
  //           return {
  //             isMultiMarkerMenuPoppedUp: false
  //           };
  //         });
  //       }
  //       // pop up a mini menu of icons
  //       else {
  //         props.setState((prevState) => {
  //           return {
  //             isMultiMarkerMenuPoppedUp: true
  //           };
  //         });
  //       }
  //     }
  //     // ======= when there is a marker in a coord, select a marker when nothing is selected =======
  //     else if (coordMarkersLength === 1) {
  //       // unselect currently-selected marker
  //       if (
  //         props.state.boardSelectedMarker &&
  //         props.state.boardSelectedMarker.id === coord.markers[0].id &&
  //         (
  //           props.currentUser.role ||
  //           (
  //             props.state.boardSelectedMarker.character &&
  //             props.state.boardSelectedMarker.character.creator_id === props.currentUser.id
  //           )
  //         )
  //       ) {
  //         props.setState((prevState) => {
  //           return {
  //             boardSelectedMarker: null,
  //             controlsSelectedMarker: null
  //           };
  //         });
  //       }
  //       // select a newly clicked marker
  //       else {
  //         if (
  //           props.currentUser.role ||
  //           (
  //             coord.markers[0] &&
  //             coord.markers[0].character &&
  //             coord.markers[0].character.creator_id === props.currentUser.id
  //           )
  //         ) {
  //           props.setState((prevState) => {
  //             return {
  //               boardSelectedMarker: coord.markers[0],
  //               controlsSelectedMarker: null
  //             };
  //           });
  //         }
  //       }
  //     }
  //   }
  // }
  //
  // async handleStopFill(e) {
  //   e.preventDefault();
  //   if (props.state.dragFilledCoordinates) {
  //     props.setState({ dragFilledCoordinates: [] });
  //   }
  // }
  //
  // async handleCoordinateRightClick(e, coord) { // handles when coordinates are clicked
  //   e.preventDefault();
  //   console.log('Right clicked')
  //   // ======= right click can fill in environment with different types of boundaries if nothing fills that spot =======
  //   if (
  //     props.currentUser.role &&
  //     (
  //       !coord.markers ||
  //       (
  //         coord.markers &&
  //         coord.markers.length === 0
  //       )
  //     )
  //     && props.state.selectedColor
  //   ) {
  //     props.socket.emit("update coordinate", {
  //       campaignId: props.campaign.id,
  //       mapId: props.state.campaignMap.id,
  //       colorHex: props.state.selectedColor.code,
  //       coordinateId: coord.id,
  //     });
  //   }
  //   // ======= right click can provide a drop-down menu for a marker (and close any other previously open menus) =======
  //   else {
  //     props.closePopUp();
  //     if (coord.markers) {
  //       if (coord.markers.length === 1) {
  //         props.setStateToMarkerSettings(coord.markers[0]);
  //         props.setState((prevState) => {
  //           return {
  //             rightClickedMarker: coord.markers[0],
  //             isMultiMarkerMenuPoppedUp: false,
  //             isMenuPoppedUp: true
  //           };
  //         });
  //       } else if (coord.markers.length > 1) {
  //         if (props.state.isMultiMarkerMenuPoppedUp) {
  //           props.setState((prevState) => {
  //             return {
  //               isMultiMarkerMenuPoppedUp: false
  //             };
  //           });
  //         } else {
  //           props.setState((prevState) => {
  //             return {
  //               isMultiMarkerMenuPoppedUp: true
  //             };
  //           });
  //         }
  //       }
  //     }
  //   }
  // }
  //
  // setStateToMarkerSettings(marker) {
  //   if (marker) {
  //     if (marker.marker_type !== null) {
  //       props.setState((prevState) => {
  //         return {
  //           markerType: marker.marker_type.id
  //         };
  //       });
  //     }
  //     if (marker.aware_users) {
  //       props.setState((prevState) => {
  //         let users = [...prevState.awareUsers];
  //         marker.aware_users.forEach((user) => {
  //           users.push(user.id);
  //         });
  //         return {
  //           awareUsers: users
  //         };
  //       });
  //     }
  //     if (marker.public_notes) {
  //       props.setState((prevState) => {
  //         return {
  //           typedPublicNotesValue: marker.public_notes
  //         };
  //       });
  //     }
  //     if (marker.private_notes) {
  //       props.setState((prevState) => {
  //         return {
  //           typedPrivateNotesValue: marker.private_notes
  //         };
  //       });
  //     }
  //     if (marker.is_hiding) {
  //       props.setState((prevState) => {
  //         return {
  //           isHideToggled: true
  //         };
  //       });
  //     } else {
  //       props.setState((prevState) => {
  //         return {
  //           isHideToggled: false
  //         };
  //       });
  //     }
  //   }
  // }
  //
  // handleControlMarkerRightClick(e, marker) {
  //   e.preventDefault();
  //   props.closePopUp();
  //   props.setStateToMarkerSettings(marker);
  //   props.setState((prevState) => {
  //     return {
  //       rightClickedMarker: marker,
  //       isMultiMarkerMenuPoppedUp: false,
  //       isMenuPoppedUp: true
  //     };
  //   });
  // }
  //
  // handleExpandedIconRightClick(e, marker) { // handles when expanded icons are clicked
  //   e.stopPropagation();
  //   e.preventDefault();
  //   props.closePopUp();
  //   props.setStateToMarkerSettings(marker);
  //   if (marker) {
  //     props.setState((prevState) => {
  //       return {
  //         rightClickedMarker: marker,
  //         isMenuPoppedUp: true
  //       };
  //     });
  //   }
  // }
  //
  //
  // handleExpandedIconClick(e, marker) {
  //   e.stopPropagation();
  //   if (
  //     props.state.boardSelectedMarker &&
  //     props.state.boardSelectedMarker.id === marker.id &&
  //     (
  //       props.currentUser.role ||
  //       (
  //         props.state.boardSelectedMarker.character &&
  //         props.state.boardSelectedMarker.character.creator_id === props.currentUser.id
  //       )
  //     )
  //   ) {
  //     props.setState((prevState) => {
  //       return {
  //         boardSelectedMarker: null,
  //         controlsSelectedMarker: null
  //       };
  //     });
  //   }
  //   // select a newly clicked marker
  //   else {
  //     if (
  //       props.currentUser.role ||
  //       (
  //         marker &&
  //         marker.character &&
  //         marker.character.creator_id === props.currentUser.id
  //       )
  //     ) {
  //       props.setState((prevState) => {
  //         return {
  //           boardSelectedMarker: marker,
  //           controlsSelectedMarker: null,
  //         };
  //       });
  //     }
  //   }
  // }
  //
  // handleExpandedIconLeaveHover(e, marker) {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   props.setState({hoveredMarker: null});
  // }
  //
  // handleExpandedIconHover(e, marker) {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   if (marker) {
  //     if (
  //       (marker.name) ||
  //       (marker.character && marker.character.name)
  //     ) {
  //       props.setState({hoveredMarker: marker});
  //     }
  //   }
  // }
  //
  // handleUserInclusionClick(e, userId) {
  //   e.preventDefault();
  //   // FUTURE FOR PERMISSIONS
  //   props.setState((prevState) => {
  //     let users = [...prevState.awareUsers];
  //     if (!users.includes(userId)) {
  //       users.push(userId)
  //     } else {
  //       users = users.filter(usr =>
  //         usr !== userId
  //       );
  //     }
  //     return {
  //       awareUsers: users,
  //     };
  //   });
  // }
  //
  // handleMarkerTypeClick(e, markerType) {
  //   e.preventDefault();
  //   // FUTURE FOR PERMISSIONS
  //   props.setState((prevState) => {
  //     return {
  //       markerType: markerType,
  //     };
  //   });
  // }
  //
  // toggleHideMarker() {
  //   props.setState({
  //     isHideToggled: !props.state.isHideToggled
  //   });
  // }
  //
  // toggleDeleteMarker() {
  //   props.setState({
  //     isDeleteToggled: !props.state.isDeleteToggled
  //   });
  // }
  //
  // toggleDeleteControlMarker() {
  //   props.setState({
  //     isDeleteControlToggled: !props.state.isDeleteControlToggled
  //   });
  // }
  //
  // // handleMarkerColorPopUpClick(e) {
  // //   e.preventDefault();
  // //   props.setState({
  // //     isColorOptionsMenuPoppedUp: true
  // //   });
  // // }
  //
  // // handleMarkerColorClick(e = null, code) {
  // //   if (e !== null) {
  // //     e.preventDefault();
  // //   }
  // //   props.setState({
  // //     isColorOptionsMenuPoppedUp: false,
  // //     selectedMenuHex: code
  // //   });
  // // }
  //
  // async handleLeaveHover(e, coord) {
  //   e.preventDefault();
  //   props.setState({hoveredMarker: null});
  // }
  //
  // async handleHover(e, coord) {
  //   e.preventDefault();
  //   let hoveredMarker = null;
  //   // DO PERMISSIONS HERE
  //   if (coord.markers && coord.markers.length === 1) {
  //     let marker = coord.markers[0];
  //     if (
  //       (marker.name) ||
  //       (marker.character && marker.character.name)
  //     ) {
  //       hoveredMarker = marker;
  //     }
  //   }
  //   if (e.button === 2 || e.buttons === 2) {
  //     hoveredMarker = null;
  //     if (
  //       !props.state.dragFilledCoordinates.includes(coord.id) &&
  //       props.state.selectedColor
  //     ) {
  //       props.socket.emit("update coordinate", {
  //         campaignId: props.campaign.id,
  //         mapId: props.state.campaignMap.id,
  //         colorHex: props.state.selectedColor.code,
  //         coordinateId: coord.id,
  //       });
  //     }
  //   }
  //   props.setState({hoveredMarker: hoveredMarker});
  // }
  //
  // handleTypedNewHeightChange(e) {
  //   e.preventDefault();
  //   if (
  //     (
  //       parseInt(e.target.value) &&
  //       parseInt(e.target.value) > 0 &&
  //       parseInt(e.target.value) <= 40
  //     ) ||
  //     e.target.value === ""
  //   ) {
  //     props.setState({
  //       typedNewHeight: e.target.value
  //     });
  //   }
  // }
  //
  // handleTypedNewWidthChange(e) {
  //   e.preventDefault();
  //   if (
  //     (
  //       parseInt(e.target.value) &&
  //       parseInt(e.target.value) > 0 &&
  //       parseInt(e.target.value) <= 40
  //     ) ||
  //     e.target.value === ""
  //   ) {
  //     props.setState({
  //       typedNewWidth: e.target.value
  //     });
  //   }
  // }

  // render() {

    // PLACEHOLDER FOR REQUESTING NEW MAP
    let newMapPopUp;
    let isUnclickable = "";
    if (
      !props.typedNewHeight ||
      !props.typedNewWidth
    ) {
      isUnclickable = 'is-unclickable';
    }
    if (props.isNewMapRequestPoppedUp) {
      newMapPopUp = <div className="pop-up">
                      <div className="new-map-pop-up pop-up-inner-confirmation">
                        <div className="new-map-warning">Warning! This will reset all work done on current map.</div>
                        <div className="new-map-hint">Tip: Choosing dimesions that stray further from a perfect square may hinder visual map experience.</div>
                        <div className="new-map-label-wrapper">
                          <div className="new-map-height">
                            <label className="campaign-detail-popup-input-field">
                              <input className="campaign-detail-popup-field" type='text' onChange={props.handleTypedNewHeightChange} value={props.typedNewHeight || ""} placeholder={'New Height (up to 40)'}/>
                            </label>
                          </div>
                          <div className="new-map-width">
                            <label className="campaign-detail-popup-input-field">
                              <input className="campaign-detail-popup-field" type='text' onChange={props.handleTypedNewWidthChange} value={props.typedNewWidth || ""} placeholder={'New Width (up to 40)'}/>
                            </label>
                          </div>
                        </div>
                        <div className="new-map-pop-up-button-container">
                          <div className={`popup-button pop-up-yes ${isUnclickable}`} onClick={props.submitNewMap}>
                            Submit
                          </div>
                          <div className="popup-button pop-up-no" type='submit' onClick={((e) => props.closePopUp(e))}>
                            Close
                          </div>
                        </div>
                      </div>
                    </div>;
    }

    let newMarkerPopUp;
    if (props.isNewMarkerRequestPoppedUp || props.rightClickedMarker) {
      newMarkerPopUp =  <CampaignMapMarkerPopUp
                          allUsers={props.allUsers}
                          currentUser={props.currentUser}
                          isNewRequest={props.isNewMarkerRequestPoppedUp}
                          rightClickedMarker={props.rightClickedMarker}
                          awareUsers={props.awareUsers}
                          markerType={props.markerType}
                          markerTypes={props.markerTypes}
                          // hex={hex}
                          // colorHexes={props.colorHexes}
                          // selectedMenuHex={props.selectedMenuHex}
                          nameContent={props.nameContent}
                          typedPublicNotesValue={props.typedPublicNotesValue}
                          typedPrivateNotesValue={props.typedPrivateNotesValue}
                          isColorOptionsMenuPoppedUp={props.isColorOptionsMenuPoppedUp}
                          isDeleteToggled={props.isDeleteToggled}
                          isDeleteControlToggled={props.isDeleteControlToggled}
                          isHideToggled={props.isHideToggled}
                          handleMarkerColorClick={props.handleMarkerColorClick}
                          handleNameChange={props.handleNameChange}
                          handleTypedPublicNotesValueChange={props.handleTypedPublicNotesValueChange}
                          handleTypedPrivateNotesValueChange={props.handleTypedPrivateNotesValueChange}
                          toggleDeleteMarker={props.toggleDeleteMarker}
                          toggleDeleteControlMarker={props.toggleDeleteControlMarker}
                          toggleHideMarker={props.toggleHideMarker}
                          handleMarkerTypeClick={props.handleMarkerTypeClick}
                          handleUserInclusionClick={props.handleUserInclusionClick}
                          // handleMarkerColorPopUpClick={props.handleMarkerColorPopUpClick}
                          onSubmit={props.handlePopUpSave}
                          closePopUp={props.closePopUp}
                        />
    }

    let campaignMapCoordinates;
    let campaignMapColumnIds;
    let campaignMapRowIds;
    let dynamicColumnStyling = {};
    let dynamicRowStyling = {};
    let dynamicMapStyling = {};
    let dynamicMapAndRowStyling = {};
    let dynamicRowCellStyling = {};
    let dynamicColumnCellStyling = {};
    if (props.campaignMap) {
      let rowSize = props.campaignMap.rows_count > 10
        ? 250 / props.campaignMap.rows_count
        : 25;
      let rowFontSize = `${rowSize}px`;
      let columnSize = props.campaignMap.columns_count > 10
        ? 250 / props.campaignMap.columns_count
        : 25;
      let columnFontSize = `${columnSize}px`;

      let heightRatio = 0;
      let widthRatio = 0;
      //do stuff to widthRatio
      if (props.campaignMap.columns_count > props.campaignMap.rows_count) {
        widthRatio = 10*(props.campaignMap.columns_count / props.campaignMap.rows_count);
      }
      //do stuff to heightRatio
      else if (props.campaignMap.columns_count < props.campaignMap.rows_count) {
        heightRatio = 10*(props.campaignMap.rows_count / props.campaignMap.columns_count);
      }

      dynamicMapStyling = {
        gridTemplateColumns: `repeat(${props.campaignMap.columns_count}, 1fr)`,
        outline: `#333 solid`,
        width: `calc(${((props.campaignMap.columns_count-1)/props.campaignMap.columns_count)*100}% - ${widthRatio}px)`
      };
      dynamicColumnStyling = {
        gridTemplateColumns: `repeat(${props.campaignMap.columns_count}, 1fr)`,
        height: `calc(${(1/props.campaignMap.rows_count)*100}% + ${heightRatio}px)`,
        marginLeft: `calc(${(1/props.campaignMap.columns_count)*100}% + ${widthRatio}px)`,
        width: `calc(${((props.campaignMap.columns_count-1)/props.campaignMap.columns_count)*100}% - ${widthRatio}px)`
      };
      dynamicRowStyling = {
        width: `calc(${(1/props.campaignMap.columns_count)*100}% + ${widthRatio}px)`,
      };
      dynamicMapAndRowStyling = {
        height: `calc(${((props.campaignMap.rows_count-1)/props.campaignMap.rows_count)*100}% - ${heightRatio}px)`,
      };
      dynamicRowCellStyling = {
        fontSize: rowFontSize,
        gridColumn: `${(1/props.campaignMap.columns_count)*100}%`
      };
      dynamicColumnCellStyling = {
        fontSize: columnFontSize,
        gridColumn: `${(1/props.campaignMap.columns_count)*100}%`
      };

      let column_alphabet = [ // up to 40 (for map columns)
          'A','B','C','D','E','F','G','H','I','J',
          'K','L','M','N','O','P','Q','R','S','T',
          'U','V','W','X','Y','Z','AA','AB','AC','AD',
          'AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN',
          // 'AO','AP','AQ','AR','AS','AT','AU','AV','AW','AX',
          // 'AY','AZ','BA','BB','BC','BD','BE','BF','BG','BH',
          // 'BI','BJ','BK','BL','BM','BN','BO','BP','BQ','BR',
          // 'BS','BT','BU','BV','BW','BX','BY','BZ','CA','CB',
          // 'CC','CD','CE','CF','CG','CH','CI','CJ','CK','CL',
          // 'CM','CN','CO','CP','CQ','CR','CS','CT','CU','CV'
      ];
      campaignMapColumnIds = [...Array(props.campaignMap.columns_count)].map((e, i) => {
        return  <div className="map-column-identifier" style={dynamicColumnCellStyling} key={i}>
                  {column_alphabet[i]}
                </div>;
      });
      campaignMapRowIds = [...Array(props.campaignMap.rows_count)].map((e, i) => {
        return  <div className="map-row-identifier" style={dynamicRowCellStyling} key={i}>
                  {i+1}
                </div>;
      });
      campaignMapCoordinates = props.campaignMap.coordinates.map((coordinate, index) => {
        return  <CampaignMapCoordinate
                  key={index}
                  currentUser={props.currentUser}
                  handleCoordinateClick={props.handleCoordinateClick}
                  handleCoordinateRightClick={props.handleCoordinateRightClick}
                  handleExpandedIconClick={props.handleExpandedIconClick}
                  handleExpandedIconRightClick={props.handleExpandedIconRightClick}
                  handleExpandedIconHover={props.handleExpandedIconHover}
                  handleExpandedIconLeaveHover={props.handleExpandedIconLeaveHover}
                  handleHover={props.handleHover}
                  handleLeaveHover={props.handleLeaveHover}
                  handleStopFill={props.handleStopFill}
                  width={props.campaignMap.columns_count}
                  height={props.campaignMap.rows_count}
                  coordinate={coordinate}
                  boardSelectedMarker={props.boardSelectedMarker}
                  hoveredMarker={props.hoveredMarker}
                  isMultiMarkerMenuPoppedUp={props.isMultiMarkerMenuPoppedUp}
                />
      });
    }

    let rendering;
    if (props.loading !== false) return <Loader/>;
    let loader;
    if (props.loading) {
      loader = <i className="fa fa-cog fa-spin" />;
      dynamicMapStyling['display'] = 'flex';
      dynamicMapStyling['justifyContent'] = 'center';
      dynamicMapStyling['alignItems'] = 'center';
    }

    let campaignMapInitiative =
      <div className="campaign-map-initiative">
        Initiative-Rolling Section Coming Soon!
      </div>


    if (false) {
      rendering = <div>Coming soon!</div>;
    } else {
      rendering = <div className="campaign-map-inner">
                    {newMapPopUp}
                    {newMarkerPopUp}
                    <div className="campaign-map-and-initiative">
                      <div className="campaign-map-coordinates-outer-wrapper">
                        <div className="campaign-map-coordinates campaign-map-coordinates-columns-ids" style={dynamicColumnStyling}>
                          {campaignMapColumnIds}
                        </div>
                        <div className="campaign-map-coordinates-inner-wrapper" style={dynamicMapAndRowStyling}>
                          <div className="campaign-map-coordinates campaign-map-coordinates-rows-ids" style={dynamicRowStyling}>
                            {campaignMapRowIds}
                          </div>
                          <div className="campaign-map-coordinates" style={dynamicMapStyling}>
                            {loader}
                            {campaignMapCoordinates}
                          </div>
                        </div>
                      </div>
                      {campaignMapInitiative}
                    </div>
                    <div className="campaign-map-controls">
                      <CampaignMapControls
                        currentUser={props.currentUser}
                        selectHexColor={props.selectHexColor}
                        selectMapMarker={props.selectMapMarker}
                        selectMapIndicator={props.selectMapIndicator}
                        requestNewMap={props.requestNewMap}
                        createNewMarkerClick={props.createNewMarkerClick}
                        handleControlMarkerRightClick={props.handleControlMarkerRightClick}
                        controlsSelectedMarker={props.controlsSelectedMarker}
                        controlsSelectedIndicator={props.controlsSelectedIndicator}
                        selectedColor={props.selectedColor}
                        colorHexes={props.colorHexes}
                        fowHexes={props.fowHexes}
                        // markerHexes={props.markerHexes}
                        campaignMap={props.campaignMap}
                        campaign={props.campaign}
                        controlsMarkers={props.controlsMarkers}
                      />
                    </div>
                  </div>;
    // }

    return(
      <div className="campaign-map">
        {rendering}
      </div>
    )
  }
}

export default CampaignMap
